import React, { useState, useCallback, useEffect, useRef } from 'react';
import './ManifestSearch.scss';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../ClickableRegion';
import { Input, InputGroup } from 'rsuite';
import { Loader } from '@mantine/core';
import debounce from 'lodash.debounce';
import Icon from '../Icon';
import hitApi from '../../api';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { DateTime } from 'luxon';
import Loading from '../Loading';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import capitalizeWords from '../../lib/capitalizeWords';

export const ManifestSearch = ({
  condensed,
  showRecent,
  showPopular,
}: {
  condensed?: boolean;
  showRecent?: boolean;
  showPopular?: boolean;
}) => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId(Cookies.get('organizationid'));
  const { pathname } = useLocation();

  const [recentSearches, setRecentSearches] = useState<any[]>([]);
  const [popularSearches] = useState<any[]>([
    'log4j',
    'axios',
    'CVE-2016-4563',
    'async',
    'lodash',
  ]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [valueToSearch, setValueToSearch] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>(null);
  const [clickedOutside, setClickedOutside] = useState(false);

  const clickRef = useRef(null);

  const handleClickOutside = (e: any) => {
    // @ts-ignore - TS doesn't like the contains method on our ref, but it will always be there
    if (!clickRef?.current?.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => {
    try {
      const recentFromLocal =
        JSON.parse(window?.localStorage?.getItem('recent-searches') || '[]') || [];
      if (recentFromLocal.length > 0) {
        // Only add the items that are > 1 char, and only add first five.
        setRecentSearches(
          recentFromLocal.flatMap((r: string) => (r.length > 1 ? [r] : [])).splice(0, 5),
        );
      }
    } catch (err) {
      console.info('Error while fetching recent searches', err);
    }
    setClickedOutside(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    setClickedOutside(true);
  }, [pathname]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((nextValue) => setValueToSearch(nextValue), 200),
    [],
  );

  const handleChange = (nextValue: string) => {
    setSearchValue(nextValue);
    if (!nextValue || nextValue.length >= 2) {
      debouncedSave(nextValue);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && !isSearching && searchValue.length >= 2) {
      submitSearch();
    }
  };

  const submitSearch = async () => {
    setSearchResults(null);
    setIsSearching(true);
    const runSearch = await hitApi.get(
      `/search?search_for=${encodeURIComponent(valueToSearch)}&grouped=true&limit=5`,
    );
    if (runSearch) {
      setSearchResults(runSearch?.data);

      const recent = new Set(recentSearches);
      if (recent.has(valueToSearch)) {
        recent.delete(valueToSearch);
      }

      if (valueToSearch.length > 1) {
        const newRecentValues = [
          `${valueToSearch}`,
          ...(recent.size > 4 ? Array.from(recent).shift() : Array.from(recent)),
        ];

        window.localStorage.setItem(
          'recent-searches',
          JSON.stringify([...newRecentValues]),
        );
        setRecentSearches([...newRecentValues].splice(0, 5));
      }
    }

    setIsSearching(false);
  };

  const clearSearch = () => {
    setSearchResults(null);
    setIsSearching(false);
    setSearchValue('');
  };

  const searchNow = (newValue: string) => {
    let fireSearchNow = false;
    if (newValue === valueToSearch) {
      fireSearchNow = true;
    }
    setSearchValue(newValue);
    setValueToSearch(newValue);

    if (fireSearchNow) submitSearch();
  };

  useEffect(() => {
    if (!isSearching && valueToSearch.length >= 2) {
      submitSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueToSearch]);

  // On mount
  useEffect(() => {
    try {
      const recentFromLocal =
        JSON.parse(window?.localStorage?.getItem('recent-searches') || '[]') || [];
      if (recentFromLocal.length > 0) {
        // Only add the items that are > 1 char, and only add first five.
        setRecentSearches(
          recentFromLocal.flatMap((r: string) => (r.length > 1 ? [r] : [])).splice(0, 5),
        );
      }
    } catch (err) {
      console.info('Error while fetching recent searches', err);
    }
  }, []);

  return (
    <form
      className={`manifest-search ${condensed ? 'condensed' : ''} ${
        !clickedOutside ? 'condensed-visible' : ''
      } ${searchResults ? 'has-results' : ''} ${isSearching ? 'has-results' : ''}`}
      ref={clickRef}
      onClick={handleClickInside}
    >
      <InputGroup className="manifest-search-input anim-slideInDownShort">
        <span id="searchInputLabel" className="visually-hidden">
          {t('search.placeholder')}
        </span>
        <Input
          type="text"
          placeholder={t('search.placeholder')}
          aria-labelledby="searchInputLabel"
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className="manifest-search-input-field"
        />
        <InputGroup.Addon
          className="search-action"
          disabled={isSearching}
          onClick={() => (searchResults ? clearSearch() : submitSearch())}
        >
          {isSearching && <Loader />}
          {!isSearching && !searchResults && <Icon icon="search" iconSharp />}
          {!isSearching && searchResults && <Icon icon="times" iconSharp />}
        </InputGroup.Addon>
      </InputGroup>
      {showRecent && !condensed && recentSearches && recentSearches?.length > 0 && (
        <ul className="recent-searches">
          <li key={uuid()}>
            <span>{t('search.recent')}</span>
          </li>
          {recentSearches.map((r) => (
            <li key={uuid()}>
              <ClickableRegion
                regionLabel={t('search.click-to-search')}
                onClick={() => searchNow(r)}
              >
                <span>{r}</span>
              </ClickableRegion>
            </li>
          ))}
        </ul>
      )}
      {showPopular && !condensed && popularSearches && (
        <ul className="popular-searches">
          <li key={uuid()}>
            <span>{t('search.popular')}</span>
          </li>
          {popularSearches.map((r) => (
            <li key={uuid()}>
              <ClickableRegion
                regionLabel={t('search.click-to-search')}
                onClick={() => searchNow(r)}
              >
                <span>{r}</span>
              </ClickableRegion>
            </li>
          ))}
        </ul>
      )}
      {((condensed && !clickedOutside) || !condensed) && (
        <div className="search-results">
          {searchResults && (
            <>
              <div className="assets">
                <div className="search-head">
                  <h5>{t('search.assets')}</h5>
                  <div>
                    {/* <ClickableRegion regionLabel={t('search.view-all')} href={`/assets`}>
                      {t('search.view-all')}
                    </ClickableRegion> */}
                  </div>
                </div>
                <ul className="search-list">
                  {searchResults &&
                    searchResults?.assets &&
                    searchResults?.assets?.map((s: any) => (
                      <li key={uuid()}>
                        <ClickableRegion
                          className={`result-inner`}
                          regionLabel={t('search.assets-details')}
                          href={`/asset/${s?._id}/${currentOrgId || ''}`}
                        >
                          <>
                            <div className="name">
                              {t('search.asset.name', {
                                name: s?.name || s?.coordinates,
                                version:
                                  s?.version && s?.version !== 'NOASSERTION'
                                    ? `@${s?.version}`
                                    : '',
                              })}
                            </div>
                            <div className="details">
                              {s?.countVulnerabilities?.total
                                ? t(
                                    `search.asset.count-vuln${
                                      s?.countVulnerabilities?.total > 1 ? 's' : ''
                                    }`,
                                    {
                                      count: s?.countVulnerabilities?.total,
                                    },
                                  )
                                : ''}
                              {s?.description?.substring(0, 50) ||
                                s?.supplier?.name ||
                                s?.coordinates}
                            </div>
                            <div className="hover-arrow">
                              <Icon icon="angle-right" />
                            </div>
                          </>
                        </ClickableRegion>
                      </li>
                    ))}
                  {(!searchResults?.assets || searchResults?.assets?.length < 1) && (
                    <div className="no-results">{t('search.assets-none')}</div>
                  )}
                </ul>
              </div>
              <div className="components">
                <div className="search-head">
                  <h5>{t('search.components')}</h5>
                  <div>
                    {/* <ClickableRegion
                      regionLabel={t('search.view-all')}
                      href={`/components`}
                    >
                      {t('search.view-all')}
                    </ClickableRegion> */}
                  </div>
                </div>
                <ul className="search-list">
                  {searchResults &&
                    searchResults?.components &&
                    searchResults?.components?.map((s: any) => (
                      <li key={uuid()}>
                        <ClickableRegion
                          className={`result-inner`}
                          regionLabel={t('search.component-details')}
                          href={`/component/${s?._id}/${currentOrgId || ''}`}
                        >
                          <>
                            <div className="name">
                              {s?.name}
                              {s?.version ? `@${s?.version}` : ''}
                            </div>
                            <div className="details">
                              {s?.supplier?.name && s?.supplier?.name !== 'NOASSERTION'
                                ? `${s?.supplier?.name}: `
                                : ''}
                              {s?.description?.substring(0, 50) || s?.coordinates}
                            </div>
                            <div className="hover-arrow">
                              <Icon icon="angle-right" />
                            </div>
                          </>
                        </ClickableRegion>
                      </li>
                    ))}
                  {(!searchResults?.components ||
                    searchResults?.components?.length < 1) && (
                    <div className="no-results">{t('search.components-none')}</div>
                  )}
                </ul>
              </div>
              <div className="vulnerabilities">
                <div className="search-head">
                  <h5>{t('search.vulnerabilities')}</h5>
                  <div>
                    {/* <ClickableRegion
                      regionLabel={t('search.view-all')}
                      href={`/vulnerabilities`}
                    >
                      {t('search.view-all')}
                    </ClickableRegion> */}
                  </div>
                </div>
                <ul className="search-list">
                  {searchResults &&
                    searchResults?.vulnerabilities &&
                    searchResults?.vulnerabilities?.map((s: any) => (
                      <li key={uuid()}>
                        <ClickableRegion
                          className={`result-inner`}
                          regionLabel={t('search.vulnerabilities-details')}
                          href={`/vulnerability/${s?.fields?.cveId}`}
                        >
                          <>
                            <div className="name">{s?.fields?.cveId}</div>
                            <div className="details">
                              {capitalizeWords(
                                (
                                  s?.fields?.cvss3BaseSeverity ||
                                  s?.fields?.cvss2BaseSeverity
                                )?.toLowerCase(),
                              )}
                              {s?.fields?.cvss3BaseScore || s?.fields?.cvss3BaseScore
                                ? ` (${
                                    s?.fields?.cvss3BaseScore || s?.fields?.cvss3BaseScore
                                  })`
                                : ''}{' '}
                              {t('jargon.severity.label')} -{' '}
                              {s?.fields?.description?.substring(0, 50) ||
                                t('search.published', {
                                  datetime: DateTime.fromISO(
                                    s?.fields?.publishDate,
                                  ).toLocaleString(DateTime.DATETIME_MED),
                                })}
                              ...
                            </div>
                            <div className="hover-arrow">
                              <Icon icon="angle-right" />
                            </div>
                          </>
                        </ClickableRegion>
                      </li>
                    ))}
                  {(!searchResults?.vulnerabilities ||
                    searchResults?.vulnerabilities?.length < 1) && (
                    <div className="no-results">{t('search.vulnerabilities-none')}</div>
                  )}
                </ul>
              </div>
            </>
          )}

          {!searchResults &&
            !clickedOutside &&
            !isSearching &&
            condensed &&
            showRecent &&
            recentSearches?.length > 0 && (
              <ul className="recent-searches">
                <li key={uuid()}>
                  <span>{t('search.recent')}</span>
                </li>
                {recentSearches.map((r) => (
                  <li key={uuid()}>
                    <ClickableRegion
                      regionLabel="Click to search"
                      onClick={() => searchNow(r)}
                    >
                      <span>{r}</span>
                    </ClickableRegion>
                  </li>
                ))}
              </ul>
            )}

          {!searchResults &&
            !clickedOutside &&
            !isSearching &&
            condensed &&
            showPopular && (
              <ul className="recent-searches">
                <li key={uuid()}>
                  <span>Popular:</span>
                </li>
                {popularSearches.map((r) => (
                  <li key={uuid()}>
                    <ClickableRegion
                      regionLabel="Click to search"
                      onClick={() => searchNow(r)}
                    >
                      <span>{r}</span>
                    </ClickableRegion>
                  </li>
                ))}
              </ul>
            )}

          {isSearching && <Loading />}
        </div>
      )}
    </form>
  );
};

export default ManifestSearch;
