import { UseFormReturnType } from '@mantine/form';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SbomAlertRiskCriteriasFormValues } from '../../../hooks/useSbomAlertsForms';
import { RiskCriteriaFilterId } from '../../../utils/getSbomAlertsConfig';

const getFormattedVulnRecommendations = (values: string[], t: TFunction): string => {
  if (values.length === 1) {
    return t(
      `page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.${values[0]}`,
    );
  }
  if (values.length > 1) {
    const lastElement = values[values.length - 1];
    const restElements = values.slice(0, -1);
    return `${restElements
      .map((value) =>
        t(`page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.${value}`),
      )
      .join(
        `, `,
      )} ${t('global.and')} ${t(`page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.${lastElement}`)}`;
  }

  return '';
};

const RiskCriteriasDetail = ({
  form,
}: {
  form: UseFormReturnType<SbomAlertRiskCriteriasFormValues>;
}) => {
  const { t } = useTranslation();
  const appliedRiskCriterias = Object.values(form.values.riskCriteriaSelectedFilters);
  const isAppliedCriteria = (criteriaToFind: RiskCriteriaFilterId) =>
    appliedRiskCriterias.some((criteria) => criteria === criteriaToFind);
  const isAppliedOnCISAKEVList = isAppliedCriteria('onCISAKEVList');
  const isAppliedCvssScore = isAppliedCriteria('cvssScore');
  const isAppliedLicenseIssues = isAppliedCriteria('licenseIssues');
  const isAppliedMinRiskScore = isAppliedCriteria('minRiskScore');
  const isAppliedVulnRecommendationst = isAppliedCriteria('vulnRecommendations');
  const { values } = form;

  return (
    <ul>
      {isAppliedCvssScore && (
        <li>
          {t('page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.viewString')}{' '}
          {values.cvssScore}
        </li>
      )}
      {isAppliedVulnRecommendationst && (
        <li>
          {t(
            'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.viewString',
          )}{' '}
          {getFormattedVulnRecommendations(values.vulnRecommendations, t)}
        </li>
      )}
      {isAppliedLicenseIssues && (
        <li>
          {t('page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.viewString')}{' '}
          "
          {values.licenseIssues
            .map(
              (value) =>
                `${t(`page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.${value.toLowerCase()}`)}`,
            )
            .join(`, `)
            .toLowerCase()}
          "
        </li>
      )}
      {isAppliedOnCISAKEVList && (
        <li>
          {t('page.userSettings.sbomUploadAlerts.riskCriteria.onCISAKEVList.viewString')}
        </li>
      )}
      {isAppliedMinRiskScore && (
        <li>
          {`${t('page.userSettings.sbomUploadAlerts.riskCriteria.minRiskScore.viewString')} ${t(`global.${values.minRiskScore}`)}`}
        </li>
      )}
    </ul>
  );
};

export default RiskCriteriasDetail;
