import { FileType } from 'rsuite/esm/Uploader';
import { create } from 'zustand';
import { SBOMSelectionData } from '../../pages/Sboms/models/SBOMSelectionData.model';
import { UploadTabStatus } from '../../types/FullInterfaces';

//A store for the SBOMs page
interface SbomsStore {
  activeTab: UploadTabStatus;
  cantMergeDownloadReason: string | null;
  cantMergeShareReason: string | null;
  deleteModalOpen: boolean;
  errors: string[];
  fetchLimit: number;
  fetchPage: number;
  isMergeDownloadModalOpen: boolean;
  isMergeModalOpen: boolean;
  isShareTriggeredByMerge: boolean;
  mergeModalOpen: boolean;
  sbomIdsToDelete: string[];
  selectedSBOMsData: SBOMSelectionData[];
  shareModalOpen: boolean;
  sortColumn: string;
  sortType: 'desc' | 'asc';
  uploadFileList: FileType[];
  actions: {
    setActiveTab: (activeTab: UploadTabStatus) => void;
    setCantMergeDownloadReason: (cantMergeDownloadReason: string | null) => void;
    setCantMergeShareReason: (cantMergeShareReason: string | null) => void;
    setDeleteModalOpen: (deleteModalOpen: boolean) => void;
    setErrors: (errors: string[]) => void;
    setFetchLimit: (fetchLimit: number) => void;
    setFetchPage: (fetchPage: number) => void;
    setIsMergeDownloadModalOpen: (isMergeDownloadModalOpen: boolean) => void;
    setIsMergeModalOpen: (isMergeModalOpen: boolean) => void;
    setIsShareTriggeredByMerge: (isShareTriggeredByMerge: boolean) => void;
    setMergeModalOpen: (mergeModalOpen: boolean) => void;
    setSbomIdsToDelete: (sbomIdsToDelete: string[]) => void;
    setSelectedSBOMsData: (selectedSbom: SBOMSelectionData[]) => void;
    setShareModalOpen: (shareModalOpen: boolean) => void;
    setSortColumn: (sortColumn: string) => void;
    setSortType: (sortType: 'desc' | 'asc') => void;
    setUploadFileList: (uploadFileList: FileType[]) => void;
  };
}

export const useSbomsStore = create<SbomsStore>((set, _get) => ({
  activeTab: 'successful',
  cantMergeDownloadReason: null,
  cantMergeShareReason: null,
  deleteModalOpen: false,
  errors: [],
  fetchLimit: 20,
  fetchPage: 1,
  isMergeDownloadModalOpen: false,
  isMergeModalOpen: false,
  isShareTriggeredByMerge: false,
  mergeModalOpen: false,
  sbomIdsToDelete: [],
  selectedSBOMsData: [],
  shareModalOpen: false,
  sortColumn: 'dateCreated',
  sortType: 'desc',
  uploadFileList: [],
  actions: {
    setActiveTab: (activeTab) => set({ activeTab }),
    setCantMergeDownloadReason: (cantMergeDownloadReason) =>
      set({ cantMergeDownloadReason }),
    setCantMergeShareReason: (cantMergeShareReason) => set({ cantMergeShareReason }),
    setDeleteModalOpen: (deleteModalOpen) => set({ deleteModalOpen }),
    setErrors: (errors) => set({ errors }),
    setFetchLimit: (fetchLimit) => set({ fetchLimit }),
    setFetchPage: (fetchPage) => set({ fetchPage }),
    setIsMergeDownloadModalOpen: (isMergeDownloadModalOpen) =>
      set({ isMergeDownloadModalOpen }),
    setIsMergeModalOpen: (isMergeModalOpen) => set({ isMergeModalOpen }),
    setIsShareTriggeredByMerge: (isShareTriggeredByMerge) =>
      set({ isShareTriggeredByMerge }),
    setMergeModalOpen: (mergeModalOpen) => set({ mergeModalOpen }),
    setSbomIdsToDelete: (sbomIdsToDelete) => set({ sbomIdsToDelete }),
    setSelectedSBOMsData: (selectedSBOMsData) => set({ selectedSBOMsData }),
    setShareModalOpen: (shareModalOpen) => set({ shareModalOpen }),
    setSortColumn: (sortColumn) => set({ sortColumn }),
    setSortType: (sortType) => set({ sortType }),
    setUploadFileList: (uploadFileList) => set({ uploadFileList }),
  },
}));
