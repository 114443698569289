import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceVulnerabilityWithVexInfo } from '../../types/FullInterfaces';

export const useFetchVuln = (vulnerabilityId: string) => {
  const fetchUrl = `vulnerability?cveId=${vulnerabilityId}`;

  return useQuery({
    queryKey: ['vuln', { _id: vulnerabilityId }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data?.[0] as InterfaceVulnerabilityWithVexInfo,
    onError: (error) => {
      console.error('Unable to fetch vulnerability: ', error);
    },
  });
};
