import { UseFormReturnType } from '@mantine/form';

import {
  SbomAlertDeliveryMethodsFormValues,
  SbomAlertRiskCriteriasFormValues,
  SbomAlertSourceFormValues,
} from '../hooks/useSbomAlertsForms';
import { SbomAlert } from '../types/SBOMAlerts.interface';

export const mapFormsToAlertPayload = ({
  notificationEventId,
  riskCriteriasForm,
  sourceForm,
  deliveryMethodsForm,
  isActive,
}: {
  notificationEventId: string;
  sourceForm: UseFormReturnType<SbomAlertSourceFormValues>;
  riskCriteriasForm: UseFormReturnType<SbomAlertRiskCriteriasFormValues>;
  deliveryMethodsForm: UseFormReturnType<SbomAlertDeliveryMethodsFormValues>;
  isActive: boolean;
}): Omit<SbomAlert, '_id'> => {
  const appliedRiskFilters = Object.values(
    riskCriteriasForm.values.riskCriteriaSelectedFilters,
  );
  const labels = sourceForm.values.labels;

  const filters: SbomAlert['filters'] = [
    {
      id: 'uploadedVia',
      type: 'multiSelect',
      section: 'source',
      value: sourceForm.values.uploadedVia,
    },
  ];

  if (labels.length > 0) {
    filters.push({
      id: 'labels',
      type: 'multiSelect',
      section: 'source',
      value: labels,
    });
  }

  if (appliedRiskFilters.includes('cvssScore')) {
    filters.push({
      id: 'cvssScore',
      type: 'number',
      section: 'riskCriteria',
      value: riskCriteriasForm.values.cvssScore,
    });
  }

  if (appliedRiskFilters.includes('licenseIssues')) {
    filters.push({
      id: 'licenseIssues',
      type: 'multiSelect',
      section: 'riskCriteria',
      value: riskCriteriasForm.values.licenseIssues,
    });
  }

  if (appliedRiskFilters.includes('vulnRecommendations')) {
    filters.push({
      id: 'vulnRecommendations',
      type: 'multiSelect',
      section: 'riskCriteria',
      value: riskCriteriasForm.values.vulnRecommendations,
    });
  }

  if (
    appliedRiskFilters.includes('minRiskScore') &&
    riskCriteriasForm.values.minRiskScore
  ) {
    filters.push({
      id: 'minRiskScore',
      type: 'select',
      section: 'riskCriteria',
      value: riskCriteriasForm.values.minRiskScore,
    });
  }

  if (appliedRiskFilters.includes('onCISAKEVList')) {
    filters.push({
      id: 'onCISAKEVList',
      type: 'boolean',
      section: 'riskCriteria',
      value: riskCriteriasForm.values.onCISAKEVList,
    });
  }

  return {
    name: sourceForm.values.name,
    active: isActive,
    filters,
    deliveryMethods: ['email'],
    destinations: [
      {
        type: 'email',
        destinations: deliveryMethodsForm.values.emails,
      },
    ],
    notificationEventId,
    isDefault: false,
  };
};
