import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import ManifestScatterBubble from '../../components/ManifestCharts/ScatterBubble';
import { useFetchBubbleChartData } from '../../hooks/queries/useFetchBubbleChartData';

export const VulnerabilitySummaryChart = () => {
  const { t } = useTranslation();

  const {
    data: {
      vulnsBubbleCriticalData = [],
      vulnsBubbleHighData = [],
      vulnsBubbleMediumData = [],
      vulnsBubbleLowData = [],
    } = {},
    isLoading: isLoadingBubbleData,
  } = useFetchBubbleChartData();

  return (
    <div className="widget analysis">
      <h2 className="widget-header">{t('page.dashboard.analysis.header')}</h2>
      <div className="chart anim-slideInUp">
        {isLoadingBubbleData ? (
          <Loading />
        ) : (
          <>
            {vulnsBubbleCriticalData.length === 0 &&
              vulnsBubbleHighData.length === 0 &&
              vulnsBubbleMediumData.length === 0 &&
              vulnsBubbleLowData.length === 0 && (
                <div className="no-data">
                  <p>{t('page.dashboard.analysis.no-data')}</p>
                </div>
              )}
            <ManifestScatterBubble
              data={[
                ...vulnsBubbleCriticalData,
                ...vulnsBubbleHighData,
                ...vulnsBubbleMediumData,
                ...vulnsBubbleLowData,
              ]}
              dataType="default"
              options={{
                xaxis: {
                  show: true,
                  label: 'CVSS Severity',
                },
                yaxis: {
                  show: true,
                  label: 'EPSS % (Vulnerability)',
                },
                tooltipPadding: 50,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
