const MAX_COMPONENT_NAME_LENGTH = 40;

export const RenderName = (fqn: string) => {
  const truncatedFqn =
    fqn?.length >= MAX_COMPONENT_NAME_LENGTH
      ? `${fqn.substring(0, MAX_COMPONENT_NAME_LENGTH)}...`
      : fqn;

  const split = truncatedFqn.split('/');
  const name = split.length > 0 ? split[split.length - 1] : fqn;

  return <span>{name}</span>;
};

export default RenderName;
