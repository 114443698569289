import { MantineNumberSize, Slider } from '@mantine/core';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import styles from './Slider.module.scss';

interface SliderGradientProps {
  description: string;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  onBlur?: () => void;
  onChange?: (value: number) => void;
  onChangeEnd?: (value: number) => void;
  onFocus?: () => void;
  size?: MantineNumberSize | undefined;
}

export const SliderGradient = forwardRef(
  (
    {
      description,
      min,
      max,
      step,
      value,
      onBlur,
      onChange,
      onFocus,
      onChangeEnd,
      size,
    }: SliderGradientProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [internalValue, setInternalValue] = useState(value);
    const percentageCompleted = value ? ((internalValue || 0) / (max || 0)) * 100 : 0;

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    return (
      <div className={styles.container}>
        <div className={styles.slider}>
          <Slider
            label={description}
            ref={ref}
            classNames={{
              bar: clsx(
                percentageCompleted <= 80 && styles.bar80,
                percentageCompleted > 80 && styles.bar100,
              ),
              thumb: styles.thumb,
            }}
            min={min}
            max={max}
            step={step}
            value={value}
            onBlur={onBlur}
            onChange={(value) => {
              setInternalValue(value);
              onChange?.(value);
            }}
            onChangeEnd={onChangeEnd}
            onFocus={onFocus}
            size={size}
          />
        </div>
        <span className={styles.description}>{description}</span>
      </div>
    );
  },
);
