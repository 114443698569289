import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

export const usePostChildOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (name: string) => {
      return hitApi.post(`organization/child`, true, {
        name,
      });
    },
    onSuccess: () => {
      // Invalidate user.
      queryClient.invalidateQueries(['childOrganizations']);
      queryClient.invalidateQueries(['currentOrganization']);
      queryClient.invalidateQueries(['user']);
    },
  });
};
