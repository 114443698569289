import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DeleteAccesToResourceParams,
  ShareResourceApi,
} from '../../api/shareResource/shareResource.api';
import { SHARED_RESOURCE_USERS_CACHE_KEY } from '../useFetchSharedResourceUsers/useFetchSharedResourceUsers';

export const useDeleteSharedResourceUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: DeleteAccesToResourceParams): Promise<void> =>
      ShareResourceApi.deleteAccesToResource(payload),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [SHARED_RESOURCE_USERS_CACHE_KEY, variables.sbomId],
      });
    },
  });
};
