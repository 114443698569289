import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  InterfaceRiskCategory,
  IRCategoryObject,
  IRCategoryObjectForKev,
} from '@manifest-cyber/types/interface/db';
import { useFetchSLAs } from '../../../hooks/queries/useFetchSLAs.query';
import { usePostSLAs } from '../../../hooks/mutations/usePostSLAs';
import { useDisclosure } from '@mantine/hooks';
import { useNotifications } from '../../../hooks/utils/useNotifications';
import { InterfaceOrganizationSlaSetting } from '@manifest-cyber/types/interface/dbTables';

export const useSlaPage = () => {
  const { t } = useTranslation();

  const {
    data: response,
    isLoading: isLoadingQuery,
    isFetching,
    isError,
  } = useFetchSLAs({});
  const { mutate, isLoading: isLoadingMutation } = usePostSLAs();

  const [opened, { open, close }] = useDisclosure(false);
  const { error, info } = useNotifications();

  const [currentRow, setCurrentRow] = useState<
    IRCategoryObject | IRCategoryObjectForKev | undefined | null
  >(undefined);
  const [vulnerabilityRisk, setVulnerabilityRisk] = useState<string>('');
  const vulnerabilityRiskLabels = [
    t('page.sla.onKev'),
    t('page.sla.critical'),
    t('page.sla.high'),
    t('page.sla.medium'),
    t('page.sla.low'),
    t('page.sla.informational'),
  ];
  const [tableData, setTableData] = useState<
    (IRCategoryObject | IRCategoryObjectForKev)[]
  >([]);
  const [apiResponse, setApiResponse] = useState(response);
  const allDisabledNotifiedRef = useRef(false);

  useEffect(() => {
    setApiResponse(response);
    if (response?.data?.riskCategory) {
      const initialData = Object.keys(response.data.riskCategory).map((key) => {
        const item =
          response.data.riskCategory?.[key as keyof InterfaceRiskCategory] || {};
        return {
          status: item.status ?? 'disabled',
          vulnerabilityRisk: key,
          description: item.description,
          criteria: item.criteria ?? (key === 'onKev' ? false : []),
          remediationTimeFrame: item.remediationTimeFrame,
        };
      });
      setTableData(initialData);
    }
  }, [response]);

  const activeSlasCount = useMemo(() => {
    return tableData.filter((item) => item.status === 'enabled').length;
  }, [tableData]);

  const handleSwitchToggle = useCallback(
    (index: number, newValue: boolean) => {
      const updatedRiskCategory = { ...apiResponse?.data?.riskCategory };
      const vulnerabilityRiskKeys = Object.keys(updatedRiskCategory);
      const key = vulnerabilityRiskKeys[index];

      if (key) {
        // @ts-ignore
        // TODO: needs to better elaborate types here
        updatedRiskCategory[key].status = newValue ? 'enabled' : 'disabled';

        const rootStatus = Object.values(updatedRiskCategory).some(
          (item) => item.status === 'enabled',
        )
          ? 'enabled'
          : 'disabled';

        const updatedSlaSetting: InterfaceOrganizationSlaSetting = {
          ...apiResponse?.data,
          riskCategory: updatedRiskCategory,
          status: rootStatus,
        };

        mutate(updatedSlaSetting, {
          onSuccess: (response) => {
            if (!response.errors.length) {
              setTableData((prevData) => {
                const newData = prevData.map((item, idx) =>
                  idx === index
                    ? { ...item, status: newValue ? 'enabled' : 'disabled' }
                    : item,
                ) as (IRCategoryObjectForKev | IRCategoryObject)[];

                const allDisabled = newData.every((item) => item.status === 'disabled');
                if (allDisabled && !allDisabledNotifiedRef.current) {
                  info({
                    title: t('page.sla.allDisabledTitle'),
                    message: t('page.sla.allDisabledDescription'),
                  });
                  allDisabledNotifiedRef.current = true;
                }

                return newData;
              });
            } else {
              error({
                title: t('page.sla.name'),
                message: t('page.sla.requestFail'),
              });
            }
          },
          onError: () => {
            error({
              title: t('page.sla.name'),
              message: t('page.sla.requestFail'),
            });
          },
        });
      }

      allDisabledNotifiedRef.current = false;
    },
    [apiResponse, mutate],
  );

  const handleEditClick = (
    row: IRCategoryObject | IRCategoryObjectForKev,
    risk: string,
  ) => {
    setCurrentRow(row);
    setVulnerabilityRisk(risk);
    open();
  };

  return {
    response,
    isLoadingQuery,
    isFetching,
    isError,
    isLoadingMutation,
    opened,
    close,
    currentRow,
    vulnerabilityRisk,
    tableData,
    activeSlasCount,
    vulnerabilityRiskLabels,
    handleSwitchToggle,
    handleEditClick,
  };
};
