import { useQuery } from '@tanstack/react-query';
import { ShareResourceApi } from '../../api/shareResource/shareResource.api';

export const SHARED_RESOURCE_USERS_CACHE_KEY = 'sharedResourceUsers';

export const useFetchSharedResourceUsers = (
  id: string,
  options: { enabled?: boolean },
) => {
  return useQuery({
    enabled: options.enabled,
    staleTime: 1000 * 60,
    queryKey: [SHARED_RESOURCE_USERS_CACHE_KEY, id],
    queryFn: () => ShareResourceApi.getSharedWithUsers(id),
    keepPreviousData: true,
  });
};
