import { useTranslation } from 'react-i18next';
import { InterfaceVulnerabilityWithVexInfo } from '../../../types/FullInterfaces';
import { useMemo, useState } from 'react';
import ClickableRegion from '../../../components/ClickableRegion';
import { useParams } from 'react-router-dom';

const INITIAL_MAX_LINKS_ALLOWED = 5;

interface ReferenceTabProps {
  aliases: InterfaceVulnerabilityWithVexInfo['aliases'];
  references: InterfaceVulnerabilityWithVexInfo['references'];
}

export const filterReferencesBasedOnUrl = (
  references: InterfaceVulnerabilityWithVexInfo['references'],
) => {
  return references?.filter((reference) => {
    const isValidReference =
      reference?.url?.startsWith('https:') || reference?.url?.startsWith('http:');

    return (
      reference.url && isValidReference && reference.type?.toLowerCase() !== 'advisory'
    );
  });
};

export const ReferenceTab = ({ aliases, references }: ReferenceTabProps) => {
  const { vulnerabilityId } = useParams();
  const { t } = useTranslation();
  const filteredAliases = aliases?.filter((aliasName) => aliasName !== vulnerabilityId);
  const hasAliases = filteredAliases && filteredAliases.length > 0;
  const hasReferences = references && references.length;

  const filteredReferences = filterReferencesBasedOnUrl(references);

  const [trimLinks, setTrimLinks] = useState<boolean>(
    (filteredReferences?.length || 0) > INITIAL_MAX_LINKS_ALLOWED,
  );

  const normalizedReferences = useMemo(() => {
    if (!hasReferences || !filteredReferences) {
      return [];
    }

    if (trimLinks) {
      return filteredReferences.slice(0, INITIAL_MAX_LINKS_ALLOWED);
    }

    return filteredReferences;
  }, [trimLinks, references, hasReferences]);

  return (
    <div className="anim-slideInDownShort reference-tab">
      <h6 className="section-title">
        {t('page.vulnerability.details.main-tabs.reference')}
      </h6>
      {hasAliases && (
        <div className="card">
          <p className="card-title">
            {t('page.vulnerability.details.reference-tab.aliases')}
            <span className="card-title-tag">{filteredAliases?.length}</span>
          </p>
          <ul className="aliases-list">
            {filteredAliases.map((alias) => (
              <li key={alias}>{alias}</li>
            ))}
          </ul>
        </div>
      )}
      {normalizedReferences.length > 0 && (
        <div className="card">
          <p className="card-title">
            {t('page.vulnerability.details.reference-tab.links')}
          </p>
          <ul className="references-list">
            {normalizedReferences.map(({ url }, index) => (
              <li key={index}>
                <ClickableRegion regionLabel="Reference Link" href={url} target="_blank">
                  {url}
                </ClickableRegion>
              </li>
            ))}
          </ul>
          {trimLinks && (
            <button className="trim-links-btn" onClick={() => setTrimLinks(false)}>
              {t('page.vulnerability.details.reference-tab.show-all-links')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
