import { Skeleton } from '@mantine/core';

const SectionDetailSkeleton = ({
  withTitle,
  itemsNumber,
}: {
  withTitle?: boolean;
  itemsNumber: number;
}) => (
  <div>
    {withTitle && (
      <Skeleton height={20} mb={12}>
        {'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
      </Skeleton>
    )}
    <ul>
      {Array.from({ length: itemsNumber }).map((_, index) => (
        <li key={index}>
          <Skeleton height={20} mb={4}>
            {'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
          </Skeleton>
        </li>
      ))}
    </ul>
  </div>
);
export default SectionDetailSkeleton;
