import hitApi from '../../../../../api';
import { rejectFailedRequest } from '../../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { APIResponse } from '../../../../../types/api_response.model';
import { ApiPortalResponse } from '../../../../../types/apiPortalResponse.interface';

const getPortal = async (portalName: string): Promise<ApiPortalResponse['portal']> => {
  const response: APIResponse<ApiPortalResponse> = await hitApi.get(
    `sharing-portal/portal?name=${portalName}`,
  );

  return (await rejectFailedRequest(response)).data.portal;
};

export const SharingPortalReceiverApi = {
  getPortal,
};
