import { UnstyledButton } from '@mantine/core';
import { ReactNode } from 'react';
import styles from './LinkButton.module.scss';

export const LinkButton = ({
  text,
  leftIcon,
  onClick,
}: {
  text: string;
  leftIcon: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <UnstyledButton onClick={onClick} className={styles.linkButton}>
      {leftIcon}
      {text}
    </UnstyledButton>
  );
};
