import { DateTime } from 'luxon';
import capitalizeWords from '../../../../../lib/capitalizeWords';
import {
  MappedSharedAsset,
  SharedSbomsApiResponse,
} from '../../types/sharedSboms.interface';

export interface MapSharedSbomsTexts {
  unknownType: string;
}

export const mapSharedSboms = (
  data: SharedSbomsApiResponse['data'],
  texts?: MapSharedSbomsTexts,
): MappedSharedAsset[] => {
  const maped = data.map((element) => ({
    id: element.sbom._id?.toString() || '',
    name: element.sbom.assetName,
    dateSharedText: DateTime.fromISO(`${element.sharedDate}`).toRelative() || '',
    type: element.sbom.assetType
      ? capitalizeWords(element.sbom.assetType)
      : texts?.unknownType || '',
    version: element.sbom.assetVersion,
  }));

  return maped;
};
