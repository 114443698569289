import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ManifestModal from '../../../../../components/ManifestModal/ManifestModal';
import { useDeleteGitHubIntegrations } from '../../../../../hooks/mutations/useDeleteGitHubIntegrations';

interface Props {
  open: boolean;
  onClose: () => void;
  organizationId: string;
}

export const GitHubUninstallIntegrationConfirmModal = ({
  open,
  onClose,
  organizationId,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    mutateAsync: deleteGitHubIntegrationsMutateAsync,
    isLoading: isRemovingAccess,
  } = useDeleteGitHubIntegrations();

  const handleRemoveAccess = async () => {
    if (organizationId) {
      await deleteGitHubIntegrationsMutateAsync({
        organizationId: organizationId,
      });
      navigate('/settings/integrations');
      onClose();
    }
  };

  return (
    <ManifestModal
      opened={open}
      withCloseButton={true}
      onClose={onClose}
      title={t('page.userSettings.integrations.github.confirm-uninstall')}
      body={t('page.userSettings.integrations.github.uninstall-integration-info')}
      primaryAction={{
        label: t('page.userSettings.integrations.github.uninstall'),
        onClick: () => {
          handleRemoveAccess();
        },
        isLoading: isRemovingAccess,
        color: 'red',
      }}
      secondaryAction={{
        label: t('global.cancel'),
        onClick: onClose,
      }}
    />
  );
};
