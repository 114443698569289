import { AssetType } from '@manifest-cyber/types/interface/db';

export const getTypeIcon = (type: AssetType, fullyQualifiedName: string) => {
  let fontAwesomeIcon = 'browser';

  switch (type) {
    case 'application':
      fontAwesomeIcon = 'desktop';
      break;
    case 'container':
      fontAwesomeIcon = fullyQualifiedName?.toLowerCase()?.includes('docker')
        ? 'docker'
        : 'compact-disc';
      break;
    case 'file':
      fontAwesomeIcon = 'files';
      break;
    case 'library':
      fontAwesomeIcon = 'toolbox';
      break;
    default:
      break;
  }

  return fontAwesomeIcon;
};
