import './dndList.scss';
import cx from 'clsx';
import Icon from '../Icon';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import { Switch } from '@mantine/core';
import { useEffect } from 'react';

interface Props {
  columns: ColumnConfig[];
  onChange: (columnsConfig: ColumnConfig[]) => void;
}
export interface ColumnConfig {
  id: string;
  label: string;
  visible: boolean;
  locked: boolean;
}

function DragAndDropList({ columns, onChange }: Props) {
  const [columnsConfig, handlers] = useListState(columns);
  useEffect(() => {
    onChange(columnsConfig);
  }, [columnsConfig]);
  const columnConfigItems = columnsConfig.map((item, index) => (
    <Draggable key={item.id} index={index} draggableId={item.id}>
      {(provided, snapshot) => {
        return (
          <div
            className={cx('item', { ['item-dragging']: snapshot.isDragging })}
            ref={provided.innerRef}
            {...(!item.locked && provided.draggableProps)}
            {...(!item.locked && provided.dragHandleProps)}
          >
            <div className="item-content">
              <Switch
                checked={item.visible}
                disabled={item.locked}
                onChange={(event) =>
                  handlers.setItem(index, {
                    ...item,
                    visible: event.currentTarget.checked,
                  })
                }
              />
              <span>{item.label}</span>
            </div>
            {!item.locked && (
              <div className="drag-handle">
                <Icon icon="bars" />
              </div>
            )}
          </div>
        );
      }}
    </Draggable>
  ));

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) =>
        handlers.reorder({ from: source.index, to: destination?.index || 0 })
      }
    >
      <StrictModeDroppable droppableId="dnd-list" direction="vertical">
        {(provided) => {
          return (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {columnConfigItems}
              {provided.placeholder}
            </div>
          );
        }}
      </StrictModeDroppable>
    </DragDropContext>
  );
}

export default DragAndDropList;
