import { Flex, Loader, Switch, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/Icon';
import featureFlagDisciminators from '../../../../../configs/featureFlags';
import { useFetchVulnImpactCount } from '../../../../../hooks/queries/useFetchVulnImpactCount';
import useFeatureFlag from '../../../../../hooks/useFeatureFlag';
import { useURLFilterStringState } from '../../../../../hooks/utils/usePersistentStates';
import { InterfaceVulnerabilityWithVexInfo } from '../../../../../types/FullInterfaces';
import styles from '../../../../Component/Component.module.scss';
import { VulnReccomendation } from '../../../components/VulnReccomendation';
import { ImpactTabs } from '../ImpactTabs';
import { VulnerabilityAffectedAssets } from '../VulnerabilityAffectedAssets/VulnerabilityAffectedAssets';
import { VulnerabilityAffectedComponents } from '../VulnerabilityAffectedComponents/VulnerabilityAffectedComponents';
import { VulnerabilityAffectedProducts } from '../VulnerabilityAffectedProducts/VulnerabilityAffectedProducts';

const SELECTED_TAB_PARAM = 'impact_tab';

export enum VULNERABILITY_OVERVIEW_IMPACT_TABS {
  COMPONENTS = 'COMPONENTS',
  ASSETS = 'ASSETS',
  PRODUCTS = 'PRODUCTS',
}

interface OverviewTabProps {
  reccomendedAction: InterfaceVulnerabilityWithVexInfo['recommendedAction'];
  vulnerabilityId: string | undefined;
  setShowSimpleVexModal: (open: boolean) => void;
}

export const OverviewTab = ({
  reccomendedAction,
  vulnerabilityId,
  setShowSimpleVexModal,
}: OverviewTabProps) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useURLFilterStringState(
    SELECTED_TAB_PARAM,
    VULNERABILITY_OVERVIEW_IMPACT_TABS.ASSETS,
    'replaceIn',
  );
  const [isActiveAssetsOnly, setIsActiveAssetsOnly] = useState(true);

  const handleSwitchChange = (): void => {
    setIsActiveAssetsOnly((prev: boolean) => !prev);
  };

  const { data: fetchedImpactCountData, isLoading: isLoadingImpactCountData } =
    useFetchVulnImpactCount(vulnerabilityId!, isActiveAssetsOnly);

  useEffect(() => {
    // If the selected tab from url is invalid
    if (selectedTab in VULNERABILITY_OVERVIEW_IMPACT_TABS) {
      return;
    }

    setSelectedTab(VULNERABILITY_OVERVIEW_IMPACT_TABS.ASSETS);
  }, []);

  const showLoader = isLoadingImpactCountData;

  return (
    <div className="anim-slideInDownShort overview-tab">
      <VulnReccomendation reccomendedAction={reccomendedAction} />
      <Flex justify={'space-between'} align={'center'}>
        <h6 className="section-title">
          {t('page.vulnerability.details.overview-tab.impact')}
        </h6>
        <Switch
          checked={isActiveAssetsOnly}
          onChange={handleSwitchChange}
          label={
            <span>
              {t('page.vulnerability.details.overview-tab.activeAssetsOnly')}{' '}
              <Tooltip
                classNames={{
                  tooltip: styles.tooltip,
                }}
                label={t(
                  'page.vulnerability.details.overview-tab.activeAssetsOnlyTooltip',
                )}
                position="top"
                withArrow
              >
                <span>
                  <Icon icon="info-circle" />
                </span>
              </Tooltip>
            </span>
          }
        />
      </Flex>

      <Flex align="center" justify="center" className="card" direction="column">
        {showLoader && (
          <Loader className="impact-section-loader" data-testid="impact-section-loader" />
        )}
        {!showLoader && (
          <ImpactTabs
            assetsCount={fetchedImpactCountData?.assetsCount}
            componentsCount={fetchedImpactCountData?.componentsCount}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            productsCount={fetchedImpactCountData?.productsCount}
          />
        )}
        <div className="impact-tab-content-container impact-tables">
          {selectedTab === VULNERABILITY_OVERVIEW_IMPACT_TABS.COMPONENTS && (
            <VulnerabilityAffectedComponents
              vulnId={vulnerabilityId!}
              tabsLoaded={!showLoader}
              isActiveComponentsOnly={isActiveAssetsOnly}
            />
          )}
          {selectedTab === VULNERABILITY_OVERVIEW_IMPACT_TABS.ASSETS && (
            <VulnerabilityAffectedAssets
              vulnId={vulnerabilityId!}
              setShowSimpleVexModal={setShowSimpleVexModal}
              tabsLoaded={!showLoader}
              isActiveAssetsOnly={isActiveAssetsOnly}
            />
          )}
          {selectedTab === VULNERABILITY_OVERVIEW_IMPACT_TABS.PRODUCTS && (
            <VulnerabilityAffectedProducts vulnId={vulnerabilityId!} />
          )}
        </div>
      </Flex>
    </div>
  );
};
