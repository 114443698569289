import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/Icon';
import { SbomUploadAlertSectionState } from '../../../../../types/FullInterfaces';
import { SbomAlertDeliveryMethodsFormValues } from '../../hooks/useSbomAlertsForms';
import AlertFormSection from '../AlertSection/AlertSection';
import EmailInput from '../EmailInput/EmailInput';
import SectionDetailSkeleton from '../SectionDetailSkeleton/SectionDetailSkeleton';
import DeliveryMethodDetail from './DeliveryMethodDetail/DeliveryMethodDetail';
import styles from './DeliveryMethodSection.module.scss';

interface DeliveryMethodProps {
  state: SbomUploadAlertSectionState;
  form: UseFormReturnType<SbomAlertDeliveryMethodsFormValues>;
  isLoading?: boolean;
  editButton?: {
    onClick?: () => void;
    isVisible: boolean;
  };
  nextButton?: {
    onClick?: () => void;
    isDisabled?: boolean;
    isVisible: boolean;
    isLoading?: boolean;
  };
}

function DeliveryMethod({
  state,
  editButton,
  nextButton,
  form,
  isLoading = false,
}: Readonly<DeliveryMethodProps>) {
  const { t } = useTranslation();

  return (
    <AlertFormSection
      titleIconName="paper-plane"
      title={t('page.userSettings.sbomUploadAlerts.deliveryMethod.title')}
      description={t('page.userSettings.sbomUploadAlerts.deliveryMethod.description')}
      state={state}
      nextButton={{
        isVisible: Boolean(nextButton?.isVisible),
        isDisabled: nextButton?.isDisabled,
        onClick: nextButton?.onClick,
        label: t('page.userSettings.sbomUploadAlerts.createAlertLabel'),
        isLoading: nextButton?.isLoading,
      }}
      editButton={{
        isVisible: Boolean(editButton?.isVisible),
        onClick: editButton?.onClick,
        label: t('global.edit'),
      }}
      ViewComponent={
        isLoading ? (
          <SectionDetailSkeleton itemsNumber={1} />
        ) : (
          <DeliveryMethodDetail
            text={t('page.userSettings.sbomUploadAlerts.deliveryMethod.viewString', {
              emails: form.values.emails.join(', '),
            })}
          />
        )
      }
      EditComponent={
        <div className={styles.field}>
          <Icon icon="envelope" size="lg" />
          <div className={styles.fieldControl}>
            <EmailInput
              id="delivery-method-emails"
              labelPosition="left"
              showRequiredMark
              label={t('page.userSettings.sbomUploadAlerts.deliveryMethod.emailTo')}
              onChange={(_emails) => {
                form.getInputProps('emails').onChange(_emails);
              }}
              error={form.errors.emails}
              onFocus={() => {
                form.getInputProps('emails').onFocus();
              }}
              onBlur={() => {
                form.getInputProps('emails').onBlur();
              }}
              value={form.values.emails}
            />
          </div>
        </div>
      }
    />
  );
}

export default DeliveryMethod;
