import styles from '../AssetsTable.module.scss';
import { Flex, useMantineTheme } from '@mantine/core';
import { MRT_Cell, MRT_Row } from 'mantine-react-table';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';

export const RiskScoreColumn = ({
  cell,
  row,
}: {
  cell: MRT_Cell<InterfaceOrganizationAsset>;
  row: MRT_Row<InterfaceOrganizationAsset>;
}) => {
  const riskScoreMap = {
    high: 'High',
    medium: 'Med',
    low: 'Low',
    '': 'N/A',
  };
  const theme = useMantineTheme();
  const riskScore = cell.getValue<'high' | 'medium' | 'low' | null>();
  return (
    <span
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      <Flex
        sx={{
          borderRadius: '4px',
          color: riskScore === 'medium' ? theme.black : theme.white,
          width: '3rem',
          padding: '4px',
          justifyContent: 'center',
          backgroundColor:
            riskScore === 'high'
              ? theme.colors.red[5]
              : riskScore === 'medium'
                ? theme.colors.yellow[5]
                : riskScore === 'low'
                  ? theme.colors.dark[6]
                  : theme.colors.gray[5],
        }}
      >
        {riskScoreMap[riskScore || '']}
      </Flex>
    </span>
  );
};
