import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

interface Params {
  memberId: string;
}

export const useDeleteOrganizationUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ memberId }: Params) => {
      // FYI Target with the memberId, not the userId
      return hitApi.delete(`user/member/${memberId}`, true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organizationUsers']);
      queryClient.invalidateQueries(['currentOrganization']);
    },
  });
};
