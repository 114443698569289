import '../../scss/pages/licenses.scss';
import { useMemo } from 'react';
import { Flex, Tabs, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { LicensesTable } from '../../components/DataTables/LicensesTable';
import { UnmatchedLicensesTable } from '../../components/DataTables/UnmatchedLicensesTable';

export const Licenses = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const hasAdminEditPermissions = useMemo(
    () =>
      !!user?.internalRoles?.length &&
      (user?.internalRoles?.includes('staff') || user?.internalRoles?.includes('admin')),
    [user],
  );

  return (
    <div className="page-licenses anim-slideInDownShort">
      <Flex justify="space-between">
        <Title order={2}>{t('page.userSettings.licenses.header')}</Title>
      </Flex>
      {hasAdminEditPermissions ? (
        <Tabs defaultValue="licenses">
          <Tabs.List>
            <Tabs.Tab value="licenses">
              {t('page.userSettings.licenses.licenses')}
            </Tabs.Tab>
            <Tabs.Tab value="unmatched">
              {t('page.userSettings.licenses.unmatched-licenses')}
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="licenses">
            <LicensesTable hasAdminEditPermissions />
          </Tabs.Panel>
          <Tabs.Panel value="unmatched">
            <UnmatchedLicensesTable />
          </Tabs.Panel>
        </Tabs>
      ) : (
        <LicensesTable hasAdminEditPermissions />
      )}
    </div>
  );
};

export default Licenses;
