import { InterfaceLicense } from '@manifest-cyber/types/interface/dbTables';
import {
  Modal,
  Button,
  Stack,
  TextInput,
  Autocomplete,
  Flex,
  Textarea,
  Switch,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
  onConfirm: (license: InterfaceLicense) => Promise<void>;
  opened: boolean;
  validationErrors: Partial<Record<keyof InterfaceLicense, string>>;
  isCreating: boolean;
}

export const CreateLicenseModal = ({
  onCancel,
  onConfirm,
  opened,
  validationErrors,
  isCreating,
}: Props) => {
  const { t } = useTranslation();

  const form = useForm<Partial<InterfaceLicense>>({
    initialValues: {
      fullName: '',
      shortName: '',
      aliases: [],
      licenseUrl: '',
      alertWhenFound: false,
      licenseType: '',
      description: '',
    },
    validate: {
      fullName: (value) => (value ? null : 'Invalid email'),
      shortName: (value) => (value ? null : 'Invalid email'),
    },
  });

  return (
    <Modal
      opened={opened}
      onClose={onCancel}
      title={t('page.userSettings.licenses.add-license')}
    >
      <form
        onSubmit={form.onSubmit(async (newLicenseValues) => {
          await onConfirm(newLicenseValues);
          form.reset();
        })}
      >
        <Stack>
          <TextInput
            label={t('page.userSettings.licenses.table.headers.fullName')}
            required
            error={validationErrors.fullName}
            {...form.getInputProps('fullName')}
          />
          <TextInput
            label={t('page.userSettings.licenses.table.headers.shortName')}
            required
            error={validationErrors.shortName}
            {...form.getInputProps('shortName')}
          />
          <TextInput
            label={t('page.userSettings.licenses.table.headers.licenseUrl')}
            error={validationErrors.licenseUrl}
            {...form.getInputProps('licenseUrl')}
          />
          <Autocomplete
            data={['Copyleft', 'Custom', 'Permissive', 'Proprietary']}
            label={t('page.userSettings.licenses.table.headers.licenseType')}
            error={validationErrors.licenseType}
            {...form.getInputProps('licenseType')}
          />
          <Textarea
            label={t('page.userSettings.licenses.table.headers.description')}
            error={validationErrors.description}
            {...form.getInputProps('description')}
          />
          <Switch
            label={t('page.userSettings.licenses.table.headers.alertWhenFound')}
            {...form.getInputProps('alertWhenFound')}
          />
          <Flex justify="flex-end" gap="md">
            <Button type="button" onClick={onCancel} color="dark">
              {t('global.cancel')}
            </Button>
            <Button loading={isCreating} type="submit">
              {t('global.confirm')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};
