import './BasicSearch.scss';
import { useState, useEffect } from 'react';
import { InputGroup, Input } from 'rsuite';
import Icon from '../../components/Icon';
import { useTranslation } from 'react-i18next';

interface IBasicSearchProps {
  handleSearch: (searchQuery: string) => void;
  placeholder?: string;
  minCharCount?: number;
  disabled?: boolean;
  initialValue?: string;
}
/**
 * Basic search component that calls a search callback function when at least n characters are typed in.
 * @param handleSearch Callback function that the component will call to actuallty do the search
 * @param placeholder Optional prop for custom placeholder text
 * @param minCharCount Optional prop that sets when the search will be called. Defaults to min 3 characters
 * @param initialValue Optional prop that sets the inputs initial value
 * @returns
 */
function BasicSearch({
  handleSearch,
  placeholder = '',
  minCharCount = 3,
  disabled,
  initialValue,
}: IBasicSearchProps) {
  const { t } = useTranslation();
  const [searchInputValue, setSearchInputvalue] = useState(initialValue || '');

  useEffect(() => {
    if (searchInputValue && searchInputValue.length > minCharCount) {
      handleSearch(searchInputValue);
    }
  }, [searchInputValue]);

  const handleClear = () => {
    setSearchInputvalue('');
    doSearch('');
  };

  const handleSearchChange = (value: string) => {
    setSearchInputvalue(value);
    doSearch(value);
  };
  const handleSearchKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      doSearch(searchInputValue);
    }
  };
  const doSearch = (value: string) => {
    if (value.length >= minCharCount) {
      return handleSearch(value);
    } else {
      handleSearch('');
    }
  };

  return (
    <form>
      <InputGroup className="search-input" inside aria-label={t('search.placeholder')}>
        {!searchInputValue ? (
          <InputGroup.Button className="btn-search" type="button">
            <Icon icon="search" iconSharp aria-label={t('global.search-icon')} />
          </InputGroup.Button>
        ) : (
          <InputGroup.Button className="btn-search" onClick={handleClear} type="button">
            <Icon icon="times" iconSharp aria-label={t('global.search-icon')} />
          </InputGroup.Button>
        )}

        <Input
          type="text"
          className="search-input"
          placeholder={placeholder || t('search.placeholder')}
          value={searchInputValue}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          disabled={disabled}
          aria-label={t('search.placeholder')}
        />
      </InputGroup>
    </form>
  );
}

export default BasicSearch;
