import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mantine/core';
import Icon from '../../../Icon';

export const SupplierHeader = () => {
  const { t } = useTranslation();

  return (
    <span>
      {t('tables.components.headers.supplier')}
      <Tooltip label={t('tables.components.headers.source-tooltip')}>
        <span style={{ marginLeft: '5px' }}>
          <Icon icon="question-circle" style={{ position: 'relative', top: '1px' }} />
        </span>
      </Tooltip>
    </span>
  );
};
