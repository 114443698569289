import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import Icon from '../../../../../components/Icon';
import styles from './DownloadCell.module.scss';

export const DownloadCell = ({
  onClick,
  label,
}: {
  onClick: () => void;
  label: string;
}) => {
  return (
    <Flex justify="center" align={'center'} h="100%" w="100%">
      <ActionIcon onClick={onClick} aria-label={label}>
        <Tooltip label={label}>
          <span className={styles.downloadIcon}>
            <Icon icon="arrow-down-to-bracket" />
          </span>
        </Tooltip>
      </ActionIcon>
    </Flex>
  );
};
