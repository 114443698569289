import { useTranslation } from 'react-i18next';
import { InterfaceVulnerabilityWithVexInfo } from '../../../types/FullInterfaces';
import { Flex } from '@mantine/core';
import { FC, PropsWithChildren } from 'react';
import Icon from '../../../components/Icon';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';

interface VulnReccomendationProps {
  reccomendedAction: InterfaceVulnerabilityWithVexInfo['recommendedAction'];
}

const VulnReccomendationWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Flex p="24px" gap="32px" className="vuln-recommendation-container card">
      <p className="title">{t('global.recommendation')}</p>
      <div>{children}</div>
    </Flex>
  );
};

const showedReccomendations: Record<string, { icon: IconName }> = {
  mitigate: {
    icon: 'hexagon-exclamation',
  },
  monitor: {
    icon: 'eye',
  },
  accept: {
    icon: 'circle-check',
  },
};

export const VulnReccomendation = ({ reccomendedAction }: VulnReccomendationProps) => {
  const { t } = useTranslation();

  const reccomendationMeta =
    reccomendedAction &&
    reccomendedAction in showedReccomendations &&
    showedReccomendations[reccomendedAction];

  return (
    <VulnReccomendationWrapper>
      {reccomendationMeta ? (
        <Flex gap="8px" direction="column">
          <Flex
            align="center"
            gap="8px"
            className={classNames(`manifest-tag-${reccomendedAction}`)}
          >
            <Icon icon={reccomendationMeta.icon} />
            <h6 className="action">
              {t(
                `page.vulnerability.details.recommendation-block.${reccomendedAction}.title`,
              )}
            </h6>
          </Flex>
          <p>
            {t(
              `page.vulnerability.details.recommendation-block.${reccomendedAction}.desc`,
            )}
          </p>
        </Flex>
      ) : (
        <Flex gap="8px" direction="column">
          <h6 className="no-action-text">
            {t(`page.vulnerability.details.recommendation-block.not-present.title`)}
          </h6>
          <p>{t(`page.vulnerability.details.recommendation-block.not-present.desc`)}</p>
        </Flex>
      )}
    </VulnReccomendationWrapper>
  );
};
