import { InterfaceOrganizationIntegration } from '@manifest-cyber/types/interface/dbTables';
import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  TextInput,
  Textarea,
} from '@mantine/core';
import { hasLength, useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'rsuite';
import hitApi from '../../api';
import {
  CreateTicketFormValues,
  usePostTicket,
} from '../../hooks/mutations/usePostTicket';
import { useAuth } from '../../hooks/useAuth';
import Icon from '../Icon';
import './ticket.scss';

const JIRA_LOGO_SRC = '/jira_logo.png';

interface SelectData {
  value: string;
  label: string;
}
interface Props {
  open: boolean;
  entityType: 'asset' | 'vulnerability';
  entityId: string;
  onSuccess: (
    integration: NonNullable<InterfaceOrganizationIntegration['integrationType']>,
    ticketData: any,
  ) => void;
  onError: (integration: Parameters<this['onSuccess']>[0]) => void;
  onCancel: () => void;
}
function CreateJiraTicket({
  open,
  entityType,
  entityId,
  onSuccess,
  onError,
  onCancel,
}: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [projects, setProjects] = useState<SelectData[]>([]);
  const [creators, setCreators] = useState<SelectData[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [issueTypes, setIssueTypes] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [assignees, setAssignees] = useState<SelectData[]>([]);
  // const [priorities, setPriorities] = useState<string[]>([])
  const [labels, setLabels] = useState<string[]>([]);

  const auth = useAuth();

  const user = auth?.user;

  const { mutateAsync: postTicket, isLoading: isCreatingTicket } = usePostTicket();

  const form = useForm({
    initialValues: {
      project: '',
      creator: '',
      issueType: '',
      status: '',
      summary: '',
      description: '',
      assignee: [],
      // priority: '',
      labels: [],
    },
    validate: {
      summary: hasLength(
        { max: 255 },
        t('tickets.createJiraTicketModal.form.validationErrors.summary'),
      ),
      description: hasLength(
        { max: 32767 },
        t('tickets.createJiraTicketModal.form.validationErrors.description'),
      ),
    },
  });
  useEffect(() => {
    getTicketMetadata();
  }, []);

  useEffect(() => {
    trySetCreator();
  }, [users]);

  useEffect(() => {
    const { project, creator, issueType, summary } = form.values;
    if (!project || !issueType || !summary || !creator) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [form.values]);

  const handleCreateTicket = async () => {
    try {
      setIsLoading(true);
      const submitValues: CreateTicketFormValues = {
        collectionId: form.values.project,
        projectId: form.values.project,
        issueType: form.values.issueType,
        status: form.values.status,
        summary: form.values.summary,
        description: form.values.description,
        assigneeIds: form.values.assignee,
        labels: form.values.labels.map((label: string) =>
          label.trim().replaceAll(' ', '-'),
        ),
        creatorId: form.values.creator,
        type: entityType,
        id: entityId,
        integration: 'jira',
      };

      const { success, data } = await postTicket({ ticket: submitValues });
      if (!success) {
        throw new Error('error while trying to create jira ticket');
      }
      return onSuccess('jira', data);
    } catch (e) {
      return onError('jira');
    } finally {
      setIsLoading(false);
      form.reset();
    }
  };
  const convertToSelectData = (data: any[]) => {
    return data.map((item: any) => ({ value: item.id, label: item.name || '' }));
  };

  const trySetCreator = () => {
    if (!user) return null;
    const [email] = user?.decryptedEmails;
    const currentUser = users.find((user: any) => user.email === email);
    if (currentUser) {
      form.setValues({ creator: currentUser.id });
      return currentUser.id;
    }
    return null;
  };

  const getTicketMetadata = async () => {
    try {
      setIsLoading(true);
      const { success, data } = await hitApi.get(
        '/integration/merge/ticket-metadata?integration=jira',
      );
      if (!success) {
        throw new Error('error while trying to fetch jira form options');
      }
      const { ticketTypes: issueTypes, statuses, users, tags: labels } = data;
      const projects = convertToSelectData(data.projects || data.collections);
      const creators = convertToSelectData(users);
      const assignees = convertToSelectData(users);

      setProjects(projects);
      setCreators(creators);

      const issueTypeDefaultValue = issueTypes.find(
        (type: string) => type.toLowerCase() === 'task',
      );

      if (issueTypeDefaultValue) {
        form.setFieldValue('issueType', issueTypeDefaultValue);
      }
      setIssueTypes(issueTypes);
      setStatuses(statuses);
      setAssignees(assignees);
      // setPriorities(priorities)
      setLabels(labels);
      setUsers(users);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    if (isCreatingTicket) {
      return;
    }

    onCancel();
  };

  return (
    <Modal open={open} onClose={handleCloseModal} className="create-ticket-modal">
      <LoadingOverlay data-testid="Loading..." visible={isLoading} overlayBlur={8} />
      <Modal.Header>
        <Modal.Title>
          <div className="header">
            <div className="logo">
              <img src={JIRA_LOGO_SRC} alt="Jira Logo" />
            </div>
            {t(`tickets.createJiraTicketModal.header`)}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box mx="auto">
          <form id="create-jiraticket" onSubmit={form.onSubmit(handleCreateTicket)}>
            <Select
              mb={'lg'}
              label={t(`tickets.createJiraTicketModal.form.project`)}
              data={projects}
              searchable
              required
              nothingFound={t(`tickets.createJiraTicketModal.form.noResults`)}
              {...form.getInputProps('project')}
            />
            <Select
              mb={'lg'}
              label={t(`tickets.createJiraTicketModal.form.creator`)}
              data={creators}
              searchable
              required
              nothingFound={t(`tickets.createJiraTicketModal.form.noResults`)}
              {...form.getInputProps('creator')}
            />
            <Group grow mb={'lg'} spacing={40}>
              {/* <TextInput
                label={t(`tickets.createJiraTicketModal.form.issueType`)}
                required
                {...form.getInputProps('issueType')}
              /> */}
              <Select
                label={t(`tickets.createJiraTicketModal.form.issueType`)}
                data={issueTypes}
                searchable
                required
                nothingFound={t(`tickets.createJiraTicketModal.form.noResults`)}
                {...form.getInputProps('issueType')}
              />
              <Select
                label={t(`tickets.createJiraTicketModal.form.status`)}
                data={statuses}
                searchable
                nothingFound={t(`tickets.createJiraTicketModal.form.noResults`)}
                {...form.getInputProps('status')}
              />
            </Group>
            <TextInput
              mb={'lg'}
              label={t(`tickets.createJiraTicketModal.form.summary`)}
              required
              {...form.getInputProps('summary')}
            />
            <Textarea
              mb={'lg'}
              label={t(`tickets.createJiraTicketModal.form.description`)}
              {...form.getInputProps('description')}
            />
            <MultiSelect
              mb={'lg'}
              label={t(`tickets.createJiraTicketModal.form.assignee`)}
              data={assignees}
              searchable
              nothingFound={t(`tickets.createJiraTicketModal.form.noResults`)}
              rightSection={<Icon icon="chevron-down" />}
              {...form.getInputProps('assignee')}
            />
            <MultiSelect
              mb={'lg'}
              label={t(`tickets.createJiraTicketModal.form.labels`)}
              data={labels}
              searchable
              creatable
              nothingFound={t(`tickets.createJiraTicketModal.form.noResults`)}
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(query) => {
                const item = query.trim();
                setLabels((current) => [...current, item]);
                return item;
              }}
              {...form.getInputProps('labels')}
            />
          </form>
        </Box>
      </Modal.Body>
      <Modal.Footer className="footer">
        <Button variant="default" onClick={onCancel} disabled={isCreatingTicket}>
          {t(`global.cancel`)}
        </Button>
        <Button
          type="submit"
          form="create-jiraticket"
          disabled={submitDisabled}
          loading={isCreatingTicket}
        >
          {t('global.submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateJiraTicket;
