import { Dispatch, SetStateAction, useState } from 'react';
import {
  useURLFilterArrayState,
  useURLFilterBooleanState,
  useURLFilterNumberState,
  useURLFilterStringState,
} from '../utils/usePersistentStates';

export interface ProductsFilters {
  hasVulns: boolean;
  hasCriticalVulns: boolean;
  recommendation: string[];
  cveIds: string[];
  cvssScore: number;
  search: string;
  labels: string[];
}

export type UpdateFilter = <K extends keyof ProductsFilters>(
  filterName: K,
  value: ProductsFilters[K],
) => void;

export interface UseProductsFilterResult {
  filterSidebarOpen: boolean;
  setFilterSidebarOpen: Dispatch<SetStateAction<boolean>>;
  filters: ProductsFilters;
  updateFilter: UpdateFilter;
  getCurrentFilters: () => { field: keyof ProductsFilters; value: any }[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}

type Setters = {
  [K in keyof ProductsFilters]: Dispatch<SetStateAction<ProductsFilters[K]>>;
};

type FilterValueType<K extends keyof ProductsFilters> = K extends 'cvssScore'
  ? [number, number]
  : ProductsFilters[K];

type CurrentFilter<K extends keyof ProductsFilters> = {
  field: K;
  value: FilterValueType<K>;
};

export type CurrentFiltersArray = CurrentFilter<keyof ProductsFilters>[];

export const useProductsFilter = (): UseProductsFilterResult => {
  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);

  // Suggested
  const [labels, setLabels] = useURLFilterArrayState('labels', []);
  const [hasVulns, setHasVulns] = useURLFilterBooleanState('hasVulns', false);
  const [hasCriticalVulns, setHasCriticalVulns] = useURLFilterBooleanState(
    'hasCriticalVulns',
    false,
  );

  // Vulnerabilities
  const [recommendation, setRecommendation] = useURLFilterArrayState(
    'recommendation',
    [],
  );
  const [cveIds, setCveIds] = useURLFilterArrayState('cveIds', []);
  const [cvssScore, setCvssScore] = useURLFilterNumberState('cvssScore', 0);

  const [search, setSearch] = useURLFilterStringState('search', '');

  const filters: ProductsFilters = {
    hasVulns,
    hasCriticalVulns,
    recommendation,
    cveIds,
    cvssScore,
    search,
    labels,
  };

  const setters: Setters = {
    hasVulns: setHasVulns,
    hasCriticalVulns: setHasCriticalVulns,
    recommendation: setRecommendation,
    cveIds: setCveIds,
    cvssScore: setCvssScore,
    search: setSearch,
    labels: setLabels,
  };

  const updateFilter: UpdateFilter = (filterName, value) => {
    const setter = setters[filterName];
    if (setter) {
      setter(value);
    }
  };

  const createFilter = <K extends keyof ProductsFilters>(
    field: K,
    value: FilterValueType<K>,
  ): CurrentFilter<K> => ({
    field,
    value,
  });

  const getCurrentFilters = (): CurrentFiltersArray => {
    const currentFilters: CurrentFiltersArray = [];

    for (const [field, value] of Object.entries(filters)) {
      if (Array.isArray(value) && value.length) {
        currentFilters.push(createFilter(field as keyof ProductsFilters, value));
      } else if (typeof value === 'boolean' && value) {
        currentFilters.push(createFilter(field as keyof ProductsFilters, value));
      } else if (typeof value === 'number' && value !== 0) {
        currentFilters.push(createFilter(field as keyof ProductsFilters, [value, 10]));
      } else if (typeof value === 'string' && value) {
        currentFilters.push(createFilter(field as keyof ProductsFilters, value));
      }
    }

    return currentFilters;
  };

  return {
    filterSidebarOpen,
    setFilterSidebarOpen,
    filters,
    updateFilter,
    getCurrentFilters,
    search,
    setSearch,
  };
};
