import { TFunction } from 'i18next';

export const getSortOptions = (t: TFunction) =>
  [
    {
      value: 'lastUpdated',
      label: `${t('page.userSettings.sbomUploadAlerts.sortOrders.lastUpdated')}`,
    },
    {
      value: 'ascending',
      label: `${t('page.userSettings.sbomUploadAlerts.sortOrders.ascending')}`,
    },
    {
      value: 'descending',
      label: `${t('page.userSettings.sbomUploadAlerts.sortOrders.descending')}`,
    },
  ] as const;
