import hitApi from '../api';

/**
 * Request to represent a new organization
 * @param orgId
 */
export const representOrganization = async (orgId: string) => {
  if (!orgId || orgId.length < 1) {
    console.error('Unable to find orgId to rep');
  }

  let changedOrg = false;

  const currentOrgId = window.localStorage.getItem('organizationid');

  if (currentOrgId === orgId) {
    console.info('already repping this org');
  } else {
    const attempt = await hitApi.get(`organization/choose/${orgId}`);
    if (attempt && attempt?.success) {
      console.info('new org should be repped');
      window.localStorage.setItem('organizationid', JSON.stringify(orgId));
      changedOrg = true;
    }
  }

  // Remove the organizationId from the URL search params if present to prevent infinite loop
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  params.delete('organizationId');

  if (changedOrg) {
    //hard refresh
    window.location.href = `${window.location.origin}${
      window.location.pathname
    }?${params.toString()}`;
  } else {
    //soft refresh
    window.history.replaceState(
      {},
      '',
      `${window.location.origin}${window.location.pathname}?${params.toString()}`,
    );
  }
};

export default representOrganization;
