import { useForm } from '@mantine/form';

export interface PortalUserInformationFormInterface {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  role: string;
}

export interface PortalUserInformationFormProps {
  initialValues?: PortalUserInformationFormInterface;
  requiredFieldMessage: string;
}

export const useUserInformationForm = ({
  initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    organization: '',
    role: '',
  },
  requiredFieldMessage,
}: PortalUserInformationFormProps) => {
  const form = useForm<PortalUserInformationFormInterface>({
    initialValues,
    validate: ({
      firstName,
      lastName,
      email,
      role,
      organization,
    }: PortalUserInformationFormInterface) => {
      const errors: Partial<Record<keyof PortalUserInformationFormInterface, string>> =
        {};

      if (!firstName) {
        errors['firstName'] = requiredFieldMessage;
      }
      if (!lastName) {
        errors['lastName'] = requiredFieldMessage;
      }
      if (!email) {
        errors['email'] = requiredFieldMessage;
      }
      if (!role) {
        errors['role'] = requiredFieldMessage;
      }
      if (!organization) {
        errors['organization'] = requiredFieldMessage;
      }

      return errors;
    },
    validateInputOnBlur: true,
  });
  return form;
};
