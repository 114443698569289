import '../../../../scss/pages/integration.scss';
import { Button } from '@mantine/core';
import { IIntegration } from '../integrations-data';
import Icon from '../../../../components/Icon/Icon';
import { useTranslation } from 'react-i18next';

interface Props {
  integrationInfo: IIntegration;
  onInstall: () => void;
}

export const IntegrationAddStart = ({ integrationInfo, onInstall }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="integration-page-content">
        <h5>{t('page.userSettings.integrations.description')}</h5>
        <p>{integrationInfo.description}</p>
        <h5>{t('page.userSettings.integrations.features')}</h5>
        <ul>
          {integrationInfo.features.map((feature, index) => (
            <li key={index}>
              <Icon icon="check" color="var(--color-success)" /> <div>{feature}</div>
            </li>
          ))}
        </ul>
      </div>
      <Button size="lg" onClick={onInstall}>
        {integrationInfo.installText}
      </Button>
    </>
  );
};
