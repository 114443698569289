import { InterfaceOrganizationComponentWithLicenses } from '../../../types/FullInterfaces';

export const determineVersionStatus = (
  original: InterfaceOrganizationComponentWithLicenses,
  componentData:
    | {
        component?: InterfaceOrganizationComponentWithLicenses;
        versions?: InterfaceOrganizationComponentWithLicenses[];
      }
    | undefined,
): boolean => {
  if (original._id == null) {
    return false;
  }

  return componentData?.component?.version === original.version;
};

export const formatVulnerabilityCount = (
  countVulnerabilities: { total?: number } | undefined,
): number => {
  return countVulnerabilities?.total ?? 0;
};

export const formatLicenses = (
  original: InterfaceOrganizationComponentWithLicenses,
): string => {
  let licenses = '';

  if (original?.licensesData) {
    for (const license of original.licensesData) {
      if (license?.shortName) {
        if (licenses.length > 0) licenses += ', ';
        licenses += license.shortName;
      }
    }
  }

  return licenses;
};
