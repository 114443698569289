import { useTranslation } from 'react-i18next';
import ToggleCard from '../../../../../../components/ToggleCard/ToggleCard';
import { useFetchUser } from '../../../../../../hooks/queries/useFetchUser';
import { AlertMetadata } from '../../../components/AlertMetadata/AlertMetadata';
import { MappedSbomAlert } from '../../../types/SBOMAlerts.interface';
import { getAlertMetadata } from '../../../utils/getAlertMetadata';

interface AlertRowProps {
  alert: Pick<
    MappedSbomAlert,
    | 'lastActionType'
    | 'modifiedByUserId'
    | 'createdByUserId'
    | 'dateModifiedText'
    | 'dateCreatedText'
    | 'dateLastExecutedText'
    | 'hasNeverRan'
    | 'isActive'
    | 'hasConfiguredDeliveryMethod'
    | 'name'
    | 'id'
  >;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
  onClickDetail: (id: string) => void;
  onClickToggle: (id: string, isActive: boolean) => void;
}

const AlertRow = ({
  alert,
  onClickDelete,
  onClickDetail,
  onClickEdit,
  onClickToggle,
}: AlertRowProps) => {
  const { t } = useTranslation();
  const { lastActionType, modifiedByUserId, createdByUserId } = alert;
  const { data: userInfo } = useFetchUser({
    userId: lastActionType === 'updated' ? modifiedByUserId : createdByUserId,
  });

  const { lastExecutedText, lastUpdateText } = getAlertMetadata({
    t,
    userInfo,
    dateModifiedText: alert.dateModifiedText,
    dateCreatedText: alert.dateCreatedText,
    lastActionType: alert.lastActionType,
    dateLastExecutedText: alert.dateLastExecutedText,
    hasNeverRan: alert.hasNeverRan,
  });

  return (
    <ToggleCard
      toggle={{
        isDisabled: !alert.hasConfiguredDeliveryMethod,
        isChecked: alert.isActive,
        onClick: () => onClickToggle(alert.id, alert.isActive),
      }}
      editAction={{
        tooltip: t('global.edit'),
        onClick: () => onClickEdit(alert.id),
      }}
      deleteAction={{
        tooltip: t('global.delete'),
        onClick: () => onClickDelete(alert.id),
      }}
      mainSection={{
        tooltip: t('page.userSettings.sbomUploadAlerts.seeAlertDetails'),
        label: alert.name,
        onClick: () => onClickDetail(alert.id),
      }}
      Details={<AlertMetadata leftText={lastExecutedText} rigthText={lastUpdateText} />}
    />
  );
};

export default AlertRow;
