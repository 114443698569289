export const timeSince = (
  isoDateString: string | Date | null | undefined,
  t: any,
): string => {
  if (isoDateString === null || isoDateString === undefined) {
    return 'N/A (Anon)';
  }

  const birthDate =
    typeof isoDateString === 'string' ? new Date(isoDateString) : isoDateString;
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const diffMs = Math.abs(currentDate.getTime() - birthDate.getTime());

  // Calculate the difference in hours, days, and total months
  const hours = Math.floor(diffMs / (1000 * 60 * 60));
  const days = Math.floor(hours / 24);
  let totalMonths =
    (currentDate.getFullYear() - birthDate.getFullYear()) * 12 +
    currentDate.getMonth() -
    birthDate.getMonth();

  // Adjust for day of the month
  if (currentDate.getDate() < birthDate.getDate()) {
    totalMonths--;
  }

  // Calculate years
  const years = Math.floor(totalMonths / 12);

  // Determine the largest relevant unit
  if (years > 0) {
    return t('global.time.ago.years', {
      years,
    });
  } else if (totalMonths >= 2) {
    return t('global.time.ago.months', {
      months: totalMonths,
    });
  } else if (days >= 14) {
    return t('global.time.ago.weeks', {
      weeks: Math.floor(days / 7),
    });
  } else if (days >= 2) {
    return t('global.time.ago.days', {
      days,
    });
  } else {
    return t('global.time.ago.hours', {
      hours,
    });
  }
};

export default timeSince;
