import '../../scss/pages/vendor_portal.scss';
import { Accordion } from '@mantine/core';
import Icon from '../../components/Icon';
import { useTranslation } from 'react-i18next';

interface InterfaceFaq {
  question: string;
  answer: string;
}
function Faq() {
  const MANIFEST_EMAIL = 'info@manifestcyber.com';
  const { t } = useTranslation();
  const faqs = t('page.vendorPortal.faq', {
    returnObjects: true,
  }) as unknown as InterfaceFaq[];
  return (
    <div className="faq-container">
      <div className="header-faq header-alt">
        {t('page.vendorPortal.faqHeader')}
        <Icon icon="circle-question" iconStyle="far" />
      </div>
      <Accordion className="faq-list" multiple>
        {faqs.map((faq, idx) => (
          <Accordion.Item className="faq-item " key={idx} value={faq.question}>
            <Accordion.Control>
              <div className="faq-item-header">{faq.question}</div>
            </Accordion.Control>
            <Accordion.Panel>{faq.answer}</Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
      <div className="faq-helper-text">
        {t('page.vendorPortal.faqHelperText')} {` `}
        <a href={`mailto:${MANIFEST_EMAIL}`}>{MANIFEST_EMAIL}</a>
      </div>
    </div>
  );
}

export default Faq;
