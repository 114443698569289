import { Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';

export const VersionErrors = ({
  errorType = 'default'
}: {
  errorType?: 'default' | 'length';
}) => {
  const { t } = useTranslation();

  return (
    <Group spacing="xs" noWrap>
      <Icon icon={'triangle-exclamation'} size="xl" />
      <Text size="sm" weight="normal">
        {
          errorType === 'length' && (
            t('tables.products.errors.product-version-length')
          )
        }
        {
          errorType === 'default' && (
            t('tables.products.errors.product-already-exists-by-name-and-version')
          )
        }
      </Text>
    </Group>
  );
};
