import {
  useURLFilterStringState,
  useURLandLocalStorageSortingState,
} from '../../../../../hooks/utils/usePersistentStates';
import { MRT_PaginationState } from 'mantine-react-table';
import { useCallback, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

export enum ProductFilterSortParams {
  search = 'products_search',
  sort = 'products_sort',
  relationship = 'rproducts_elationship',
  page = 'products_page',
  limit = 'products_limit',
}

export enum ProductRelationshipFilter {
  all = 'all',
  firstParty = 'first',
  thirdParty = 'third',
}

export const useVulnAffectedProductsFilters = () => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [productRelationshipFilter, setProductRelationshipFilter] =
    useURLFilterStringState(
      ProductFilterSortParams.relationship,
      ProductRelationshipFilter.all,
      'replaceIn',
    );

  const [productSearchFilter, setProductSearchFilter] = useURLFilterStringState(
    ProductFilterSortParams.search,
    '',
    'replaceIn',
  );

  const debouncedProductSearchFilter = useRef(debounce(setProductSearchFilter, 200));

  const handleSetProductSearchFilter = useCallback((value: string) => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    debouncedProductSearchFilter.current(value);
  }, []);

  const handleSetProductRelationshipFilter = (value: string) => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setProductRelationshipFilter(value);
  };

  const [sorting, setSorting] = useURLandLocalStorageSortingState(
    'impactProducts',
    [],
    'replaceIn',
  );

  return {
    productRelationshipFilter,
    setProductRelationshipFilter: handleSetProductRelationshipFilter,
    productSearchFilter,
    handleSetProductSearchFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
  };
};
