/**
 * capitalizeWords()
 * Attempts to capitalize words in string. Useful for generated auth codes, etc
 * @param {string} string Contains one or more words to be capitalized
 * @returns {string}
 */
export const capitalizeWords = (
  string: string,
  ignoreBlacklist: boolean = false,
): string => {
  if (typeof string !== 'string' || string === '') return '';
  const words = string.split(' ');
  const capBlacklist = ['the', 'to', 'with', 'and', 'for']; // words to avoid capitalizing. Should localize at some point

  for (let i = 0, x = words.length; i < x; i++) {
    if (ignoreBlacklist && capBlacklist.includes(words[i])) return '';
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }

  return words.join(' ');
};

export default capitalizeWords;
