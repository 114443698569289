import { useTranslation } from 'react-i18next';
import { InterfaceVulnerabilityWithVexInfo } from '../../../types/FullInterfaces';
import { useFetchTickets } from '../../../hooks/queries/useFetchTickets';
import { BasicTable } from '../components/BasicTable';
import { VexDocsTableEmptyScreen } from './ActionTabComponents/VexDocsTableEmptyScreen';
import Icon from '../../../components/Icon';
import { truncateString } from '../../../lib/truncateString';
import { DateTime } from 'luxon';
import { TicketsTableEmptyScreen } from './ActionTabComponents/TicketsTableEmptyScreen';
import { Flex, Loader } from '@mantine/core';
import {
  InterfaceOrganizationTicket,
  InterfaceSbom,
} from '@manifest-cyber/types/interface/dbTables';
import appConfig from '../../../configs/appConfig';
import { useFetchIntegrations } from '../../../hooks/queries/useFetchIntegrations';

const JIRA_LOGO_SRC = '/jira_logo.png';
const SERVICENOW_LOGO_SRC = '/servicenow_logo.png';
const LINEAR_LOGO_SRC = '/linear_logo.png';

const PLATFORM_LOGO_MAP: Record<string, string> = {
  jira: JIRA_LOGO_SRC,
  servicenow: SERVICENOW_LOGO_SRC,
  linear: LINEAR_LOGO_SRC,
};

interface ActionTabProps {
  id: InterfaceVulnerabilityWithVexInfo['_id'];
  vexInfo: InterfaceVulnerabilityWithVexInfo['vexInfo'];
  onError: (errorMsg: string) => void;
}

export const ActionTab = ({ id, vexInfo, onError }: ActionTabProps) => {
  const { t } = useTranslation();

  const { data: fetchedTickets, isLoading } = useFetchTickets(
    id?.toString() || '',
    'vulnerability',
  );

  const { data: integrations, isLoading: isLoadingIntegrations } = useFetchIntegrations();

  const hasAtLeastOneIntegration = integrations && integrations?.length > 0;

  const shouldLoad = isLoading || isLoadingIntegrations;

  const handleTicketTableRowClick = (ticket: InterfaceOrganizationTicket) => {
    window.open(ticket.ticketUrl, '_blank');
  };

  async function handleDownloadVex(vex: InterfaceSbom) {
    const fileId = vex._id?.toString();

    if (!fileId || !fileId.length) {
      return;
    }
    try {
      window.open(
        `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/download/${fileId}?redirect=1`,
        '_blank',
      );
    } catch (e) {
      console.error(e);
      onError(t('error.general.file-download'));
    }
  }

  return (
    <div className="action-tab anim-slideInDownShort">
      <h6 className="section-title">
        {t('page.vulnerability.details.main-tabs.actions')}
      </h6>
      <div className="main-section">
        <Flex align="center" justify="center" className="card">
          {shouldLoad && <Loader />}
          {fetchedTickets && !shouldLoad && (
            <BasicTable
              data={fetchedTickets.map((ticket) => ({
                value: DateTime.fromISO(`${ticket.dateCreated}`).toLocaleString(
                  DateTime.DATETIME_MED,
                ),
                name: ticket.ticketName,
                rowId: ticket.ticketId,
                icon: PLATFORM_LOGO_MAP[ticket.platformName] || '',
                data: ticket,
              }))}
              title={`${t('page.vulnerability.details.tickets')} (${fetchedTickets.length
                })`}
              noResults={
                <TicketsTableEmptyScreen
                  hasAtLeastOneIntegration={Boolean(hasAtLeastOneIntegration)}
                />
              }
              onRowClick={handleTicketTableRowClick}
            />
          )}
        </Flex>
        <div className="card">
          <BasicTable
            data={(vexInfo || []).map((vexDoc, index) => ({
              value: DateTime.fromISO(`${vexDoc.dateCreated}`).toLocaleString(
                DateTime.DATETIME_MED,
              ),
              name: truncateString(vexDoc.fileName ?? '', 25),
              rowId: vexDoc._id?.toString() || index.toString(),
              icon: <Icon icon="arrow-down-to-bracket" />,
              data: vexDoc,
            }))}
            title={`${t('page.vulnerability.details.vex')} (${vexInfo?.length || 0})`}
            noResults={<VexDocsTableEmptyScreen />}
            onRowClick={handleDownloadVex}
          />
        </div>
      </div>
    </div>
  );
};
