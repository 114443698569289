import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Box, Flex } from '@mantine/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import Icon from '../Icon';
import styles from './ColoredMessage.module.scss';

const ColoredMessage = ({
  message,
  type = 'error',
}: {
  message: ReactNode;
  type?: 'error' | 'warning';
}) => {
  const iconName: IconName =
    type === 'error' ? 'triangle-exclamation' : 'circle-exclamation';

  return (
    <Flex
      className={clsx(
        styles.container,
        type === 'error' ? styles.colorError : styles.colorWarning,
      )}
    >
      <Box>
        <Icon icon={iconName} classNames={styles.iconContainer} size="1x" />
      </Box>
      <Box>
        <span
          className={clsx(
            styles.text,
            type === 'error' ? styles.colorError : styles.colorWarning,
          )}
        >
          {message}
        </span>
      </Box>
    </Flex>
  );
};

export default ColoredMessage;
