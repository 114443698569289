import { FileType, SbomFormat } from '@manifest-cyber/types/interface/db';
import { Types } from 'mongoose';

export interface SBOMSelectionData {
  id: string;
  fileType: FileType;
  format: SbomFormat | null;
  formatVersion: string | null;
  assetId: string;
}

export interface FromSBOMToSelectionDataParam {
  _id?: Types.ObjectId | null | string;
  fileType?: FileType;
  format?: SbomFormat;
  formatVersion?: string;
  assetId?: string;
}

export const fromSBOMToSelectionData = (
  sbom: FromSBOMToSelectionDataParam,
): SBOMSelectionData => {
  return {
    id: `${sbom._id}`,
    fileType: sbom.fileType || 'SBOM', // the same fall-back that we are using on table
    format: sbom.format || null,
    formatVersion: sbom.formatVersion || null,
    assetId: sbom.assetId || '',
  };
};
