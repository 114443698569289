import { InterfaceSBOMAsset } from '../types/FullInterfaces';

export const getSBOMName = (sbom?: InterfaceSBOMAsset): string => {
  if (!sbom) return '';

  const { _id, assetData, assetName, fileName, whenUploaded } = sbom;
  const coordinates = assetData?.[0]?.coordinates;
  const version = assetData?.[0]?.version;

  const name = () => {
    if (assetName) return assetName;
    if (fileName) return fileName;
    return _id;
  };

  const nameSuffix = () => {
    if (version && version != 'NOASSERTION') return `@${version}`;
    if (whenUploaded) return `_${whenUploaded}`;
    return '';
  };

  if (!assetName && coordinates) {
    return coordinates;
  }

  return `${name()}${nameSuffix()}`;
};
