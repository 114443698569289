import { Text, Title } from '@mantine/core';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { VulnerabilitiesTable } from '../../components/DataTables/VulnerabilitiesTable';
import { useFetchVulns } from '../../hooks/queries/useFetchVulns';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import '../../scss/pages/vulnerabilities.scss';

const Vulnerabilities = () => {
  const { t } = useTranslation();
  const [organizationId] = useOrganizationId();

  //fetch major vulns total vulnerabilities count
  const { data: { queryInfo: { countVulnerabilities = 0 } = {} } = {} } = useFetchVulns({
    organizationId: organizationId || undefined,
    limit: 100,
    page: 1,
    sortColumn: 'epssScore',
    sortType: 'desc',
  });

  return (
    <section className="page-vulnerabilities anim-slideInUpShort">
      <Helmet title={t('page.vulnerabilities.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <Title mb="sm" order={1} className="anim-slideInDownShort">
        {t('page.vulnerabilities.header')}
      </Title>
      <Text className="anim-slideInUpShort">
        {t('page.vulnerabilities.total', {
          count: countVulnerabilities?.toLocaleString() as any,
        })}
      </Text>
      <VulnerabilitiesTable />
    </section>
  );
};

export default Vulnerabilities;
