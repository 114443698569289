import styles from './ProductVulnerabilites.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useProductVulnerabilitiesTab = () => {
  const { t } = useTranslation();

  const getSeverity = (value: number) => {
    /**
     * None – 0.0
     Low – 0.1 – 3.9
     Medium – 4.0 – 6.9
     High 7.0 – 8.9
     Critical – 9.0 – 10.0
     */
    if (value >= 0 && value < 0.1) {
      return (
        <>
          <span className={styles['none']}>{t(`jargon.severity.none`)}</span>{' '}
          <span>({value.toFixed(1)})</span>
        </>
      );
    } else if (value >= 0.1 && value < 4) {
      return (
        <>
          <span className={styles['low']}>{t(`jargon.severity.low`)}</span>{' '}
          <span>({value.toFixed(1)})</span>
        </>
      );
    } else if (value >= 4 && value < 7) {
      return (
        <>
          <span className={styles['medium']}>{t(`jargon.severity.medium`)}</span>{' '}
          <span>({value.toFixed(1)})</span>
        </>
      );
    } else if (value >= 7 && value < 9) {
      return (
        <>
          <span className={styles['high']}>{t(`jargon.severity.high`)}</span>{' '}
          <span>({value.toFixed(1)})</span>
        </>
      );
    } else if (value >= 9 && value <= 10) {
      return (
        <>
          <span className={styles['critical']}>{t(`jargon.severity.critical`)}</span>{' '}
          <span>({value.toFixed(1)})</span>
        </>
      );
    }
  };

  const getEPSSScore = (value: number) => {
    /**
     * None – 0.0
     Low – 0.1 – 3.9
     Medium – 4.0 – 6.9
     High 7.0 – 8.9
     Critical – 9.0 – 10.0
     */
    if (value >= 0 && value < 0.01) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    } else if (value >= 0.01 && value < 0.4) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    } else if (value >= 0.4 && value < 0.7) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    } else if (value >= 0.7 && value < 0.9) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    } else if (value >= 0.9 && value <= 1) {
      return (
        <>
          <span>{(value * 100).toFixed(1)}%</span>
        </>
      );
    }
  };

  return {
    getSeverity,
    getEPSSScore,
  };
};
