import '../../scss/pages/login.scss';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import hitApi from '../../api';
import ClickableRegion from '../../components/ClickableRegion';
import Lottie from 'lottie-react-web';
import * as loginLotti from '../../img/illustrations/login/login.json';
import * as loginSubmittedLotti from '../../img/illustrations/login/loginSubmitted.json';
import { useLastPath } from '../../hooks/utils/useLastPath';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../global.constants';

/**
 * A note on Login:
 * - Auth is done via session cookie
 * - Route will first attempt to fetch our profile.
 * - If unsuccessful, prompt for login
 * - User enters email, we start a new attempt
 * - We receive back a manualToken and do 2 things:
 * - a) We start an interval to check to fetch our profile
 * - - a1) If successful, user clicked magic link and now has a session/cookie.
 * - b) We allow user to attempt manual secret entry and attach manualToken
 * - - b2) If successful, remote will set cookie and we attempt to fetch profile, etc.
 * ----
 * - In any case, a user won't be logged in until we can successfully fetch profile data (GET /user).
 */

/**
 *
 * @returns
 */

const LoginVerify = () => {
  // Grab user context
  const { login, logout, user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [lastPath] = useLastPath();
  // States
  const [, setIsCheckedProfile] = useState(false);
  const [canFetchUserProfile, setCanFetchUserProfile] = useState(false);
  const [isAuthSubmitting, setIsAuthSubmitting] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isManualCodeSubmitted, setIsManualCodeSubmitting] = useState(false);
  const [userHasNoOrgs, setUserHasNoOrgs] = useState(false);

  // Values
  const [authToken, setToken] = useState('');
  const [authSecret, setSecret] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [incomingError] = useState('');

  // Run once to get the auth parameters
  useEffect(() => {
    const fetchParameters = () => {
      console.log('location', location);
      var params = location?.pathname?.replace(/\/login-verify\//, '');
      if (params.length < 2) {
        setErrors([...errors, 'Invalid login link']);
        return;
      }
      var params_split = params.split('/');
      setToken(params_split[0]);
      setSecret(params_split[1]);
    };

    fetchParameters();
  }, [errors, location]);

  useEffect(() => {
    const getUserProfile = async () => {
      const hitProfile = await hitApi.get('user');

      if (hitProfile && hitProfile.success && hitProfile.data.length > 0) {
        console.info('Fetched user profile!');

        const thisUserData = hitProfile.data[0];
        if (thisUserData?.organizations && thisUserData?.organizations?.length > 0) {
          login(thisUserData);
        } else {
          setUserHasNoOrgs(true);
        }

        setCanFetchUserProfile(true);
      } else if (hitProfile?.status === 401) {
        // No cookie set.
        if (user && user?._id) {
          // We don't have a cookie but we have a user - we should do some cleanup here.
          logout('no-cookie-but-user');
        }
      }

      setIsCheckedProfile(true);
    };

    getUserProfile();
  }, [user, login, logout]);

  // Attempts to trigger new login attempt with a given email
  const triggerLoginVerification = async (token: string, secret: string) => {
    try {
      setIsAuthSubmitting(true);

      // TODO: Change check to be on authToken and authSecret
      if (!token || !secret) {
        setErrors([t('page.login-verify.verification-missing')]);
        setIsAuthSubmitting(false);
        return true;
      }

      const newAttempt = await hitApi.post('auth/verifyEmail', true, {
        authCodeEmailToken: token,
        authCodeSecret: secret,
      });

      // If successful, we should get a `data` object back with success: true and `authCodeManualToken`
      if (newAttempt) {
        const { success, errors: attemptErrors } = newAttempt;
        if (success && (!attemptErrors || attemptErrors?.length === 0)) {
          setIsAuthenticated(true);
        } else if (attemptErrors && attemptErrors.length > 0) {
          if (attemptErrors.length === 1 && attemptErrors[0] === null) {
            setErrors([t('page.login-verify.incoming-error.failed-attempt')]);
          } else {
            setErrors(attemptErrors);
          }
          setIsAuthSubmitting(false);
        } else {
          setErrors([t('page.login-verify.incoming-error.failed-attempt')]);
          setIsAuthSubmitting(false);
        }
      } else {
        setErrors([t('page.login-verify.network-error')]);
        setIsAuthSubmitting(false);
      }
    } catch (err) {
      console.error('Error attempting to create new auth attempt', err);
    }
  };

  if (user && canFetchUserProfile && !userHasNoOrgs) {
    return <Navigate to={lastPath || '/'} />;
  }

  if (isAuthenticated) {
    return <Navigate to={lastPath || '/'} />;
  }

  return (
    <div className="page-login">
      <Helmet>
        <title>{t('page.login-verify.title', { brand: t('app.name') })}</title>
        <meta
          name="description"
          content={t('page.login-verify.title', { brand: t('app.name') })}
        />
      </Helmet>
      <section className="illustration">
        {/* <h1>Know what you're made of.</h1> */}
        {!isAuthenticated && (
          <div className="anim-slideInUp">
            <Lottie
              options={{
                // @ts-ignore
                animationData: loginLotti?.default,
                // rendererSettings: {
                //   preserveAspectRatio: 'xMidYMid slice',
                // },
              }}
              height="100%"
              width="auto"
              isStopped={false}
              isPaused={false}
            />
          </div>
        )}
        {isAuthenticated && (
          <div className="anim-slideInUp">
            <Lottie
              options={{
                // @ts-ignore
                animationData: loginSubmittedLotti?.default,
                // rendererSettings: {
                //   preserveAspectRatio: 'xMidYMid slice',
                // },
              }}
              height="100%"
              width="auto"
              isStopped={false}
              isPaused={false}
            />
          </div>
        )}
      </section>
      <section className="login-container">
        {errors?.length > 0 && (
          <div className="submission-errors">
            <span>
              {errors.map((error) => (
                <Trans key={error} i18nKey={error} />
              ))}
            </span>
          </div>
        )}

        {incomingError?.length > 0 && (
          <div className="submission-errors">
            <span>{t(`page.login-verify.incoming-error.${incomingError}`)}</span>
          </div>
        )}

        {!isAuthenticated && (
          <div className="login-verify-form anim-slideInRight">
            <h1>{t('page.login-verify.title')}</h1>
            <p className="lead login-verify">{t('page.login-verify.intro')}</p>
            <p className="agreement">
              <Trans
                i18nKey="page.login.legal-login-verify"
                values={{
                  tos: t('legal.tos.title'),
                  privacy: t('legal.privacy.title'),
                }}
              >
                By signing in or creating an account, you agree to our{' '}
                <ClickableRegion
                  regionLabel={t('legal.tos.title')}
                  href={TERMS_OF_USE_LINK }
                  target="_blank"
                >
                  {t('legal.tos.title')}
                </ClickableRegion>{' '}
                and
                <ClickableRegion
                  regionLabel={t('legal.privacy.title')}
                  href={PRIVACY_POLICY_LINK}
                  target="_blank"
                >
                  {t('legal.privacy.title')}
                </ClickableRegion>
                .
              </Trans>
            </p>

            <div className="login-submit-verify">
              <ClickableRegion
                regionLabel={t('page.login-verify.submit-label')}
                className="its-awesome"
                disabled={isAuthSubmitting}
                onClick={() => triggerLoginVerification(authToken, authSecret)}
              >
                <Trans
                  i18nKey={
                    isAuthSubmitting
                      ? 'page.login-verify.submitting'
                      : 'page.login-verify.submit'
                  }
                />
              </ClickableRegion>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default LoginVerify;
