import {
  InterfaceOrganizationComponent,
  InterfaceVulnerability,
} from '@manifest-cyber/types/interface/dbTables';
import { TopRiskiestComponents } from './asset-risk-overview-tab-components/TopRiskiestComponents';
import { RiskOverviewCard } from './asset-risk-overview-tab-components/RiskOverviewCard/RiskOverviewCard';
import { InterfaceFullAsset } from '../../../types/FullInterfaces';
import { LicenseIssues } from './asset-risk-overview-tab-components/LicenseIssues';
import Loading from '../../../components/Loading';
import { Tickets } from './asset-risk-overview-tab-components/Tickets';

interface Props {
  fetchedAsset?: InterfaceFullAsset;
  riskiestComponents?: InterfaceOrganizationComponent[];
  isLoadingRiskiestComponents?: boolean;
}

export const AssetRiskOverviewTab = ({
  fetchedAsset,
  riskiestComponents,
  isLoadingRiskiestComponents,
}: Props) => {
  if (!fetchedAsset) return <Loading />;

  return (
    <div className="asset-risk-overview-content">
      <div className="primary-view anim-slideInLeftShort">
        <RiskOverviewCard fetchedAsset={fetchedAsset} />
        <TopRiskiestComponents
          fetchedAsset={fetchedAsset}
          riskiestComponents={riskiestComponents}
          isLoadingRiskiestComponents={isLoadingRiskiestComponents}
        />
        <LicenseIssues fetchedAsset={fetchedAsset} />
      </div>
      <div className="sidebar anim-slideInRightShort">
        <Tickets entityType="asset" entityId={fetchedAsset?._id?.toString() || ''} />
      </div>
    </div>
  );
};
