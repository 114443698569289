import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import type {
  InterfaceOrganizationAsset,
  InterfaceVulnerability,
} from '@manifest-cyber/types/interface/dbTables';

export interface VulnerabilityResult extends InterfaceVulnerability {
  impactedVersionsCount: number;
  impactedVersions: string | string[];
}

interface Params {
  limit?: number;
  page?: number;
  sortColumn?: string;
  sortType?: string;
  componentId: string;
}

export const useFetchComponentVulns = ({
  componentId,
  limit,
  page,
  sortColumn,
  sortType,
}: Params) => {
  let fetchUrl = `vulnerabilities/component/${componentId}?`;

  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (sortColumn && sortType) {
    fetchUrl += `&sort={"${sortColumn}":"${sortType === 'asc' ? 1 : -1}","_id":"${
      sortType === 'asc' ? 1 : -1
    }"}`;
  }

  fetchUrl = fetchUrl.replace('?&', '?');

  return useQuery({
    queryKey: ['component-vulns', { componentId }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) =>
      data as {
        data: {
          distinctVulnerabilities: VulnerabilityResult[];
          thisVersionVulnerabilities: VulnerabilityResult[];
          versionVulnerabilityCount: Record<string, number>;
          affectedAssets: Array<InterfaceOrganizationAsset>;
        };
        queryInfo: {
          totalCount: number;
          nLimit: number;
          nPage: number;
        };
      },
  });
};
