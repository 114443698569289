import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Button, Divider, Flex } from '@mantine/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import Icon from '../../../../../components/Icon';
import { SbomUploadAlertSectionState } from '../../../../../types/FullInterfaces';
import { LinkButton } from '../LinkButton/LinkButton';
import styles from './AlertSection.module.scss';

interface AlertSectionProps {
  state: SbomUploadAlertSectionState;
  nextButton?: {
    isDisabled?: boolean;
    isVisible: boolean;
    label: string;
    onClick?: () => void;
    isLoading?: boolean;
  };
  editButton?: {
    isVisible: boolean;
    label: string;
    onClick?: () => void;
  };
  ViewComponent: ReactNode;
  EditComponent: ReactNode;
  title: string;
  description: string;
  titleIconName: IconName;
}

function AlertFormSection({
  state,
  nextButton: primaryAction,
  editButton: secondaryAction,
  ViewComponent,
  EditComponent,
  title,
  description,
  titleIconName,
}: Readonly<AlertSectionProps>) {
  const isActive = state === 'edit' || state === 'view';

  return (
    <div>
      <Divider />
      <div className={styles.content}>
        <div className={clsx(styles.icon, isActive ? styles.active : styles.inactive)}>
          <Icon icon={titleIconName} size="lg" />
        </div>
        <div className={styles.rigthContent}>
          <div className={styles.header}>
            <Flex gap={8}>
              <span
                className={clsx(styles.title, isActive ? styles.active : styles.inactive)}
              >
                {title}
              </span>
            </Flex>

            {state === 'view' && secondaryAction?.isVisible && (
              <LinkButton
                text={secondaryAction.label}
                leftIcon={<Icon icon="pen" />}
                onClick={secondaryAction.onClick}
              />
            )}
          </div>
          <div>
            {state === 'edit' && <div className={styles.subheader}>{description}</div>}

            {state === 'view' && (
              <div className={clsx(styles.body, styles.viewBody)}>{ViewComponent}</div>
            )}
            {state === 'edit' && (
              <div className={clsx(styles.body)}>
                {EditComponent}
                <div className={styles.nextActionContainer}>
                  {primaryAction?.isVisible && (
                    <Button
                      className={styles.nextButton}
                      disabled={primaryAction.isDisabled}
                      onClick={primaryAction.onClick}
                      loading={primaryAction.isLoading}
                    >
                      {primaryAction.label}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertFormSection;
