import { InterfaceOrganizationTicket } from '@manifest-cyber/types/interface/dbTables';
import './ticket.scss';
import { Popover, Table, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import TicketRow from './TicketRow';

interface Props {
  showTableHeaders?: boolean;
  tickets: InterfaceOrganizationTicket[] | [];
}
function TicketList({ showTableHeaders, tickets }: Props) {
  const { t } = useTranslation();

  const renderRows = () => {
    if (tickets?.length === 0) {
      return (
        <tr>
          <td colSpan={2} style={{ textAlign: 'center' }}>
            {t('tickets.ticketList.noTickets')}
          </td>
        </tr>
      );
    }
    return tickets?.map((ticket: InterfaceOrganizationTicket) => {
      return <TicketRow ticket={ticket} key={ticket?._id?.toString()} />;
    });
  };

  return (
    <Table highlightOnHover>
      <thead>
        {showTableHeaders && (
          <tr className="ticket-headers">
            <th>{t('tickets.ticketList.tableHeaders.link')}</th>
            <th>{t('tickets.ticketList.tableHeaders.dateCreated')}</th>
          </tr>
        )}
      </thead>
      <tbody className="ticket-list">{renderRows()}</tbody>
    </Table>
  );
}

export default TicketList;
