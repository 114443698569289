import { DateTime } from 'luxon';
import { isDateAfter } from '../../../../../lib/date/isDateAfter';
import {
  CvssScoreFilter,
  LabelsFilter,
  LicenseIssuesFilter,
  MappedSbomAlert,
  MinRiskScoreFilter,
  OnCISAKEVListFilter,
  SbomAlert,
  SbomAlertFilter,
  UploadedViaFilter,
  VulnRecommendationsFilter,
} from '../../types/SBOMAlerts.interface';

export const mapSbomAlert = (alert: SbomAlert): MappedSbomAlert => {
  const findFilterById = (id: SbomAlertFilter['id']) =>
    alert.filters.find(({ id: filterId }) => filterId === id);
  const dateLastExecutedText =
    DateTime.fromISO(alert.dateLastExecuted?.toString() || '').toRelative() ?? undefined;
  const dateModifiedText =
    DateTime.fromISO(alert.dateModified?.toString() || '').toRelative() ?? undefined;
  const dateCreatedText =
    DateTime.fromISO(alert.dateCreated?.toString() || '').toRelative() ?? undefined;
  const lastActionType = isDateAfter(
    alert.dateModified?.toString(),
    alert.dateCreated?.toString(),
  )
    ? 'updated'
    : 'created';
  return {
    id: alert._id || '',
    name: alert.name || '',
    uploadedVia: (findFilterById('uploadedVia') as UploadedViaFilter)?.value || [],
    labels: (findFilterById('labels') as LabelsFilter)?.value || [],
    cvssScore: (findFilterById('cvssScore') as CvssScoreFilter)?.value || undefined,
    vulnRecommendations:
      (findFilterById('vulnRecommendations') as VulnRecommendationsFilter)?.value || [],
    licenseIssues: (findFilterById('licenseIssues') as LicenseIssuesFilter)?.value || [],
    onCISAKEVList:
      (findFilterById('onCISAKEVList') as OnCISAKEVListFilter)?.value || false,
    minRiskScore:
      (findFilterById('minRiskScore') as MinRiskScoreFilter)?.value || undefined,
    emails:
      alert.destinations.find((destination) => destination.type === 'email')
        ?.destinations || [],
    isActive: alert.active,
    createdByUserId: alert.createdByUserId?.toString() ?? undefined,
    modifiedByUserId: alert.modifiedByUserId?.toString() ?? undefined,
    dateLastExecutedText,
    dateModifiedText,
    dateCreatedText,
    hasNeverRan: !alert.dateLastExecuted,
    lastActionType,
    hasConfiguredDeliveryMethod: alert.destinations.some(
      ({ destinations }) => destinations.length > 0,
    ),
    dateModified: alert.dateModified?.toString(),
    dateCreated: alert.dateCreated?.toString(),
  };
};
