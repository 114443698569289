import { Avatar, Skeleton } from '@mantine/core';
import styles from './AvatarComponent.module.scss';

interface AvatarComponentProps {
  avatarLetter: string;
  isLoading?: boolean;
}

export const AvatarComponent = ({ avatarLetter, isLoading }: AvatarComponentProps) => {
  return (
    <>
      {!isLoading && (
        <Avatar
          radius="lg"
          variant="filled"
          classNames={{
            root: styles.avatar,
            placeholder: styles.avatarPlaceholder,
          }}
        >
          {avatarLetter}
        </Avatar>
      )}
      {isLoading && (
        <Skeleton radius="lg" visible={isLoading} className={styles.skeleton} />
      )}
    </>
  );
};
