import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/Icon';
import { useFetchAssetLicenseIssues } from '../../../../hooks/queries/useFetchAssetLicenseIssues';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';
import { LicenseIssueCard } from './LicenseIssueCard';

interface Props {
  fetchedAsset: InterfaceFullAsset;
}

export const LicenseIssues = ({ fetchedAsset }: Props) => {
  const { t } = useTranslation();

  const { data: fetchedAssetLicenseIssues = [] } = useFetchAssetLicenseIssues(
    { assetId: fetchedAsset._id?.toString()! },
    { enabled: !!fetchedAsset?.countLicenseIssues?.total },
  );

  return (
    <div className="unstyled-cont prioritization-map anim-slideInLeftShort">
      <h3>
        <Icon icon="key" />
        {t('page.asset.license-issues')}
      </h3>
      <div className="license-issue-cards">
        {fetchedAssetLicenseIssues.length > 0 ? (
          fetchedAssetLicenseIssues.map((vuln) => (
            <LicenseIssueCard
              key={vuln._id?.toString()}
              licenseIssue={vuln}
              fetchedAsset={fetchedAsset}
            />
          ))
        ) : (
          <p>{t('page.asset.no-license-issues')}</p>
        )}
      </div>
    </div>
  );
};
