import { useState } from 'react';

export enum AssetModals {
  ProductAssets = 'productAssets',
  RemoveAsset = 'removeAsset',
  BulkRemoveAssetsFromProduct = 'bulkRemoveAssetsFromProduct',
  ProductsAssociation = 'productsAssociation',
  ConfirmAssociation = 'confirmAssociation',
  MarkInactiveAssetWarning = 'markInactiveAssetWarning',
}

export type AssetModalsState = Record<AssetModals, boolean>;

export type AssetModalsControls = {
  openModal: (modal: AssetModals) => void;
  closeModal: (modal: AssetModals) => void;
};

const useAssetsModals = () => {
  const [modalState, setModalState] = useState<AssetModalsState>({
    [AssetModals.ProductAssets]: false,
    [AssetModals.RemoveAsset]: false,
    [AssetModals.BulkRemoveAssetsFromProduct]: false,
    [AssetModals.ProductsAssociation]: false,
    [AssetModals.ConfirmAssociation]: false,
    [AssetModals.MarkInactiveAssetWarning]: false,
  });

  const openModal = (modal: AssetModals) =>
    setModalState((prevState) => ({ ...prevState, [modal]: true }));
  const closeModal = (modal: AssetModals) =>
    setModalState((prevState) => ({ ...prevState, [modal]: false }));

  const modalControls: AssetModalsControls = {
    openModal,
    closeModal,
  };

  return { modalState, modalControls };
};

export default useAssetsModals;
