const formatBigCount = (num: number) => {
  if (num < 1000) {
    return num.toString(); // Return the number as a string if it's less than 1000
  }
  const units = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];
  let unitIndex = 0;

  while (num >= 1000 && unitIndex < units.length - 1) {
    num /= 1000;
    unitIndex++;
    if (num < 1000) {
      break; // Stop if the number is less than 1000 after division
    }
  }

  // Format to one decimal place if needed and remove any trailing '.0'
  const numFixed = num % 1 === 0 ? num.toString() : num.toFixed(1);
  return numFixed.replace(/\.0$/, '') + units[unitIndex];
};

export default formatBigCount;
