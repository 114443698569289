import '../../scss/pages/vendor_portal.scss';
import '../../scss/pages/sboms.scss';
import { useState, useEffect, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { Form } from 'rsuite';
import { Button, Loader } from '@mantine/core';
import { FileType } from 'rsuite/esm/Uploader';
import { Uploader, Input } from 'rsuite';
import { useInterval } from '../../hooks/utils/useInterval';
import prettyBytes from 'pretty-bytes';
import Icon from '../../components/Icon';
import Logo from '../../components/Logo';
import Faq from './faq';
import appConfig from '../../configs/appConfig';
import Loading from '../../components/Loading';
import hitApi from '../../api';

const Textarea = forwardRef<HTMLTextAreaElement>((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));
interface InterfaceUploadedSbom {
  sbomId: string;
  fileName: string;
  status: 'inited' | 'processing' | 'error' | 'finished';
  size: number;
  formatVersion?: string;
  errors?: string[];
}
function VendorPortalUploadCenter() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [uploadFileList, setUploadFileList] = useState<FileType[]>([]);
  const [uploadedSboms, setUploadedSboms] = useState<InterfaceUploadedSbom[]>([]);
  const [orgName, setOrgName] = useState('Manifest');
  // const [messageToOrg, setMessageToOrg] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [processingComplete, setProcessingComplete] = useState(false);

  const uploaderRef = useRef();

  const requestInfo = sessionStorage.getItem('requestInfo');
  if (!requestInfo) return <Navigate to="/" />;
  const { requestingOrgId, requestingOrgName, vendorHash } = JSON.parse(
    requestInfo || '',
  );
  if (!requestingOrgId || !requestingOrgName || !vendorHash) return <Navigate to="/" />;

  useEffect(() => {
    setOrgName(requestingOrgName);
    setIsLoading(false);
  });
  useEffect(() => {
    setFormValid(uploadFileList.length > 0);
  }, [uploadFileList]);

  const handleUploadDone = async (e: any, uploadedFile: FileType) => {
    const { data } = e;
    const newlyUploadedSboms: InterfaceUploadedSbom[] = [];
    data.forEach((item: any) => {
      // the following properties come form the server response
      const { fileName, _id: sbomId } = item;
      let itemSize = 0;
      // remove the uploaded item from the filelist and add it to the uploaded sboms list
      // at the same time record its size as this is the last time we have access to it.
      setUploadFileList((uploadFileList) => {
        return uploadFileList.filter((file) => {
          if (file.fileKey !== uploadedFile.fileKey) {
            return true;
          } else {
            itemSize = file?.blobFile?.size || 0;
          }
        });
      });
      const sbom: InterfaceUploadedSbom = {
        sbomId,
        fileName,
        status: 'inited',
        size: itemSize,
      };
      newlyUploadedSboms.push(sbom);
    });
    if (newlyUploadedSboms.length > 0)
      setUploadedSboms((prevSboms) => [...prevSboms, ...newlyUploadedSboms]);
  };

  useEffect(() => {
    if (uploadFileList.length > 0 || uploadedSboms.length === 0)
      return setProcessingComplete(false);
    for (const sbom of uploadedSboms) {
      if (sbom.status !== 'finished' && sbom.status !== 'error') {
        return setProcessingComplete(false);
      }
    }
    return setProcessingComplete(true);
  }, [uploadFileList, uploadedSboms]);

  const fetchSboms = async () => {
    if (uploadedSboms.length > 0) {
      const allSbomsResponse = await hitApi.get(`/sboms`);
      if (allSbomsResponse.success) {
        const { data: allSboms } = allSbomsResponse;
        const newSbomStatus = uploadedSboms.map((sbom: InterfaceUploadedSbom) => {
          const update: InterfaceUploadedSbom = {
            ...sbom,
          };
          const sbomDb = allSboms.find((sdb: any) => sdb._id.toString() === sbom.sbomId);
          if (sbomDb.whenUploaded) update.status = 'inited';
          if (sbomDb.whenProcessing) update.status = 'processing';
          if (sbomDb.whenProcessed) update.status = 'finished';
          if (sbomDb.uploadErrors.length > 0 || sbomDb.processErrors.length > 0) {
            update.status = 'error';
            update.errors = [...sbomDb.uploadErrors, ...sbomDb.processErrors];
          }
          if (sbomDb.formatVersion || sbomDb.format) {
            update.formatVersion = sbomDb.formatVersion || sbomDb.format;
          }
          return update;
        });
        setUploadedSboms(newSbomStatus);
      }
    }
  };
  useInterval(fetchSboms, 2000);

  const handleFormSubmit = async (e: any) => {
    // @ts-ignore
    uploaderRef.current.start();
  };
  const handleComplete = async () => {
    try {
      await hitApi.get(
        `vendor/completeUpload?vendorHash=${vendorHash}&requestingOrgId=${requestingOrgId}`,
      );
    } catch (e) {
      console.error(e);
    }
    navigate('/vendor-portal/thankyou');
  };

  const getIcon = (
    status: 'inited' | 'uploading' | 'processing' | 'error' | 'finished' | undefined,
  ) => {
    switch (status) {
      case 'inited':
        return <Icon icon="circle-ellipsis" iconStyle="far" />;
      case 'uploading':
        return <Loader className="status-loader" speed="slow" />;
      case 'processing':
        return <Loader className="status-loader" speed="slow" />;
      case 'finished':
        return <Icon icon="circle-check" iconStyle="far" />;
      case 'error':
        return <Icon icon="circle-exclamation" iconStyle="far" />;
      default:
        return <Icon icon="circle-exclamation" iconStyle="far" />;
    }
  };

  return (
    <div className="vendor-portal-container">
      {isLoading ? (
        <div className="loader-container">
          <Loading />
        </div>
      ) : (
        <>
          <div className="banner">
            {' '}
            <Logo wordmark={true} />
          </div>
          <div className="upload-center-content">
            <div className="pane">
              <div className="section-upload portal-text">
                <div className="header">{t('page.vendorPortal.uploadCenter.header')}</div>
                <div className="subHeader">
                  {t('page.vendorPortal.uploadCenter.subHeader', {
                    organizationName: orgName,
                  })}
                </div>
                <div className="sbom-upload anim-slideInUpShort">
                  <Uploader
                    fileList={uploadFileList}
                    listType="picture-text"
                    action={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/upload?onBehalfOfOrgId=${requestingOrgId}&vendorUploadHash=${vendorHash}`}
                    method="PUT"
                    multiple
                    withCredentials
                    autoUpload={false}
                    onChange={setUploadFileList}
                    onSuccess={handleUploadDone}
                    draggable
                    ref={uploaderRef}
                    renderFileInfo={(file, fileElement) => {
                      const { status, blobFile } = file;
                      return (
                        <div className="upload-file-item portal-text">
                          <div className="">{file.name}</div>
                          <div className={`subText ${status === 'error' && 'error'}`}>
                            {status === 'error' ? (
                              <span>
                                {t('page.vendorPortal.uploadCenter.uploadErrorMessage')}
                              </span>
                            ) : (
                              <>
                                <span>{prettyBytes(blobFile?.size || 0)}</span>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }}
                    renderThumbnail={(file, fileElement) => {
                      const { status } = file;
                      return (
                        <div className={`upload-file-item-icon ${status}`}>
                          {getIcon(status)}
                        </div>
                      );
                    }}
                    accept=".json,.xml"
                  >
                    <div
                      style={{
                        height: 118,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon icon="arrow-up-from-bracket" iconStyle="far" />
                      <span>
                        <span className="hilighted-text">Click to upload</span> or drag
                        and drop a new SBOM
                      </span>
                      <p className="uploader-subtext">
                        {t('page.uploads.uploader-subtext')}
                      </p>
                    </div>
                  </Uploader>
                  <div className="message-to-org">
                    <Form onSubmit={handleFormSubmit} fluid className="login-form">
                      {/* <Form.Group className="form-field" controlId="vendorMessageToOrg">
                        <Form.ControlLabel className="bold">
                          {t('page.vendorPortal.uploadCenter.labelMessageToOrg', {
                            organizationName,
                          })}
                        </Form.ControlLabel>
                        <Form.Control
                          accepter={Textarea}
                          onChange={setMessageToOrg}
                          value={messageToOrg}
                          name="vendorName"
                        />
                      </Form.Group> */}
                      <Form.Group className="form-field">
                        {formError && (
                          <div className="form-error">
                            {t('page.vendorPortal.errorMessage')}
                          </div>
                        )}
                        <Button size="lg" fullWidth type="submit" disabled={!formValid}>
                          {t(`page.vendorPortal.uploadCenter.startButtonLabel`)}
                        </Button>
                      </Form.Group>
                    </Form>
                    {uploadedSboms.length > 0 && (
                      <div className="processing-items">
                        <div className="bold">Processing</div>
                        {uploadedSboms.map((sbom: InterfaceUploadedSbom, idx: number) => (
                          <div className="sbom-processing-row" key={idx}>
                            <div className="upload-file-item-icon-container">
                              <div className={`upload-file-item-icon ${sbom.status}`}>
                                {getIcon(sbom.status)}
                              </div>
                            </div>
                            <div className="upload-file-item padded portal-text">
                              <div className="">{sbom.fileName}</div>
                              <div
                                className={`subText ${
                                  sbom.status === 'error' && 'error'
                                }`}
                              >
                                {sbom.status === 'error' ? (
                                  <span>
                                    {sbom.errors && sbom?.errors?.length > 0 ? (
                                      <>{sbom.errors.join(' ')}</>
                                    ) : (
                                      <>
                                        {t(
                                          'page.vendorPortal.uploadCenter.uploadErrorMessage',
                                        )}
                                      </>
                                    )}
                                  </span>
                                ) : (
                                  <>
                                    <span className="sbom-meta">
                                      {prettyBytes(sbom?.size || 0)}
                                    </span>
                                    <span className="sbom-meta">
                                      {sbom?.formatVersion}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {uploadedSboms.length ? (
                      <Button
                        color="dark"
                        fullWidth
                        size="lg"
                        disabled={!processingComplete}
                        onClick={handleComplete}
                        sx={{ marginTop: '1rem' }}
                      >
                        {t(`page.vendorPortal.uploadCenter.completeUpload`)}
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="pane pane-slim bg-component">
              <div className="section-content">
                <Faq />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default VendorPortalUploadCenter;
