import hitApi from '../../../../../api';
import { buildUrlWithParams } from '../../../../../lib/url/buildWithParams/buildWithParams';
import {
  SharedSbomsApiResponse,
  SharedSbomsResponse,
} from '../../types/sharedSboms.interface';

import { mapSharedSboms, MapSharedSbomsTexts } from './sharedSboms.mapper';

interface GetUrlParams {
  limit?: number;
  page?: number;
  sort?: {
    column: string;
    type: 1 | -1;
  };
  portalName: string;
}

const getUrl = ({ limit, page, sort, portalName }: GetUrlParams): string => {
  const sortParam = sort
    ? {
        [sort.column]: sort.type,
        _id: sort.type,
      }
    : undefined;

  return buildUrlWithParams(`sharing-portal/${portalName}/sboms`, {
    limit,
    page,
    sort: sortParam,
  });
};

export interface GetSharedSbomsParams extends GetUrlParams {
  texts?: MapSharedSbomsTexts;
}

const getElements = async ({
  limit,
  page,
  sort,
  texts,
  portalName,
}: GetSharedSbomsParams): Promise<SharedSbomsResponse> => {
  const response: SharedSbomsApiResponse = await hitApi.get(
    SharedSbomsApi.getUrl({ limit, page, sort, portalName }),
  );

  if (!response.success) {
    return Promise.reject(response);
  }

  return {
    ...response,
    data: mapSharedSboms(response.data, texts),
  };
};

export const SharedSbomsApi = {
  getElements,
  getUrl,
};
