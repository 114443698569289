import { Card, Table, Title, Flex, Text, Badge, Tooltip } from '@mantine/core';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { truncateString } from '../../../../lib/truncateString';
import { IconCategory } from '@tabler/icons-react';
import styles from './TopAssets.module.scss';
import Icon from '../../../Icon';
import ClickableRegion from '../../../ClickableRegion';
import capitalizeWords from '../../../../lib/capitalizeWords';
import { FetchProductOverviewResponse } from '../../../../hooks/products/useFetchProductOverview';

type ProductOverviewData = FetchProductOverviewResponse['data'];

interface InterfaceProductOverviewTopAssets {
  top5Assets: ProductOverviewData['top5Assets'];
}

export const ProductOverviewTopAssets = ({
  top5Assets,
}: InterfaceProductOverviewTopAssets) => {
  const { t } = useTranslation();

  function formatVulnerabilityDetails(asset: InterfaceOrganizationAsset): JSX.Element {
    const detailElements = [];

    if (asset.countVulnerabilities?.inKEV) {
      detailElements.push(
        <Trans
          key="inKEV"
          i18nKey="page.product.vulnerabilityDetails.kev"
          components={{
            span: <span />,
            number: <span style={{ color: 'white' }} />,
            kev: <span className={styles.kev} />,
          }}
          values={{ count: asset.countVulnerabilities.inKEV }}
          defaults="<number>{{count}}</number> <kev>KEV</kev>"
        />,
      );
    }

    if (asset.countVulnerabilities?.mitigate) {
      detailElements.push(
        <Trans
          key="mitigate"
          i18nKey="page.product.vulnerabilityDetails.mitigate"
          components={{
            span: <span />,
            number: <span style={{ color: 'white' }} />,
            mitigate: <span className={styles.mitigate} />,
          }}
          values={{ count: asset.countVulnerabilities.mitigate }}
          defaults="<number>{{count}}</number> <mitigate>Mitigate</mitigate>"
        />,
      );
    }

    if (asset.countVulnerabilities?.monitor) {
      detailElements.push(
        <Trans
          key="monitor"
          i18nKey="page.product.vulnerabilityDetails.monitor"
          components={{
            span: <span />,
            number: <span style={{ color: 'white' }} />,
            monitor: <span className={styles.monitor} />,
          }}
          values={{ count: asset.countVulnerabilities.monitor }}
          defaults="<number>{{count}}</number> <monitor>Monitor</monitor>"
        />,
      );
    }

    // Combine the detail elements with commas
    const combinedDetails =
      detailElements.length > 0
        ? detailElements.reduce(
            (acc, elem, index) => [
              ...acc,
              index > 0 ? <span key={`comma-${index}`}>, </span> : null,
              elem,
            ],
            [] as React.ReactNode[],
          )
        : null;

    return (
      <>
        <Trans
          i18nKey="page.product.vulnerabilityDetails.total"
          components={{
            span: <span style={{ color: 'white' }} />,
          }}
          values={{ count: asset.countVulnerabilities?.total }}
          defaults="<span>{{count}}</span> vulns"
        />
        {combinedDetails && <span> ({combinedDetails})</span>}
      </>
    );
  }

  return (
    <Card className={styles.topAssets}>
      <Title className={styles.title}>{t('page.product.top5RiskiestAssets')} </Title>
      {!top5Assets && (
        <Text className={styles.noAssets}>{t('page.product.noAssets')}</Text>
      )}
      {!!top5Assets && (
        <Table>
          <thead>
            <tr>
              <th className={styles.tableHead}>{t('page.product.assetName')}</th>
            </tr>
          </thead>
          <tbody>
            {top5Assets.map((asset: InterfaceOrganizationAsset, index: number) => (
              <tr key={index}>
                <td>
                  <Flex gap="4px" direction="column">
                    <Flex gap="8px" align="center">
                      <ClickableRegion
                        className={styles.asset}
                        target="_blank"
                        href={`/asset/${asset._id}`}
                      >
                        <Text className={styles.assetName}>{asset.name}</Text>
                      </ClickableRegion>
                      <Tooltip
                        disabled={(asset?.version?.length ?? 0) < 10}
                        label={asset?.version}
                      >
                        <Badge className={styles.assetVersion}>
                          {!!asset.version && truncateString(asset.version, 10)}
                        </Badge>
                      </Tooltip>
                    </Flex>
                    <Flex gap="16px">
                      <Flex gap="4px" className={styles.assetType}>
                        {/*Follows the same specs from Assets Table*/}
                        {asset.type && (
                          <>
                            <IconCategory
                              color="#8B8F98"
                              className={styles.icon}
                              size="14px"
                            />
                            <p>{capitalizeWords(asset.type)}</p>
                          </>
                        )}
                      </Flex>
                      <Flex gap="4px" align="flex-start" className={styles.assetVulns}>
                        <Icon icon="exclamation-triangle" classNames={styles.icon} />
                        <p>{formatVulnerabilityDetails(asset)}</p>
                      </Flex>
                    </Flex>
                  </Flex>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Card>
  );
};
