import { Box, Button, Divider, Flex, Stack, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import * as Sentry from '@sentry/react';
import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchSharingPortal } from '../../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import { usePostSharingPortal } from '../../../api/sharingPortal/hooks/usePostSharingPortal/usePostSharingPortal';
import { ActionableCard } from '../../../components/ActionableCard/ActionableCard';
import ClickableRegion from '../../../components/ClickableRegion';
import Icon from '../../../components/Icon';
import Loading from '../../../components/Loading';
import { ManifestTextInput } from '../../../components/ManifestTextInput/ManifestTextInput';
import MessageWithBackground from '../../../components/MessageWithBackground/MessageWithBackground';
import appConfig from '../../../configs/appConfig';
import { isValidURL } from '../../../lib/url/isValidUrl/isValidUrl';
import { ALPHANUMERIC_REGEX } from '../../../regExp.constant';
import styles from './SharingPortalSettings.module.scss';

interface SharingPortalSettingsForm {
  isEnabled: boolean;
  name: string;
  logoUrl: string;
}

const PORTAL_NAME_ALREADY_TAKEN = 'Portal name is already taken';

export const SharingPortalSettings = () => {
  const { t } = useTranslation();
  const { data: portal, isLoading, isError, refetch } = useFetchSharingPortal();
  const {
    mutateAsync: postPortal,
    isError: isErrorUpdating,
    error: errorUpdatingList,
  } = usePostSharingPortal();
  const errorUpdating = (errorUpdatingList as unknown[] | undefined)?.[0];
  const isDuplicatedNameError =
    isErrorUpdating && errorUpdating === PORTAL_NAME_ALREADY_TAKEN;
  console.log(isDuplicatedNameError, errorUpdatingList, errorUpdating, isErrorUpdating);
  const form = useForm<SharingPortalSettingsForm>({
    initialValues: {
      isEnabled: false,
      name: '',
      logoUrl: '',
    },
    validate: ({ name, logoUrl }: SharingPortalSettingsForm) => {
      const errors: Partial<Record<keyof SharingPortalSettingsForm, string>> = {};
      const requiredFieldMessage = t('global.form.errors.fieldCannotBeBlank');
      if (!name) {
        errors['name'] = requiredFieldMessage;
      }

      if (logoUrl && !isValidURL(logoUrl)) {
        errors['logoUrl'] = t('global.form.errors.invalidUrl');
      }

      return errors;
    },
    validateInputOnBlur: true,
  });
  const portalPath = `${appConfig.siteProtocol}${appConfig.siteURL}/o/${form.values.name}`;

  useEffect(() => {
    if (portal) {
      form.setValues({
        isEnabled: portal.isActive,
        name: portal.portalName,
        logoUrl: portal.logoUrl,
      });
    }
  }, [portal]);

  useEffect(() => {
    if (errorUpdating && isDuplicatedNameError) {
      form.setFieldError('name', t('global.form.errors.nameIsDuplicated'));
    }
  }, [errorUpdating, isDuplicatedNameError]);

  const onUpdatePortalSettings = async () => {
    if (!form.isValid()) {
      form.validate();
    }

    // When creating portal by first time, should be enabled to save name and logoUrl
    if (!portal && !form.values.isEnabled) {
      return;
    }

    const payload = {
      isActive: form.values.isEnabled,
      portalName: form.values.name,
      logoUrl: form.values.logoUrl,
    };

    try {
      await postPortal(payload);
    } catch (error) {
      Sentry.captureMessage(`Error updating sharing portal`, 'error');
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {isError && (
        <ActionableCard
          title={t('error.general.errorLoadingData')}
          subtitle={t('error.general.errorLoadingDataSubtitle')}
          Icon={<Icon icon="circle-exclamation" size="lg" />}
          primaryAction={{
            label: t('global.retry'),
            onClick: () => {
              refetch();
            },
            isLoading: isLoading,
          }}
        />
      )}
      {!isError && !isLoading && (
        <div>
          <div>
            {isErrorUpdating && !isDuplicatedNameError && (
              <Box mb="12px">
                <MessageWithBackground
                  message={'An unexpected error occurred while saving. Please try again.'}
                  messageType={'critical'}
                />
              </Box>
            )}
            <Flex justify={'space-between'} gap="40px">
              <div>
                <h1 className={styles.title}>
                  {t('page.userSettings.sharingPortal.title')}
                </h1>
                <span className={styles.subtitle}>
                  {t('page.userSettings.sharingPortal.subtitle')}
                </span>
              </div>
              <Button variant="filled" onClick={onUpdatePortalSettings}>
                {t('global.save.buttonShort')}
              </Button>
            </Flex>
          </div>
          <Divider my="24px" />

          <Stack spacing={'32px'}>
            <Switch
              labelPosition="left"
              size="md"
              label={t('page.userSettings.sharingPortal.enableLabel')}
              description={t('page.userSettings.sharingPortal.enableDescription')}
              classNames={{
                description: styles.switchDescription,
                label: styles.switchLabel,
              }}
              {...form.getInputProps('isEnabled')}
              checked={form.getInputProps('isEnabled').value}
            />

            <div>
              <ManifestTextInput
                inputClasName={styles.nameInput}
                size="md"
                label={t('page.userSettings.sharingPortal.urlLabel')}
                description={t('page.userSettings.sharingPortal.urlDescription')}
                inputProps={{
                  ...form.getInputProps('name'),
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    form.setFieldValue('name', value.replace(ALPHANUMERIC_REGEX, ''));
                  },
                }}
              />
              <div>
                <span>URL:</span>{' '}
                <ClickableRegion target="_blank" href={portalPath}>
                  {portalPath}
                </ClickableRegion>
              </div>
            </div>
            <ManifestTextInput
              inputClasName={styles.logoUrlInput}
              size="md"
              label={t('page.userSettings.sharingPortal.logoLabel')}
              description={t('page.userSettings.sharingPortal.logoDescription')}
              inputProps={form.getInputProps('logoUrl')}
            />
          </Stack>
        </div>
      )}
    </>
  );
};
