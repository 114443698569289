import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input } from 'rsuite';
import { Button } from '@mantine/core';
import { ReactMultiEmail } from 'react-multi-email';
import hitApi from '../../api';

interface RequestSbomModalProps {
  open: boolean;
  onClose: () => void;
}
function RequestSbomModal({ open, onClose }: RequestSbomModalProps) {
  const { t } = useTranslation();
  const [requestSbomModalOpen, setRequestSbomModalOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [messageToVendor, setMessageToVendor] = useState('');

  useEffect(() => {
    if (open) {
      setRequestSbomModalOpen(true);
    } else {
      setRequestSbomModalOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setErrors([]);
  }, [emails]);

  const handleSendEmails = async () => {
    setLoading(true);
    try {
      const result = await hitApi.post('/vendor/requestSboms', true, {
        emails,
        messageToVendor,
      });
      if (!result.success) throw new Error('unable to send request');
      setLoading(false);
      setEmails([]);
      setMessageToVendor('');
      onClose();
    } catch (e) {
      setErrors([...errors, 'unable to send request']);
    } finally {
      setLoading(false);
    }
  };
  const handleMessageToVendorUpdate = (e: any) => {
    setMessageToVendor(e);
  };
  return (
    <Modal
      open={requestSbomModalOpen}
      onClose={onClose}
      className="request-sbom-modal"
      // onEntered={shareSboms}
      // onExited={() => {
      //   setShareLink('');
      // }}
    >
      <Modal.Header>
        <Modal.Title>{t(`page.uploads.requestSbomModal.header`)}</Modal.Title>
        <span className="subHeader">{t(`page.uploads.requestSbomModal.subHeader`)}</span>
      </Modal.Header>
      <Modal.Body>
        {errors && errors.length > 0 && (
          <ul className="page-errors anim-slideInUpShort">
            {errors.map((error) => (
              <li key="error">{error}</li>
            ))}
          </ul>
        )}
        <div className="emails-container manifest-react-multi-email">
          <span className="field-label">
            {t(`page.uploads.requestSbomModal.enterEmails`)}
          </span>
          {/* <ClickableRegion
            regionLabel="View Asset"
            href={`#`}
          >
            <Icon icon="arrow-up-from-bracket" iconStyle="far" />
            <span>{t('page.uploads.requestSbomModal.uploadCsv')}</span>
          </ClickableRegion> */}
          <div className="multi-email">
            <ReactMultiEmail
              placeholder={t('page.uploads.requestSbomModal.enterEmailsPlaceholder')}
              emails={emails}
              onChange={(_emails: string[]) => {
                setEmails(_emails);
              }}
              autoFocus={true}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="message-container">
          <span className="field-label full-width">
            {t(`page.uploads.requestSbomModal.message`)}
          </span>
          {/* <Input className="manifest-input" onChange={handleMessageToVendorUpdate} /> */}
          <Input
            as="textarea"
            rows={3}
            className="manifest-textarea"
            value={messageToVendor}
            onChange={handleMessageToVendorUpdate}
            placeholder={t('page.uploads.requestSbomModal.message-placeholder')}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button
          loading={loading}
          fullWidth
          disabled={emails.length === 0}
          onClick={() => {
            handleSendEmails();
          }}
        >
          {t(`page.uploads.requestSbomModal.btnSendEmails`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RequestSbomModal;
