import { TFunction } from 'i18next';
import { InterfaceFullUser } from '../../../../types/FullInterfaces';
import { MappedSbomAlert } from '../types/SBOMAlerts.interface';

interface GetAlertMetadataParams {
  dateModifiedText?: MappedSbomAlert['dateModifiedText'];
  dateCreatedText?: MappedSbomAlert['dateCreatedText'];
  dateLastExecutedText?: MappedSbomAlert['dateLastExecutedText'];
  lastActionType: MappedSbomAlert['lastActionType'];
  hasNeverRan: boolean;
  t: TFunction;
  userInfo: InterfaceFullUser | undefined;
}

export const getAlertMetadata = ({
  dateModifiedText,
  dateCreatedText,
  lastActionType,
  dateLastExecutedText,
  hasNeverRan,
  userInfo,
  t,
}: GetAlertMetadataParams): {
  lastExecutedText: string;
  lastUpdateText: string;
} => {
  const lastModifiedText =
    lastActionType === 'updated'
      ? t('global.updated', {
          date: dateModifiedText,
        })
      : t('global.createdDate', {
          date: dateCreatedText,
        });

  const byUserText = userInfo
    ? `${t('global.byUser')} ${`${userInfo?.firstName} ${userInfo?.lastName}`.trim()}`
    : '';

  const lastExecutedText = hasNeverRan
    ? t('page.userSettings.sbomUploadAlerts.neverRan')
    : t('page.userSettings.sbomUploadAlerts.lastRan', {
        date: dateLastExecutedText,
      });

  return {
    lastExecutedText,
    lastUpdateText: `${lastModifiedText} ${byUserText}`,
  };
};
