import { InterfaceNotificationSetting } from '@manifest-cyber/types/interface/dbTables';
import { Box, CloseButton, Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../../../components/ColoredMessage/ColoredMessage';
import Icon from '../../../../../components/Icon';
import MessageWithBackground from '../../../../../components/MessageWithBackground/MessageWithBackground';
import { SbomUploadAlertSectionState } from '../../../../../types/FullInterfaces';
import { SbomAlertRiskCriteriasFormValues } from '../../hooks/useSbomAlertsForms';
import {
  RiskCriteriaSelector,
  SbomAlertCreationState,
} from '../../reducer/alertCreateReducer.interface';
import {
  RiskCriteriaFilterId,
  SBOMAlertControlsConfig,
} from '../../utils/getSbomAlertsConfig';
import AlertFormSection from '../AlertSection/AlertSection';
import { LinkButton } from '../LinkButton/LinkButton';
import SectionDetailSkeleton from '../SectionDetailSkeleton/SectionDetailSkeleton';
import RiskCriteriasDetail from './RiskCriteriaDetail/RiskCriteriaDetail';
import RiskCriteriaFilter from './RiskCriteriaFiler/RiskCriteriaFilter';
import styles from './RiskCriteriaSection.module.scss';

const filterOptionsAlreadySelected = ({
  values,
  options,
  id,
}: {
  id: keyof SbomAlertRiskCriteriasFormValues['riskCriteriaSelectedFilters'];
  values: SbomAlertRiskCriteriasFormValues['riskCriteriaSelectedFilters'];
  options: SBOMAlertControlsConfig['riskCriteriaSection']['filters'];
}): SBOMAlertControlsConfig['riskCriteriaSection']['filters'] => {
  const otherSelectsValues = Object.entries(values).filter(([key]) => key !== id);

  return options.filter(
    (option) => !otherSelectsValues.some(([_, value]) => option.value === value),
  );
};

interface RiskCriteriaProps {
  state: SbomUploadAlertSectionState;
  riskCriterias: SbomAlertCreationState['riskCriteria']['criterias'];
  sectionConfig: SBOMAlertControlsConfig['riskCriteriaSection'];
  form: UseFormReturnType<SbomAlertRiskCriteriasFormValues>;
  editButton?: {
    isVisible: boolean;
    onClick?: () => void;
  };
  nextButton?: {
    isVisible: boolean;
    onClick?: () => void;
    isDisabled?: boolean;
  };
  alert?: InterfaceNotificationSetting;
  onClickAddCriteria?: () => void;
  onClickRemoveCriteria?: (criteria: RiskCriteriaSelector) => void;
  isLoading?: boolean;
}

function RiskCriteriaSection({
  state,
  editButton,
  nextButton,
  onClickAddCriteria,
  sectionConfig,
  riskCriterias,
  onClickRemoveCriteria,
  form,
  isLoading = false,
}: Readonly<RiskCriteriaProps>) {
  const { t } = useTranslation();
  const criteriaOptions = sectionConfig.filters;

  return (
    <AlertFormSection
      titleIconName="shield-exclamation"
      title={t('page.userSettings.sbomUploadAlerts.riskCriteria.title')}
      description={t('page.userSettings.sbomUploadAlerts.riskCriteria.description')}
      state={state}
      nextButton={{
        isVisible: Boolean(nextButton?.isVisible),
        isDisabled: nextButton?.isDisabled,
        onClick: nextButton?.onClick,
        label: t('global.next'),
      }}
      editButton={{
        isVisible: Boolean(editButton?.isVisible),
        onClick: editButton?.onClick,
        label: t('global.edit'),
      }}
      ViewComponent={
        isLoading ? (
          <SectionDetailSkeleton itemsNumber={4} />
        ) : (
          <RiskCriteriasDetail form={form} />
        )
      }
      EditComponent={
        <>
          <div className={styles.filters}>
            {form.errors.riskCriteriaSelectedFilters && (
              <MessageWithBackground
                message={form.errors.riskCriteriaSelectedFilters}
                messageType="critical"
              />
            )}
            {riskCriterias.map((riskCriteria) => {
              const controlProps = form.getInputProps(
                `riskCriteriaSelectedFilters.${riskCriteria.id}`,
              );
              return (
                <div className={styles.filter} key={riskCriteria.id}>
                  <div className={styles.filterSelect}>
                    <Select
                      aria-label={t(
                        'page.userSettings.sbomUploadAlerts.riskCriteria.selectCriteria',
                      )}
                      data-testid="risk-criteria-selector"
                      classNames={{
                        input: styles.filterText,
                      }}
                      key={riskCriteria.value}
                      {...controlProps}
                      error={
                        controlProps.error && (
                          <Box mt={8}>
                            <ErrorMessage message={controlProps.error} />
                          </Box>
                        )
                      }
                      onChange={(value) => {
                        const typedValue = value as RiskCriteriaFilterId;

                        if (typedValue === 'onCISAKEVList') {
                          form.setFieldValue('onCISAKEVList', true);
                        }

                        form
                          .getInputProps(`riskCriteriaSelectedFilters.${riskCriteria.id}`)
                          .onChange(value);
                      }}
                      data={filterOptionsAlreadySelected({
                        options: criteriaOptions,
                        id: riskCriteria.id,
                        values: form.values.riskCriteriaSelectedFilters,
                      })}
                    />
                  </div>
                  {form.values.riskCriteriaSelectedFilters[`${riskCriteria.id}`] && (
                    <div className={styles.filterValue}>
                      <RiskCriteriaFilter
                        criteriaId={
                          form.values.riskCriteriaSelectedFilters[`${riskCriteria.id}`]
                        }
                        controls={sectionConfig.controls}
                        form={form}
                      />
                    </div>
                  )}
                  <CloseButton
                    onClick={() => {
                      onClickRemoveCriteria?.(riskCriteria);
                    }}
                    size="md"
                    aria-label={t(
                      'page.userSettings.sbomUploadAlerts.riskCriteria.removeCriteria',
                    )}
                  />
                </div>
              );
            })}
          </div>

          {criteriaOptions.length > riskCriterias.length && (
            <div className={styles.addFilterButtonContainer}>
              <LinkButton
                text={t('page.userSettings.sbomUploadAlerts.riskCriteria.addCriteria')}
                leftIcon={<Icon icon="plus" />}
                onClick={() => {
                  form.clearFieldError('riskCriteriaSelectedFilters');
                  onClickAddCriteria?.();
                }}
              />
            </div>
          )}
        </>
      }
    />
  );
}

export default RiskCriteriaSection;
