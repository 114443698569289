import debounce from 'lodash.debounce';
import { MRT_PaginationState } from 'mantine-react-table';
import { useState, useRef, useCallback } from 'react';
import {
  useURLFilterStringState,
  useURLandLocalStorageSortingState,
} from '../../../../../hooks/utils/usePersistentStates';

export enum ComponentsFilterSortParams {
  search = 'components_search',
  sort = 'components_sort',
  page = 'components_page',
  limit = 'components_limit',
}

export const useVulnAffectedComponentFilters = () => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [componentSearchFilter, setComponentSearchFilter] = useURLFilterStringState(
    ComponentsFilterSortParams.search,
    '',
    'replaceIn',
  );

  const debouncedComponentSearchFilter = useRef(debounce(setComponentSearchFilter, 200));

  const handleSetComponentSearchFilter = useCallback((value: string) => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    debouncedComponentSearchFilter.current(value);
  }, []);

  const [sorting, setSorting] = useURLandLocalStorageSortingState(
    'impactComponents',
    [],
    'replaceIn',
  );

  return {
    componentSearchFilter,
    handleSetComponentSearchFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
  };
};
