import { useState, useEffect } from 'react';
import { Modal } from 'rsuite';
// import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';

interface MergeModalProps {
  open: boolean;
  onClose: () => void;
}

function MergeModal({ open, onClose }: MergeModalProps) {
  // const { t } = useTranslation();
  const [shareModalOpen, setShareModalOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setShareModalOpen(true);
    } else {
      setShareModalOpen(false);
    }
  }, [open]);

  return (
    <Modal open={shareModalOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Merging, Just a Moment...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="merge-modal-inner">
          <Loading />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MergeModal;
