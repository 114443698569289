import { useState, useEffect } from 'react';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { useAddAssetsToProduct } from './useAddAssetsToProduct';

interface UseManageAssetsProps {
  assets: InterfaceOrganizationAsset[];
  currentAssets: InterfaceOrganizationAsset[];
  productId?: string;
  close: () => void;
}

export const useManageAssets = ({
  assets,
  currentAssets,
  productId,
  close,
}: UseManageAssetsProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAssets, setFilteredAssets] = useState(
    assets.filter(
      (asset) => !currentAssets.some((currentAsset) => currentAsset._id === asset._id),
    ),
  );
  const [selectedAssets, setSelectedAssets] = useState<InterfaceOrganizationAsset[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const { mutate, isError, isLoading, isSuccess } = useAddAssetsToProduct({
    hasErrorCallback: (errors: string[]) => setErrors(errors),
  });

  const handleFilter = (term: string) => {
    setSearchTerm(term);
    setFilteredAssets(
      assets.filter((asset) => asset.name.toLowerCase().includes(term.toLowerCase())),
    );
  };

  const handleSort = (order: 'name' | 'dateCreated' | 'dateModified') => {
    let sortedAssets;
    switch (order) {
      case 'name':
        sortedAssets = [...filteredAssets].sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'dateCreated':
        sortedAssets = [...filteredAssets].sort((a, b) => {
          if (!a.dateCreated && !b.dateCreated) return 0;
          if (!a.dateCreated) return -1;
          if (!b.dateCreated) return 1;
          return new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
        });
        break;
      case 'dateModified':
        sortedAssets = [...filteredAssets].sort((a, b) => {
          if (!a.dateModified && !b.dateModified) return 0;
          if (!a.dateModified) return -1;
          if (!b.dateModified) return 1;
          return new Date(a.dateModified).getTime() - new Date(b.dateModified).getTime();
        });
        break;
      default:
        sortedAssets = [...filteredAssets];
    }
    setFilteredAssets(sortedAssets);
  };

  const handleSelectAsset = (asset: InterfaceOrganizationAsset) => {
    if (selectedAssets.find((selected) => selected._id === asset._id)) {
      setSelectedAssets(selectedAssets.filter((selected) => selected._id !== asset._id));
    } else {
      setSelectedAssets([...selectedAssets, asset]);
    }
  };

  const submit = () => {
    if (productId) {
      mutate(
        [
          productId,
          selectedAssets.map(
            (asset: InterfaceOrganizationAsset) => asset._id?.toString() as string,
          ),
        ],
        {
          onSuccess: () => {
            setSelectedAssets([]);
            close();
          },
        },
      );
    }
  };

  const clearErrors = () => setErrors([]);

  const isAssetSelected = (assetId: string) =>
    selectedAssets.some((selected) => selected._id?.toString() === assetId);

  useEffect(() => {
    const newFilteredAssets = assets.filter(
      (asset) => !currentAssets.some((currentAsset) => currentAsset._id === asset._id),
    );

    if (searchTerm) {
      setFilteredAssets(
        newFilteredAssets.filter((asset) =>
          asset.name.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    } else {
      setFilteredAssets(newFilteredAssets);
    }

    if (!isError) {
      clearErrors();
    }
  }, [assets, currentAssets, isError, searchTerm]);

  return {
    searchTerm,
    filteredAssets,
    selectedAssets,
    setSelectedAssets,
    isLoading,
    errors,
    clearErrors,
    handleFilter,
    handleSort,
    handleSelectAsset,
    submit,
    isAssetSelected,
  };
};
