import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';
import { DefaultRepositorySettings } from '@manifest-cyber/types/interface/dbIntegration';

interface Params {
  manifestOrganizationId: string; //organizationId
  githubOrganizationId: string;
  defaultRepositoryOptions: DefaultRepositorySettings;
}

export const usePostGitHubDefaultSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      manifestOrganizationId,
      githubOrganizationId,
      defaultRepositoryOptions,
    }: Params) => {
      return hitApi.post(`integration/github/default-settings`, true, {
        manifestOrganizationId,
        githubOrganizationId,
        defaultRepositoryOptions,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['integration-github']);
    },
  });
};
