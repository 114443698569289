import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FeatureFlag } from '../../configs/featureFlags';
import { useAuth } from '../../hooks/useAuth';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import AssetsIcon from '../../img/illustrations/sidebar/Assets.svg?react';
import ComponentsIcon from '../../img/illustrations/sidebar/Components.svg?react';
import ProductsIcon from '../../img/illustrations/sidebar/Products.svg?react';
import ClickableRegion from '../ClickableRegion';
import Icon from '../Icon';
import Logo from '../Logo';
import './LeftNav.scss';

export const LeftNav = ({
  toggleExpandSidebar,
  isPaidOrg,
}: {
  toggleExpandSidebar: any;
  isPaidOrg: boolean;
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  // const [isSeasonalTheme, setSeasonalTheme] = useLocalStorage({
  //   key: 'enable_seasonal_theme',
  //   defaultValue: false,
  // });

  const triggerExpansion = () => {
    setIsExpanded(!isExpanded);
    toggleExpandSidebar();
  };

  const renderIcon = (icon: IconName | ReactElement) => {
    if (typeof icon === 'string') {
      // Render FontAwesome icon
      return <Icon icon={icon} iconSharp />;
    } else {
      // Render SVG icon
      return icon;
    }
  };

  // MOve this to a shared constant later
  const navigationItems: {
    icon: IconName | ReactElement;
    label: any;
    href: string;
    /**
     * Is this limited to Paid orgs?
     */
    isPaid: boolean;
    /**
     * Show 'New' moniker?
     */
    isNew?: boolean;
    /**
     * Show 'New' moniker?
     */
    isBeta?: boolean;
    /**
     * Classname/animation to attach on hover
     */
    hoverAnimation?: string;
    /**
     * Position this on the bottom, instad of the main top-left nav?
     */
    onBottom?: boolean;
    /**
     * Available to Staff only?
     */
    staffOnly?: boolean;
    /**
     * Hide from view? Will overwrite other conditions
     */
    hideFromView?: boolean;
    /**
     * Require a feature flag to be enabled.
     */
    requireFeatureFlag?: FeatureFlag;
  }[] = [
    {
      icon: 'house-flag',
      label: t('page.dashboard.nav-title'),
      href: '/',
      isPaid: true,
    },
    {
      icon: <ProductsIcon className="left-nav__custom-icon" />,
      label: t('page.products.nav-title'),
      href: '/products',
      isBeta: true,
      isPaid: true,
    },
    {
      icon: <AssetsIcon className="left-nav__custom-icon" />,
      label: t('page.assets.nav-title'),
      href: '/assets',
      isPaid: true,
    },
    {
      icon: <ComponentsIcon className="left-nav__custom-icon" />,
      label: t('page.components.nav-title'),
      href: '/components',
      isPaid: true,
    },
    {
      icon: 'brake-warning',
      label: t('page.vulnerabilities.nav-title'),
      href: '/vulnerabilities',
      isPaid: true,
    },
    {
      icon: 'file-shield',
      label: t('page.uploads.nav-title'),
      href: '/uploads',
      isPaid: false,
    },
    {
      icon: 'users-crown',
      label: 'Administration',
      href: '/administration',
      isPaid: false,
      // hoverAnimation: 'rotate',
      onBottom: true,
      requireFeatureFlag: 'engineerElements',
    },
  ];

  return (
    <div className="left-sidebar">
      <div className="top-side">
        <div className="top-menu">
          {isExpanded && (
            <div className="logo anim-slideInLeft">
              <Logo wordmark />
            </div>
          )}
          <ClickableRegion
            regionLabel="Click to toggle sidebar"
            onClick={() => triggerExpansion()}
            className="toggle-button anim-slideInLeftShort"
            tooltipPlacement="right"
          >
            <Icon icon="bars" />
          </ClickableRegion>
        </div>
        <ul className="nav-list">
          {navigationItems.map((navItem) => {
            const isActive = location?.pathname === navItem?.href;
            let isFeatureFlagEnabled = true;

            if (navItem?.requireFeatureFlag) {
              isFeatureFlagEnabled = useFeatureFlag(`${navItem?.requireFeatureFlag}`);
            }

            if (
              isFeatureFlagEnabled &&
              !navItem?.hideFromView &&
              !navItem?.onBottom &&
              (!navItem?.staffOnly || user?.internalRoles?.includes('staff')) &&
              (!navItem?.isPaid || isPaidOrg || user?.internalRoles?.includes('staff'))
            ) {
              return (
                <li key={`${navItem?.href}`}>
                  <ClickableRegion
                    regionLabel={navItem?.label}
                    href={navItem?.href}
                    className={`anim-slideInLeftShort ${isActive ? 'is-active' : ''}`}
                    tooltipPlacement="right"
                  >
                    <>
                      {navItem?.icon && renderIcon(navItem.icon)}
                      {isExpanded && navItem?.label && (
                        <span className="nav-text anim-slideInLeft">
                          {navItem?.label}
                        </span>
                      )}
                      {navItem?.isNew && (
                        <span className="new-feature">{t('global.new')}</span>
                      )}
                      {navItem?.isBeta && (
                        <span className="beta-feature">{t('global.beta')}</span>
                      )}
                    </>
                  </ClickableRegion>
                </li>
              );
            }

            return null;
          })}
        </ul>
      </div>

      <div className="bottom-side">
        <ul className="nav-list">
          {navigationItems.map((navItem) => {
            const isActive = location?.pathname === navItem?.href;
            let isFeatureFlagEnabled = true;

            if (navItem?.requireFeatureFlag) {
              isFeatureFlagEnabled = useFeatureFlag(`${navItem?.requireFeatureFlag}`);
            }

            if (
              isFeatureFlagEnabled &&
              !navItem?.hideFromView &&
              navItem?.onBottom &&
              (!navItem?.staffOnly || user?.internalRoles?.includes('staff')) &&
              (!navItem?.isPaid || isPaidOrg || user?.internalRoles?.includes('staff'))
            ) {
              return (
                <li key={`${navItem?.href}`}>
                  <ClickableRegion
                    regionLabel={navItem?.label}
                    href={navItem?.href}
                    className={`anim-slideInLeftShort ${isActive ? 'is-active' : ''} ${
                      navItem.hoverAnimation ? `hover-${navItem.hoverAnimation}` : ''
                    }`}
                  >
                    <>
                      {navItem?.icon && renderIcon(navItem.icon)}
                      {isExpanded && navItem?.label && (
                        <span className="nav-text anim-slideInLeft">
                          {navItem?.label}
                        </span>
                      )}
                      {navItem?.isNew && (
                        <span className="new-feature on-bottom">{t('global.new')}</span>
                      )}
                      {navItem?.isBeta && (
                        <span className="beta-feature on-bottom">{t('global.beta')}</span>
                      )}
                    </>
                  </ClickableRegion>
                </li>
              );
            }

            return null;
          })}
          {/* Enable to show toggle for Seasonal nav on the dashboard */}
          {/* {location?.pathname === '/' && (
            <li key="seasonal">
              <ClickableRegion
                regionLabel={t(`global.seasonal.snow-${isSeasonalTheme ? 'off' : 'on'}`)}
                onClick={() => {
                  setSeasonalTheme((current) => !current);
                }}
                className="anim-slideInLeftShort"
              >
                <>
                  {renderIcon(isSeasonalTheme ? 'snowplow' : 'snowman')}
                  {isExpanded && (
                    <span className="nav-text anim-slideInLeft">
                      {t(`global.seasonal.snow-${isSeasonalTheme ? 'off' : 'on'}`)}
                    </span>
                  )}
                </>
              </ClickableRegion>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};

export default LeftNav;
