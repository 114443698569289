import { Button, Flex } from '@mantine/core';
import clsx from 'clsx';
import Lottie from 'lottie-react-web';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import ClickableRegion from '../../../components/ClickableRegion';
import Toolbar from '../../../components/Toolbar/Toolbar';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../../../global.constants';
import loginLotti from '../../../img/illustrations/login/login.json';
import { PortalUserInformationForm } from '../../components/PortalUserInformationForm/PortalUserInformationForm';
import { useUserInformationForm } from '../../components/PortalUserInformationForm/useUserInformationForm';
import styles from './PortalRegister.module.scss';

const PortalRegisterPage = () => {
  const { t } = useTranslation();
  const form = useUserInformationForm({
    requiredFieldMessage: t('global.form.errors.requiredField'),
  });

  const saveUserInfo = () => {
    if (!form) {
      return;
    }
    if (form.isValid()) {
      // TODO: send user info to endpoint
      // tickt: https://linear.app/manifest-cyber/issue/MFST-3513/implement-login-logic-and-navigation-to-portal
    } else {
      form.validate();
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('page.login-verify.title', { brand: t('app.name') })}</title>
        <meta
          name="description"
          content={t('page.login-verify.title', { brand: t('app.name') })}
        />
      </Helmet>
      <Toolbar variant="simple" />

      <Flex className={styles.container}>
        <div className={styles.illustration}>
          <Lottie
            options={{
              animationData: loginLotti,
            }}
            height="100%"
            width="auto"
            isStopped={false}
            isPaused={false}
          />
        </div>
        <Flex direction="column" className={styles.formContainer}>
          <span className={styles.title}>{t('portal.register.title')}</span>
          <span className={styles.subtitle}>{t('portal.register.subtitle')}</span>

          <div className={styles.form}>
            <PortalUserInformationForm form={form} showEmailField={false} />
          </div>
          <Button
            onClick={() => {
              saveUserInfo();
            }}
          >
            {t('portal.register.register')}
          </Button>

          <span className={clsx(styles.disclaimerContainer, styles.disclaimer)}>
            <Trans
              className={styles.disclaimer}
              i18nKey="portal.register.disclaimer"
              values={{
                tos: t('legal.tos.title'),
                privacy: t('legal.privacy.title'),
              }}
              components={{
                Tos: (
                  <ClickableRegion
                    className={styles.disclaimerLink}
                    regionLabel={t('legal.tos.title')}
                    href={TERMS_OF_USE_LINK}
                    target="_blank"
                  />
                ),
                Privacy: (
                  <ClickableRegion
                    className={styles.disclaimerLink}
                    regionLabel={t('legal.privacy.title')}
                    href={PRIVACY_POLICY_LINK}
                    target="_blank"
                  />
                ),
              }}
            />
          </span>
        </Flex>
      </Flex>
    </>
  );
};

export default PortalRegisterPage;
