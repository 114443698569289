import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import capitalizeWords from '../../lib/capitalizeWords';

const normalizeAuthorSupplierPublisherName = (rawName: string): string => {
  return capitalizeWords(
    rawName?.toLowerCase()?.replace('organization:', '').replace('person:', '').trim(),
  );
};

interface AuthorSupplierDatum {
  label: string;
  value: string;
}

export const useFetchAssetSuppliers = () => {
  let fetchUrl = `/assets/suppliers?includePublishers=1`;

  return useQuery({
    staleTime: 60000, // 1 minute
    queryKey: ['assets-suppliers', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) =>
      data.data
        ?.map((thisSupplier: any) => ({
          label: normalizeAuthorSupplierPublisherName(thisSupplier),
          value: thisSupplier,
        }))
        .sort((a: AuthorSupplierDatum, b: AuthorSupplierDatum) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }) as AuthorSupplierDatum[],
  });
};
