import type { InterfaceNotificationEvent } from '@manifest-cyber/types/interface/dbTables';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useFetchActiveNotificationEvents } from '../../../../hooks/queries/useFetchSbomUploadAlerts';
import {
  GetSbomAlertsReturnValue,
  SbomUploadAlertsApi,
} from '../api/sbomAlerts/sbomAlerts.api';

type SbomAlertsData =
  | { notificationEventId: string; alerts: GetSbomAlertsReturnValue }
  | undefined;

export const SBOM_ALERTS_QUERY_KEY = 'sbomUploadAlerts';

export const useFetchSbomAlerts = (): UseQueryResult<SbomAlertsData, unknown> => {
  const activeNotificationsQueryResult = useFetchActiveNotificationEvents();
  const sbomUploadAlertEvent = activeNotificationsQueryResult.data?.find(
    (event: InterfaceNotificationEvent) => event.name === 'sbomUploadAlerts',
  );

  const notificationEventId = sbomUploadAlertEvent?._id?.toString() || '';
  const sbomAlertsQueryResult = useQuery({
    enabled: Boolean(notificationEventId),
    queryKey: [SBOM_ALERTS_QUERY_KEY],
    queryFn: () => SbomUploadAlertsApi.getSbomAlerts(notificationEventId),
  });

  const data: SbomAlertsData =
    sbomAlertsQueryResult.data && notificationEventId
      ? {
          alerts: sbomAlertsQueryResult.data,
          notificationEventId,
        }
      : undefined;

  const refetchData = async () => {
    await activeNotificationsQueryResult.refetch();

    if (notificationEventId) {
      await sbomAlertsQueryResult.refetch();
    }
  };

  return {
    ...sbomAlertsQueryResult,
    data,
    isLoading:
      activeNotificationsQueryResult.isLoading ||
      (sbomAlertsQueryResult.isLoading &&
        Boolean(sbomAlertsQueryResult.fetchStatus === 'fetching')),
    error: activeNotificationsQueryResult.error || sbomAlertsQueryResult.error,
    refetch: () => {
      refetchData();
    },
  } as UseQueryResult<SbomAlertsData, unknown>;
};
