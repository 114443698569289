import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../components/ClickableRegion';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { getDefaultTableOptions } from '../MRT/ManifestMRT';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { Text, useMantineTheme } from '@mantine/core';
import capitalizeWords from '../../lib/capitalizeWords';
import { useFetchComponentAssets } from '../../hooks/queries/useFetchComponentAssets';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import featureFlagDisciminators from '../../configs/featureFlags';
import styles from './ComponentAssetsTable.module.scss';

const resolveName = (asset: InterfaceOrganizationAsset) => {
  let resolvedName: string | null = null;

  // Name or fallback to packageUrlNoVersion
  if (asset?.name && asset?.name?.length > 0) {
    resolvedName = asset?.name;
  } else if (asset?.packageUrlNoVersion && asset?.packageUrlNoVersion?.length > 0) {
    resolvedName = asset?.packageUrlNoVersion;
  } else if (asset?.coordinates && asset?.coordinates?.length > 0) {
    resolvedName = asset?.coordinates;
  }

  // Add version data if available
  if (asset?.version && asset?.version?.length > 0 && asset?.version !== 'NOASSERTION') {
    resolvedName = `${resolvedName}@${asset?.version}`;
  }

  return resolvedName;
};

const resolveType = (asset: InterfaceOrganizationAsset, t: any) => {
  if (asset?.type) {
    return capitalizeWords(asset?.type || '');
  }
  return t('page.asset.unknown.type');
};

const defaultTableOptions = getDefaultTableOptions<InterfaceOrganizationAsset>();

interface Props {
  componentId: string;
  isActiveAssetsOnly: boolean;
}

export const ComponentAssetsTable = ({ componentId, isActiveAssetsOnly }: Props) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId(null);

  const columns = useMemo<MRT_ColumnDef<InterfaceOrganizationAsset>[]>(
    () => [
      {
        accessorFn: (row) => resolveName(row),
        id: 'name',
        header: t('tables.componentAssets.headers.assetName'),
        size: 300,
        Cell: ({ cell, row }) => {
          return (
            <ClickableRegion
              className={`table-link ${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
              regionLabel={''}
              href={`/asset/${row.original._id}`}
            >
              <Text sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {cell.getValue<string>()}
              </Text>
            </ClickableRegion>
          );
        },
      },
      {
        accessorFn: (row) => row.version,
        id: 'version',
        size: 150,
        header: t('tables.componentAssets.headers.assetVersion'),
        Cell: ({ cell, row }) => {
          return (
            <span
              className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
            >
              {cell.getValue<string>()}
            </span>
          );
        },
      },
      {
        accessorFn: (row) => resolveType(row, t),
        id: 'type',
        size: 150,
        header: t('tables.componentAssets.headers.assetType'),
        Cell: ({ cell, row }) => {
          return (
            <span
              className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
            >
              {cell.getValue<string>()}
            </span>
          );
        },
      },
    ],
    [t],
  );

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'name', desc: false }]);

  const {
    data: { data: fetchedAssets = [] } = {},
    isFetching: isFetchingAssets,
    isLoading: isLoadingAssets,
  } = useFetchComponentAssets({
    componentId,
    isActive: isActiveAssetsOnly,
  });

  const table = useMantineReactTable<InterfaceOrganizationAsset>({
    ...defaultTableOptions,
    columns,
    data: fetchedAssets,
    enableFilters: false,
    enableTopToolbar: false,
    enablePagination: fetchedAssets.length > 10,
    state: {
      pagination,
      sorting,
      isLoading: isLoadingAssets,
      showLoadingOverlay: isFetchingAssets,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyRowProps: {
      sx: {
        td: {
          backgroundColor: theme.colors.dark[6],
        },
      },
    },
    mantineTableHeadRowProps: {
      sx: {
        th: {
          backgroundColor: theme.colors.dark[6],
        },
      },
    },
    mantineBottomToolbarProps: {
      sx: {
        backgroundColor: theme.colors.dark[6],
      },
    },
    mantinePaginationProps: {
      showRowsPerPage: false,
    },
  });

  if (!isLoadingAssets && fetchedAssets.length === 0) {
    return <Text>{t('tables.componentAssets.no-assets')}</Text>;
  }

  return <MantineReactTable table={table} />;
};
