import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceOrganizationIntegration } from '@manifest-cyber/types/interface/dbTables';

export const useFetchIntegrations = (
  {
    includeGithub,
  }: {
    includeGithub?: boolean;
  } = {
    includeGithub: true,
  },
) => {
  const fetchUrl = `integration`;

  return useQuery({
    queryKey: ['integrations', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) =>
      (data?.data as InterfaceOrganizationIntegration[]).filter(({ integrationType }) => {
        if (includeGithub) {
          return true;
        } else {
          return integrationType !== 'github';
        }
      }),
  });
};
