import { useEffect, useMemo, useRef } from 'react';
import {
  MRT_ColumnDef,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { InterfaceUnmatchedLicense } from '@manifest-cyber/types/interface/dbTables';
import { useTranslation } from 'react-i18next';
import { getDefaultTableOptions } from '../MRT/ManifestMRT';
import { useFetchUnmatchedLicenses } from '../../hooks/queries/useFetchUnmatchedLicenses';

const defaultTableOptions = getDefaultTableOptions<InterfaceUnmatchedLicense>();

interface Props {}

export const UnmatchedLicensesTable = ({}: Props) => {
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<InterfaceUnmatchedLicense>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('page.userSettings.licenses.table.headers.shortName'),
      },
    ],
    [],
  );

  const {
    data: fetchedUnmatchedLicenses = [],
    isLoading: isLoadingUnmatchedLicenses,
    isFetching: isFetchingUnmatchedLicenses,
  } = useFetchUnmatchedLicenses();

  const table = useMantineReactTable({
    ...defaultTableOptions,
    columns,
    data: fetchedUnmatchedLicenses,
    getRowId: (row) => row._id?.toString(),
    enableFacetedValues: true,
    initialState: {
      ...defaultTableOptions.initialState,
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [{ id: 'name', desc: false }],
    },
    state: {
      isLoading: isLoadingUnmatchedLicenses,
      showLoadingOverlay: isFetchingUnmatchedLicenses,
    },
  });

  return <MantineReactTable table={table} />;
};
