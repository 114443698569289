import { Box } from '@mantine/core';
import clsx from 'clsx';
import { ReactNode, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import ColoredMessage from '../../../../../components/ColoredMessage/ColoredMessage';
import ChipComponent from '../ChipComponent/ChipComponent';
import styles from './EmailInput.module.scss';

interface EmailInputProps {
  id: string;
  onChange: (emails: string[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  value: string[];
  autoFocus?: boolean;
  label?: string;
  placeholder?: string;
  error?: ReactNode;
  showRequiredMark?: boolean;
  labelPosition?: 'top' | 'left';
}

const EmailInput = ({
  label,
  placeholder,
  onBlur,
  onChange,
  onFocus,
  value,
  error,
  showRequiredMark,
  labelPosition = 'top',
  id,
}: Readonly<EmailInputProps>) => {
  const [hasText, setHasText] = useState(false);

  return (
    <div
      className={clsx(styles.container, labelPosition === 'left' && styles.labelToLeft)}
    >
      {label && (
        <label
          htmlFor={id}
          className={clsx(
            styles.inputLabel,
            labelPosition === 'left' && styles.labelToLeft,
          )}
        >
          {label}
          {showRequiredMark && <span className={styles.requiredStar}> *</span>}
        </label>
      )}
      <div
        className={clsx(
          styles.inputContainer,
          labelPosition === 'left' && styles.labelToLeft,
        )}
      >
        <ReactMultiEmail
          id={id}
          placeholder={placeholder}
          emails={value}
          className={clsx(
            styles.emailInput,
            error && styles.emailInputError,
            value.length === 0 && !hasText && styles.emptyInput,
          )}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onChangeInput={(value) => {
            setHasText(value.length > 0);
          }}
          getLabel={(email, index, removeEmail) => (
            <div className={styles.chipContainer} key={index}>
              <ChipComponent
                onClick={() => {
                  removeEmail(index);
                }}
                leftIcon={{ isVisible: false }}
                label={email}
              />
            </div>
          )}
        />
        {error && (
          <Box mt={8}>
            <ColoredMessage message={error} />
          </Box>
        )}
      </div>
    </div>
  );
};

export default EmailInput;
