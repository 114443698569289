import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import capitalizeWords from '../../../lib/capitalizeWords';

export const resolveName = (asset: InterfaceOrganizationAsset) => {
  let resolvedName: string | null = null;

  // Name or fallback to packageUrlNoVersion
  if (asset?.name && asset?.name?.length > 0) {
    resolvedName = asset?.name;
  } else if (asset?.packageUrlNoVersion && asset?.packageUrlNoVersion?.length > 0) {
    resolvedName = asset?.packageUrlNoVersion;
  } else if (asset?.coordinates && asset?.coordinates?.length > 0) {
    resolvedName = asset?.coordinates;
  }

  return resolvedName;
};

export const resolveType = (asset: InterfaceOrganizationAsset, t: any) => {
  if (asset?.type) {
    return capitalizeWords(asset?.type || '');
  }
  return t('page.asset.unknown.type');
};

export const resolveVulnerabilitiesCount = (asset: InterfaceOrganizationAsset) => {
  if (asset.countVulnerabilities && asset.countVulnerabilities.total) {
    return asset.countVulnerabilities.total;
  }

  return 0;
};
