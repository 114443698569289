import { useMutation } from '@tanstack/react-query';
import hitApi from '../../api';

interface Params {
  triggerType: string;
  force: boolean;
}

export const useTriggerMessage = () => {
  return useMutation({
    mutationFn: ({ triggerType, force }: Params) => {
      return hitApi.post(`/admin/trigger/${triggerType}?force=${force}`, true, alert);
    },
  });
};
