export interface SelectItem {
  value: string;
  label: string;
}

export const convertToSelectData = (
  data: { id: string; name: string }[],
): SelectItem[] => {
  return data.map((item) => ({ value: item.id, label: item.name }));
};

const LINEAR_LOGO_SRC = '/linear_logo.png';
const JIRA_LOGO_SRC = '/jira_logo.png';
const SERVICENOW_LOGO_SRC = '/servicenow_logo.png';

export const getPlatformLogo = (platformName: string) => {
  if (platformName === 'jira') {
    return JIRA_LOGO_SRC;
  } else if (platformName === 'servicenow') {
    return SERVICENOW_LOGO_SRC;
  } else if (platformName === 'linear') {
    return LINEAR_LOGO_SRC;
  }
};
