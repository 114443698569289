import { useTranslation } from 'react-i18next';
import getLoadingMessage from '../../lib/getLoadingMessage';
import Logo from '../Logo';

export const Loading = ({
  selfContain,
  className,
  customLoadingMessage,
}: {
  selfContain?: boolean;
  className?: string;
  customLoadingMessage?: string | null;
}) => {
  const { t } = useTranslation();

  return (
    <div className={`manifest-loader ${selfContain ? 'self-contain' : ''} ${className}`}>
      <Logo />
      <span>{`${
        customLoadingMessage ? customLoadingMessage : getLoadingMessage(t)
      }`}</span>
    </div>
  );
};

export default Loading;
