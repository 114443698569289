import '../../scss/pages/dashboard.scss';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/Icon';
import ManifestSearch from '../../components/ManifestSearch';
import { TopVulnerabilities } from './TopVulnerabilities';
import { MostImpactedAssets } from './MostImpactedAssets';
import { VulnerabilitySummaryChart } from './VulnerabilitySummaryChart';
import { AtAGlance } from './AtAGlance';
import { MostRecentVulnerabilities } from './MostRecentVulnerabilities';
import { useFetchRecentVulns } from '../../hooks/queries/useFetchRecentVulns';
import { useFetchCountStats } from '../../hooks/queries/useFetchCountStats';
import useMediaQuery from '../../hooks/utils/useMediaQuery';
import Snowfall from 'react-snowfall';
import { useLocalStorage } from '@mantine/hooks';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import featureFlagDisciminators from '../../configs/featureFlags';

const Dashboard = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1000px)');

  const { data: fetchedStats, isLoading: isLoadingStats } = useFetchCountStats();
  const { data: fetchedRecentVulns = [], isLoading: isLoadingRecentVulns } =
    useFetchRecentVulns();

  // Enable to add Seasonal Theme (Snow, etc)
  // const [isSeasonalTheme] = useLocalStorage({
  //   key: 'enable_seasonal_theme',
  //   defaultValue: false,
  // });

  return (
    <>
      <section className="page-dashboard">
        <div className="primary-view">
          <h1 className="anim-slideInDownShort">{t('page.dashboard.header-noname')}</h1>
          <div className="widget-row">
            <div className="widget alt intro anim-slideInLeftShort">
              <h2 className="widget-header">
                <Icon icon="hand-wave" iconSharp />
                <span>{t('page.dashboard.intro.gettingStarted')}</span>
              </h2>
              <>
                <p>{t('page.dashboard.intro.stepOne')}</p>
                <p>{t('page.dashboard.intro.stepTwo')}</p>
                <p>{t('page.dashboard.intro.stepThree')}</p>
              </>
            </div>

            {isMobile && (
              <AtAGlance isFetching={isLoadingStats} fetchedStats={fetchedStats} />
            )}

            <div className="widget explore-search anim-slideInRightShort">
              <h2 className="widget-header">{t('page.dashboard.explore.header')}</h2>
              <div className="dashboard-search">
                <ManifestSearch showPopular showRecent />
              </div>
            </div>
          </div>

          <div className="widget-row single-widget-row anim-slideInUpShort">
            <VulnerabilitySummaryChart />
          </div>
          <div className="widget-row">
            <TopVulnerabilities />
            <MostImpactedAssets />
            {isMobile && (
              <MostRecentVulnerabilities
                isFetchingRecentVulns={isLoadingRecentVulns}
                fetchedRecentVulns={fetchedRecentVulns}
              />
            )}
          </div>
        </div>
        {!isMobile && (
          <div className="sidebar-view">
            <AtAGlance isFetching={isLoadingStats} fetchedStats={fetchedStats} />
            <MostRecentVulnerabilities
              isFetchingRecentVulns={isLoadingRecentVulns}
              fetchedRecentVulns={fetchedRecentVulns}
            />
          </div>
        )}
      </section>
      {/* Enable to show seasonal elements, like snow, etc */}
      {/* {isSeasonalTheme && (
        <Snowfall snowflakeCount={200} speed={[0.5, 3]} wind={[-0.5, 3]} />
      )} */}
    </>
  );
};

export default Dashboard;
