const MAX_COMPONENT_NAME_LENGTH = 40;

export const RenderNameWithGroupName = (fqn: string) => {
  const truncatedFqn =
    fqn?.length >= MAX_COMPONENT_NAME_LENGTH
      ? fqn?.substring(0, MAX_COMPONENT_NAME_LENGTH) + '...'
      : fqn;

  const split = truncatedFqn.split('/');
  if (split && split.length > 1) {
    const popped = [...split];
    if (split.length > 2 && split[split.length - 2].length > 1) {
      popped.shift();
    }
    popped.pop();
    return (
      <>
        <span className="fqn-group-name">{popped.join('/')}/</span>
        {split[split.length - 1]}
      </>
    );
  }
  return <span>{fqn}</span>;
};

export default RenderNameWithGroupName;
