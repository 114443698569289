import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

export interface CreateTicketFormValues {
  collectionId?: string; // TODO: remove this once BE replacement with projectId is released to prod
  projectId?: string;
  creatorId: string;
  issueType: string;
  status: string;
  summary: string;
  description: string;
  assigneeIds: string[];
  labels?: string[];
  integration: string;
  type: string;
  id: string;
  priority?: string;
  dueDate?: string;
  teamId?: string;
}
interface Params {
  ticket: CreateTicketFormValues;
}

export const usePostTicket = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ ticket }: Params) => {
      return hitApi.post(`/integration/merge/ticket`, true, {
        ...ticket,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tickets']);
    },
  });
};
