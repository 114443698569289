import { useEffect, useState } from 'react';
import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { DateTime } from 'luxon';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { VulnImpactApiResponse } from '../../hooks/queries/useFetchVulnImpact';

interface Props {
  fetchedVulnData: InterfaceVulnerability;
  vulnerabilityId: string | undefined;
  countImpactedAssets: VulnImpactApiResponse['countImpactedAssets'];
  hideBrowserChart?: boolean;
}

export const VulnerabilityDetailChart = ({
  fetchedVulnData,
  vulnerabilityId,
  countImpactedAssets,
  hideBrowserChart = false,
}: Props) => {
  const { t } = useTranslation();

  const [radarScores, setRadarScores] = useState<{
    numberAssets: number;
    exploitability: number;
    percentile: number;
    impact: number;
    age: number;
  } | null>(null);

  useEffect(() => {
    if (fetchedVulnData) {
      let cvssScore = fetchedVulnData?.cvss3BaseScore || fetchedVulnData?.cvss2BaseScore;
      let publishYear = DateTime.fromISO(`${fetchedVulnData?.publishDate}`).get('year');
      let currentYear = DateTime.now().get('year');
      const totalYearDiff = 100 / (currentYear - 1988); // earliest publish year was 1988

      // Update radar scores
      // Radar operates on a 0-100 score, so match to fit
      const radarScores = {
        // @ts-ignore
        numberAssets: countImpactedAssets || 0,
        exploitability: (fetchedVulnData.epssScore || 0) * 100 * 10,
        percentile: (fetchedVulnData?.epssPercentile || 0) * 100,
        impact: (cvssScore || 0) * 10,
        age: (currentYear - publishYear) * totalYearDiff,
      };
      setRadarScores(radarScores);
    }
  }, [fetchedVulnData, fetchedVulnData?._id, countImpactedAssets]);

  return (
    <div className="graphical anim-slideInLeftShort">
      {!hideBrowserChart && (
        <div className="browser-view-only">
          <Chart
            options={{
              chart: {
                id: 'vuln-radar',
                background: 'none',
                redrawOnParentResize: true,
                toolbar: {
                  show: false,
                },
                width: '100%',
                offsetX: 1,
                // parentHeightOffset: 0,
                fontFamily: 'Karla, sans-serif',
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontFamily: 'Karla, sans-serif',
                  fontWeight: '300',
                },
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                  let roundedValue = Math.round(parseFloat(`${value}`));
                  if (dataPointIndex === 0) {
                    // # Impacted Assets
                    return t('page.vulnerability.chart.impacted', {
                      value: `${roundedValue / 100}`,
                    });
                  }

                  if (dataPointIndex === 1) {
                    // CVSS
                    return `${roundedValue / 100}`;
                  }

                  if (dataPointIndex === 2) {
                    // EPSS Exploitability Score
                    return `${roundedValue / 10}%`;
                  }

                  if (dataPointIndex === 3) {
                    // EPSS Percentile
                    return `${roundedValue / 10}%`;
                  }

                  if (dataPointIndex === 4) {
                    // Age
                    return t('page.vulnerability.chart.age', {
                      value:
                        seriesIndex < 1
                          ? Math.round(roundedValue / 100) || roundedValue / 100
                          : roundedValue / 100,
                    });
                  }

                  return `${roundedValue}`;
                },
              },
              fill: {
                colors: ['#625CD1', '#CC3663'],
              },
              colors: ['#625CD1', '#CC3663'],
              grid: {
                show: false,
              },
              legend: {
                show: true,
                position: 'bottom',
              },
              markers: {
                showNullDataPoints: false,
                size: 0,
              },
              theme: {
                mode: 'dark',
              },
              labels: [
                t('page.vulnerability.graph.impacted-assets'),
                t('page.vulnerability.graph.cvss-score'),
                t('page.vulnerability.graph.epss-score'),
                t('page.vulnerability.graph.epss-percentile'),
                t('page.vulnerability.graph.publish-age'),
              ],
              plotOptions: {
                radar: {
                  size: 125,
                  offsetY: 20,
                  polygons: {
                    strokeColors: '#3F3F46',
                    connectorColors: '#3F3F46',
                    fill: {
                      colors: ['#161e22'],
                    },
                  },
                },
              },
              stroke: {
                show: false,
              },
              yaxis: {
                show: false,
              },
            }}
            series={[
              {
                name: t('page.vulnerability.chart.average'),
                data: [2 * 100, 21 * 10, 20, 20 * 10, 6 * 100],
              },
              {
                name: `${vulnerabilityId}`,
                data: [
                  radarScores?.numberAssets
                    ? parseInt(`${radarScores?.numberAssets}`) * 100
                    : 0,
                  radarScores?.impact ? parseInt(`${radarScores?.impact}`) * 10 : 0,
                  radarScores?.exploitability || 0,
                  radarScores?.percentile
                    ? parseInt(`${radarScores?.percentile}`) * 10
                    : 0,
                  radarScores?.age ? parseInt(`${radarScores?.age}`) * 100 : 0,
                ],
              },
            ]}
            type="radar"
            width="460"
            height="280"
          />
        </div>
      )}

      <div className="pdf-view-only">
        {/* This chart is used for PDF export only - has same data, diff styles */}
        <Chart
          options={{
            chart: {
              id: 'vuln-radar-pdf',
              background: '#FFF',
              redrawOnParentResize: true,
              toolbar: {
                show: false,
              },
              width: '100%',
              offsetX: 1,
              // parentHeightOffset: 0,
              fontFamily: 'Karla, sans-serif',
            },
            dataLabels: {
              enabled: true,
              style: {
                fontFamily: 'Karla, sans-serif',
                fontWeight: '300',
              },
              formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                let roundedValue = Math.round(parseFloat(`${value}`));
                if (dataPointIndex === 0) {
                  // # Impacted Assets
                  return t('page.vulnerability.chart.impacted', {
                    value: `${roundedValue / 100}`,
                  });
                }

                if (dataPointIndex === 1) {
                  // CVSS
                  return `${roundedValue / 100}`;
                }

                if (dataPointIndex === 2) {
                  // EPSS Exploitability Score
                  return `${roundedValue / 10}%`;
                }

                if (dataPointIndex === 3) {
                  // EPSS Percentile
                  return `${roundedValue / 10}%`;
                }

                if (dataPointIndex === 4) {
                  // Age
                  return t('page.vulnerability.chart.age', {
                    value:
                      seriesIndex < 1
                        ? Math.round(roundedValue / 100) || roundedValue / 100
                        : roundedValue / 100,
                  });
                }

                return `${roundedValue}`;
              },
            },
            fill: {
              colors: ['#625CD1', '#CC3663'],
            },
            colors: ['#625CD1', '#CC3663'],
            grid: {
              show: false,
            },
            legend: {
              show: true,
              position: 'bottom',
            },
            markers: {
              showNullDataPoints: false,
              size: 0,
            },
            theme: {
              mode: 'light',
            },
            labels: [
              t('page.vulnerability.graph.impacted-assets'),
              t('page.vulnerability.graph.cvss-score'),
              t('page.vulnerability.graph.epss-score'),
              t('page.vulnerability.graph.epss-percentile'),
              t('page.vulnerability.graph.publish-age'),
            ],
            plotOptions: {
              radar: {
                size: 125,
                offsetY: 20,
                // polygons: {
                //   strokeColors: '#3F3F46',
                //   connectorColors: '#3F3F46',
                //   fill: {
                //     colors: ['#161e22'],
                //   },
                // },
              },
            },
            stroke: {
              show: false,
            },
            yaxis: {
              show: false,
            },
          }}
          series={[
            {
              name: t('page.vulnerability.chart.average'),
              data: [2 * 100, 21 * 10, 20, 20 * 10, 6 * 100],
            },
            {
              name: `${vulnerabilityId}`,
              data: [
                radarScores?.numberAssets
                  ? parseInt(`${radarScores?.numberAssets}`) * 100
                  : 0,
                radarScores?.impact ? parseInt(`${radarScores?.impact}`) * 10 : 0,
                radarScores?.exploitability || 0,
                radarScores?.percentile ? parseInt(`${radarScores?.percentile}`) * 10 : 0,
                radarScores?.age ? parseInt(`${radarScores?.age}`) * 100 : 0,
              ],
            },
          ]}
          type="radar"
          width="460"
          height="280"
        />
      </div>
    </div>
  );
};
