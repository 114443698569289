import { InterfaceSBOMAsset } from '../types/FullInterfaces';

export const getSBOMVersion = (sbom?: InterfaceSBOMAsset): string => {
  if (!sbom) return '';

  const { assetData, whenUploaded, assetVersion } = sbom;
  const version = assetData?.[0]?.version;

  const getVersion = () => {
    if (assetVersion) return `${assetVersion}`;
    if (version && version.trim().length > 0 && version !== 'NOASSERTION')
      return `${version.trim()}`;
    if (whenUploaded) return `${whenUploaded}`;
    return '';
  };

  return getVersion();
};
