import { Button, Menu, Tooltip } from '@mantine/core';
import { ReactNode } from 'react';
import Icon from '../../../components/Icon';
import { IconName } from '@fortawesome/fontawesome-svg-core';

interface Props {
  selectionCount: number;
  allSelected?: boolean;
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  text?: ReactNode;
  icon?: IconName;
  disabledText?: string;
  showArrow?: boolean;
  showSelectionCount?: boolean;
  ariaLabel: string;
}

export const MenuButton = (props: Props) => {
  const {
    icon,
    text,
    selectionCount,
    children,
    allSelected,
    disabled,
    loading,
    disabledText,
    showArrow = true,
    showSelectionCount = true,
    ariaLabel,
  } = props;

  return (
    <Menu position="bottom-end" disabled={disabled}>
      <Menu.Target>
        {disabled && disabledText ? (
          <Tooltip label={disabledText}>
            <span className="disabled-btn-wrapper" data-testid="tooltip">
              <Button
                className="manifest-menu__target"
                rightIcon={showArrow && <Icon icon="chevron-down" />}
                leftIcon={icon && <Icon icon={icon} />}
                variant="default"
                disabled={disabled}
                loading={loading}
                aria-label={ariaLabel}
              >
                {text}
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            className="manifest-menu__target"
            rightIcon={showArrow && <Icon icon="chevron-down" />}
            leftIcon={icon && <Icon icon={icon} />}
            variant="default"
            disabled={disabled}
            loading={loading}
          >
            <span>
              {text}{' '}
              {!disabled && showSelectionCount && selectionCount > 1 && (
                <>{allSelected ? 'all' : `(${selectionCount})`}</>
              )}
            </span>
          </Button>
        )}
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
};
