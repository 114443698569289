import styles from '../AssetsTable.module.scss';
import Labels from '../../../Labels';
import { MRT_Row } from 'mantine-react-table';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { Dispatch, SetStateAction } from 'react';

export const LabelsColumn = ({
  row,
  setFilterLabels,
}: {
  row: MRT_Row<InterfaceOrganizationAsset>;
  setFilterLabels: Dispatch<SetStateAction<string[]>>;
}) => {
  return (
    <div
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      <Labels
        entity={row.original}
        condensed
        justifyContent="flex-end"
        onLabelClick={(label) => setFilterLabels([label._id?.toString()])}
        rounded
      />
    </div>
  );
};
