import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, forwardRef, useMemo, ReactNode } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  useMantineReactTable,
} from 'mantine-react-table';
import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import ClickableRegion from '../../ClickableRegion';
import capitalizeWords from '../../../lib/capitalizeWords';
import { Tooltip } from '@mantine/core';
import { VulnerabilityResult } from '../../../hooks/queries/useFetchComponentVulns';
import DataTableFooter from '../DataTableFooter';
import { getDefaultTableOptions } from '../../MRT/ManifestMRT';
import {
  determineSeverityColor,
  formatDate,
  formatPercentile,
  formatScore,
  generateTooltipContent,
} from './methods';

interface Props {
  thisVersionVulnerabilities: VulnerabilityResult[] | undefined;
  isLoadingVulns: boolean;
  countVulnerabilities: number;
  isFetchingVulns: boolean;
  pagination: MRT_PaginationState;
  setPagination: Dispatch<SetStateAction<MRT_PaginationState>>;
  sorting: MRT_SortingState;
  setSorting: Dispatch<SetStateAction<MRT_SortingState>>;
}

interface TooltipContentProps {
  children: ReactNode;
}

export const ComponentVulnerabilitiesTable = ({
  thisVersionVulnerabilities,
  isLoadingVulns,
  countVulnerabilities,
  isFetchingVulns,
  pagination,
  setPagination,
  sorting,
  setSorting,
}: Props) => {
  const { t } = useTranslation();

  const TooltipContent = forwardRef<HTMLSpanElement, TooltipContentProps>(
    ({ children }, ref) => <span ref={ref}>{children}</span>,
  );

  const columns = useMemo<MRT_ColumnDef<InterfaceVulnerability>[]>(
    () => [
      {
        id: 'cveId',
        accessorKey: 'cveId',
        header: t('page.vulnerabilities.table.header.cveId'),
        Cell: ({ row }) => {
          return row.original?.cveId ? (
            <ClickableRegion
              className="table-link"
              regionLabel={t('page.component.view-vulnerability-details')}
              href={`/vulnerability/${row.original.cveId.toUpperCase()}`}
            >
              {row.original.cveId.toUpperCase()}
            </ClickableRegion>
          ) : (
            `${row.original.cveId}`.toUpperCase()
          );
        },
      },
      {
        id: 'cvss3BaseScore',
        accessorKey: 'cvss3BaseScore',
        header: t('page.vulnerabilities.table.header.severity-impact'),
        Cell: ({ row }) => {
          const severity =
            row.original.cvss3BaseSeverity || row.original.cvss2BaseSeverity;
          const score = row.original.cvss3BaseScore || row.original.cvss2BaseScore;
          const color = determineSeverityColor(severity || undefined);
          const formattedScore = formatScore(score || undefined);

          return score ? (
            <span className={`table-severity ${color}`}>
              <strong>{formattedScore}</strong>
              &nbsp;
              {severity ? t(`global.${severity.toLowerCase()}`) : ''}
            </span>
          ) : (
            <span className="table-na">{t('global.notProvided')}</span>
          );
        },
      },
      {
        id: 'epssScore',
        accessorKey: 'epssScore',
        header: t('page.vulnerabilities.table.header.epssExploitability'),
        Cell: ({ row }) => {
          const displayValue = formatPercentile(row.original.epssScore || undefined);

          return displayValue ? (
            <Tooltip label={generateTooltipContent(row.original.epssScore || undefined, t)}>
              <TooltipContent>{displayValue}</TooltipContent>
            </Tooltip>
          ) : (
            <span className="table-na">{t('global.notProvided')}</span>
          );
        },
      },
      {
        id: 'epssPercentile',
        accessorKey: 'epssPercentile',
        header: t('page.vulnerabilities.table.header.epssPercentile'),
        Cell: ({ row }) => {
          const displayValue = formatPercentile(row.original.epssPercentile || undefined);

          return displayValue ? (
            <Tooltip label={generateTooltipContent(row.original.epssPercentile || undefined, t)}>
              <TooltipContent>{displayValue}</TooltipContent>
            </Tooltip>
          ) : (
            <span className="table-na">{t('global.notProvided')}</span>
          );
        },
      },
      {
        id: 'publishDate',
        accessorKey: 'publishDate',
        header: t('page.vulnerabilities.table.header.publishDate'),
        Cell: ({ row }) => formatDate(row.original.publishDate),
      },
      {
        id: 'dateModified',
        accessorKey: 'dateModified',
        header: t('page.vulnerabilities.table.header.lastUpdated'),
        Cell: ({ row }) => formatDate(row.original.dateModified),
      },
    ],
    [],
  );

  const defaultTableOptions = getDefaultTableOptions<InterfaceVulnerability>();

  const table = useMantineReactTable<InterfaceVulnerability>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    columns,
    data: thisVersionVulnerabilities || [],
    enableFilters: false,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: countVulnerabilities,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: false,
    },
    state: {
      pagination,
      sorting,
      isLoading: isLoadingVulns,
      showLoadingOverlay: isFetchingVulns,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <div className="component-vulnerability-content anim-slideInUpShort">
      <div className="list-components">
        <MantineReactTable table={table} />
        <DataTableFooter
          currentPage={pagination.pageIndex}
          limitPerPage={pagination.pageSize}
          totalResults={countVulnerabilities}
          onChangePage={setPagination}
        />
      </div>
    </div>
  );
};
