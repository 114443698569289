import { useQuery } from '@tanstack/react-query';
import { SharingPortalApi } from '../../sharingPortal.api';

export const SHARING_PORTAL_QUERY_KEY = 'sharing_portal';

export const useFetchSharingPortal = () => {
  return useQuery({
    staleTime: 1000 * 60 * 10, // 10 min (or until invalidated)
    queryKey: [SHARING_PORTAL_QUERY_KEY],
    queryFn: () => SharingPortalApi.getPortal(),
  });
};
