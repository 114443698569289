import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import capitalizeWords from '../../../lib/capitalizeWords';
import { InterfaceVulnerabilityWithVexInfo } from '../../../types/FullInterfaces';
import { useParams } from 'react-router-dom';
import { formatNumberToPrecision } from '../../../lib/numbers';

export const useVulnMetaParsedData = ({
  description,
  aliases = [],
  references,
  cvss2BaseSeverity,
  cvss3BaseSeverity,
  publishDate,
  cvss2BaseScore,
  cvss3BaseScore,
  epssPercentile,
  epssScore,
  kevData,
  firstSeen,
}: InterfaceVulnerabilityWithVexInfo) => {
  const { vulnerabilityId } = useParams<{ vulnerabilityId: string }>();
  const published = publishDate?.toString();
  const firstSeenDate = firstSeen?.toString();
  const hasDescription = (description?.length || 0) > 0;

  const filteredAliases = aliases?.filter((aliasName) => aliasName !== vulnerabilityId);
  const firstAlias = filteredAliases?.[0];
  const hasOtherAliases = filteredAliases?.length > 1;

  const nvdLink = references?.find(({ url }) => {
    return url?.includes('nvd.nist.gov');
  });

  const osvLink = references?.find(({ url }) => {
    return url?.includes('osv.dev');
  });

  const generatedReferenceLink = useMemo<{ link: string; label: string } | null>(() => {
    const hasAtLeastOneReference = nvdLink || osvLink;

    if (hasAtLeastOneReference || !vulnerabilityId) {
      // dont generate
      return null;
    }

    const isInNvdDB = vulnerabilityId.startsWith('CVE-');

    if (isInNvdDB) {
      return {
        link: `https://nvd.nist.gov/vuln/detail/${vulnerabilityId}`,
        label: t('page.vulnerability.details.meta-data.nvd'),
      };
    } else {
      // Cause osv.dev is case-sensitive and only wants the prefix in uppercase, and the rest of the id on lowercase
      let link = `https://osv.dev/vulnerability/${vulnerabilityId}`;
      const splittedVulnId = vulnerabilityId.split('-');

      if (splittedVulnId.length > 1) {
        const [prefix, ...id] = splittedVulnId;

        link = link.replace(vulnerabilityId, `${prefix}-${id.join('-').toLowerCase()}`);
      }
      return {
        link,
        label: t('page.vulnerability.details.meta-data.osv'),
      };
    }
  }, [nvdLink, osvLink]);

  const severity = useMemo(() => {
    let color = '';
    switch (cvss3BaseSeverity || cvss2BaseSeverity) {
      case 'CRITICAL':
        color = 'severity-critical';
        break;
      case 'HIGH':
        color = 'severity-high';
        break;
      case 'MEDIUM':
        color = 'severity-medium';
        break;
      case 'LOW':
        color = 'severity-low';
        break;
      case 'INFO':
        color = 'severity-info';
        break;
      default:
        color = 'severity-info';
        break;
    }

    if (cvss3BaseScore) {
      return (
        <span className={`table-severity ${color}`}>
          {capitalizeWords((cvss3BaseSeverity || cvss2BaseSeverity || '').toLowerCase())}
          &nbsp; (<strong>{`${cvss3BaseScore.toFixed(1)}`}</strong>)
        </span>
      );
    } else if (cvss2BaseScore) {
      return (
        <span className={`table-severity ${color}`}>
          {capitalizeWords((cvss2BaseSeverity || cvss3BaseSeverity || '').toLowerCase())}
          &nbsp; (<strong>{`${cvss2BaseScore.toFixed(1)}`}</strong>)
        </span>
      );
    }

    return <span className="table-na">{t('global.notProvided')}</span>;
  }, [cvss2BaseScore, cvss2BaseSeverity, cvss3BaseScore, cvss3BaseSeverity]);

  const formattedExploitability =
    epssScore && `${formatNumberToPrecision(100 * epssScore, 2)}%`;
  const formattedPercentile =
    epssPercentile && `${formatNumberToPrecision(100 * epssPercentile, 2)}%`;
  const isInKev = kevData?.inKEV;

  const inKevList = useMemo(() => {
    if (isInKev) {
      return <span className="severity-medium">{t('global.yes')}</span>;
    } else {
      return <span className="detail-title">--</span>;
    }
  }, [isInKev]);

  const formattedDatePublished =
    published && DateTime.fromISO(published).toLocaleString(DateTime.DATE_SHORT);
  const formattedFirstSeen =
    firstSeenDate && DateTime.fromISO(firstSeenDate).toLocaleString(DateTime.DATE_SHORT);

  return {
    hasDescription,
    formattedExploitability,
    formattedPercentile,
    formattedDatePublished,
    inKevList,
    severity,
    firstAlias,
    hasOtherAliases,
    generatedReferenceLink,
    isInKev,
    filteredAliases,
    osvLink,
    nvdLink,
    description,
    formattedFirstSeen,
  };
};
