import React from 'react';
import './ListItemAction.scss';
import ClickableRegion from '../ClickableRegion';
import { ClickableRegionProps } from '../ClickableRegion/ClickableRegion';
import Icon from '../Icon';
import { IconProps } from '../Icon/Icon';

interface Props {
  clickableRegionProps: Omit<ClickableRegionProps, 'children'>;
  iconProps: IconProps;
  iconSeverity?: 'critical' | 'high' | 'medium' | 'low' | 'info' | 'na';
  children: React.ReactNode;
  className?: string;
}

export const ListItemAction = ({
  children,
  clickableRegionProps,
  iconProps,
  iconSeverity = 'critical',
  ...rest
}: Props) => {
  return (
    <li {...rest}>
      <ClickableRegion {...clickableRegionProps}>
        <div className="list-item-action-grid">
          <div className={`icon-cont severity-${iconSeverity}`}>
            <Icon {...iconProps} />
          </div>
          {children}
          <div className="cta">
            <Icon icon="angle-right" iconSharp />
          </div>
        </div>
      </ClickableRegion>
    </li>
  );
};
