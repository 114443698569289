import './ComponentsTable.scss';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import ClickableRegion from '../../components/ClickableRegion';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { useFetchComponents } from '../../hooks/queries/useFetchComponents';
import normalizeAuthor from '../../lib/normalizeAuthor';
import { getDefaultTableOptions } from '../MRT/ManifestMRT';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MantineReactTable,
  useMantineReactTable,
  MRT_GlobalFilterTextInput,
} from 'mantine-react-table';
import Icon from '../Icon/Icon';
import { Box, Flex, Stack, Text, Tooltip } from '@mantine/core';
import { InterfaceOrganizationComponentWithVulns } from '../../types/FullInterfaces';
import DataTableFooter from './DataTableFooter';
import { useURLandLocalStorageSortingState } from '../../hooks/utils/usePersistentStates';
import { useComponentsFilter } from '../../hooks/components/useComponentsFilter';
import { ComponentsLicenseCell } from './ComponentsLicenseCell';
import RenderNameWithGroupName from '../RenderNameWithGroupName';
import SupplierModal from '../SupplierModal';
import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import { useQueryClient } from '@tanstack/react-query';
import RenderName from '../RenderNameOnly';

export const renderText = (text: string) => {
  return (
    <Tooltip disabled={(text?.length ?? 0) <= 30} label={text}>
      <Box
        sx={{
          height: '3.125rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Text
          lineClamp={2}
          sx={{
            wordWrap: 'break-word',
          }}
        >
          {text}
        </Text>
      </Box>
    </Tooltip>
  );
};

const defaultTableOptions =
  getDefaultTableOptions<InterfaceOrganizationComponentWithVulns>();

interface Props {
  assetId?: string;
}

export const ComponentsTable = ({ assetId }: Props) => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId(null);
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [editSupplierComponent, setEditSupplierComponent] = useState<
    InterfaceOrganizationComponent | InterfaceOrganizationComponentWithVulns | null
  >(null);
  const queryClient = useQueryClient();

  const { getCurrentFilters, componentName, setComponentName } = useComponentsFilter();

  const columns = useMemo<MRT_ColumnDef<InterfaceOrganizationComponentWithVulns>[]>(
    () => [
      {
        accessorFn: (row) => row.name,
        id: 'fullyQualifiedName',
        header: t('tables.components.headers.name'),
        Cell: ({ row }) => {
          if (row.original?._id) {
            return (
              <ClickableRegion
                className="table-link"
                regionLabel="View Component Details"
                href={`/component/${row.original?._id}/${currentOrgId || ''}`}
              >
                {RenderName(
                  `${
                    row.original?.fullyQualifiedName ||
                    row.original?.name ||
                    row.original?.packageUrlNoVersion ||
                    row.original?._id.toString()
                  }`,
                )}
              </ClickableRegion>
            );
          }
          return renderText(row.original?.name);
        },
      },
      {
        accessorFn: (row) => renderText(row.version || ''),
        id: 'version',
        header: t('tables.components.headers.version'),
      },
      {
        accessorFn: (row) => renderText(row.derivedEcosystem || ''),
        id: 'derivedEcosystem',
        header: t('tables.components.headers.ecosystem'),
      },
      {
        accessorFn: (row) => row.vulnerabilities,
        id: 'countVulnerabilities.total',
        header: t('tables.components.headers.vulns'),
        Cell: ({ row }) => {
          const vulns = row.original.vulnerabilities || [];
          const cveIds = vulns.map((v) => v.cveId);
          if (cveIds?.length) {
            if (cveIds.length > 1) {
              return (
                <Tooltip
                  multiline
                  width={200}
                  label={
                    <Stack>
                      {cveIds.map((cveId) => (
                        <Text>{cveId}</Text>
                      ))}
                    </Stack>
                  }
                >
                  <Flex wrap="nowrap">
                    {cveIds.slice(0, 1).join(', ')}...{' '}
                    <Text color="cyan">+{cveIds.length - 1}</Text>
                  </Flex>
                </Tooltip>
              );
            }
            return cveIds.join(', ');
          }
          return <span className="table-na">{t('global.noneFound')}</span>;
        },
      },
      {
        accessorFn: (row) => row.licensesData,
        id: 'licensesData.fullName',
        header: t('tables.components.headers.license'),
        Cell: ComponentsLicenseCell,
      },
      {
        enableSorting: false,
        accessorFn: (row) => row.supplier,
        id: 'supplier.name',
        header: t('tables.components.headers.source'),
        Header: () => (
          <span>
            {t('tables.components.headers.source')}
            <Tooltip label={t('tables.components.headers.source-tooltip')}>
              <span style={{ marginLeft: '5px' }}>
                <Icon
                  icon="question-circle"
                  style={{ position: 'relative', top: '1px' }}
                />
              </span>
            </Tooltip>
          </span>
        ),
        Cell: ({ row }) => {
          const normalizedSource = normalizeAuthor(row.original, t, 'author');

          const normalizedAuthor =
            normalizedSource === 'unknown-source'
              ? t('jargon.unknown-source')
              : normalizedSource;

          return (
            <>
              <span
                className={`${normalizedSource === 'unknown-source' ? 'table-na' : ''}`}
              >
                {renderText(normalizedAuthor)}
                <ClickableRegion
                  regionLabel="Modify source information."
                  onClick={() => {
                    setEditSupplierComponent(row.original);
                    setShowSupplierModal(true);
                  }}
                  className="inline-list-link"
                >
                  <>
                    <Icon icon="pencil" />
                  </>
                </ClickableRegion>
              </span>
            </>
          );
        },
      },
      {
        accessorFn: (row) => row.description,
        id: 'description',
        header: t('tables.components.headers.description'),
        Cell: ({ cell }) => {
          const cellValue = cell.getValue<string>();
          if (cellValue?.length) {
            return renderText(cellValue);
          }
          return <span className="table-na">{t('global.notProvided')}</span>;
        },
      },
      {
        accessorFn: (row) => row.dateModified,
        id: 'dateModified',
        header: t('tables.components.headers.lastUpdated'),
        Cell: ({ cell }) => {
          const curr = DateTime.fromISO(cell.getValue<string>()).toLocaleString(
            DateTime.DATETIME_MED,
          );
          return renderText(curr);
        },
      },
    ],
    [],
  );

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useURLandLocalStorageSortingState('components', []);

  const {
    data: {
      data: fetchedComponents = [],
      queryInfo: { totalCount: countComponents = 0 } = {},
    } = {},
    isFetching: isFetchingComponents,
    isLoading: isLoadingComponents,
    isError: isErrorLoadingComponents,
  } = useFetchComponents({
    assetId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filters: getCurrentFilters(),
  });

  //prefetch the next page for faster navigation
  useFetchComponents({
    assetId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 2,
    limit: pagination.pageSize,
    filters: getCurrentFilters(),
  });

  const table = useMantineReactTable<InterfaceOrganizationComponentWithVulns>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    columns,
    data: fetchedComponents,
    enableFilters: false,
    enablePagination: false, // Disable pagination for this table
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: countComponents,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: true,
    },
    state: {
      globalFilter: componentName,
      pagination,
      sorting,
      isLoading: isLoadingComponents,
      showLoadingOverlay: isFetchingComponents,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setComponentName,
    mantineSearchTextInputProps: {
      placeholder: t('tables.components.search-components'),
      sx: {
        minWidth: '260px',
      },
    },
    renderTopToolbar: ({ table }) => (
      <Flex justify="space-between" m="1rem 0">
        <Flex gap="xs">
          <MRT_GlobalFilterTextInput table={table} />
          {/* <Button
            onClick={() => setFilterSidebarOpen(!filterSidebarOpen)}
            leftIcon={<Icon icon="filter" />}
            variant="default"
          >
            {t('tables.components.filter')}
          </Button> */}
        </Flex>
      </Flex>
    ),
  });

  return (
    <>
      {isErrorLoadingComponents && (
        <ul className="page-errors anim-slideInUpShort">
          <li>{t('tables.components.unable-to-fetch-components')}</li>
        </ul>
      )}
      {editSupplierComponent && (
        <SupplierModal
          opened={showSupplierModal}
          onCancel={() => {
            setEditSupplierComponent(null);
            setShowSupplierModal(false);
          }}
          onConfirm={() => {
            queryClient.invalidateQueries(['components']);
            setEditSupplierComponent(null);
            setShowSupplierModal(false);
          }}
          component={editSupplierComponent as InterfaceOrganizationComponent}
        />
      )}
      <div className="list-components">
        <MantineReactTable table={table} />
        {/* <Drawer
          title={t('global.filters')}
          onClose={() => setFilterSidebarOpen(false)}
          opened={filterSidebarOpen}
          position="right"
          overlayProps={{ opacity: 0.3 }}
          size="xs"
          shadow="xl"
          styles={{
            inner: {
              top: '58px',
            },
            content: {
              border: 'solid 2px var(--color-bg-layout-stroke)',
              overflowX: 'hidden',
            },
          }}
        >
          <ComponentsTableFilters filters={filters} updateFilter={updateFilter} />
        </Drawer> */}
        <DataTableFooter
          currentPage={pagination.pageIndex}
          limitPerPage={pagination.pageSize}
          totalResults={countComponents}
          onChangePage={setPagination}
        />
      </div>
    </>
  );
};
