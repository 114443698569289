import { MRT_Row } from 'mantine-react-table';
import { Badge, Flex, Text } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';

export const Manufacturer = ({ row }: { row: MRT_Row<InterfaceOrganizationProduct> }) => {
  const { t } = useTranslation();

  return (
    <Flex sx={{ alignItems: 'center' }}>
      <Text>{row.original.manufacturer}</Text>
      {/*{row.original?.relationship === 'first' && (
        <Badge
          sx={{
            marginLeft: '10px',
            width: '35px',
            '& span': {
              textOverflow: 'none',
              overflow: 'visible',
            },
          }}
        >
          {t('global.first')}
        </Badge>
      )}
      {row.original?.relationship === 'third' && (
        <Badge
          color="gray"
          sx={{
            marginLeft: '10px',
            width: '35px',
            '& span': {
              textOverflow: 'none',
              overflow: 'visible',
            },
          }}
        >
          {t('global.third')}
        </Badge>
      )}*/}
    </Flex>
  );
};
