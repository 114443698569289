import { Badge, Flex } from '@mantine/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useURLFilterStringState } from '../../../../hooks/utils/usePersistentStates';
import styles from './ImpactTabs.module.scss';
import { VULNERABILITY_OVERVIEW_IMPACT_TABS } from './OverviewTab/OverviewTab';
interface ImpactTabsProps {
  componentsCount: number | undefined;
  assetsCount: number | undefined;
  productsCount: number | undefined;
  selectedTab: ReturnType<typeof useURLFilterStringState>[0];
  setSelectedTab: ReturnType<typeof useURLFilterStringState>[1];
}

export const ImpactTabs = ({
  componentsCount,
  assetsCount,
  selectedTab,
  setSelectedTab,
  productsCount,
}: ImpactTabsProps) => {
  const { t } = useTranslation();

  const handleChangeTabs = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <div className="impact-tabs">
      <div
        onClick={() => handleChangeTabs(VULNERABILITY_OVERVIEW_IMPACT_TABS.ASSETS)}
        className={classNames(
          'tab card',
          selectedTab === VULNERABILITY_OVERVIEW_IMPACT_TABS.ASSETS && 'tab-active',
        )}
      >
        <p className="tab-name">{t('page.vulnerability.details.overview-tab.assets')}</p>
        <p className="tab-value">{assetsCount ? assetsCount : '--'}</p>
      </div>
      <div
        onClick={() => handleChangeTabs(VULNERABILITY_OVERVIEW_IMPACT_TABS.COMPONENTS)}
        className={classNames(
          'tab card',
          selectedTab === VULNERABILITY_OVERVIEW_IMPACT_TABS.COMPONENTS && 'tab-active',
        )}
      >
        <p className="tab-name">
          {t('page.vulnerability.details.overview-tab.components')}
        </p>
        <p className="tab-value">{componentsCount ? componentsCount : '--'}</p>
      </div>
      <div
        onClick={() => handleChangeTabs(VULNERABILITY_OVERVIEW_IMPACT_TABS.PRODUCTS)}
        className={classNames(
          'tab card',
          selectedTab === VULNERABILITY_OVERVIEW_IMPACT_TABS.PRODUCTS && 'tab-active',
        )}
      >
        <Flex>
          <p className="tab-name">
            {t('page.vulnerability.details.overview-tab.products')}
          </p>
          <div className={styles.badgeContainer}>
            <div className={styles.badgeContent}>
              <Badge className="beta-chip">{t('global.beta')}</Badge>
            </div>
          </div>
        </Flex>
        <p className="tab-value">{productsCount ? productsCount : '--'}</p>
      </div>
    </div>
  );
};
