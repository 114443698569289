import { Dispatch, SetStateAction, useEffect } from 'react';
import { Button, Group, Modal, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import hitApi from '../../../api';
import { useDeleteProduct } from '../../../hooks/products/useDeleteProduct';
import { ProductFormData } from '../../../hooks/products/usePostProduct';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

interface DeleteProductModalProps {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  productData: ProductFormData | undefined;
}

export const DeleteProductModal = ({
  opened,
  setOpened,
  productData,
}: DeleteProductModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess } = useDeleteProduct({
    closeModalCallback: () => setOpened(false),
  });

  const handleProductDelete = async (productId: string): Promise<void> => {
    mutate(productId);
  };

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries({ queryKey: ['products'] });
      setOpened(false);
      navigate('/products');
    }
  }, [isSuccess]);

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title={t('product.delete-modal.title')}
    >
      <Text>{t('product.delete-modal.description')}</Text>
      <Group position="right" mt="md">
        <Button
          variant="default"
          type="button"
          onClick={() => setOpened(false)}
          disabled={isLoading}
        >
          {t('product.cancel')}
        </Button>
        <Button
          type="submit"
          color="red"
          onClick={() => (productData?.id ? handleProductDelete(productData?.id) : null)}
          disabled={isLoading}
        >
          {t('product.delete')}
        </Button>
      </Group>
    </Modal>
  );
};
