import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceFullGithubIntegration } from '../../types/FullInterfaces';

export const useFetchGithubIntegrations = () => {

  const fetchUrl = `integration/github/all`;

  return useQuery({
    queryKey: ['integration-github', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data as InterfaceFullGithubIntegration[],
  });
};
