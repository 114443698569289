import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/Icon';
import { Link } from 'react-router-dom';

export const VexDocsTableEmptyScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="vex-docs-empty-screen">
      <Icon icon="arrow-up-from-bracket" />
      <p className="table-empty-screen-text">
        {t('page.vulnerability.details.action-tab.empty-states.vex-docs')}
        <Link to="/uploads">{t('page.uploads.page-title')}</Link>
      </p>
    </div>
  );
};
