import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';

export const useFetchCountStats = () => {
  const filters = [{ field: 'assetActive', value: [true] }];
  const queryParams = new URLSearchParams({ filters: JSON.stringify(filters) });

  const fetchUrl = `stats?${queryParams.toString()}`;

  return useQuery({
    queryKey: ['countStats', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) =>
      (data?.data ?? {
        countAssets: 0,
        countSboms: 0,
        countVulnerabilities: 0,
        countComponents: 0,
      }) as {
        countAssets: number;
        countSboms: number;
        countVulnerabilities: number;
        countComponents: number;
      },
    cacheTime: 0,
    staleTime: 0,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
  });
};
