import { TFunction } from 'i18next';
import { LabelInterface } from '../../../../hooks/queries/useFetchLabels';
import {
  CvssScoreFilter,
  LabelsFilter,
  LicenseIssuesFilter,
  MinRiskScoreFilter,
  OnCISAKEVListFilter,
  UploadedViaFilter,
  UploadedViaFilterValue,
  VulnRecommendationsFilter,
  VulnRecommendationsFilterValue,
} from '../types/SBOMAlerts.interface';

export interface SBOMAlertControlsConfig {
  sourceSection: {
    controls: {
      uploadedVia: UploadedViaFilter & {
        label: string;
        value: [UploadedViaFilterValue, ...UploadedViaFilterValue[]];
        options: {
          value: UploadedViaFilterValue;
          label: string;
        }[];
      };
      labels: LabelsFilter & {
        label: string;
        options: {
          value: string;
          color: string;
          label: string;
        }[];
      };
    };
  };
  riskCriteriaSection: {
    controls: {
      cvssScore: CvssScoreFilter & {
        label: string;
        value: number;
        optionsLabel: string;
        options: {
          min: number;
          max: number;
          step: number;
        };
      };
      vulnRecommendations: VulnRecommendationsFilter & {
        label: string;
        optionsLabel: string;
        options: {
          value: VulnRecommendationsFilterValue;
          label: string;
        }[];
      };
      minRiskScore: Omit<MinRiskScoreFilter, 'value'> & {
        value?: MinRiskScoreFilter['value'];
        label: string;
        optionsLabel: string;
        options: {
          value: string;
          label: string;
        }[];
      };
      licenseIssues: LicenseIssuesFilter & {
        label: string;
        value: string[];
        optionsLabel: string;
        options: {
          value: string;
          label: string;
        }[];
      };
      onCISAKEVList: OnCISAKEVListFilter & {
        label: string;
        value: boolean;
        options: string[];
      };
    };
    filters: { value: RiskCriteriaFilterId; label: string }[];
  };
}

export type RiskCriteriaFilterId =
  keyof SBOMAlertControlsConfig['riskCriteriaSection']['controls'];

export const getSBOMAlertsControls = ({
  t,
  labelsResponse,
}: {
  t: TFunction;
  labelsResponse: LabelInterface[];
}): SBOMAlertControlsConfig => {
  return {
    sourceSection: {
      controls: {
        uploadedVia: {
          id: 'uploadedVia',
          section: 'source',
          label: t('page.userSettings.sbomUploadAlerts.source.uploadedVia'),
          type: 'multiSelect',
          value: ['source', 'vendorUploads'],
          options: [
            {
              value: 'source',
              label: t('page.userSettings.sbomUploadAlerts.source.vendorUploadsSource'),
            },
            {
              value: 'vendorUploads',

              label: t('page.userSettings.sbomUploadAlerts.source.vendorUploads'),
            },
            {
              value: 'manual',

              label: t('page.userSettings.sbomUploadAlerts.source.manualUpload'),
            },
          ],
        },
        labels: {
          id: 'labels',
          section: 'source',
          label: t('page.userSettings.sbomUploadAlerts.source.labels'),
          type: 'multiSelect',
          value: [],
          options: labelsResponse.map((label) => ({
            value: label._id || '',
            label: label.name || '',
            color: label.color || '',
          })),
        },
      },
    },
    riskCriteriaSection: {
      controls: {
        cvssScore: {
          id: 'cvssScore',
          section: 'riskCriteria',
          label: t('page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.name'),
          type: 'number',
          value: 5,
          optionsLabel: t(
            'page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.optionsLabel',
          ),
          options: {
            min: 0,
            max: 10,
            step: 1,
          },
        },
        vulnRecommendations: {
          id: 'vulnRecommendations',
          section: 'riskCriteria',
          label: t(
            'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.name',
          ),
          type: 'multiSelect',
          value: [],
          optionsLabel: t(
            'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.optionsLabel',
          ),
          options: [
            {
              value: 'mitigate',
              label: t(
                'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.mitigate',
              ),
            },
            {
              value: 'monitor',
              label: t(
                'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.monitor',
              ),
            },
            {
              value: 'acceptRisk',
              label: t(
                'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.acceptRisk',
              ),
            },
          ],
        },
        minRiskScore: {
          id: 'minRiskScore',
          section: 'riskCriteria',
          label: t('page.userSettings.sbomUploadAlerts.riskCriteria.minRiskScore.name'),
          type: 'select',
          value: undefined,
          optionsLabel: t(
            'page.userSettings.sbomUploadAlerts.riskCriteria.minRiskScore.optionsLabel',
          ),
          options: [
            {
              value: 'high',
              label: t('global.high'),
            },
            {
              value: 'medium',
              label: t('global.medium'),
            },
            {
              value: 'low',
              label: t('global.low'),
            },
          ],
        },
        licenseIssues: {
          id: 'licenseIssues',
          section: 'riskCriteria',
          label: t('page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.name'),
          type: 'multiSelect',
          value: [],
          optionsLabel: t(
            'page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.optionsLabel',
          ),
          options: [
            {
              value: 'Copyleft',
              label: t(
                'page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.copyleft',
              ),
            },
          ],
        },
        onCISAKEVList: {
          id: 'onCISAKEVList',
          section: 'riskCriteria',
          label: t('page.userSettings.sbomUploadAlerts.riskCriteria.onCISAKEVList.name'),
          type: 'boolean',
          value: false,
          options: [],
        },
      },
      filters: [
        {
          value: 'cvssScore',
          label: t('page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.name'),
        },
        {
          value: 'vulnRecommendations',
          label: t(
            'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.name',
          ),
        },
        {
          value: 'minRiskScore',
          label: t('page.userSettings.sbomUploadAlerts.riskCriteria.minRiskScore.name'),
        },
        {
          value: 'licenseIssues',
          label: t('page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.name'),
        },
        {
          value: 'onCISAKEVList',
          label: t('page.userSettings.sbomUploadAlerts.riskCriteria.onCISAKEVList.name'),
        },
      ],
    },
  };
};
