import '../../../scss/pages/labels.scss';
import { useState, useEffect } from 'react';
import { Input } from 'rsuite';
import { Button, Popover, Text } from '@mantine/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Icon/Icon';
import ClickableRegion from '../../../components/ClickableRegion';
import ColorPicker, { DEFAULT_LABEL_COLOR, LABEL_COLORS } from './ColorPicker';
import { InterfaceManageableLabel } from './Labels';
import { InterfaceLabel } from '@manifest-cyber/types/interface/dbTables';
import { useLabel } from './useLabel.hook';

interface ILabelProps {
  label: InterfaceManageableLabel;
  updateFn: (name: string, color: string, _id: string) => Promise<void>;
  insertFn: (name: string, color: string) => Promise<void>;
  deleteFn: (_id?: string) => Promise<void>;
  checkExistFn: (name?: string) => boolean;
}

function Label({ label, updateFn, insertFn, deleteFn, checkExistFn }: ILabelProps) {
  const { t } = useTranslation();
  const {
    name,
    displayName,
    color,
    inlineError,
    isEditing,
    handleNameChange,
    handleKeyDown,
    handleSave,
    handleCancel,
    handleEditClick,
    handleDeleteClick,
    handleUpdateColor,
    renderLabelCount,
  } = useLabel({ label, updateFn, insertFn, deleteFn, checkExistFn });
  const { dateCreated } = label;


  return (
    <div className="label">
      {isEditing ? (
        <div className="label-container">
          <div style={{ width: '100%' }}>
            <div className="label-edit">
              <Popover position="bottom" shadow="md">
                <Popover.Target>
                  <div className="label-color-container editing">
                    <div
                      className="label-color editing"
                      style={{ backgroundColor: color }}
                    />
                  </div>
                </Popover.Target>
                <Popover.Dropdown>
                  <ColorPicker currentColor={color} updateColorFn={handleUpdateColor} />
                </Popover.Dropdown>
              </Popover>

              <Input
                type="text"
                className="label-name-input"
                placeholder={t('page.userSettings.labels.newName')}
                maxLength={200}
                value={name}
                onChange={handleNameChange}
                onKeyDown={handleKeyDown}
              />
              <Button variant="default" onClick={handleCancel}>
                {t('global.cancel')}
              </Button>
              <Button
                disabled={name?.length < 1 || inlineError !== null}
                onClick={handleSave}
              >
                {t('global.save.buttonShort')}
              </Button>
            </div>
          </div>
          <div>
            {inlineError && (
              <div className={`label-${inlineError?.type || 'error'}`}>
                {inlineError?.type === 'error' && <Icon icon="exclamation-triangle" />}
                <span>
                  {t(`page.userSettings.labels.error.${inlineError?.key || 'generic'}`)}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="label-view">
          <div className="label-info">
            <div className="label-color-container">
              <div className="label-color" style={{ backgroundColor: color }} />
            </div>
            <Popover position="top" withArrow shadow="md">
              <Popover.Target>
                <div className="label-name">{displayName}</div>
              </Popover.Target>
              <Popover.Dropdown>{name}</Popover.Dropdown>
            </Popover>
            <div className="label-assets">{renderLabelCount()}</div>
          </div>
          <div className="label-actions">
            {dateCreated && (
              <Popover position="top" withArrow shadow="md">
                <Popover.Target>
                  <span>
                    <Icon icon="circle-info" />
                  </span>
                </Popover.Target>
                <Popover.Dropdown>
                  {t('global.createdOn', {
                    date: DateTime.fromJSDate(dateCreated).toFormat('DDD'),
                  })}
                </Popover.Dropdown>
              </Popover>
            )}
            <Icon icon="pen" onClick={handleEditClick} />
            <Popover width={150} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <span>
                  <Icon icon="ellipsis" />
                </span>
              </Popover.Target>
              <Popover.Dropdown onClick={handleDeleteClick} className="">
                <div
                  className="label-actions--delete"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <Icon icon="trash-can" />
                  <Text>{t('page.userSettings.labels.deleteLabel')}</Text>
                </div>
              </Popover.Dropdown>
            </Popover>
          </div>
        </div>
      )}
    </div>
  );
}

export default Label;
