import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceOrganizationComponentWithVulns } from '../../types/FullInterfaces';

interface Params {
  assetId?: string;
  enabled?: boolean;
  limit?: number;
  page?: number;
  sortColumn?: string;
  sortType?: string;
  filters?: any;
}

export const useFetchComponents = ({
  assetId,
  enabled = true,
  limit,
  page,
  sortColumn,
  sortType,
  filters,
}: Params) => {
  let fetchUrl = 'components?';
  if (assetId) {
    fetchUrl += `&assetId=${assetId}`;
  }
  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (sortColumn && sortType) {
    fetchUrl += `&sort={"${sortColumn}":"${sortType === 'asc' ? 1 : -1}","_id":"${
      sortType === 'asc' ? 1 : -1
    }"}`;
  }
  if (filters) {
    fetchUrl += `&filters=${JSON.stringify(filters)}`;
  }
  fetchUrl = fetchUrl.replace('?&', '?');

  return useQuery({
    enabled: enabled,
    keepPreviousData: true,
    queryKey: ['components', assetId && { assetId }, `/${fetchUrl}`].filter(Boolean),
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) =>
      data as {
        data: InterfaceOrganizationComponentWithVulns[];
        queryInfo: { totalCount: number; nLimit: number; nPage: number };
      },
    onError: (error) => {
      console.error(`Unable to fetch components for asset ${assetId}: `, error);
    },
  });
};
