import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';

export const useFetchRiskiestAssetComponents = (assetId: string, count: number) => {
  const fetchUrl = `asset/${assetId}/riskiestComponents?count=${count}`;

  return useQuery({
    enabled: !!assetId,
    queryKey: ['riskiestAssetComponents', { _id: assetId }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data as InterfaceOrganizationComponent[],
    onError: (error) => {
      console.error('Unable to fetch riskiest asset components: ', error);
    },
  });
};
