import {
  MRT_TableOptions,
  type MRT_Icons,
  MRT_GlobalFilterTextInput,
} from 'mantine-react-table';
import Icon from '../Icon/Icon';
import { Flex } from '@mantine/core';

const icons: Partial<MRT_Icons> = {
  IconArrowAutofitContent: (props: any) => <Icon icon={'text-width'} {...props} />,
  IconArrowsSort: (props: any) => <Icon icon={'sort'} {...props} />,
  IconBoxMultiple: (props: any) => <Icon icon={'layer-group'} {...props} />,
  IconChevronDown: (props: any) => <Icon icon={'chevron-down'} {...props} />,
  IconChevronsDown: (props: any) => <Icon icon={'angle-down'} {...props} />,
  IconCircleX: (props: any) => <Icon icon={'circle-xmark'} {...props} />,
  IconClearAll: (props: any) => <Icon icon={'bars-staggered'} {...props} />,
  IconColumns: (props: any) => <Icon icon={'columns'} {...props} />,
  IconDeviceFloppy: (props: any) => <Icon icon={'floppy-disk'} {...props} />,
  IconDots: (props: any) => <Icon icon={'ellipsis-v'} {...props} />, //also vertical
  IconDotsVertical: (props: any) => <Icon icon={'ellipsis-v'} {...props} />,
  IconEdit: (props: any) => <Icon icon={'edit'} {...props} />,
  IconEyeOff: (props: any) => <Icon icon={'eye-slash'} {...props} />,
  IconFilter: (props: any) => <Icon icon={'filter'} {...props} />,
  IconFilterOff: (props: any) => <Icon icon={'filter-circle-xmark'} {...props} />,
  IconGripHorizontal: (props: any) => <Icon icon={'grip'} {...props} />,
  IconMaximize: (props: any) => <Icon icon={'expand'} {...props} />,
  IconMinimize: (props: any) => <Icon icon={'compress'} {...props} />,
  IconPinned: (props: any) => <Icon icon={'thumb-tack'} {...props} />,
  IconPinnedOff: (props: any) => <Icon icon={'thumb-tack'} {...props} />,
  IconSearch: (props: any) => <Icon icon={'search'} {...props} />,
  IconSearchOff: (props: any) => <Icon icon={'search-minus'} {...props} />,
  IconSortAscending: (props: any) => (
    <Icon icon={'arrow-up'} color="primary" {...props} />
  ),
  IconSortDescending: (props: any) => (
    <Icon icon={'arrow-down'} color="primary" {...props} />
  ),
  IconBaselineDensityLarge: (props: any) => <Icon icon={'bars'} {...props} />,
  IconBaselineDensityMedium: (props: any) => <Icon icon={'bars'} {...props} />,
  IconBaselineDensitySmall: (props: any) => <Icon icon={'bars'} {...props} />,
};

export const getDefaultTableOptions = <TData extends Record<string, any>>(): Partial<
  MRT_TableOptions<TData>
> => ({
  enableToolbarInternalActions: false,
  enableHiding: false,
  enableColumnActions: false,
  icons,
  initialState: {
    showGlobalFilter: true,
    showColumnFilters: true,
    density: 'xs',
  },
  mantineTableProps: {
    sx: {
      tableLayout: 'fixed',
    },
  },
  mantineSearchTextInputProps: {
    sx: {
      minWidth: '260px',
    },
  },
  mantinePaperProps: {
    sx: {
      margin: '1rem auto',
    },
    withBorder: false,
    shadow: 'none',
  },
  mantineTopToolbarProps: {
    sx: {
      gridTemplateColumns: 'auto auto',
      '@media (max-width: 1100px)': {
        gridTemplateColumns: 'auto',
      },
    },
  },
  mantineLoadingOverlayProps: {
    overlayOpacity: 0.2,
  },
  mantineBottomToolbarProps: {
    sx: {
      marginTop: '1rem',
    },
  },
  mantineSelectAllCheckboxProps: {
    size: 'md',
  },
  mantineSelectCheckboxProps: {
    size: 'md',
  },
  mantineTableBodyRowProps: ({ row }) => ({
    sx: {
      td: {
        backgroundColor: row.getIsSelected()
          ? 'rgba(30,188,226,0.15)'
          : 'rgba(19,27,38,1.0)', //dark.7
        whiteSpace: 'normal',
      },
      '&:hover': {
        td: {
          backgroundColor: row.getIsSelected() ? undefined : 'rgba(25, 36, 52, 1)', //dark.5
          whiteSpace: 'normal',
        },
      },
    },
  }),
  paginationDisplayMode: 'pages',
  positionPagination: 'bottom',
  positionActionsColumn: 'last',
  renderTopToolbar: ({ table }) => (
    <Flex justify="space-between" m="1rem 0">
      <MRT_GlobalFilterTextInput table={table} />
      {table.options.renderTopToolbarCustomActions?.({ table })}
    </Flex>
  ),
});
