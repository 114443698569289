import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

type FetchError = Error;

type Response = {
  success: boolean;
  data: boolean | null;
  errors: string[];
};

export const useCheckProductAssociation = () => {
  const queryClient = useQueryClient();

  return useMutation<Response, FetchError, string[]>(
    (assetIds) =>
      hitApi
        .post('assets/check-product-associations', true, { assetIds })
        .then((response) => ({
          success: response.success,
          data: response.data,
          errors: response.errors,
        })),
    {
      onSuccess: (data, assetIds) => {
        queryClient.setQueryData(['checkProductAssociations', assetIds.join(',')], data);
      },
      onError: (error) => {
        console.error('Unable to check product associations:', error);
      },
    },
  );
};
