import styles from '../AssetsTable.module.scss';
import { MRT_Row } from 'mantine-react-table';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';

export const VersionColumn = ({ row }: { row: MRT_Row<InterfaceOrganizationAsset> }) => {
  return (
    <span
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      {row.original?.version}
    </span>
  );
};
