import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceFullGithubIntegration } from '../../types/FullInterfaces';

export const useFetchOneGithubIntegration = (orgId: string, installationId: string) => {

  const fetchUrl = `integration/github/${orgId}/${installationId}`;

  return useQuery({
    queryKey: ['integration-github', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data as InterfaceFullGithubIntegration,
  });
};
