import { NumberInput, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../ClickableRegion';
import Icon from '../../Icon/Icon';
import './DataTableFooter.scss';

interface DataTableFooterProps {
  currentPage: number;
  limitPerPage: number;
  totalResults: number;
  onChangePage: any; // Function to fire
}

interface PaginationStoreValue {
  pageIndex: number;
  pageSize: number;
}

/**
 * DataTableFooter
 * Intended to be used as global footer for all DataTables.
 * Pass in props & then modify the respective DataTable with below options:
 * - `enablePagination: false` to disable the DataTable's built-in pagination
 * - `manualPagination: true` to enable manual pagination
 * - `mantinePaperProps: { className: 'manifest-data-table-no-footer' }` to remove the DataTable's built-in footer
 * See src/components/DataTables/ComponentsTable.tsx for an example.
 */
export const DataTableFooter = ({
  currentPage,
  limitPerPage,
  totalResults,
  onChangePage,
}: DataTableFooterProps) => {
  const { t } = useTranslation();
  const currentOffset = currentPage * limitPerPage;
  const totalPages = Math.ceil(totalResults / limitPerPage);

  const offsetEnd = Math.min(currentOffset + limitPerPage, totalResults);

  const handlePageNumberChange = (newTargetPage: number) => {
    const newPaginationValue: PaginationStoreValue = {
      pageIndex: newTargetPage,
      pageSize: limitPerPage,
    };

    onChangePage(newPaginationValue);
  };

  const handleLimitChange = (newValuePerPage: string) => {
    const newPaginationValue: PaginationStoreValue = {
      pageIndex: 0,
      pageSize: parseInt(`${newValuePerPage}`), // Select gives us a string, so coerce
    };

    onChangePage(newPaginationValue);
  };

  return (
    <div className="manifest-data-table-footer">
      <div>
        {t('tables.footer.showing', {
          offsetStart: totalResults > 0 ? currentOffset + 1 : currentOffset,
          offsetEnd: offsetEnd,
          total: totalResults,
        })}
      </div>
      <div>
        <ClickableRegion
          regionLabel={t('tables.footer.previousPage')}
          onClick={() => handlePageNumberChange(currentPage - 1)}
          className="manifest-data-table-footer__page-change"
          disabled={currentPage === 0}
        >
          <Icon icon="angle-left" />
        </ClickableRegion>
        <div className="manifest-data-table-footer__inner">
          <NumberInput
            value={currentPage + 1}
            defaultValue={1}
            min={1}
            onChange={(newValue) =>
              handlePageNumberChange(parseInt(`${newValue || 1}`) - 1)
            }
            aria-label={t('tables.footer.currentPageLabel', { pages: totalPages })}
            max={totalPages}
            className="manifest-data-table-footer__page-number"
          />
          <span>
            {t('tables.footer.currentPage', {
              pages: totalPages,
            })}
          </span>
        </div>
        <ClickableRegion
          regionLabel={t('tables.footer.nextPage')}
          onClick={() => handlePageNumberChange(currentPage + 1)}
          className="manifest-data-table-footer__page-change"
          disabled={currentPage === totalPages - 1}
        >
          <Icon icon="angle-right" />
        </ClickableRegion>
      </div>
      <div>
        <span>{t('tables.footer.itemsPerPage')}</span>
        <Select
          className="manifest-data-table-footer__select"
          data={[
            { value: '10', label: '10' },
            { value: '20', label: '20' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
          ]}
          value={`${limitPerPage}`}
          onChange={(newValue) => handleLimitChange(`${newValue || 20}`)}
          size="sm"
          radius="sm"
          aria-label={t('tables.footer.itemsPerPage')}
        />
      </div>
    </div>
  );
};

export default DataTableFooter;
