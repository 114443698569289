import { useTranslation } from 'react-i18next';
import { UploadedViaFilterValue } from '../../../types/SBOMAlerts.interface';
import styles from './SourceSection.module.scss';

const SourceDetail = ({
  labels,
  uploadedVia,
  allLabels,
}: {
  allLabels: { value: string; label: string; color: string }[];
  labels: string[];
  uploadedVia: UploadedViaFilterValue[];
}) => {
  const { t } = useTranslation();
  const selectedTags = allLabels.filter((label) => labels.includes(label.value));
  const uploadedViaSource = uploadedVia.includes('source');
  const uploadedViaVendorUploads = uploadedVia.includes('vendorUploads');
  const uploadedViaManual = uploadedVia.includes('manual');

  return (
    <div>
      <div className={styles.detailSubtitle}>
        {t('page.userSettings.sbomUploadAlerts.source.detailDescription')}
      </div>
      <ul>
        {uploadedViaSource && (
          <li>{t('page.userSettings.sbomUploadAlerts.source.vendorUploadsSource')}</li>
        )}
        {uploadedViaVendorUploads && (
          <li>{t('page.userSettings.sbomUploadAlerts.source.vendorUploads')}</li>
        )}
        {uploadedViaManual && (
          <li>{t('page.userSettings.sbomUploadAlerts.source.manualUpload')}</li>
        )}
        {selectedTags.length > 0 && (
          <li>
            {t('page.userSettings.sbomUploadAlerts.source.labels')}{' '}
            {selectedTags.map((v) => v.label).join(', ')}
          </li>
        )}
      </ul>
    </div>
  );
};

export default SourceDetail;
