import { Paper, Space, Switch, Text, Title } from '@mantine/core';
import Cookies from 'js-cookie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/Icon';
import featureFlagDisciminators from '../../configs/featureFlags';
import { getNormalizedCookieName } from '../../hooks/useFeatureFlag';
import './FeatureToggle.scss';

export const FeatureToggle = () => {
  const { t } = useTranslation();

  // Available options for feature toggle
  const availableOptions = {
    ENABLED: 'on',
    DISABLED: 'off',
  };

  /**
   * Add available features here!
   */
  const availableFeatures: {
    /**
     * Human-friendly name of the feature.
     */
    name: string;
    /**
     * This is prefixed with `experimental_` and used to lookup the respective cookiename.
     * e.g. value of `products` will be used to lookup `experimental_products` cookie.
     */
    cookieDiscriminator: string;
    /**
     * Human-friendly description of the feature.
     */
    description: string;
    /**
     * If enabled, this will trigger a soft page reload so that the app is aware of the new value
     */
    requiresReload: boolean;
  }[] = [
    {
      name: t('page.features.flags.sbomUploadAlerts.name'),
      cookieDiscriminator: featureFlagDisciminators.sbomUploadAlerts,
      description: t('page.features.flags.sbomUploadAlerts.description'),
      requiresReload: true,
    },
    {
      name: t('page.features.flags.engineerElements.name'),
      cookieDiscriminator: featureFlagDisciminators.engineerElements,
      description: t('page.features.flags.engineerElements.description'),
      requiresReload: true,
    },
    {
      name: t('page.features.flags.triageScopeAllVersionsOfThisAssetCurrent.name'),
      cookieDiscriminator:
        featureFlagDisciminators.triageScopeAllVersionsOfThisAssetCurrent,
      description: t(
        'page.features.flags.triageScopeAllVersionsOfThisAssetCurrent.description',
      ),
      requiresReload: true,
    },
    {
      name: t('page.features.flags.sbomEnrich.name'),
      cookieDiscriminator: featureFlagDisciminators.sbomEnrich,
      description: t('page.features.flags.sbomEnrich.description'),
      requiresReload: true,
    },
    {
      name: t('page.features.flags.githubMultiOrg.name'),
      cookieDiscriminator: featureFlagDisciminators.githubMultiOrg,
      description: t('page.features.flags.githubMultiOrg.description'),
      requiresReload: true,
    },
    {
      name: t('page.features.flags.sharingPortal.name'),
      cookieDiscriminator: featureFlagDisciminators.sharingPortal,
      description: t('page.features.flags.sharingPortal.description'),
      requiresReload: true,
    },
  ];

  /**
   * Given a cookieDiscriminator value, determine if the Cookie is set.
   * @param cookieDiscriminator {string}
   * @returns boolean
   */
  const getInitialStateFromCookies = (cookieDiscriminator: string) => {
    return (
      Cookies.get(`${getNormalizedCookieName(cookieDiscriminator)}`) ===
      availableOptions.ENABLED
    );
  };

  // Setup default state based on available features.
  const defaultFeatureStates: {
    [key: string]: boolean;
  } = {};

  for (const singleFeature of availableFeatures) {
    defaultFeatureStates[singleFeature.cookieDiscriminator] = getInitialStateFromCookies(
      singleFeature.cookieDiscriminator,
    );
  }

  const [featureStates, setFeatureStates] =
    React.useState<Record<string, boolean>>(defaultFeatureStates);

  const handleToggle = (cookieDiscriminator: string) => {
    const newState = !featureStates[cookieDiscriminator];
    setFeatureStates({
      ...featureStates,
      [cookieDiscriminator]: newState,
    });
    Cookies.set(
      `${getNormalizedCookieName(cookieDiscriminator)}`,
      newState ? availableOptions.ENABLED : availableOptions.DISABLED,
    );

    // Reload page if needed so all changes take effect.
    if (
      availableFeatures.find(
        (feature) => feature.cookieDiscriminator === cookieDiscriminator,
      )?.requiresReload
    ) {
      window.location.reload();
    }
  };

  return (
    <div className="feature-toggle">
      <Title className="feature-toggle__title">
        <Icon icon="flask-vial" />
        <span>{t('page.features.header')}</span>
      </Title>
      <Text className="feature-toggle__description">
        {t('page.features.description')}
      </Text>
      <Paper className="feature-toggle__feature">
        {availableFeatures.map(({ cookieDiscriminator: feature, name, description }) => (
          <div key={feature} className="feature-toggle__switch">
            <h5>{name}</h5>
            <p>{description}</p>
            <Switch
              aria-label={`switch-${feature}`}
              id={`switch-${feature}`}
              checked={featureStates[feature]}
              onChange={() => handleToggle(feature)}
            />
          </div>
        ))}
        <Space h="md" />
      </Paper>
    </div>
  );
};
