import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import type { InterfaceOssIngest } from '@manifest-cyber/types/interface/dbTables';

interface Params {
  ossIngestId: string;
}

interface Options {
  enabled?: boolean;
  refetchInterval?: number;
  staleTime?: number;
  keepPreviousData?: boolean;
}

export const useFetchOssIngest = ({ ossIngestId }: Params, options?: Options) => {
  let fetchUrl = `oss-ingest/${ossIngestId}`;

  return useQuery({
    queryKey: ['ossingest', ossIngestId && { ossIngestId }, `/${fetchUrl}`].filter(
      Boolean,
    ),
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data?.[0] as InterfaceOssIngest,
    onError: (error) => {
      console.error(`Unable to fetch single OSS Ingest ${ossIngestId}: `, error);
    },
    enabled: options?.enabled,
    refetchInterval: options?.refetchInterval,
    staleTime: options?.staleTime,
    keepPreviousData: options?.keepPreviousData,
  });
};
