import { InterfaceNotificationSetting } from '@manifest-cyber/types/interface/dbTables';
import { Types } from 'mongoose';
import { useTranslation } from 'react-i18next';
import { getSeverityLabelKey } from '../../lib/getSeverityLabelKey';
import { useOrganizationId } from './useOrganizationId';

export const useAllNotificationsDefinitions = () => {
  const { t } = useTranslation();

  const allNotificationsDefinitions = [
    {
      id: 'vulnerabilityNotifications',
      type: 'userNotification',
      name: t('page.userSettings.notifications.vulnerabilityNotifications.name'),
      description: t(
        'page.userSettings.notifications.vulnerabilityNotifications.description',
      ),
      active: false,
      filters: [
        {
          id: 'cvssScore',
          name: t(
            'page.userSettings.notifications.vulnerabilityNotifications.filters.cvssScore.name',
          ),
          description: (value: number) =>
            t(
              'page.userSettings.notifications.vulnerabilityNotifications.filters.cvssScore.description',
              {
                value: `${value} (${t(getSeverityLabelKey({ value }))})`,
              },
            ),
          type: 'slider',
          value: 5,
          options: {
            className: 'gradient',
            min: 0,
            max: 10,
            step: 1,
            graduated: false,
          },
        },
        {
          id: 'numAssetsImpacted',
          name: t(
            'page.userSettings.notifications.vulnerabilityNotifications.filters.numAssetsImpacted.name',
          ),
          description: (value: number) => {
            if (value === 100) {
              return t(
                'page.userSettings.notifications.vulnerabilityNotifications.filters.numAssetsImpacted.maxValueDescription',
                { value },
              );
            } else {
              return t(
                'page.userSettings.notifications.vulnerabilityNotifications.filters.numAssetsImpacted.description',
                { value },
              );
            }
          },
          type: 'slider',
          value: 1,
          options: {
            min: 1,
            max: 100,
            step: 1,
            graduated: false,
          },
        },
        /**
         * Disabling this for now
         */
        // {
        //   id: 'epssPercentile',
        //   name: t(
        //     'page.userSettings.notifications.vulnerabilityNotifications.filters.epssPercentile.name',
        //   ),
        //   description: (value: string) =>
        //     t(
        //       'page.userSettings.notifications.vulnerabilityNotifications.filters.epssPercentile.description',
        //       { value: `${value}%` },
        //     ),
        //   type: 'slider',
        //   value: 50,
        //   options: {
        //     min: 0,
        //     max: 100,
        //     step: 1,
        //     graduated: false,
        //   },
        // },
      ],
      integrations: {
        value: ['email'],
        options: [
          {
            id: 'email',
            name: t('global.email'),
          },
        ],
      },
    },
    {
      id: 'sbomUploadAlerts',
      type: 'organizationNotification',
      active: false,
      filters: [
        {
          id: 'uploadedVia',
          section: 'source',
          name: t('page.userSettings.sbomUploadAlerts.source.uploadedVia'),
          type: 'checkbox',
          value: [],
          options: [
            {
              id: 'source',
              viewString: t(
                'page.userSettings.sbomUploadAlerts.source.vendorUploadsSourceViewString',
              ),
              name: t('page.userSettings.sbomUploadAlerts.source.vendorUploadsSource'),
            },
            {
              id: 'vendorUploads',
              viewString: t(
                'page.userSettings.sbomUploadAlerts.source.vendorUploadsViewString',
              ),
              name: t('page.userSettings.sbomUploadAlerts.source.vendorUploads'),
            },
            {
              id: 'manual',
              viewString: t(
                'page.userSettings.sbomUploadAlerts.source.manualUploadViewString',
              ),
              name: t('page.userSettings.sbomUploadAlerts.source.manualUpload'),
            },
          ],
        },
        {
          id: 'labels',
          section: 'source',
          name: t('page.userSettings.sbomUploadAlerts.source.labels'),
          viewString: (values: string[]) =>
            t('page.userSettings.sbomUploadAlerts.source.labelsViewString', {
              labels: values.map((v) => `"${v}"`).join(', '),
            }),
          type: 'labelsContainer',
          value: [],
          options: [],
        },
        {
          id: 'cvssScore',
          section: 'riskCriteria',
          viewString: (value: string) =>
            t('page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.viewString', {
              value,
            }),
          name: t('page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.name'),
          description: (value: number) =>
            t('page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.description', {
              value: `${value} (${t(getSeverityLabelKey({ value }))})`,
            }),
          type: 'slider',
          value: 5,
          options: {
            className: 'gradient',
            min: 0,
            max: 10,
            step: 1,
            graduated: false,
          },
        },
        {
          id: 'vulnRecommendations',
          section: 'riskCriteria',
          name: t(
            'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.name',
          ),
          viewString: (values: string[]) =>
            t(
              'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.viewString',
              { recommendations: values.join(`, `) },
            ),
          type: 'multiSelect',
          value: [],
          options: [
            {
              id: 'mitigate',
              name: t(
                'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.mitigate',
              ),
            },
            {
              id: 'monitor',
              name: t(
                'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.monitor',
              ),
            },
            {
              id: 'acceptRisks',
              name: t(
                'page.userSettings.sbomUploadAlerts.riskCriteria.vulnRecommendations.acceptRisks',
              ),
            },
          ],
        },
        {
          id: 'minRiskScore',
          section: 'riskCriteria',
          name: t('page.userSettings.sbomUploadAlerts.riskCriteria.minRiskScore.name'),
          viewString: (value: string) =>
            t('page.userSettings.sbomUploadAlerts.riskCriteria.minRiskScore.viewString', {
              value,
            }),
          type: 'select',
          value: '',
          options: [
            {
              id: 'high',
              name: t('global.high'),
            },
            {
              id: 'medium',
              name: t('global.medium'),
            },
            {
              id: 'low',
              name: t('global.low'),
            },
          ],
        },
        {
          id: 'licenseIssues',
          section: 'riskCriteria',
          name: t('page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.name'),
          viewString: (values: string[]) =>
            t(
              'page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.viewString',
              { licenseIssues: values.join(`, `) },
            ),
          type: 'multiSelect',
          value: [],
          options: [
            {
              id: 'Copyleft',
              name: t(
                'page.userSettings.sbomUploadAlerts.riskCriteria.licenseIssues.Copyleft',
              ),
            },
          ],
        },
        {
          id: 'onCISAKEVList',
          section: 'riskCriteria',
          name: t('page.userSettings.sbomUploadAlerts.riskCriteria.onCISAKEVList.name'),
          viewString: (_value: boolean) =>
            t('page.userSettings.sbomUploadAlerts.riskCriteria.onCISAKEVList.viewString'),
          type: 'boolean',
          value: false,
          options: [],
        },
      ],
      integrations: {
        value: ['email'],
        options: [
          {
            id: 'email',
            name: t('page.userSettings.sbomUploadAlerts.deliveryMethod.emailTo'),
          },
        ],
      },
    },
  ];
  return allNotificationsDefinitions;
};

export const useNewSbomUploadAlertDefinition = (
  sbomUploadAlertEventId: string,
): InterfaceNotificationSetting => {
  const [currentOrgId] = useOrganizationId();

  const sbomUploadAlert: InterfaceNotificationSetting = {
    name: '',
    userId: null,
    createdByUserId: null,
    modifiedByUserId: null,
    organizationId: new Types.ObjectId(currentOrgId || ''),
    notificationEventId: sbomUploadAlertEventId
      ? new Types.ObjectId(sbomUploadAlertEventId)
      : undefined,
    filters: [
      {
        id: 'uploadedVia',
        type: 'multiSelect',
        section: 'source',
        value: ['vendorUploads'],
      },
      {
        id: 'labels',
        type: 'multiSelect',
        section: 'source',
        value: [],
      },
      {
        id: 'cvssScore',
        section: 'riskCriteria',
        type: 'number',
      },
      {
        id: 'vulnRecommendations',
        section: 'riskCriteria',
        type: 'multiSelect',
      },
      {
        id: 'minRiskScore',
        type: 'select',
        section: 'riskCriteria',
      },
      {
        id: 'licenseIssues',
        type: 'multiSelect',
        section: 'riskCriteria',
      },
      {
        id: 'onCISAKEVList',
        section: 'riskCriteria',
        type: 'boolean',
      },
    ],
    deliveryMethods: ['email'],
    destinations: [
      {
        type: 'email',
        destinations: [],
      },
    ],
    isDefault: true,
    active: false,
    dateLastExecuted: null,
  };
  return sbomUploadAlert;
};
