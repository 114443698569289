import React, { useEffect, useState } from 'react';
import { Alert, Button, Group, Modal, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteAssets } from '../../../hooks/products/useDeleteAssets';
import { MRT_RowSelectionState } from 'mantine-react-table';

interface BulkRemoveAssetsFromProductProps {
  isOpen: boolean;
  close: () => void;
  assetsIds: MRT_RowSelectionState;
  productId?: string;
}

export const BulkRemoveAssetsFromProduct = ({
  isOpen,
  close,
  assetsIds,
  productId,
}: BulkRemoveAssetsFromProductProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const createRequestBody = (assetsMap: Record<string, boolean>) => {
    return Object.keys(assetsMap);
  };

  const [errors, setErrors] = useState<string[]>([]);
  const { mutate, isLoading, isSuccess } = useDeleteAssets({
    hasErrorCallback: (errors: string[]) => setErrors(errors),
  });

  const handleAssetRemoval = async (productId: string): Promise<void> => {
    if (Object.keys(assetsIds).length) {
      mutate([productId, createRequestBody(assetsIds)]);
    }
  };

  const clearErrors = () => setErrors([]);

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries({ queryKey: ['product'] });
      close();
    }
  }, [isSuccess]);

  return (
    <Modal opened={isOpen} onClose={close} title={t('product.delete-assets.title')}>
      <Text>{t('product.delete-assets.description')}</Text>
      {errors.length > 0 && (
        <Alert color="red" withCloseButton onClose={clearErrors} mb={16}>
          {errors.map((error, index) => (
            <div key={index}>{t(error)}</div>
          ))}
        </Alert>
      )}
      <Group position="right" mt="md">
        <Button variant="default" type="button" onClick={close} disabled={isLoading}>
          {t('global.cancel')}
        </Button>
        <Button
          type="submit"
          color="red"
          onClick={() => (productId ? handleAssetRemoval(productId) : null)}
          disabled={isLoading}
        >
          {t('global.remove')}
        </Button>
      </Group>
    </Modal>
  );
};
