import {
  Alert,
  Collapse,
  Modal,
  Button,
  Stack,
  TextInput,
  Flex,
  Switch,
  Text,
  Title,
  Checkbox,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import { modals } from '@mantine/modals';
import hitApi from '../../api';

interface Props {
  onCancel: () => void;
  onConfirm?: () => void;
  opened: boolean;
  validationErrors?: Partial<Record<keyof any, string>>;
  component: InterfaceOrganizationComponent; // Used to check for external emails
}

export const SupplierModal = ({ onCancel, onConfirm, opened, component }: Props) => {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  // check the box if all same or all null/undefined
  const [isAllFieldsSameValue, setIsAllFieldsSameValue] = useState(
    component?.author === null ||
      (component?.author === component?.publisher &&
        component?.author === component?.supplier?.name &&
        component?.author !== null),
  );
  const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);

  const form = useForm<Partial<any>>({
    initialValues: {
      author: component?.author || '',
      publisher: component?.publisher || '',
      supplier: component?.supplier?.name || '',
    },
  });

  return (
    <Modal
      opened={opened}
      onClose={() => {
        form.reset();
        onCancel();
      }}
      title={
        <Title order={3}>
          {t('component.supplierModal.title', {
            componentName: component?.name,
          })}
        </Title>
      }
    >
      <form
        onSubmit={form.onSubmit(async (newValues) => {
          setIsUpdating(true);

          let valuesToSubmit = newValues;

          if (isAllFieldsSameValue) {
            valuesToSubmit = {
              author: newValues.author,
              publisher: newValues.author,
              supplier: newValues.author,
            };
          }

          // Fire the update
          console.info('posting update');
          await hitApi.post(`component/${component?._id}`, true, {
            ...valuesToSubmit,
          });

          if (onConfirm) {
            onConfirm();
          }

          setIsUpdating(false);
          form.reset();
        })}
      >
        <Stack>
          {submissionErrors.length > 0 && (
            <ul className="page-errors anim-slideInUpShort">
              {submissionErrors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          )}
          <TextInput
            label={t('component.supplierModal.author-field')}
            {...form.getInputProps('author')}
          />
          <Checkbox
            defaultChecked
            onChange={() => {
              setIsAllFieldsSameValue(!isAllFieldsSameValue);
              form.values.publisher = component?.publisher || '';
              form.values.supplier = component?.supplier?.name || '';
            }}
            checked={isAllFieldsSameValue}
            label={t('component.supplierModal.fieldsSameAsAuthor')}
          />

          {!isAllFieldsSameValue && (
            <>
              <TextInput
                label={t('component.supplierModal.publisher-field')}
                {...form.getInputProps('publisher')}
              />
              <TextInput
                label={t('component.supplierModal.supplier-field')}
                {...form.getInputProps('supplier')}
              />
            </>
          )}
          <Flex justify="flex-end" gap="md">
            <Button
              type="button"
              onClick={() => {
                form.reset();
                onCancel();
              }}
              color="dark"
            >
              {t('global.cancel')}
            </Button>
            <Button loading={isUpdating} type="submit">
              {t('component.supplierModal.confirm')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};

export default SupplierModal;
