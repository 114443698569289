import { InterfaceOrganizationTicket } from '@manifest-cyber/types/interface/dbTables';
import './ticket.scss';
import { Popover, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
const JIRA_LOGO_SRC = '/jira_logo.png';
const SERVICENOW_LOGO_SRC = '/servicenow_logo.png';
const LINEAR_LOGO_SRC = '/linear_logo.png';

const PLATFORM_LOGO_MAP = {
  jira: JIRA_LOGO_SRC,
  servicenow: SERVICENOW_LOGO_SRC,
  linear: LINEAR_LOGO_SRC,
};

interface TicketRowProps {
  ticket: InterfaceOrganizationTicket;
}
function TicketRow({ ticket }: TicketRowProps) {
  const [popoverOpened, { close: popoverClose, open: popoverOpen }] =
    useDisclosure(false);
  const trimTicketTitle = (title: string) => {
    if (title.length > 20) {
      return title.substring(0, 20) + '...';
    }
    return title;
  };
  const date = new Date(ticket.dateCreated?.toString() || '').toUTCString();
  return (
    <tr key={ticket._id?.toString()} className="ticket-row">
      <td className="ticket-link">
        <div>
          <img src={PLATFORM_LOGO_MAP[ticket.platformName]} />
        </div>
        <Popover
          width={200}
          position="top"
          withArrow
          shadow="md"
          opened={popoverOpened}
          disabled={trimTicketTitle(ticket.ticketName) === ticket.ticketName}
        >
          <Popover.Target>
            <a
              onMouseEnter={popoverOpen}
              onMouseLeave={popoverClose}
              href={ticket.ticketUrl}
              target="_blank"
            >
              {trimTicketTitle(ticket.ticketName)}
            </a>
          </Popover.Target>
          <Popover.Dropdown
            sx={{
              pointerEvents: 'none',
              backgroundColor: 'var(--color-color-text-base)',
              color: 'var(--color-forceBlack)',
            }}
          >
            <Text size="sm">{ticket.ticketName}</Text>
          </Popover.Dropdown>
        </Popover>
      </td>
      <td className="ticket-create-date">{date}</td>
    </tr>
  );
}

export default TicketRow;
