export const commonReplacements: { [key: string]: string } = {
  'united kingdom': 'UK',
  'united states of america': 'USA',
  'united states': 'USA',
  'new york, new york': 'NYC, NY',
  'new york city': 'NYC, NY',
  russia: 'RU',
  china: 'CN',
};

export const replaceKeysWithStringValues = (input: string): string => {
  Object.keys(commonReplacements).forEach((key) => {
    const pattern = new RegExp(key, 'gi'); // 'g' for global, 'i' for case-insensitive
    input = input.replace(pattern, commonReplacements[key]);
  });
  return input;
};

export const checkForScaryLocations = (
  input: string,
  locationWatchlist: { [key: string]: 'warn' | 'scare' },
): string[] => {
  if (!locationWatchlist || !input || input.length > 0) return [];

  const foundValues: string[] = [];

  Object.entries(locationWatchlist).forEach(([key, value]) => {
    const pattern = new RegExp(key, 'gi'); // 'g' for global, 'i' for case-insensitive
    if (pattern.test(input)) {
      foundValues.push(value);
    }
  });

  return foundValues;
};

export default replaceKeysWithStringValues;
