import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { useFetchProducts } from '../../../hooks/products/useFetchProducts';
import { useProductsAssociation } from '../../../hooks/products/useProductsAssociation';
import { MRT_RowSelectionState } from 'mantine-react-table';
import { createRequestBody, sortProducts } from './methods';

interface Props {
  openNotificationModal: VoidFunction;
  clearRowSelection: VoidFunction;
  close: VoidFunction;
}

export const useProductsAssociationModal = ({
  openNotificationModal,
  clearRowSelection,
  close,
}: Props) => {
  const { t } = useTranslation();

  const [productNameFilter, setProductNameFilter] = useState({
    field: 'name',
    value: '',
  });
  const [filteredProducts, setFilteredProducts] = useState<
    InterfaceOrganizationProduct[]
  >([]);
  const [selectedProducts, setSelectedProducts] = useState<
    InterfaceOrganizationProduct[]
  >([]);
  const [selectAll, setSelectAll] = useState(false);

  const { isLoading, data: productsResponse } = useFetchProducts({
    page: 0,
    limit: 100,
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const { mutate, isLoading: isLoadingMutation } = useProductsAssociation({
    hasErrorCallback: (errors: string[]) => setErrors(errors),
    clearRowSelection: clearRowSelection,
    openNotificationModal: openNotificationModal,
  });

  const handleSelectAllChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedProducts(filteredProducts);
    } else {
      setSelectedProducts([]);
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setProductNameFilter((prevState) => ({ ...prevState, value: e.target.value }));

  const onCloseModal = () => {
    setSelectedProducts([]);
    close();
  };

  const handleSort = (order: 'name' | 'dateCreated' | 'dateModified') => {
    if (productsResponse) {
      const sortedProducts = sortProducts([...productsResponse.data], order);
      setFilteredProducts(sortedProducts);
    }
  };

  const handleCheckboxChange = (product: InterfaceOrganizationProduct) => {
    setSelectedProducts((prevSelectedProducts) => {
      if (prevSelectedProducts.find((p) => p._id === product._id)) {
        return prevSelectedProducts.filter((p) => p._id !== product._id);
      } else {
        return [...prevSelectedProducts, product];
      }
    });
  };

  const submit = (
    assetsIds: MRT_RowSelectionState,
    selectedProducts: InterfaceOrganizationProduct[],
  ) => {
    mutate(createRequestBody(assetsIds, selectedProducts), {
      onSuccess: () => {
        setSelectedProducts([]);
        setIsSubmissionSuccessful(true);
      },
      onError: () => {
        setSelectedProducts([]);
        setIsSubmissionSuccessful(false);
      },
    });
  };

  return {
    productNameFilter,
    selectedProducts,
    selectAll,
    isLoading,
    errors,
    isSubmissionSuccessful,
    handleSelectAllChange,
    onInputChange,
    onCloseModal,
    handleSort,
    handleCheckboxChange,
    submit,
    setSelectAll,
    productsResponse,
    filteredProducts,
    isLoadingMutation,
    setFilteredProducts,
  };
};
