import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import hitApi from '../../api';
import {
  GetVulnImpactApiResponse,
  sanitizeQueryParamProperty,
} from './useFetchVulnImpact';

export const useFetchVulnAssets = (
  vulnerabilityId: string,
  isActiveAssetsOnly: boolean,
  searchFilter?: string,
  assetVendorFilter?: string,
  { pageIndex, pageSize }: Partial<PaginationState> = {},
  sorting?: SortingState,
  noPaginate?: boolean,
) => {
  const params = new URLSearchParams();

  const filters: { field: string; value: any }[] = [];

  if (sorting && sorting[0]?.id) {
    params.set(
      'sort',
      `{${sorting
        .map((sortObj) => {
          return `"${sanitizeQueryParamProperty(sortObj.id)}":"${sortObj.desc ? 1 : -1}"`;
        })
        .join('')}}`,
    );
  }

  if (typeof pageIndex !== 'undefined' && typeof pageSize !== 'undefined') {
    params.set('page', (pageIndex + 1).toString());
    params.set('limit', pageSize.toString());
  }

  if (searchFilter) {
    filters.push({ field: 'assetName', value: [searchFilter] });
  }

  if (isActiveAssetsOnly) {
    filters.push({ field: 'assetActive', value: [true] });
  }

  if (assetVendorFilter === 'first') {
    filters.push({ field: 'onlyFirstParty', value: true });
  }

  if (assetVendorFilter === 'third') {
    filters.push({ field: 'onlyThirdParty', value: true });
  }

  if (filters.length) {
    params.set('filters', JSON.stringify(filters));
  }

  if (noPaginate) {
    params.set('noPaginate', 'true');
  }

  let fetchUrl = `vulnerability/${vulnerabilityId}/assets?${params.toString()}`;

  return useQuery({
    queryKey: [
      'vulnImpactAssets',
      vulnerabilityId,
      isActiveAssetsOnly,
      searchFilter,
      assetVendorFilter,
      pageIndex,
      pageSize,
      sorting,
      noPaginate,
    ],
    queryFn: ({ signal }) => hitApi.get(fetchUrl, true, undefined, undefined, signal),
    select: (data) => data as GetVulnImpactApiResponse<InterfaceOrganizationAsset>,
    onError: (error) => {
      console.error('Unable to fetch vulnerability assets: ', error);
    },
    keepPreviousData: true,
  });
};
