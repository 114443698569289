export const removeProperty = (
  object: Record<string, string>,
  keyToRemove: string,
): Record<string, string> => {
  const typedFilters = Object.entries(object);

  const filteredFields = typedFilters.reduce(
    (acum, [key, value]) => {
      if (key !== keyToRemove) {
        acum[key] = value;
      }

      return acum;
    },
    {} as Record<string, string>,
  );

  return filteredFields;
};
