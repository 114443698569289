import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './utils/useLocalStorage';
import { removeSessionCookie } from '../hooks/utils/useSessionCookie';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import { useOrganizationId } from '../hooks/utils/useOrganizationId';
import { useLastPath } from './utils/useLastPath';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('manifest-user', null);
  const [currentOrgId, setOrgId] = useOrganizationId(null);
  const navigate = useNavigate();
  const [lastPath] = useLastPath();

  /**
   * Log user in locally
   * @param {any} userDataToWrite
   * @param {boolean} redirect Redirect user to homepage
   * @returns
   */
  const login = async (userDataToWrite, redirect = lastPath || '/') => {
    if (userDataToWrite?._id) {
      // Add to Sentry
      Sentry.setUser({
        id: userDataToWrite?._id,
        ip_address: '{{auto}}',
        locale: userDataToWrite?.locale || 'en-us',
        access: userDataToWrite?.internalRoles,
      });

      // If user has orgs, write the one they'll use
      if (userDataToWrite?.organizations && (!currentOrgId || currentOrgId.length < 1)) {
        if (currentOrgId && userDataToWrite?.organizations.includes(currentOrgId)) {
          setOrgId(currentOrgId);
        } else {
          setOrgId(userDataToWrite?.organizations[0]);
        }
      }

      setUser(userDataToWrite);

      if (redirect) {
        navigate(`${redirect}`, { replace: true });
      }
    } else {
      console.error('AuthProvider login(): unable to set userdata', userDataToWrite);
    }

    return true;
  };

  /**
   * Log user out locally
   * @param {string} reason
   * @param {boolean | string} redirect
   * @returns
   */
  const logout = (reason, redirect = true) => {
    console.info(`Logging user out${reason ? ` (${reason})` : ''}.`);
    // Make sure Sentry is updated.
    Sentry.setUser(null);

    setUser(null);
    // removeSessionCookie();
    Cookies.remove('organizationId');
    // Remove all storage values
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (redirect === true) {
      navigate(`/login?logout=${reason}`);
      return true;
    }

    if (redirect) {
      window.location.href = redirect;
    }

    return true;
  };

  const value = useMemo(
    () => ({
      user,
      currentOrgId,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
