import { Flex } from '@mantine/core';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionableCard } from '../../../../../components/ActionableCard/ActionableCard';
import Icon from '../../../../../components/Icon';
import MessageWithBackground from '../../../../../components/MessageWithBackground/MessageWithBackground';
import { usePutAlert } from '../../../../../hooks/mutations/usePutAlert';
import { isUniqueString } from '../../../../../lib/string/isUniqueString';
import SbomAlertHeader from '../../components/AlertHeader/AlertHeader';
import { AlertNameInput } from '../../components/AlertNameInput/AlertNameInput';
import DeliveryMethod from '../../components/DeliveryMethodSection/DeliveryMethodSection';
import RiskCriteriaSection from '../../components/RiskCriteriaSection/RiskCriteriaSection';
import Source from '../../components/SourceSection/SourceSection';
import { useFetchSbomAlert } from '../../hooks/useFetchSbomAlert';
import { useFetchSbomAlerts } from '../../hooks/useFetchSbomAlerts';
import { useSbomAlertForms } from '../../hooks/useSbomAlertsForms';
import { alertCreateReducer, getInitialState } from '../../reducer/alertCreateReducer';
import { RiskCriteriaSelector } from '../../reducer/alertCreateReducer.interface';
import { SbomAlert } from '../../types/SBOMAlerts.interface';
import { mapFormsToAlertPayload } from '../../utils/mapFormsToAlertPayload';
import { removeRiskCriteria } from '../../utils/removeRiskCriteria';

function AlertEdit() {
  const [{ source, riskCriteria, deliveryMethod }, dispatch] = useReducer(
    alertCreateReducer,
    getInitialState('edit'),
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasCreationError, setHasCreationError] = useState(false);
  const { data: alertsResponse, isLoading: isLoadingAlerts } = useFetchSbomAlerts();
  const { mutateAsync: updateAlert, isLoading: isUpdatingAlert } = usePutAlert();
  const { alertId } = useParams();
  const {
    data: alertResponse,
    isLoading: isLoadingAlert,
    refetch,
    error,
    /* Setting refetchOnWindowFocus on false to avoid user updates looses when re focusing window */
  } = useFetchSbomAlert(alertId, { refetchOnWindowFocus: false });
  const mappedAlert = alertResponse?.mappedAlert;

  const {
    deliveryMethodsForm,
    riskCriteriasForm,
    riskCriteriasInitialValues,
    sourceForm,
    sectionsConfig,
  } = useSbomAlertForms({
    riskCriteriaState: riskCriteria,
    alertResponse,
    updateRiskCriteriaSelectors: (filters) => {
      dispatch({ type: 'setRiskCriteriaVisibleFilters', payload: { filters } });
    },
  });

  const handleSaveClick = async () => {
    if (
      !alertsResponse?.notificationEventId ||
      !alertsResponse?.alerts ||
      !mappedAlert ||
      isUpdatingAlert
    ) {
      return;
    }
    setHasCreationError(false);

    // Validating here in case the user filled the name field before alerts request has finishes and name validation in on blur did not run
    const isUniqueName = isUniqueString({
      list: alertsResponse.alerts.mappedAlerts
        .filter((alert) => alert.id !== mappedAlert.id)
        .map((alert) => alert.name),
      query: sourceForm.values.name,
      isCaseSensitive: false,
    });

    if (!isUniqueName) {
      sourceForm.setErrors({
        ...sourceForm.errors,
        name: t('page.userSettings.sbomUploadAlerts.nameIsDuplicatedErrorMessage'),
      });
      return;
    }

    const areValidForms =
      sourceForm.isValid() &&
      riskCriteriasForm.isValid() &&
      deliveryMethodsForm.isValid();

    if (!areValidForms) {
      sourceForm.validate();
      deliveryMethodsForm.validate();
      riskCriteriasForm.validate();
      return;
    }

    const alertToCreate: SbomAlert = {
      ...mapFormsToAlertPayload({
        notificationEventId: alertsResponse.notificationEventId,
        riskCriteriasForm,
        sourceForm,
        deliveryMethodsForm,
        isActive: mappedAlert.isActive,
      }),
      _id: mappedAlert.id,
    };

    const response = await updateAlert({ alert: alertToCreate });

    if (response.success) {
      navigate(-1);
    } else {
      setHasCreationError(true);
    }
  };

  const gotBack = async () => {
    navigate(-1);
  };

  return (
    <>
      {error && (
        <ActionableCard
          title={t('error.general.errorLoadingData')}
          subtitle={t('error.general.errorLoadingDataSubtitle')}
          Icon={<Icon icon="circle-exclamation" size="lg" />}
          primaryAction={{
            label: t('global.retry'),
            onClick: () => {
              refetch();
            },
            isLoading: isLoadingAlert,
          }}
        />
      )}
      {!error && (
        <Flex gap={20} direction={'column'}>
          <div>
            <SbomAlertHeader
              badge={{ isVisible: false }}
              title={t('page.userSettings.sbomUploadAlerts.editSbomAlert')}
              subtitle={t('page.userSettings.sbomUploadAlerts.createAlertSubheader')}
              goBack={gotBack}
              primaryAction={{
                label: t('global.cancel'),
                onClick: gotBack,
                isFilled: false,
              }}
              secondaryAction={{
                onClick: handleSaveClick,
                label: t('global.saveChanges'),
                variant: 'filled',
                isDisabled: isLoadingAlert,
                isLoading: isUpdatingAlert,
              }}
              isLoading={isLoadingAlert}
            />
            {hasCreationError && (
              <MessageWithBackground
                message={t('error.general.unexpectedErrorTryAgain')}
                messageType="critical"
              />
            )}
          </div>
          <AlertNameInput
            inputProps={{
              ...sourceForm.getInputProps('name'),
              onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => {
                const currentName = event.currentTarget.value;
                const isUnique = isUniqueString({
                  list:
                    alertsResponse?.alerts.mappedAlerts
                      .filter((alert) => alert.id !== mappedAlert?.id)
                      .map((alert) => alert.name) || [],
                  query: currentName,
                  isCaseSensitive: false,
                });

                if (!isUnique && currentName.length > 0) {
                  sourceForm.setErrors({
                    name: t(
                      'page.userSettings.sbomUploadAlerts.nameIsDuplicatedErrorMessage',
                    ),
                  });
                  return;
                }

                sourceForm.getInputProps('name').onBlur(event);
              },
            }}
            showLoader={isLoadingAlerts && sourceForm.getInputProps('name').value}
            label={t('page.userSettings.sbomUploadAlerts.alertName')}
          />
          <Source
            form={sourceForm}
            sectionConfig={sectionsConfig.sourceSection}
            state={source.state}
          />

          <RiskCriteriaSection
            onClickRemoveCriteria={(criteria: RiskCriteriaSelector) => {
              removeRiskCriteria({
                criteria,
                form: riskCriteriasForm,
                dispatch,
                initialValues: riskCriteriasInitialValues,
              });
            }}
            onClickAddCriteria={() => {
              dispatch({ type: 'addRiskCriteria' });
            }}
            riskCriterias={riskCriteria.criterias}
            form={riskCriteriasForm}
            state={riskCriteria.state}
            sectionConfig={sectionsConfig.riskCriteriaSection}
          />
          <DeliveryMethod form={deliveryMethodsForm} state={deliveryMethod.state} />
        </Flex>
      )}
    </>
  );
}

export default AlertEdit;
