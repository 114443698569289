import { useState } from 'react';

interface PdfDownloadData {
  chartDataUri: string | null;
  isPdfDownloadModalOpen: boolean;
}

export const useVulnPdfHandlers = () => {
  const [{ isPdfDownloadModalOpen, chartDataUri }, setIsPdfDownloadOpen] =
    useState<PdfDownloadData>({
      chartDataUri: null,
      isPdfDownloadModalOpen: false,
    });

  const openPdfDownloadModal = async () => {
    // @ts-ignore
    if (window?.Apex?._chartInstances) {
      // @ts-ignore
      const chartInstance = window?.Apex?._chartInstances.find(
        (chart: ApexChart) => chart.id === 'vuln-radar-pdf',
      );

      const base64Image = await chartInstance?.chart?.dataURI();
      setIsPdfDownloadOpen({
        isPdfDownloadModalOpen: true,
        chartDataUri: `${base64Image?.imgURI || base64Image}`,
      });
    }
  };

  const closePdfDownloadModal = () => {
    setIsPdfDownloadOpen((state) => ({
      ...state,
      isPdfDownloadModalOpen: false,
    }));
  };

  return {
    openPdfDownloadModal,
    closePdfDownloadModal,
    chartDataUri,
    isPdfDownloadModalOpen,
  };
};
