import { Flex } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate, useOutlet } from 'react-router-dom';
import { ManifestLogo } from '../../components/Icons/ManifestLogoIcon/ManifestLogoIcon';
import TopBarWrapper from '../../components/Toolbar/Toolbar';
import { useFetchSharingPortalReceiver } from '../pages/sharedSboms/api/sharingPortal/useFetchSharingPortalReceiver/useFetchSharingPortalReceiver';
import { UserSettings } from '../pages/UserSettings/UserSettings';
import { PortalFooter } from './PortalFooter/PortalFooter';
import styles from './PortalLayout.module.scss';
import { UserMenu } from './UserMenu/UserMenu';

export const PortalLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const { portalName = '' } = useParams();
  const { data: portal } = useFetchSharingPortalReceiver({ portalName });
  const onClose = () => {
    setOpenSettingsModal(false);
  };

  return (
    <>
      {openSettingsModal && <UserSettings onClose={onClose} />}
      <Flex className={styles.pageContentArea} gap="20px" direction={'column'}>
        <TopBarWrapper
          withSidebar={false}
          isSidebarOpen={false}
          logo={
            portal?.logoUrl ? (
              <img height={30} width={120} src={portal.logoUrl} />
            ) : (
              <ManifestLogo height={30} width={120} />
            )
          }
          rightSide={
            <UserMenu
              onLogOut={() => {
                navigate('/logout');
              }}
              texts={{
                logOut: t('navigation.logout.label'),
              }}
            />
          }
          goBack={() => navigate(-1)}
          goBackLabel={t('global.goBack')}
          goForward={() => navigate(1)}
          goForwardLabel={t('global.goForward')}
        />

        <main>{outlet}</main>
        <PortalFooter description={t('portal.footer.poweredBy')} link="/portal/assets" />
      </Flex>
    </>
  );
};
