import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

interface Params {
  labels: Record<string, string[]>;
}

export const usePutAssetLabels = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ labels }: Params) => {
      return hitApi.put(`assets/updateLabels`, true, {
        labels,
      });
    },
    onSuccess: (_data, { labels }) => {
      Object.keys(labels).forEach((assetId) => {
        queryClient.invalidateQueries({
          queryKey: ['asset', { _id: assetId }], //re-fetch each asset
        });
      });
      queryClient.invalidateQueries({
        queryKey: ['assets'], //re-fetch the assets list
      });
      queryClient.invalidateQueries({
        queryKey: ['labels'], //re-fetch the labels list
      });
    },
  });
};
