import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Flex, Modal, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface MarkInactiveAssetWarningModalProps {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
  count?: number;
}

export const MarkInactiveAssetWarningModal = ({
  isOpen,
  close,
  confirm,
  count,
}: MarkInactiveAssetWarningModalProps) => {
  const { t } = useTranslation();
  const [dontAskAgain, setDontAskAgain] = useState(false);

  useEffect(() => {
    const markInactiveAssetWarning = localStorage.getItem('markInactiveAssetWarning');
    if (markInactiveAssetWarning === null) {
      localStorage.setItem('markInactiveAssetWarning', 'true');
    }
  }, []);

  const handleConfirm = () => {
    if (dontAskAgain) {
      localStorage.setItem('markInactiveAssetWarning', 'false');
    }
    confirm();
  };

  return (
    <Modal.Root opened={isOpen} onClose={close} size="sm">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Flex direction="column" gap={4}>
              <Modal.Title>
                <b>
                  {t('page.assets.markInactiveAsset', {
                    count: count,
                  })}
                </b>
              </Modal.Title>
            </Flex>
            <Modal.CloseButton />
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <Text>
            {t('page.assets.markInactiveAssetDescription', {
              count: count,
            })}
          </Text>
          <Checkbox
            label={t('page.assets.dontAskAgain')}
            checked={dontAskAgain}
            onChange={(event) => setDontAskAgain(event.currentTarget.checked)}
            mt="md"
          />
          <Flex gap={8} mt={16} justify="flex-end">
            <Flex gap={8} justify="flex-end">
              <Button variant="default" onClick={close}>
                {t('global.cancel')}
              </Button>
              <Button onClick={handleConfirm}>
                {t('page.assets.yesMarkAsInactive')}
              </Button>
            </Flex>
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
