import {
  ActionIcon,
  Badge,
  Button,
  ButtonProps,
  Flex,
  Menu,
  Skeleton,
} from '@mantine/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/Icon';
import styles from './AlertHeader.module.scss';

interface AlertHeaderProps {
  title: string;
  goBack: () => void;
  primaryAction?: {
    label: string;
    isFilled: boolean;
    isDisabled?: boolean;
    onClick?: () => void;
    isLoading?: boolean;
  };
  secondaryAction?: {
    label: string;
    isDisabled?: boolean;
    onClick?: () => void;
    variant?: ButtonProps['variant'];
    icon?: ReactNode;
    isLoading?: boolean;
  };
  menuAction?: {
    label: string;
    onClick?: () => void;
  };
  badge: {
    isVisible: boolean;
    label?: string;
    isActive?: boolean;
  };
  subtitle?: string;
  isLoading?: boolean;
}

function AlertHeader({
  title,
  goBack,
  subtitle,
  badge = { isActive: true, isVisible: true, label: '' },
  primaryAction,
  secondaryAction,
  menuAction,
  isLoading = false,
}: AlertHeaderProps) {
  const { t } = useTranslation();
  const titleToDisplay = isLoading ? 'Lorem ipsum dolor' : title;

  return (
    <div className={styles.alertHeader}>
      <div className={styles.alertInfo}>
        <div className={styles.alertHeaderTextContainer}>
          <ActionIcon
            className={styles.btnBack}
            size="md"
            onClick={goBack}
            aria-label={t('global.back')}
          >
            <Icon icon="chevron-left" />
          </ActionIcon>

          <Flex align="center" gap={8}>
            <Skeleton visible={isLoading} className={styles.skeleton}>
              <span className={styles.alertHeaderText}>{titleToDisplay}</span>
            </Skeleton>
            {badge.isVisible && badge.label && (
              <Skeleton visible={isLoading} radius="xl" className={styles.skeleton}>
                <Badge
                  m={0}
                  variant="filled"
                  classNames={{
                    root: clsx(
                      badge.isActive ? styles.activeBadge : styles.inactiveBadge,
                    ),
                  }}
                >
                  {badge.label}
                </Badge>
              </Skeleton>
            )}
          </Flex>
        </div>
        {subtitle && <div className={styles.alertSubheaderText}>{subtitle}</div>}
      </div>

      <div className={styles.alertActions}>
        {primaryAction && (
          <Button
            variant={primaryAction.isFilled ? 'filled' : 'default'}
            classNames={{ label: styles.actionButton }}
            onClick={primaryAction.onClick}
            disabled={primaryAction.isDisabled}
            loading={primaryAction.isLoading}
          >
            {primaryAction.label}
          </Button>
        )}
        {secondaryAction && (
          <Button
            onClick={secondaryAction.onClick}
            leftIcon={secondaryAction.icon}
            variant={secondaryAction.variant || 'default'}
            classNames={{ label: styles.actionButton }}
            disabled={secondaryAction.isDisabled}
            loading={secondaryAction.isLoading}
          >
            {secondaryAction.label}
          </Button>
        )}

        {menuAction && (
          <Menu position="bottom-end" disabled={isLoading}>
            <Menu.Target>
              <ActionIcon className={clsx(styles.actionIcon)} variant="default">
                <Icon icon="ellipsis-vertical" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item icon={<Icon icon="trash-can" />} onClick={menuAction.onClick}>
                {menuAction.label}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </div>
    </div>
  );
}

export default AlertHeader;
