import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceLicense } from '@manifest-cyber/types/interface/dbTables';

interface Params {
  license: InterfaceLicense;
}

export const usePostLicense = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ license }: Params) => {
      return hitApi.post(`license`, true, {
        ...license,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['licenses']);
    },
  });
};
