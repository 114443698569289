import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';
import { SBOM_ALERTS_QUERY_KEY } from '../../pages/UserSettings/SbomUploadAlerts/hooks/useFetchSbomAlerts';
import { SbomAlert } from '../../pages/UserSettings/SbomUploadAlerts/types/SBOMAlerts.interface';

interface Params {
  alert: SbomAlert;
}

export const usePostAlert = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ alert }: Params) => {
      return hitApi.post(`/organization/notificationSettings`, true, alert);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SBOM_ALERTS_QUERY_KEY],
      });
    },
  });
};
