import { Flex, SelectItemProps } from '@mantine/core';
import { forwardRef } from 'react';
import { ChipColorCircle } from '../../ChipComponent/ChipComponent';
import styles from './SelectOption.module.scss';

const SelectOption = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, color, ...others }, ref) => {
    return (
      <div ref={ref} {...others}>
        <Flex align="center" className={styles.selectOption}>
          <ChipColorCircle color={color} />
          <div>{label}</div>
        </Flex>
      </div>
    );
  },
);

export default SelectOption;
