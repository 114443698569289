import React from 'react';
import {
  ActionIcon,
  Alert,
  Button,
  Flex,
  Menu,
  Modal,
  ScrollArea,
  Table,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import './AddAssetsToProduct.scss';
import { useTranslation } from 'react-i18next';
import { useManageAssets } from '../../../hooks/products/useManageAssets';
import Icon from '../../Icon';

interface AssetModalProps {
  assets: InterfaceOrganizationAsset[];
  currentAssets: InterfaceOrganizationAsset[];
  isOpen: boolean;
  close: () => void;
  productId?: string;
}

export const AddAssetsToProduct = ({
  assets,
  currentAssets,
  isOpen,
  close,
  productId,
}: AssetModalProps) => {
  const { t } = useTranslation();
  const {
    searchTerm,
    filteredAssets,
    selectedAssets,
    setSelectedAssets,
    isLoading,
    errors,
    clearErrors,
    handleFilter,
    handleSort,
    handleSelectAsset,
    submit,
    isAssetSelected,
  } = useManageAssets({ assets, currentAssets, productId, close });

  return (
    <Modal.Root
      opened={isOpen}
      onClose={close}
      size="60%"
      className="add-assets-to-product"
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Flex direction="column" gap={4}>
              <Modal.Title>{t('product.add-assets.title')}</Modal.Title>
              <Text size="sm" className="add-assets-to-product__subtitle">
                {t('product.add-assets.subtitle')}
              </Text>
            </Flex>
            <Modal.CloseButton />
          </Flex>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 && (
            <Alert color="red" withCloseButton onClose={clearErrors} mb={16}>
              {errors.map((error, index) => (
                <div key={index}>{t(error)}</div>
              ))}
            </Alert>
          )}
          <Flex gap={8}>
            <TextInput
              className="add-assets-to-product__input"
              placeholder={t('product.add-assets.search.placeholder')}
              value={searchTerm}
              onChange={(event) => handleFilter(event.currentTarget.value)}
              icon={
                <Icon
                  icon="magnifying-glass"
                  className="add-assets-to-product__input-icon"
                />
              }
              sx={{ flexGrow: 1 }}
            />

            <Menu withinPortal position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="filled"
                  className="add-assets-to-product__actions"
                  size="lg"
                >
                  <Icon icon="arrow-down-arrow-up" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{t('product.add-assets.sort.by')}</Menu.Label>

                <Menu.Item onClick={() => handleSort('name')}>
                  {t('product.add-assets.sort.name')}
                </Menu.Item>
                <Menu.Item onClick={() => handleSort('dateCreated')}>
                  {t('product.add-assets.sort.dateAdded')}
                </Menu.Item>
                <Menu.Item onClick={() => handleSort('dateModified')}>
                  {t('product.add-assets.sort.dateModified')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
          <div>
            {filteredAssets.length === 0 && (
              <Flex direction="column" gap={8} align="center" mt="1rem">
                <Icon icon="magnifying-glass" color={'$color-text-muted'} size="xl" />
                <Text size="lg">{t('product.add-assets.search-title')}</Text>
                <Text className="add-assets-to-product__subtitle">
                  {t('product.add-assets.search-subtitle')}
                </Text>
              </Flex>
            )}
            {filteredAssets.length > 0 && (
              <ScrollArea h={400}>
                <Table className="add-assets-to-product__table">
                  <thead>
                    <tr>
                      <th className="add-assets-to-product__table-header">
                        {t('tables.assets.headers.assetName')}
                      </th>
                      <th className="add-assets-to-product__table-header">
                        {t('tables.assets.headers.version')}
                      </th>
                      <th className="add-assets-to-product__table-header"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAssets.map((asset) => (
                      <tr
                        key={asset._id?.toString()}
                        className={
                          isAssetSelected(asset._id?.toString() as string)
                            ? 'add-assets-to-product__selected-asset'
                            : ''
                        }
                      >
                        <td
                          title={asset.name}
                          className="add-assets-to-product__name-column"
                        >
                          {asset.name}
                        </td>
                        <td className="add-assets-to-product__version-column">
                          {asset.version}
                        </td>
                        <td>
                          <ActionIcon onClick={() => handleSelectAsset(asset)}>
                            <Tooltip
                              multiline
                              label={
                                <pre>
                                  {isAssetSelected(asset._id?.toString() as string)
                                    ? t('product.add-assets.tooltip.remove-from-product')
                                    : t('product.add-assets.tooltip.add-to-product')}
                                </pre>
                              }
                            >
                              {isAssetSelected(asset._id?.toString() as string) ? (
                                <Icon
                                  icon="circle-check"
                                  className="add-assets-to-product__remove-asset-icon"
                                />
                              ) : (
                                <Icon icon="circle-plus" />
                              )}
                            </Tooltip>
                          </ActionIcon>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            )}
          </div>

          <Flex justify="flex-end" mt={8} mb={16}>
            <Text className="add-assets-to-product__assets-count">
              {selectedAssets.length > 0
                ? t('product.add-assets.messages.assetSelected', {
                    count: selectedAssets.length,
                  })
                : null}
            </Text>
          </Flex>

          <Flex gap={8} justify="flex-end">
            <Button
              disabled={isLoading}
              variant="default"
              onClick={() => {
                setSelectedAssets([]);
                close();
              }}
            >
              {t('global.cancel')}
            </Button>
            <Button disabled={isLoading} onClick={submit}>
              {t('global.add')}
            </Button>
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
