import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { ProductsFilters } from './useProductsFilter';
import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { ProductVulnerability } from '../../pages/Product/ProductVulnerabilities/ProductVulnerabilities.tab';

export interface ProductsQueryParams {
  productId?: string;
  enabled?: boolean;
  limit?: number;
  page?: number;
  sortColumn?: string;
  sortType?: 'asc' | 'desc';
  filters?: { field: keyof ProductsFilters; value: any }[];
}

export interface PaginationInfo {
  page: number;
  limit: number;
  totalReturn: number;
  total: number;
}

export interface PaginatedResponse<T> {
  success: boolean;
  errors: string[];
  queryInfo: PaginationInfo;
  data: T[];
}

type FetchError = Error;

export const useFetchProductVulnerabilities = ({
  enabled = true,
  limit,
  page,
  sortColumn,
  sortType,
  filters,
  productId,
}: ProductsQueryParams) => {
  let fetchUrl = `product/${productId}/vulnerabilities?`;
  if (page) {
    fetchUrl += `&page=${page}`;
  }
  if (limit) {
    fetchUrl += `&limit=${limit}`;
  }
  if (sortColumn) {
    fetchUrl += `&sortBy=${sortColumn}`;
  }
  if (filters) {
    fetchUrl += `&filters=${JSON.stringify(filters)}`;
  }
  fetchUrl = fetchUrl.replace('?&', '?');

  const queryKey = ['product', `/${fetchUrl}`].filter(Boolean);

  return useQuery<PaginatedResponse<ProductVulnerability>, FetchError>({
    enabled: enabled && !!productId,
    keepPreviousData: true,
    queryKey: queryKey,
    queryFn: () => hitApi.get(fetchUrl),
    select: (response: PaginatedResponse<ProductVulnerability>) => response,
    onError: (error: FetchError) => {
      console.error(`Unable to fetch products:`, error);
    },
    refetchInterval: 1000000,
  });
};
