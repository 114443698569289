import { useTranslation } from "react-i18next";
import { GitHubOrganizationFallbackIcon } from "./GitHubOrganizationFallbackIcon";

interface Props {
  imageUrl?: string;
  iconWidth: string;
  iconHeight: string;
}

export const GitHubOrganizationIcon = ({imageUrl, iconWidth, iconHeight}: Props) => {
  const { t } = useTranslation();

  return (
    <>
    { imageUrl ? (
      <div>
        <img 
          src={imageUrl} 
          alt={t('page.userSettings.integrations.github.organization.avatar-alt')} 
          width={iconWidth}
          height={iconHeight}
        />
      </div> 
      ) : (
        <GitHubOrganizationFallbackIcon
          iconWidth={iconWidth}
          iconHeight={iconHeight}
          />
      )
    }
    </>
  )
}