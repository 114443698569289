import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';
import ClickableRegion from '../../ClickableRegion';
import { useOrganizationId } from '../../../hooks/utils/useOrganizationId';
import { getDefaultTableOptions } from '../../MRT/ManifestMRT';
import { determineVersionStatus, formatVulnerabilityCount } from './methods';
import { InterfaceOrganizationComponentWithLicenses } from '../../../types/FullInterfaces';

interface Props {
  componentData:
    | {
        component: InterfaceOrganizationComponentWithLicenses;
        versions: InterfaceOrganizationComponentWithLicenses[];
      }
    | undefined;
  isLoadingComponentVersions: boolean;
  isFetchingComponentVersions: boolean;
}

export const ComponentVersionsTable = ({
  componentData,
  isLoadingComponentVersions,
  isFetchingComponentVersions,
}: Props) => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId(null);

  const columns = useMemo<MRT_ColumnDef<InterfaceOrganizationComponentWithLicenses>[]>(
    () => [
      {
        id: 'version',
        header: t('tables.components.headers.version'),
        Cell: ({
          row,
        }: {
          row: { original: InterfaceOrganizationComponentWithLicenses };
        }) => {
          const original = row.original;
          const isCurrentVersion = determineVersionStatus(original, componentData);
          const href = `/component/${original._id?.toString()}/${currentOrgId || ''}`;

          return (
            <ClickableRegion
              className="table-link"
              regionLabel={t('page.component.view-component-details')}
              href={href}
            >
              <span className={`${isCurrentVersion ? 'table-current' : ''}`}>
                {original.version || original._id?.toString()}&nbsp;
                {isCurrentVersion && <strong>({t('global.this-version')})</strong>}
              </span>
            </ClickableRegion>
          );
        },
      },
      {
        id: 'countVulnerabilities',
        header: t('tables.components.headers.number-of-vulnerabilities'),
        Cell: ({
          row,
        }: {
          row: { original: InterfaceOrganizationComponentWithLicenses };
        }) => {
          const totalVulnerabilities = formatVulnerabilityCount(
            row.original.countVulnerabilities,
          );
          const className = totalVulnerabilities > 0 ? 'is-vulnerable' : 'not-vulnerable';

          return <span className={className}>{totalVulnerabilities}</span>;
        },
      },
      {
        id: 'licenses',
        header: t('tables.components.headers.license'),
        Cell: ({ row }) => {
          let licenses = '';

          if (row.original?.licensesData) {
            for (const license of row.original?.licensesData) {
              if (license?.shortName) {
                if (licenses.length > 0) licenses += ', ';
                licenses += license?.shortName;
              }
            }
          }

          return licenses && licenses.length > 0 ? (
            licenses
          ) : (
            <span className="table-na">{t('global.notProvided')}</span>
          );
        },
      },
      {
        id: 'supplier',
        header: t('tables.components.headers.supplier'),
        Cell: ({ row }) => {
          if (row.original?.supplier) {
            let s = row.original?.supplier;
            if (s.name && s?.name?.length > 0) {
              return s.name;
            }
          }

          return <span className="table-na">{t('global.notProvided')}</span>;
        },
      },
      {
        id: 'description',
        header: t('tables.components.headers.description'),
        Cell: ({ row }) => {
          if (row.original?.description && row.original?.description?.length > 0) {
            return row.original?.description;
          }

          return <span className="table-na">{t('global.notProvided')}</span>;
        },
      },
    ],
    [],
  );

  const defaultTableOptions =
    getDefaultTableOptions<InterfaceOrganizationComponentWithLicenses>();

  const table = useMantineReactTable<InterfaceOrganizationComponentWithLicenses>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    columns,
    data: componentData?.versions || [],
    enableFilters: false,
    enablePagination: false,
    manualPagination: false,
    manualSorting: false,
    manualFiltering: false,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: false,
    },
    state: {
      isLoading: isLoadingComponentVersions,
      showLoadingOverlay: isFetchingComponentVersions,
    },
  });

  return (
    <div className="component-version-content anim-slideInUpShort">
      <div className="list-components">
        <MantineReactTable table={table} />
      </div>
    </div>
  );
};
