import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

interface Params {
  licenseId: string;
}

export const useDeleteLicense = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ licenseId }: Params) => {
      return hitApi.delete(`license/${licenseId}`, true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['licenses']);
    },
  });
};
