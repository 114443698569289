import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import Icon from '../Icon';
import { ManifestLogomark } from '../Icons/ManifestLogomarkIcon/ManifestLogomarkIcon';
import styles from './Toolbar.module.scss';

export const Toolbar = ({
  isSidebarOpen,
  children,
  rightSide,
  pageTitle,
  goBack,
  goBackLabel,
  goForward,
  goForwardLabel,
  logo,
  withSidebar,
  variant = 'default',
}: {
  variant?: 'default' | 'simple';
  withSidebar?: boolean;
  isSidebarOpen?: boolean;
  children?: ReactNode;
  logo?: ReactNode;
  rightSide?: ReactNode;
  pageTitle?: string;
  goBack?: () => void;
  goBackLabel?: string;
  goForward?: () => void;
  goForwardLabel?: string;
}) => {
  const isSimple = variant === 'simple';
  return (
    <div
      className={clsx(
        styles.topbar,
        withSidebar && styles.withSidebar,
        withSidebar && isSidebarOpen && styles.topbarWithSidebarOpen,
      )}
    >
      {isSimple && (
        <Flex w="100%" align="center" justify={'center'}>
          <ManifestLogomark />
        </Flex>
      )}
      {!isSimple && (
        <>
          <div className={styles.leftSide}>
            {logo && (
              <Flex mr="4px" align={'center'}>
                {logo}
              </Flex>
            )}
            <Flex gap="md">
              <Tooltip label={goBackLabel}>
                <ActionIcon
                  onClick={goBack}
                  variant="transparent"
                  aria-label={goBackLabel}
                  className={styles.navigationButton}
                >
                  <Icon icon="arrow-left" />
                </ActionIcon>
              </Tooltip>
              <Tooltip label={goForwardLabel}>
                <ActionIcon
                  onClick={goForward}
                  variant="transparent"
                  aria-label={goForwardLabel}
                  className={styles.navigationButton}
                >
                  <Icon icon="arrow-right" />
                </ActionIcon>
              </Tooltip>
            </Flex>
            {pageTitle && <span>{pageTitle}</span>}
          </div>
          {children}
          <div className={styles.rightSide}>{rightSide}</div>
        </>
      )}
    </div>
  );
};

export default Toolbar;
