import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

interface Params {
  organizationId: string;
}

export const useDeleteGitHubIntegrations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ organizationId }: Params) => {
      return hitApi.delete(
        `integration/github/${organizationId}/all`,
        true,
        {},
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['integrations'] });
      queryClient.invalidateQueries({ queryKey: ['integration-github'] });
    },
  });
};
