import { ManifestTag } from '../../../../components/ManifestTag/ManifestTag';
import {
  InterfaceFullAsset,
  InterfaceFullAssetLicenseIssue,
} from '../../../../types/FullInterfaces';
import { Card, Text } from '@mantine/core';

interface Props {
  licenseIssue: InterfaceFullAssetLicenseIssue;
  fetchedAsset: InterfaceFullAsset;
}

export const LicenseIssueCard = ({ licenseIssue, fetchedAsset }: Props) => {
  return (
    <Card className="license-issue-card">
      <span className="license-issue-title">
        <h4>{licenseIssue.matchedString}</h4>
      </span>
      <div className="license-issue-tags">
        <Text color="gray">{licenseIssue.license.licenseType}</Text>
        <ManifestTag
          variant="components"
          count={fetchedAsset.countLicenseIssues?.[licenseIssue.matchedString!] || 0}
        />
      </div>
      <div className="fixes">{/* <ManifestTag variant="fix-available" /> */}</div>
    </Card>
  );
};
