import { Stack, Text } from '@mantine/core';
import ClickableRegion from '../../../ClickableRegion';
import { truncateString } from '../../../../lib/truncateString';
import React from 'react';
import { MRT_Cell, MRT_Row } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import './ProductName.scss';
import ProductsIcon from '../../../../img/illustrations/sidebar/Products.svg?react';

export const ProductName = ({
  cell,
  row,
}: {
  cell: MRT_Cell<InterfaceOrganizationProduct>;
  row: MRT_Row<InterfaceOrganizationProduct>;
}) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ gap: '2px' }}>
      <ClickableRegion
        className="table-link products-table__name"
        regionLabel={t('tables.products.view-product-details')}
        href={`/product/${row.original._id}`}
      >
        <ProductsIcon className="left-nav__custom-icon" />
        <Text fw="bold" size="md" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {truncateString(cell.getValue<string>(), 50)}
        </Text>
      </ClickableRegion>
    </Stack>
  );
};
