export const getSeverityLabelKey = ({ value }: { value: number }): string => {
  /**
   * None – 0.0
    Low – 0.1 – 3.9
    Medium – 4.0 – 6.9
    High 7.0 – 8.9
    Critical – 9.0 – 10.0
   */
  if (value >= 0 && value < 0.1) {
    return `jargon.severity.none`;
  } else if (value >= 0.1 && value < 4) {
    return `jargon.severity.low`;
  } else if (value >= 4 && value < 7) {
    return `jargon.severity.medium`;
  } else if (value >= 7 && value < 9) {
    return `jargon.severity.high`;
  } else if (value >= 9 && value <= 10) {
    return `jargon.severity.critical`;
  }

  return '';
};
