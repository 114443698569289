import { Flex, Title, Text, Switch, Divider, Tooltip, Alert } from '@mantine/core';
import React, { useMemo } from 'react';
import {
  useMantineReactTable,
  MantineReactTable,
  MRT_ColumnDef,
} from 'mantine-react-table';
import { useSlaPage } from './useSlaPage.hook';
import styles from './Sla.module.scss';
import { getDefaultTableOptions } from '../../../components/MRT/ManifestMRT';
import Icon from '../../../components/Icon';
import { EditSlaModal } from './EditSla/EditSla.component';
import { DateTime } from 'luxon';
import {
  IRCategoryObject,
  IRCategoryObjectForKev,
} from '@manifest-cyber/types/interface/db';
import { useTranslation } from 'react-i18next';

export const Sla = () => {
  const { t } = useTranslation();
  const {
    response,
    isLoadingQuery,
    isFetching,
    isError,
    opened,
    close,
    currentRow,
    vulnerabilityRisk,
    tableData,
    handleSwitchToggle,
    handleEditClick,
    activeSlasCount,
    isLoadingMutation,
    vulnerabilityRiskLabels,
  } = useSlaPage();

  const defaultTableOptions = getDefaultTableOptions();

  const columns = useMemo<MRT_ColumnDef<IRCategoryObject | IRCategoryObjectForKev>[]>(
    () => [
      {
        accessorKey: 'status',
        header: t('page.sla.enabled'),
        size: 80,
        Cell: ({ cell }) => {
          const row = cell.row.original;
          const isDisabled =
            !row.criteria || !row.remediationTimeFrame || isLoadingMutation;
          const withTooltip = !row.criteria || !row.remediationTimeFrame;

          if (withTooltip) {
            return (
              <Tooltip
                label={isDisabled ? t('page.sla.switchTooltip') : ''}
                disabled={!isDisabled}
                position="bottom"
              >
                <span>
                  <Switch
                    className={styles.rowPadding}
                    checked={cell.getValue() === 'enabled'}
                    onChange={(e) =>
                      handleSwitchToggle(cell.row.index, e.currentTarget.checked)
                    }
                    disabled={isDisabled}
                  />
                </span>
              </Tooltip>
            );
          } else {
            return (
              <span>
                <Switch
                  className={styles.rowPadding}
                  checked={cell.getValue() === 'enabled'}
                  onChange={(e) =>
                    handleSwitchToggle(cell.row.index, e.currentTarget.checked)
                  }
                  disabled={isDisabled}
                />
              </span>
            );
          }
        },
      },
      {
        accessorKey: 'vulnerabilityRisk',
        header: t('page.sla.vulnerabilityRisk'),
        Cell: ({ row }) => {
          return vulnerabilityRiskLabels[row.index] ?? '--';
        },
      },
      {
        accessorKey: 'description',
        header: t('page.sla.description'),
        size: 250,
        Cell: ({ cell }) => {
          return cell.getValue<string>() ?? '--';
        },
      },
      {
        accessorKey: 'criteria',
        header: t('page.sla.criteria'),
        size: 120,
        Cell: ({ cell, row }) => {
          const criteria = cell.getValue();
          if (row.index === 0) {
            return t('page.sla.onKev');
          }
          return Array.isArray(criteria) && criteria.length > 0
            ? `CVSS ${Math.min(...criteria)} - ${Math.max(...criteria)}`
            : '--';
        },
      },
      {
        accessorKey: 'remediationTimeFrame',
        header: t('page.sla.timeFrame'),
        size: 100,
        Cell: ({ cell }) => {
          const timeFrame = cell.getValue<string>();
          if (!timeFrame) return '--';
          const number = timeFrame.slice(0, -1);
          const unit =
            timeFrame.slice(-1) === 'h'
              ? t('page.sla.hour', { count: parseFloat(number) })
              : t('page.sla.day', { count: parseFloat(number) });
          return `${number} ${unit}`;
        },
      },
      {
        accessorKey: 'actions',
        header: t('page.sla.actions'),
        size: 80,
        Cell: ({ row }) => (
          <Icon
            icon="pen"
            classNames={styles.iconEdit}
            onClick={() =>
              handleEditClick(row.original, row.getValue('vulnerabilityRisk'))
            }
          />
        ),
      },
    ],
    [t, handleSwitchToggle, isLoadingMutation],
  );

  const table = useMantineReactTable({
    ...defaultTableOptions,
    columns,
    data: tableData,
    enableSorting: false,
    enableFilters: false,
    enablePagination: false,
    enableRowActions: false,
    manualPagination: false,
    manualSorting: false,
    manualFiltering: false,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: false,
    },
    state: {
      isLoading: isLoadingQuery || isFetching,
    },
    mantineTableHeadCellProps: {
      className: styles.tableHead,
    },
    mantinePaperProps: {
      className: styles.tablePaper,
      withBorder: false,
      shadow: 'none',
    },
    mantineTableBodyRowProps: ({ row }) => ({
      className: row.original.status === 'disabled' ? styles.grayedOut : '',
      sx: {
        td: {
          backgroundColor: '#131B26',
        },
        '&:hover': {
          td: {
            backgroundColor: row.getIsSelected() ? undefined : 'rgba(25, 36, 52, 1)',
          },
        },
      },
    }),
  });

  return (
    <div className={`anim-slideInDownShort`}>
      {isError ? (
        <Alert color="red" title={t('page.sla.errorTitle')}>
          {t('page.sla.errorMessage')}
        </Alert>
      ) : (
        <>
          <Flex justify={'space-between'} align={'center'}>
            <Title order={2} mb={10}>
              {t('page.sla.title')}
            </Title>
            {!!response?.data?.dateModified && (
              <Text>
                {t('page.sla.lastModified', {
                  date: DateTime.fromISO(
                    response.data?.dateModified as unknown as string,
                  ).toLocaleString(DateTime.DATE_MED),
                })}
              </Text>
            )}
          </Flex>
          <Text className={styles.subtitle}>{t('page.sla.subtitle')}</Text>
          <Divider my={24} className={styles.headerDivider} />
          <Text className={styles.activeSlasCounter}>
            {activeSlasCount > 0
              ? t('page.sla.activeSlaAmount', { count: activeSlasCount })
              : t('page.sla.noActiveSLAs')}
          </Text>
          <MantineReactTable table={table} />
          <EditSlaModal
            opened={opened}
            onClose={close}
            rowData={currentRow}
            vulnerabilityRisk={vulnerabilityRisk}
            organizationSlaSetting={response?.data}
          />
        </>
      )}
    </div>
  );
};
