import { APIResponse } from '../../../types/api_response.model';

export interface SucessApiResponse<T> {
  success: true;
  data: T;
  errors: string[];
}

export const rejectFailedRequest = async <T,>(
  response: APIResponse<T>,
): Promise<SucessApiResponse<T>> => {
  if (!response.success) {
    return Promise.reject(response.errors);
  }

  return { ...response, success: true };
};
