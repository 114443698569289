import { useQuery } from '@tanstack/react-query';
import { SharingPortalReceiverApi } from '../sharedPortalReceiver.api';

export const SHARING_PORTAL_RECEIVER_QUERY_KEY = 'sharing_portal_receiver';

export const useFetchSharingPortalReceiver = ({ portalName }: { portalName: string }) => {
  return useQuery({
    staleTime: 1000 * 60 * 10, // 10 min (or until invalidated)
    queryKey: [SHARING_PORTAL_RECEIVER_QUERY_KEY],
    queryFn: () => SharingPortalReceiverApi.getPortal(portalName),
  });
};
