import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ManifestModal from '../../../components/ManifestModal/ManifestModal';
import { USER_PORTAL_MOCK } from '../../__mocks__/user.mock';
import { PortalUserInformationForm } from '../../components/PortalUserInformationForm/PortalUserInformationForm';
import { useUserInformationForm } from '../../components/PortalUserInformationForm/useUserInformationForm';

export const UserSettings = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const userInitialValues = USER_PORTAL_MOCK;
  const form = useUserInformationForm({
    initialValues: userInitialValues,
    requiredFieldMessage: t('global.form.errors.requiredField'),
  });

  const saveUserInfo = () => {
    if (!form) {
      return;
    }
    if (form.isValid()) {
      // TODO: send user info to endpoint
      // https://linear.app/manifest-cyber/issue/MFST-3517/connect-user-settings-modal-to-endpoint
      onClose();
    } else {
      form.validate();
    }
  };

  return (
    <ManifestModal
      size="md"
      opened
      withCloseButton={true}
      onClose={onClose}
      title={t('Settings')}
      body={
        <Flex>
          <PortalUserInformationForm form={form} showEmailField />
        </Flex>
      }
      primaryAction={{
        label: t('global.saveChanges'),
        onClick: saveUserInfo,
        isLoading: false,
      }}
      secondaryAction={{
        label: t('global.cancel'),
        onClick: onClose,
      }}
    />
  );
};
