import {
  ManifestIntegration,
  TicketMetadata,
} from '@manifest-cyber/types/interface/ticketMetadata';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';

export const useFetchTicketMetadata = (
  integration: ManifestIntegration,
  enabled = true,
) => {
  const fetchUrl = `/integration/merge/ticket-metadata?integration=${integration}`;

  return useQuery({
    queryKey: ['ticket-metadata', integration],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data as TicketMetadata,
    onError: (error) => {
      console.error(`Unable to ticket metadata for integration ${integration}:`, error);
    },
    enabled,
  });
};
