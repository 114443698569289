import '../../../../scss/pages/integration.scss';
import { ReactNode } from 'react';
import { ActionIcon, Divider } from '@mantine/core';
import { IIntegration } from '../integrations-data';
import Icon from '../../../../components/Icon/Icon';
import ClickableRegion from '../../../../components/ClickableRegion';
import { useTranslation } from 'react-i18next';

interface Props {
  children?: ReactNode;
  integrationInfo: IIntegration;
}

export const IntegrationAddShell = ({ children, integrationInfo }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="integration-install-page anim-slideInDownShort">
      <ClickableRegion href="/settings/integrations">
        <ActionIcon size="xl">
          <Icon icon="arrow-left" />
        </ActionIcon>
      </ClickableRegion>
      <div className="integration-page-header">
        {integrationInfo.icon && (
          <Icon icon={integrationInfo.icon} iconStyle="fab" size="5x" />
        )}
        <h3>{integrationInfo.title}</h3>
        <h4>{integrationInfo.subtitle}</h4>
      </div>
      <Divider my="sm" />
      {children}
    </div>
  );
};
