import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceFullUser } from '../../types/FullInterfaces';

interface Params {
  userId?: string;
}

interface Options {
  staleTime?: number;
}

export const useFetchUser = ({ userId }: Params, options?: Options) => {
  const fetchUrl = `user/${userId}`;

  return useQuery({
    enabled: !!userId,
    queryKey: ['user', { _id: userId }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data?.[0] as InterfaceFullUser,
    onError: (error) => {
      console.error('Unable to fetch user: ', error);
    },
    staleTime: options?.staleTime,
  });
};
