import { useState, useEffect } from 'react';
import { Modal, Input } from 'rsuite';
import { Button, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import hitApi from '../../api';

interface MergeDownloadModalProps {
  open: boolean;
  sbomIds: string[];
  onClose: () => void;
  downloadFn: (sbomIds: string[]) => Promise<void> | void;
}
function MergeDownloadModal({
  open,
  sbomIds,
  onClose,
  downloadFn,
}: MergeDownloadModalProps) {
  const { t } = useTranslation();
  const [mergedSbomName, setMergedSbomName] = useState('');
  const [mergedSbomVersion, setMergedSbomVersion] = useState('');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setShareModalOpen(true);
      setMergedSbomName('');
      setMergedSbomVersion('');
      setErrors([]);
    } else {
      setShareModalOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setErrors([]);
  }, [mergedSbomName, mergedSbomVersion]);

  const handleMergeDownloadClick = async () => {
    try {
      setIsLoading(true);
      setErrors([]);
      const mergedSbomsResponse = await hitApi.get('sboms/multiple-sbom-return', true, {
        merge: true,
        sbomIds: sbomIds?.join(',') || '',
        mergedAssetName: mergedSbomName,
        mergedAssetVersion: mergedSbomVersion,
      });
      const {
        success,
        data: [result],
      } = mergedSbomsResponse;
      if (success === false) throw new Error(`failed to merge sboms`);
      const { sbomId } = result;
      await downloadFn([sbomId]);
      onClose();
    } catch (e) {
      setErrors([t('page.uploads.errors.merging-failure')]);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal open={shareModalOpen} onClose={onClose} className="merge-download-modal">
      <Modal.Header>
        <Modal.Title>{t(`page.uploads.mergeDownloadModal.header`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors && errors.length > 0 && (
          <ul className="page-errors anim-slideInUpShort">
            {errors.map((error, idx) => (
              <li key={`error-${idx}`}>{error}</li>
            ))}
          </ul>
        )}

        <span className="modal-subheader">{`${t(
          `page.uploads.mergeDownloadModal.subHeader`,
        )} ${sbomIds.length} ${t(
          `page.uploads.mergeDownloadModal.subHeaderContinued`,
        )}`}</span>
        <div className="input-fields">
          <div className="input-field">
            <span className="label"> Name: </span>
            <Input
              className="input"
              placeholder="Enter a name..."
              onChange={setMergedSbomName}
            />
          </div>
          <div className="input-field">
            <span className="label"> Version: </span>
            <Input
              className="input"
              placeholder="Enter a version..."
              onChange={setMergedSbomVersion}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Flex gap="md" justify="flex-end">
          <Button onClick={onClose} variant="default">
            {t('global.cancel')}
          </Button>
          <Button
            loading={isLoading}
            disabled={mergedSbomName === '' || mergedSbomVersion === ''}
            onClick={handleMergeDownloadClick}
          >
            {t('page.uploads.mergeDownloadModal.mergeAndDownloadBtnText')}
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

export default MergeDownloadModal;
