import { Button } from '@mantine/core';
import { ReactNode } from 'react';
import styles from './ActionableCard.module.scss';
interface ActionableCardProps {
  title: string;
  Icon: ReactNode;
  subtitle?: ReactNode;
  primaryAction?: {
    label: string;
    onClick: () => void;
    icon?: ReactNode;
    isLoading?: boolean;
  };
}

export const ActionableCard = ({
  title,
  subtitle,
  primaryAction,
  Icon,
}: ActionableCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.iconContainer}>{Icon}</div>
        <div className={styles.title}>{title}</div>
        {subtitle && <div className={styles.subtitle}>{subtitle} </div>}
      </div>
      {primaryAction && (
        <div className={styles.bottomContainer}>
          <Button
            loading={primaryAction.isLoading}
            variant="filled"
            leftIcon={primaryAction.icon}
            onClick={primaryAction.onClick}
          >
            {primaryAction.label}
          </Button>
        </div>
      )}
    </div>
  );
};
