import { Text } from '@mantine/core';
import styles from '../AssetsTable.module.scss';
import { DateTime } from 'luxon';
import { MRT_Row } from 'mantine-react-table';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';

export const DateCreatedColumn = ({
  row,
}: {
  row: MRT_Row<InterfaceOrganizationAsset>;
}) => {
  return (
    <Text
      color="gray"
      sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      {DateTime.fromISO(`${row.original.dateCreated}`).toRelative()}
    </Text>
  );
};
