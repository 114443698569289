import { Card, Grid, Text, Title } from '@mantine/core';
import styles from './Highlights.module.scss';
import { useTranslation } from 'react-i18next';

interface InterfaceProductOverviewHighlights {
  countHighRiskAssets: number;
  mitigateVulns: number;
  inKevVulns: number;
}

export const ProductOverviewHighlights = ({
  countHighRiskAssets,
  mitigateVulns,
  inKevVulns,
}: InterfaceProductOverviewHighlights) => {
  const { t } = useTranslation();

  return (
    <Grid align="stretch">
      <Grid.Col span={12} md={4}>
        <Card className={styles.highlight}>
          <Title className={styles.highlight__title}>
            {t('page.product.highRiskAssets')}
          </Title>
          <Text className={styles.highlight__value}>{countHighRiskAssets}</Text>
        </Card>
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <Card className={styles.highlight}>
          <Title className={styles.highlight__title}>
            {t('page.product.vulnsToMitigate')}
          </Title>
          <Text className={styles.highlight__value}>{mitigateVulns}</Text>
        </Card>
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <Card className={styles.highlight}>
          <Title className={styles.highlight__title}>{t('page.product.kevList')}</Title>
          <Text className={styles.highlight__value_inKev}>{inKevVulns}</Text>
        </Card>
      </Grid.Col>
    </Grid>
  );
};
