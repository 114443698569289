import { StyleSheet, Font } from '@react-pdf/renderer';
export const pdfColors = {
  primary: '#1EBCE2',
  white: '#FFFFFF',
  text: '#131B26',
  textLight: '#192434',
  textLight2: '#8B8F98',
  border: '#D7D8DC',
  red: '#CC3663',
  redRgb: '204,54,99',
  headerBg: '#19303F',
  headerLine: '#D9D9D9',
  headerText: '#151F2D',
  success: '#4CAF50',
  medium: '#F3A93C',
  textLightAlt: '#333F51',
  textFaint: '#6A7076',
};

export const pdfFontSizes = {
  sectionHeader: '12px',
  summary: '10px',
  p: '10px',
};

// PDF Export Styles
Font.register({
  family: 'Karla',
  fonts: [
    {
      src: '/fonts/karla/static/Karla-Light.ttf',
      fontWeight: 300,
      fontStyle: 'normal',
    },
    {
      src: '/fonts/karla/static/Karla-Regular.ttf',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    {
      src: '/fonts/karla/static/Karla-SemiBold.ttf',
      fontWeight: 500,
      fontStyle: 'normal',
    },
    {
      src: '/fonts/karla/static/Karla-Bold.ttf',
      fontWeight: 600,
      fontStyle: 'normal',
    },
  ],
});
export const pdfStyles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  page: {
    backgroundColor: pdfColors.white,
    paddingBottom: 15,
    fontSize: '12px',
    fontFamily: 'Karla',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: pdfFontSizes.p,
    bottom: 20,
    right: 20,
    textAlign: 'left',
    color: 'grey',
    fontFamily: 'Karla',
  },
  pageContent: {
    position: 'relative',
    padding: '12px 20px 0px 20px',
    width: '100%',
    color: '#000',
  },
  section: {
    marginBottom: 10,
    padding: 10,
    width: '100%',
    border: `1px solid ${pdfColors.border}`,
    borderRadius: '2px',
  },
  sectionNoBotPad: {
    marginBottom: 10,
    padding: 18,
    paddingBottom: 5,
    width: '100%',
    border: `1px solid ${pdfColors.border}`,
    borderRadius: '2px',
  },
  header: {
    position: 'relative',
    overflow: 'hidden',
    height: 'auto',
    width: '100%',
  },
  headerInner: {
    backgroundColor: pdfColors.headerBg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    flexDirection: 'row',
    padding: '16px 20px',
  },
  headerLeft: {
    width: '40%',
    zIndex: 1,
  },
  headerImage: {
    height: '20px',
    width: '100px',
  },
  headerBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  headerBackgroundImage: {
    position: 'relative',
    width: '100%',
    zIndex: 0,
    opacity: 0.8,
  },
  headerRight: {
    width: '60%',
    textAlign: 'right',
    color: pdfColors.white,
    zIndex: 1,
  },
  headerRightTitle: {
    fontWeight: 'bold',
    fontSize: '10px',
    marginBottom: '3px',
    fontFamily: 'Karla',
  },
  headerRightSubtitle: {
    fontWeight: 'normal',
    fontSize: '8px',
    marginBottom: '5px',
  },
  headerLeftOverlay: {
    position: 'absolute',
    top: 3,
    left: 0,
    textAlign: 'left',
    width: '40%',
    color: pdfColors.white,
    padding: '16px 20px',
  },
  headerImage2: {
    position: 'relative',
    height: '20px',
    width: '80px',
  },
  headerRightOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
    width: '100%',
    color: pdfColors.white,
    padding: '16px 20px',
  },
  linkOverride: {
    color: pdfColors.text,
    textDecoration: 'none', // This doesn't actually appear to work
  },
  splitView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  // Left Side (summary, vuln analysis, riskiest comps)
  splitViewLeft: {
    width: '68%',
    flexShrink: 0,
    flexGrow: 0,
  },
  // Right side of overview (overall risk, quick stats, etc)
  splitViewRight: {
    width: '30%',
    marginLeft: 10,
    flexShrink: 0,
    flexGrow: 0,
  },
  // Asset Name
  overviewSectionTitle: {
    fontFamily: 'Karla',
    fontSize: '22px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
  },
  overviewSectionVersion: {
    fontFamily: 'Karla',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: '1px',
    marginBottom: '10px',
  },
  sectionDivider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  sectionDividerText: {
    color: pdfColors.headerText,
    fontSize: pdfFontSizes.sectionHeader,
    backgroundColor: pdfColors.white,
    flexShrink: 0,
    flexGrow: 1,
    position: 'relative',
    paddingRight: '20px',
  },
  sectionDividerLine: {
    height: `1px`,
    backgroundColor: pdfColors.headerLine,
    flexGrow: 0,
    flexShrink: 1,
    width: '100%',
    position: 'relative',
    top: '1px',
  },
  sectionDividerLine2: {
    height: `1px`,
    backgroundColor: pdfColors.headerLine,
    flexGrow: 0,
    flexShrink: 1,
    width: '70%',
    position: 'relative',
    top: '1px',
  },
  sectionDividerLine3: {
    height: `1px`,
    backgroundColor: pdfColors.headerLine,
    flexGrow: 0,
    flexShrink: 1,
    width: '55%',
    position: 'relative',
    top: '1px',
  },
  sectionDividerLine4: {
    height: `1px`,
    backgroundColor: pdfColors.headerLine,
    flexGrow: 0,
    flexShrink: 1,
    width: '100%',
    position: 'relative',
    top: '1px',
    marginBottom: '5px',
  },
  sectionContent: {
    position: 'relative',
    marginTop: '2px',
    width: '100%',
  },
  sectionContentSummary: {
    position: 'relative',
    marginTop: '3px',
    width: '100%',
    padding: '10px',
    borderRadius: '2px',
    backgroundColor: '#F0F2F5CC',
  },
  sectionContentSummaryListItem: {
    position: 'relative',
    width: '100%',
    marginBottom: '2px',
    color: pdfColors.text,
    fontSize: pdfFontSizes.summary,
    paddingLeft: '18px',
  },
  sectionContentGraphContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    flexDirection: 'row',
    gap: '10px',
  },
  sectionContentGraph: {
    position: 'relative',
    marginTop: '3px',
    height: '85px',
    width: '85px',
  },
  sectionContentLegend: {
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
  },
  sectionContentLegendRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px',
    marginBottom: '2px',
    flexDirection: 'row',
  },
  sectionContentLegendRowColor: {
    position: 'relative',
    width: '5px',
    height: '5px',
    backgroundColor: pdfColors.medium,
    borderRadius: '5px',
  },
  sectionContentLegendRowLabel: {
    position: 'relative',
    color: pdfColors.textLightAlt,
    fontFamily: 'Karla',
    fontWeight: 400,
    fontSize: '10px',
    textTransform: 'uppercase',
  },
  sectionContentLegendRowValue: {
    position: 'relative',
    color: pdfColors.text,
    fontFamily: 'Karla',
    fontWeight: 'bold',
    fontSize: '10px',
    textTransform: 'uppercase',
  },
  pdfLink: {
    position: 'relative',
    width: '100%',
    marginBottom: '10px',
    marginLeft: '7px',
  },
  pdfLinkAlt: {
    position: 'relative',
    width: '100%',
    marginBottom: '0px',
    marginLeft: '0px',
    color: pdfColors.primary,
  },
  pdfLinkList: {
    position: 'relative',
    width: '100%',
    marginBottom: '6px',
  },
  pdfLinkText: {
    position: 'relative',
    marginBottom: '5px',
    color: pdfColors.text,
    textDecoration: 'none', // This doesn't actually appear to work
  },
  pdfLinkIcon: {
    height: '10px',
    position: 'relative',
    marginTop: '-16px',
    left: '67px',
    color: pdfColors.primary,
  },
  notProvidedText: {
    position: 'relative',
    marginBottom: '5px',
    color: pdfColors.textLight2,
    fontDecoration: 'italic',
    textDecoration: 'none', // This doesn't actually appear to work
  },
  sidebarBlock: {
    marginBottom: '20px',
  },
  sidebarBlockTitle: {
    fontFamily: 'Karla',
    fontSize: '8px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: pdfColors.textLight2,
    marginBottom: '4px',
  },
  sidebarBlockFlex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'relative',
  },
  sidebarBlockIcon: {
    height: '16px',
    width: '16px',
    fontWeight: 400,
    color: pdfColors.textLight2,
    position: 'absolute',
    top: '3px',
    left: 0,
    flexShrink: 0,
  },
  sidebarBlockValue: {
    fontFamily: 'Karla',
    fontSize: '18px',
    fontWeight: 400,
    color: pdfColors.text,
    position: 'relative',
    width: '60%',
    flexGrow: 1,
  },
  sidebarBlockValuePadded: {
    fontFamily: 'Karla',
    fontSize: '18px',
    fontWeight: 400,
    color: pdfColors.text,
    position: 'relative',
    width: '60%',
    flexGrow: 1,
    paddingLeft: '22px',
  },
  iconSummarySuccess: {
    height: '10px',
    width: '10px',
    position: 'absolute',
    top: '1px',
    left: '0',
    color: pdfColors.success,
  },
  iconSummaryIssue: {
    height: '10px',
    width: '10px',
    position: 'absolute',
    top: '1px',
    left: '0',
    color: pdfColors.red,
  },
  impactedAssetsList: {
    fontSize: pdfFontSizes.p,
    marginTop: '5px',
    border: '1px solid #D7D8DC',
  },
  impactedAssetsListItemHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 5px',
    color: pdfColors.text,
    backgroundColor: '#F0F2F5CC',
    fontWeight: 'bold',
  },
  impactedAssetsListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 5px',
    borderTop: `1px solid ${pdfColors.border}`,
    color: pdfColors.text,
  },
  impactedAssetsListItemColumnAHeader: {
    width: '35%',
    flexShrink: 0,
    fontSize: '8px',
    textAlign: 'left',
    wordBreak: 'break-letter',
    padding: '0 5px',
  },
  impactedAssetsListItemColumnA: {
    width: '35%',
    fontSize: '8px',
    flexShrink: 0,
    textAlign: 'left',
    wordBreak: 'break-letter',
    padding: '0 5px',
  },
  impactedAssetsListItemColumnBHeader: {
    width: '42%',
    fontSize: '8px',
    textAlign: 'left',
    padding: '0 5px',
  },
  impactedAssetsListItemColumnB: {
    width: '42%',
    fontSize: '8px',
    textAlign: 'left',
    padding: '0 5px',
  },
  impactedAssetsListItemColumnCHeader: {
    width: '23%',
    fontSize: '8px',
    textAlign: 'left',
    padding: '0 5px',
  },
  impactedAssetsListItemColumnC: {
    width: '23%',
    fontSize: '8px',
    textAlign: 'left',
    padding: '0 5px',
  },
  impactedAssetsListVulns: {
    display: 'flex',
    fontSize: '9px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '6px',
    position: 'relative',
    flexDirection: 'row',
  },
  impactedAssetsListVulnsCve: {
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
  },
  impactedAssetsListVulnsKev: {
    backgroundColor: pdfColors.medium,
    color: pdfColors.text,
    fontWeight: 'bold',
    fontSize: '7px',
    textTransform: 'uppercase',
    padding: '2px 4px',
    borderRadius: '12px',
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
    top: '-1px',
    paddingLeft: '10px',
  },
  iconKev: {
    height: '7px',
    width: '7px',
    position: 'absolute',
    top: '3px',
    left: '3px',
    color: pdfColors.text,
  },
  impactedAssetsListVulnsAction: {
    fontWeight: 'bold',
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
    textAlign: 'left',
    paddingLeft: '12px',
  },
  iconVulnMonitor: {
    height: '10px',
    width: '10px',
    position: 'absolute',
    top: '0px',
    left: '0',
    color: pdfColors.medium,
  },
  iconVulnMitigate: {
    height: '10px',
    width: '10px',
    position: 'absolute',
    top: '0px',
    left: '0',
    color: pdfColors.red,
  },
  aboutRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'relative',
    marginBottom: '6px',
  },
  aboutRowLeft: {
    width: '35%',
    flexShrink: 0,
    textAlign: 'left',
    wordBreak: 'break-letter',
    maxWidth: '120px',
    fontFamily: 'Karla',
    fontSize: '8px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: pdfColors.textLight2,
    gap: '20px',
  },
  aboutRowRight: {
    width: '65%',
    fontSize: '10px',
    textAlign: 'left',
    padding: '0 5px',
    color: pdfColors.textLight,
  },
  aboutRowRightFlex: {
    width: '85%',
    fontSize: '10px',
    textAlign: 'left',
    padding: '0 12px',
    color: pdfColors.textLight,
  },
  aboutRowRightFlexRow: {
    width: '100%',
    position: 'relative',
    maxWidth: '800px',
    fontSize: '10px',
    textAlign: 'left',
    color: pdfColors.textLight,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '20px',
    marginBottom: '4px',
  },
  aboutRowRightFlexItem1: {
    width: '40%',
    fontSize: '10px',
    textAlign: 'left',
    color: pdfColors.textLight,
    paddingRight: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  aboutRowRightFlexItem2: {
    width: '30%',
    fontSize: '10px',
    textAlign: 'left',
    color: pdfColors.textLight,
    paddingRight: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  aboutRowItemName: {
    color: pdfColors.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '9.5px',
    fontWeight: 700,
    maxWidth: '100%',
  },
  aboutRowItemCreatedDate: {
    color: pdfColors.textFaint,
    fontSize: '8px',
    fontWeight: 400,
  },
  aboutRowItemLocation: {
    color: pdfColors.headerText,
    fontSize: '9.5px',
    fontWeight: 400,
  },
  aboutRowItemContributions: {
    color: pdfColors.headerText,
    fontSize: '9.5px',
    fontWeight: 400,
  },
  repoFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'relative',
    marginBottom: '8px',
    gap: '13px',
  },
  repoFlexItem: {
    position: 'relative',
    paddingLeft: '12px',
    fontSize: '10px',
    fontFamily: 'Karla',
    color: pdfColors.textLightAlt,
    marginRight: '10px',
  },
  repoFlexItemBranch: {
    position: 'relative',
    paddingLeft: '11px',
    gap: '13px',
    fontSize: '10px',
    fontFamily: 'Karla',
    color: pdfColors.textLight,
    marginRight: '10px',
  },
  repoFlexIcon: {
    height: '10px',
    width: '10px',
    position: 'absolute',
    top: '1px',
    left: '0',
    color: pdfColors.textLight2,
  },
  locationIcon: {
    height: '10px',
    width: '10px',
    position: 'absolute',
    top: '1px',
    left: '0',
    color: pdfColors.textLight2,
  },
  fullDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: pdfColors.border,
    position: 'relative',
    top: '1px',
    marginBottom: '10px',
    marginTop: '10px',
  },
});
