import Cookies from 'js-cookie';
// import { DateTime } from "luxon";
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import appConfig from '../../configs/appConfig';

type OrganizationIdType = string | null;
export const keyName = 'organizationid';

export const useOrganizationId = (
  defaultValue: OrganizationIdType = null,
): [OrganizationIdType, (newValue: OrganizationIdType) => void] => {
  const [storedValue, setStoredValue] = useState((): OrganizationIdType => {
    try {
      const value =
        window.localStorage.getItem(keyName) || Cookies.get(keyName.toLowerCase());

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        // Cookies.set('organizationid', defaultValue || '', {
        //   domain: `${appConfig.cookieDomain}`,
        //   path: '/'
        // })
        return defaultValue;
      }
    } catch (err) {
      Sentry.captureMessage(`Error parsing cookie: ${keyName}`, 'error');
      Sentry.captureException(err);
      return defaultValue;
    }
  });

  const setValue = (newValue: OrganizationIdType) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
      Cookies.set(keyName.toLowerCase(), newValue || defaultValue || '', {
        domain: `${appConfig.cookieDomain}`,
        path: '/',
      });
    } catch (err) {
      Sentry.captureMessage(`Error setting cookie: ${keyName}`, 'error');
      Sentry.captureException(err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
