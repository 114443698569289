import { useQuery } from '@tanstack/react-query';
import { getProductCapabilities, ProductCapabilityResponse } from '../../api/product.api';

export interface ProductCapabilitiesQueryParams {
  enabled?: boolean;
  productId?: string;
}

type FetchError = Error;

type Response = {
  capabilities: {
    canMerge: boolean;
  };
};

export const useFetchProductCapabilities = ({
  enabled = true,
  productId,
}: ProductCapabilitiesQueryParams) => {
  const fetchUrl = `product/${productId}/capabilities`;

  const queryKey = [`product`, `/${fetchUrl}`];

  return useQuery({
    enabled: enabled && !!productId,
    keepPreviousData: true,
    queryKey: queryKey,
    queryFn: () => getProductCapabilities(productId!),
    onError: (error: FetchError) => {
      console.error(`Unable to fetch product capabilities:`, error);
    },
    select: (data): ProductCapabilityResponse => {
      if (data.success) {
        if (!data.data?.capabilities?.canMerge && !data.data?.capabilities?.reason) {
          // if reason does not exist set a default reason for tooltip
          data.data.capabilities.reason = 'asset formats mismatching';
          return data.data;
        }
        return data.data;
      }
      return {
        capabilities: {
          canMerge: false,
        },
      };
    },
  });
};
