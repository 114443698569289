import { UseFormReturnType } from '@mantine/form';
import { Dispatch } from 'react';
import {
  RiskCriteriaTypeFormField,
  SbomAlertRiskCriteriasFormValues,
} from '../hooks/useSbomAlertsForms';
import {
  RiskCriteriaSelector,
  SbomAlertCreationAction,
} from '../reducer/alertCreateReducer.interface';
import { removeProperty } from './removeProperty';

interface RemoveRiskCriteriaParams {
  criteria: RiskCriteriaSelector;
  form: UseFormReturnType<SbomAlertRiskCriteriasFormValues>;
  dispatch: Dispatch<SbomAlertCreationAction>;
  initialValues: SbomAlertRiskCriteriasFormValues;
}

export const removeRiskCriteria = ({
  form,
  initialValues,
  criteria,
  dispatch,
}: RemoveRiskCriteriaParams) => {
  const riskCriteriaSelectedFilters = removeProperty(
    form.values.riskCriteriaSelectedFilters,
    criteria.id,
  ) as RiskCriteriaTypeFormField;

  form.setValues({
    ...form.values,
    riskCriteriaSelectedFilters,
  });

  switch (criteria.value) {
    case 'cvssScore':
      form.setFieldValue('cvssScore', initialValues.cvssScore);
      break;
    case 'licenseIssues':
      form.setFieldValue('licenseIssues', initialValues.licenseIssues);
      break;
    case 'onCISAKEVList':
      form.setFieldValue('onCISAKEVList', initialValues.onCISAKEVList);
      break;
    case 'minRiskScore':
      form.setFieldValue('minRiskScore', initialValues.minRiskScore);
      break;
    case 'vulnRecommendations':
      form.setFieldValue('vulnRecommendations', initialValues.vulnRecommendations);
      break;
    default:
      break;
  }

  dispatch({
    type: 'removeRiskCriteria',
    payload: { id: criteria.id },
  });
};
