import './AssetVulnerabilitiesTable.scss';
import { Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../ClickableRegion';

interface ColumnFiltersProps {
  filters: FilterCategory[];
  onFiltersChange: (filterId: string, selectedFilters: string[]) => void;
}
interface Filter {
  id: string;
  label: string;
  active: boolean;
}
export interface FilterCategory {
  id: string;
  label: string;
  options: Filter[];
}
function ColumnFilters({ filters, onFiltersChange }: ColumnFiltersProps) {
  const { t } = useTranslation();
  const handleCheckboxChange = (selectedFilters: string[], filterId: string) => {
    onFiltersChange(filterId, selectedFilters);
  };

  const handleFilterClear = () => {
    filters.forEach((filter) => {
      handleCheckboxChange([], filter.id);
    });
  };
  return (
    <div className="column-filters">
      <div className="header">
        <div className="title">{t('global.filter')}</div>
        <ClickableRegion className="action" onClick={handleFilterClear}>
          {t('global.clearAll')}
        </ClickableRegion>
      </div>
      <div className="filters">
        {filters.map((filter) => (
          <Checkbox.Group
            label={filter.label}
            key={filter.id}
            onChange={(selectedFilters) => {
              handleCheckboxChange(selectedFilters, filter.id);
            }}
            value={filter.options
              .filter((option) => option.active)
              .map((option) => option.id)}
          >
            {filter.options.map((option) => (
              <Checkbox key={option.id} value={option.id} label={option.label} />
            ))}
          </Checkbox.Group>
        ))}
      </div>
    </div>
  );
}

export default ColumnFilters;
