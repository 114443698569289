import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

export interface TriageValues {
  triageStatusName: string;
  organizationAssetId: string;
  scope: string;
  cveIds: string[];
  justification: string;
  details: string;
}
interface Params {
  triage: TriageValues;
}

export const usePostVulnerabilityTriage = (organizationAssetId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ triage }: Params) => {
      return hitApi.post(`/triage`, true, {
        ...triage,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['vulnerabilities_triages']);
      queryClient.invalidateQueries(['assetVulns']);
      queryClient.invalidateQueries(['triageStatuses']);
      queryClient.invalidateQueries([`vulnerabilities_triages_${organizationAssetId}`]);
      queryClient.invalidateQueries(['countStats']);
    },
  });
};
