export const getLoadingMessage = (t: any) => {
  const loadingMessages = [
    'mainframe',
    'cake',
    'bits',
    'curtain',
    'windows',
    'spinning',
    'witty',
    'fuse',
    'purple',
    'draw',
    'npm',
    'v8',
    'serious',
    'often',
    'friends',
    'log4j',
    'slowed',
    'wishes',
    'internets',
    'haz',
    'wait',
    'think',
    'off',
    'boat',
    'humour',
    'ordering',
    'chair',
    'vista',
    'optimize',
  ];

  const loadingMessagesEnabled = false;
  if (loadingMessagesEnabled) {
    return t(
      `global.loading-message.${
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
      }`,
    );
  } else {
    return t('global.loading-message.default');
  }
};

export default getLoadingMessage;
