import { CloseButton } from '@mantine/core';
import { ReactNode } from 'react';
import styles from './ChipComponent.module.scss';

interface ChipComponentProps {
  leftIcon: {
    isVisible: boolean;
    color?: string;
  };
  label: ReactNode;
  onClick?: () => void;
}

const ChipComponent = ({ label, leftIcon, onClick }: ChipComponentProps) => {
  return (
    <div className={styles.chip}>
      {leftIcon.isVisible && <ChipColorCircle color={leftIcon.color} />}
      <div className={styles.labelName}>{label}</div>
      <CloseButton
        className={styles.closeButton}
        onMouseDown={onClick}
        variant="transparent"
        size="xs"
        iconSize={14}
        tabIndex={-1}
      />
    </div>
  );
};

export const ChipColorCircle = ({ color }: { color?: string }) => {
  return <div className={styles.colorCircle} style={{ backgroundColor: color }} />;
};

export default ChipComponent;
