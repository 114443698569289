import { useQuery } from '@tanstack/react-query';
import { getAssetVulnerabilitiesTriage } from './vulnerabilities.api';

export const useFetchVulnerabilitiesTriages = (assetID: string) => {
  const { isError, isLoading, data } = useQuery({
    queryKey: [`vulnerabilities_triages_${assetID}`],
    queryFn: () => getAssetVulnerabilitiesTriage(assetID),
    onError: (error) => {
      console.error('Unable to fetch vulnerability: ', error);
    },
    select: (data) => {
      if (data.success) {
        return data.data;
      }
      data.errors.forEach((e) => {
        console.error(e); // probably a toast services here
      });
    },
    staleTime: 30 * 1000,
  });
  return { isError, isLoading, data };
};
