import { InterfacePortal } from '@manifest-cyber/types/interface/dbTables';
import hitApi from '..';
import { rejectFailedRequest } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { APIResponse } from '../../types/api_response.model';
import { ApiPortalResponse } from '../../types/apiPortalResponse.interface';
import { TransformedToClientTypes } from '../../types/transformedToClientType';

const getPortal = async (): Promise<ApiPortalResponse['portal']> => {
  const response: APIResponse<ApiPortalResponse> = await hitApi.get('/portal');

  return (await rejectFailedRequest(response)).data.portal;
};

export interface UpdatePortalPayload {
  isActive: boolean;
  portalName: string;
  logoUrl: string;
}

const updatePortal = async (payload: UpdatePortalPayload): Promise<void> => {
  const response: APIResponse<TransformedToClientTypes<InterfacePortal>> =
    await hitApi.post(`/portal`, true, payload);

  await rejectFailedRequest(response);

  return;
};

export const SharingPortalApi = {
  getPortal,
  updatePortal,
};
