import './TriggerMessageModal.scss';
import { useState } from 'react';
import { Button, Modal, Flex, Checkbox, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useTriggerMessage } from '../../hooks/mutations/useTriggerMessage';

interface Props {
  open: boolean;
  onClose: () => void;
  triggerType: string;
}

export const TriggerMessageModal = ({ open, onClose, triggerType }: Props) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState<string[]>([]);
  const [isForceTrigger, setIsForceTrigger] = useState(false);
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  const { mutateAsync: triggerMessage, isLoading } = useTriggerMessage();

  const cleanupClosedModal = () => {
    setErrors([]);
    setIsForceTrigger(false);
    setIsAcknowledge(false);
  };

  const handleCancel = () => {
    cleanupClosedModal();
    onClose();
  };

  const handleTriggerMessage = async () => {
    const { data: res } = await triggerMessage({ triggerType, force: isForceTrigger });
    if (res?.errors?.length > 0) {
      setErrors(res.errors);
      return;
    }
    handleCancel();
  };

  return (
    <Modal opened={open} onClose={handleCancel} className="trigger-message-modal">
      <Modal.Body>
        <div className="modal-inner">
          <div className="modal-header">
            <h4>{t('page.administration.trigger-modal-title')}</h4>
          </div>
          {errors.length > 0 && (
            <div className="page-errors anim-slideInUpShort">{errors.join('. ')}</div>
          )}
          <div className="warning">
            <Text>{t('page.administration.trigger-modal-warning')}</Text>
          </div>
          <div className="is-force">
            <Checkbox
              value="is-force"
              checked={isForceTrigger}
              onChange={() => setIsForceTrigger(!isForceTrigger)}
              label={t('page.administration.trigger-modal-is-forced')}
            />
          </div>
          <div className="is-acknowledge">
            <Checkbox
              value="is-acknowledge"
              checked={isAcknowledge}
              onChange={() => setIsAcknowledge(!isAcknowledge)}
              label={t('page.administration.trigger-modal-acknowledge')}
            />
          </div>
        </div>
      </Modal.Body>
      <Flex className="modal-footer">
        <Button
          variant="default"
          onClick={() => {
            handleCancel();
          }}
        >
          {t(`global.cancel`)}
        </Button>
        <Button
          className="confirm-button"
          loading={isLoading}
          disabled={!isAcknowledge}
          onClick={handleTriggerMessage}
        >
          {t(`page.administration.trigger-modal-confirm`)}
        </Button>
      </Flex>
    </Modal>
  );
};

export default TriggerMessageModal;
