import { DefaultMantineColor } from '@mantine/core';
import { notifications } from '@mantine/notifications';

type Notification = { title: string; message: string };

const showNotification = (n: Notification, color: DefaultMantineColor) => {
  const { title, message } = n;
  notifications.show({
    color: color,
    title,
    message,
  });
};

const error = (n: Notification) => showNotification(n, 'red');
const success = (n: Notification) => showNotification(n, 'green');
const warning = (n: Notification) => showNotification(n, 'orange');
const info = (n: Notification) => showNotification(n, 'teals');

export const useNotifications = () => ({
  error,
  success,
  warning,
  info,
});
