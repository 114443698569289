import { useTranslation } from 'react-i18next';
import ClickableRegion from '../ClickableRegion';
import { ManifestLogo } from '../Icons/ManifestLogoIcon/ManifestLogoIcon';
import { ManifestLogomark } from '../Icons/ManifestLogomarkIcon/ManifestLogomarkIcon';

interface ClickableManifestLogoProps {
  wordmark?: boolean;
  className?: string;
  link?: string;
  width?: number;
  height?: number;
}

const ClickableManifestLogo = ({
  wordmark,
  className,
  link = '/',
  width,
  height,
}: ClickableManifestLogoProps) => {
  const { t } = useTranslation();

  return (
    <ClickableRegion regionLabel={t('logo')} href={link} className={className}>
      <>
        {!wordmark && <ManifestLogomark width={width || 64} height={height || 64} />}
        {wordmark && <ManifestLogo width={width || 118} height={height || 40} />}
      </>
    </ClickableRegion>
  );
};

export default ClickableManifestLogo;
