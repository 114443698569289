import { useMutation } from '@tanstack/react-query';
import hitApi from '../../api';

interface CheckActiveResponse {
  success: boolean;
  errors: string[];
  data?: boolean | null;
}

interface FetchOptions {
  onSuccess?: (data: CheckActiveResponse) => void;
  onError?: (error: any) => void;
}

export const useCheckActiveAssets = (options?: FetchOptions) => {
  return useMutation<CheckActiveResponse, any, string[]>({
    mutationFn: async (assetIds: string[]) => {
      const fetchUrl = 'assets/check-active';
      const fetchBody = { assetIds };
      return await hitApi.post(fetchUrl, true, fetchBody, {});
    },
    onSuccess: options?.onSuccess,
    onError: options?.onError,
  });
};
