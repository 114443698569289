import {
  Accordion,
  ActionIcon,
  Button,
  Flex,
  Menu,
  Spoiler,
  Tooltip,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ClickableRegion from '../../../components/ClickableRegion';
import CreateTicketButton from '../../../components/CreateTicket/CreateTicketButton';
import Icon from '../../../components/Icon';
import { ManifestTag } from '../../../components/ManifestTag/ManifestTag';
import { InterfaceVulnerabilityWithVexInfo } from '../../../types/FullInterfaces';
import { useVulnMetaParsedData } from '../hooks/useVulnMetaParsedData';
import styles from './VulnerabilityMeta.module.scss';

interface VulnerabilityMetaProps extends InterfaceVulnerabilityWithVexInfo {
  vulnId: string;
  published: string | undefined;
  onExportReport: () => void;
  onOpenVexModal: () => void;
  isLoading: boolean;
}

export const VulnerabilityMeta = (props: VulnerabilityMetaProps) => {
  const { onExportReport, vulnId, _id, cveId, onOpenVexModal, isLoading } = props;

  const { t } = useTranslation();
  const {
    firstAlias,
    formattedDatePublished,
    generatedReferenceLink,
    hasDescription,
    hasOtherAliases,
    inKevList,
    formattedExploitability,
    formattedPercentile,
    severity,
    isInKev,
    filteredAliases,
    nvdLink,
    osvLink,
    description,
    formattedFirstSeen,
  } = useVulnMetaParsedData(props);

  return (
    <Flex direction="column" gap="18px">
      <main className="anim-slideInDownShort vuln-meta-container card">
        <div className="vuln-meta-main">
          {isInKev && <ManifestTag variant="kev" />}
          <h1 className="vuln-meta-title">{vulnId}</h1>
          {firstAlias && (
            <p className="vuln-meta-alias text">
              {t('page.vulnerability.details.alias')}: &nbsp;
              <em>{firstAlias}</em>
              {hasOtherAliases && (
                <>
                  , &nbsp;
                  <Link to="reference">
                    <em>
                      {t('page.vulnerability.details.alias-rest', {
                        aliasNo: filteredAliases.length - 1,
                      })}
                    </em>
                  </Link>
                </>
              )}
            </p>
          )}
          <Flex mt={hasOtherAliases ? 8 : 4}>
            <ClickableRegion
              forceAnchor
              className="text external_link"
              href={`https://www.google.com/search?q=${cveId}`}
              target="_blank"
            >
              Google <Icon icon="arrow-up-right-from-square" />
            </ClickableRegion>
            {nvdLink && (
              <ClickableRegion
                forceAnchor
                className="text external_link"
                href={nvdLink.url}
                target="_blank"
              >
                {t('page.vulnerability.details.meta-data.nvd')}&nbsp;
                <Icon icon="arrow-up-right-from-square" />
              </ClickableRegion>
            )}
            {osvLink && (
              <ClickableRegion
                forceAnchor
                className="text external_link"
                href={osvLink.url}
                target="_blank"
              >
                {t('page.vulnerability.details.meta-data.osv')}&nbsp;
                <Icon icon="arrow-up-right-from-square" />
              </ClickableRegion>
            )}
            {generatedReferenceLink && (
              <ClickableRegion
                forceAnchor
                className="text external_link"
                href={generatedReferenceLink.link}
                target="_blank"
              >
                {generatedReferenceLink.label} <Icon icon="arrow-up-right-from-square" />
              </ClickableRegion>
            )}
          </Flex>
        </div>
        <Flex direction="column" gap={8}>
          <Button
            fullWidth
            variant="filled"
            leftIcon={<Icon icon="arrow-down-to-bracket" />}
            onClick={onExportReport}
            loading={isLoading}
            className="btn download-report-btn"
          >
            {t('page.vulnerability.export')}
          </Button>
          <Flex gap={8}>
            <Flex w="100%" pos="relative">
              <CreateTicketButton
                entityId={_id?.toString() || ''}
                entityType="vulnerability"
                variant="default"
                width="100%"
                buttonClassname="btn create-ticket-btn"
                menuWidth="100%"
              />
            </Flex>
            <Menu withinPortal position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="filled"
                  className="add-assets-to-product__actions"
                  size="lg"
                >
                  <Icon icon="ellipsis-vertical" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown onClick={onOpenVexModal}>
                <Menu.Label>
                  {t('page.vulnerability.details.meta-data.create-vex-document')}
                </Menu.Label>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Flex direction="column" gap="12px">
          <div className="vuln-meta-details">
            <p className="detail-title">
              {t('page.vulnerability.details.meta-data.severity')}
            </p>
            <p className="vuln-meta-severity detail-text-main">{severity}</p>
          </div>
          <div className="vuln-meta-details">
            <p className="detail-title">
              {t('page.vulnerability.details.meta-data.exploitability')}
            </p>
            <p className="detail-text-main">{formattedExploitability ?? '--'}</p>
          </div>
          <div className="vuln-meta-details">
            <p className="detail-title">
              {t('page.vulnerability.details.meta-data.percentile')}
            </p>
            <p className="detail-text-main">{formattedPercentile ?? '--'}</p>
          </div>
          <div className="vuln-meta-details">
            <p className="detail-title">
              {t('page.vulnerability.details.meta-data.kevlist')}
            </p>
            <p className="detail-text-main">{inKevList}</p>
          </div>
          {formattedDatePublished && (
            <div className="vuln-meta-details">
              <p className="detail-title">
                {t('page.vulnerability.details.meta-data.published')}
              </p>
              <p className="detail-text-main vuln-meta-date">{formattedDatePublished}</p>
            </div>
          )}
          {formattedFirstSeen && (
            <div className="vuln-meta-details">
              <div className="detail-title">
                {t('page.vulnerability.details.meta-data.firstSeen')}
                <Tooltip
                  classNames={{
                    tooltip: styles.tooltip,
                  }}
                  label={t('page.vulnerability.details.meta-data.firstSeen-tooltip')}
                  position="top"
                  withArrow
                >
                  <span className="detail-tooltip">
                    <Icon icon="info-circle" iconSharp />
                  </span>
                </Tooltip>
              </div>
              <p className="detail-text-main vuln-meta-date">{formattedFirstSeen}</p>
            </div>
          )}
        </Flex>
      </main>
      <Flex px="24px" py="16px" className="card vuln-meta-description">
        <Accordion defaultValue="description">
          <Accordion.Item value="description" className="description-accordion">
            <Accordion.Control className="description-btn-header">
              {t('page.vulnerability.details.description')}
            </Accordion.Control>
            <Accordion.Panel>
              <Spoiler maxHeight={100} showLabel="Show more" hideLabel="Hide">
                {hasDescription ? description : '--'}
              </Spoiler>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Flex>
    </Flex>
  );
};
