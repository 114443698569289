/**
 * FontAwesome 6
 * We manually import icons here and add to our library so we're only
 * loading the icons we need.
 *
 * We try to import same style, unless it looks horrible.
 *
 * We render the icons via the <Icon /> component.
 *
 * NOTE: You must add the icon to the library, otherwise <Icon /> will not render it.
 */

// Bring in library
import { library } from '@fortawesome/fontawesome-svg-core';

/**
 * Brands (Free/Pro)
 */
import {
  faApple,
  faDiscord,
  faFacebook,
  faGithub,
  faGithubSquare,
  faGoogle,
  faInstagram,
  faLinkedin,
  faTwitter,
  faAtlassian,
  faDocker,
  faMicrosoft,
  faYandex,
} from '@fortawesome/free-brands-svg-icons';

/**
 * Duotone (Pro)
 */
// import {} from '@fortawesome/pro-duotone-svg-icons';

/**
 * Light (Pro)
 */
import { faClone, faQrcode } from '@fortawesome/pro-light-svg-icons';

/**
 * Regular (Pro)
 */
import {
  // Alphabetized, btw. Keep it that way for easy lookup later :)
  faAbacus,
  faAddressBook,
  faAlien8bit,
  faAnalytics,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDownToBracket,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowToLeft,
  faArrowUpArrowDown,
  faArrowUpFromBracket,
  faArrowUpRightFromSquare,
  faArrowsUpDown,
  faArrowsV,
  faBadgeCheck,
  faBars,
  faBarsSort,
  faBold,
  faBolt,
  faBomb,
  faBook,
  faBookDead,
  faBookHeart,
  faBookOpen,
  faBookReader,
  faBookSpells,
  faBookmark,
  faBooks,
  faBoxHeart,
  faBoxOpenFull,
  faBrakeWarning,
  faBrowser,
  faBrowsers,
  faBuilding,
  faBuildingShield,
  faBullseyePointer,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretUp,
  faChartArea,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleEllipsis,
  faCircleExclamation,
  faCircleInfo,
  faCircleNotch,
  faCircleQuestion,
  faCircleRadiation,
  faCircleUser,
  faCloudDownload,
  faCloudDownloadAlt,
  faCloudXmark,
  faCogs,
  faCommentAltLines,
  faCommentsAlt,
  faCompass,
  faCopy,
  faCube,
  faDatabase,
  faDesktop,
  faDiamond,
  faDownToBracket,
  faDraftingCompass,
  faDungeon,
  faEllipsisH,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faEye,
  faEyeSlash,
  faFeather,
  faFileCertificate,
  faFileMagnifyingGlass,
  faFileShield,
  faFileSignature,
  faFilter,
  faFingerprint,
  faFire,
  faFlaskPotion,
  faFloppyDisk,
  faFluxCapacitor,
  faGear,
  faFolder,
  faGlasses,
  faH1,
  faH2,
  faHandHoldingHeart,
  faHandWave,
  faHeart,
  faHeartCrack,
  faHomeHeart,
  faHourglassClock,
  faHouseChimneyHeart,
  faHouseFlag,
  faItalic,
  faKey,
  faLayerGroup,
  faLifeRing,
  faLightbulbOn,
  faLineColumns,
  faListCheck,
  faListOl,
  faListUl,
  faLock,
  faLockKeyhole,
  faLongArrowUp,
  faMagnifyingGlass,
  faMapLocation,
  faMapPin,
  faMarker,
  faMasksTheater,
  faMerge,
  faMessageLines,
  faMessagesQuestion,
  faMinus,
  faMoneyCheckEdit,
  faMoonCloud,
  faMoonStars,
  faPalette,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPencil,
  faPenFancy,
  faPeopleArrowsLeftRight,
  faPercent,
  faPhoneVolume,
  faPlus,
  faPlusCircle,
  faPrintSearch,
  faQuestion,
  faQuestionCircle,
  faQuoteRight,
  faRotate,
  faSave,
  faSearch,
  faShareNodes,
  faShieldCheck,
  faShieldExclamation,
  faShovelSnow,
  faSignOut,
  faSignOutAlt,
  faSnowman,
  faSnowplow,
  faSortAlt,
  faSparkles,
  faSquareCaretLeft,
  faStar,
  faStopwatch,
  faSun,
  faSunCloud,
  faSunDust,
  faSwatchbook,
  faTachometerAltFast,
  faTag,
  faThumbtack,
  faTimer,
  faTimes,
  faTools,
  faTrash,
  faTrashAlt,
  faTrashCan,
  faTriangle,
  faTriangleExclamation,
  faUnderline,
  faUnicorn,
  faUser,
  faUserAlt,
  faUserCheck,
  faUserCog,
  faUserCowboy,
  faUserGear,
  faUserGroup,
  faUserGroupCrown,
  faUserPlus,
  faUserShield,
  faUserVisor,
  faUserVneck,
  faUsers,
  faUsersCrown,
  faWrench,
  faXmark,
  faAnglesDown,
  faBan,
  faBarsStaggered,
  faCircleXmark,
  faColumns,
  faCompress,
  faEdit,
  faEllipsisV,
  faExpand,
  faFilterCircleXmark,
  faGrip,
  faSearchMinus,
  faSort,
  faSortDown,
  faSortUp,
  faTextWidth,
  faThumbTack,
  faX,
  faArrowDown,
  faArrowUp,
  faRefresh,
  faArrowDownToLine,
  faCalendar,
  faCalendarArrowUp,
  faFiles,
  faToolbox,
  faCompactDisc,
  faFilePdf,
  faRadar,
  faFlask,
  faFlaskVial,
  faCodeBranch,
  faAlbumCollection,
  faHexagonExclamation,
  faFileLines,
  faCirclePlus,
  faBell,
} from '@fortawesome/pro-regular-svg-icons';

/**
 * Sharp Solid (Pro)
 */
// import {} from '@fortawesome/sharp-solid-svg-icons';

/**
 * Solid (Pro)
 */
// import {} from '@fortawesome/sharp-solid-svg-icons';

/**
 * After importing above, add to our library below to make them available.
 */
library.add(
  // Brands
  faFacebook,
  faLinkedin,
  faTwitter,
  faInstagram,
  faDiscord,
  faGoogle,
  faApple,
  faGithub,
  faGithubSquare,
  faAtlassian,
  faMicrosoft,
  faYandex,

  // Regular
  faAbacus,
  faAddressBook,
  faAlien8bit,
  faAnalytics,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDownToBracket,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowToLeft,
  faArrowUpArrowDown,
  faArrowUpFromBracket,
  faArrowUpRightFromSquare,
  faArrowsUpDown,
  faArrowsV,
  faBadgeCheck,
  faBars,
  faBarsSort,
  faBold,
  faBolt,
  faBomb,
  faBook,
  faBookDead,
  faBookHeart,
  faBookOpen,
  faBookReader,
  faBookSpells,
  faBookmark,
  faBooks,
  faBoxHeart,
  faBoxOpenFull,
  faBrakeWarning,
  faBrowser,
  faBrowsers,
  faBuilding,
  faBuildingShield,
  faBullseyePointer,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretUp,
  faChartArea,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleEllipsis,
  faCircleExclamation,
  faCircleInfo,
  faCircleNotch,
  faCircleQuestion,
  faCircleRadiation,
  faCircleUser,
  faClone,
  faCloudDownload,
  faCloudDownloadAlt,
  faCogs,
  faCommentAltLines,
  faCommentsAlt,
  faCompass,
  faCopy,
  faCube,
  faDatabase,
  faDesktop,
  faDiamond,
  faDownToBracket,
  faDraftingCompass,
  faDungeon,
  faEllipsisH,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpenText,
  faEnvelopesBulk,
  faEye,
  faEyeSlash,
  faFeather,
  faFileCertificate,
  faFileLines,
  faFileMagnifyingGlass,
  faFileShield,
  faFileSignature,
  faFilter,
  faFingerprint,
  faFire,
  faFlaskPotion,
  faFloppyDisk,
  faFluxCapacitor,
  faGear,
  faFolder,
  faGlasses,
  faH1,
  faH2,
  faHandHoldingHeart,
  faHandWave,
  faHeart,
  faHeartCrack,
  faHomeHeart,
  faHexagonExclamation,
  faHourglassClock,
  faHouseChimneyHeart,
  faHouseFlag,
  faItalic,
  faKey,
  faLayerGroup,
  faLifeRing,
  faLightbulbOn,
  faLineColumns,
  faListCheck,
  faListOl,
  faListUl,
  faLock,
  faLockKeyhole,
  faLongArrowUp,
  faMagnifyingGlass,
  faMapLocation,
  faMapPin,
  faMarker,
  faMasksTheater,
  faMerge,
  faMessageLines,
  faMessagesQuestion,
  faMinus,
  faMoneyCheckEdit,
  faMoonCloud,
  faMoonStars,
  faPalette,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPencil,
  faPenFancy,
  faPeopleArrowsLeftRight,
  faPercent,
  faPhoneVolume,
  faPlus,
  faPlusCircle,
  faPrintSearch,
  faQrcode,
  faQuestion,
  faQuestionCircle,
  faQuoteRight,
  faRotate,
  faSave,
  faSearch,
  faShareNodes,
  faShieldCheck,
  faShieldExclamation,
  faShovelSnow,
  faSignOut,
  faSignOutAlt,
  faSnowman,
  faSnowplow,
  faSortAlt,
  faSparkles,
  faSquareCaretLeft,
  faStar,
  faStopwatch,
  faSun,
  faSunCloud,
  faSunDust,
  faSwatchbook,
  faTachometerAltFast,
  faTag,
  faThumbtack,
  faTimer,
  faTimes,
  faTools,
  faTrash,
  faTrashAlt,
  faTrashCan,
  faTriangle,
  faTriangleExclamation,
  faUnderline,
  faUnicorn,
  faUser,
  faUserAlt,
  faUserCheck,
  faUserCog,
  faUserCowboy,
  faUserGear,
  faUserGroup,
  faUserGroupCrown,
  faUserPlus,
  faUserShield,
  faUserVisor,
  faUserVneck,
  faUsers,
  faUsersCrown,
  faWrench,
  faXmark,
  faAnglesDown,
  faBan,
  faBarsStaggered,
  faCircleXmark,
  faColumns,
  faCompress,
  faEdit,
  faEllipsisV,
  faExpand,
  faFilterCircleXmark,
  faGrip,
  faSearchMinus,
  faSort,
  faSortDown,
  faSortUp,
  faTextWidth,
  faThumbTack,
  faX,
  faArrowDown,
  faArrowUp,
  faRefresh,
  faArrowDownToLine,
  faCalendar,
  faCalendarArrowUp,
  faFiles,
  faToolbox,
  faDocker,
  faCompactDisc,
  faFilePdf,
  faRadar,
  faFlask,
  faFlaskVial,
  faCodeBranch,
  faMapLocation,
  faCloudXmark,
  faAlbumCollection,
  faFiles,
  faCirclePlus,
  faBell,
);

export default library;
