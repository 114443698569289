import { useParams } from 'react-router-dom';
import { AssetVulnerabilitiesTable } from '../../../components/DataTables/AssetVulnerabilitiesTable/AssetVulnerabilitiesTable';

interface Props {
  activeVulnerabilities: number;
}

export const AssetVulnerabilitiesTab = (props: Props) => {
  const { activeVulnerabilities } = props;
  const { assetId, organizationId } = useParams();
  return (
    <div
      id="asset-vulnerability-content"
      className="asset-vulnerability-content anim-slideInUpShort"
    >
      <AssetVulnerabilitiesTable
        assetId={assetId}
        organizationId={organizationId}
        activeVulnerabilities={activeVulnerabilities}
      />
    </div>
  );
};
