import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  GetSbomAlertReturnValue,
  SbomUploadAlertsApi,
} from '../api/sbomAlerts/sbomAlerts.api';

export const useFetchSbomAlert = (
  alertId?: string,
  options?: {
    refetchOnWindowFocus?: boolean;
  },
): UseQueryResult<GetSbomAlertReturnValue, unknown> =>
  useQuery({
    enabled: Boolean(alertId),
    queryKey: ['sbomUploadAlert', alertId],
    queryFn: () => SbomUploadAlertsApi.getSbomAlert(alertId as string),
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
