import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';
import { RepositoryManifestSettings } from '@manifest-cyber/types/interface/dbIntegration';

interface Params {
  manifestOrganizationId: string;
  githubOrganizationId: string;
  repositoryManifestSettings: Record<string, RepositoryManifestSettings>;
}

export const usePostGitHubSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      manifestOrganizationId,
      githubOrganizationId,
      repositoryManifestSettings,
    }: Params) => {
      return hitApi.post(`integration/github/settings`, true, {
        manifestOrganizationId,
        githubOrganizationId,
        repositoryManifestSettings,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['integration-github']);
    },
  });
};
