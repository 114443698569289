import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import hitApi from '../../api';

/**
 * useDeleteSBOMs mutation (react-query)
 * delete api request to delete sboms, assets, and other related data
 */
export const useDeleteSBOMs = (
  options?: UseMutationOptions<unknown, unknown, string[], unknown>,
) =>
  useMutation({
    mutationFn: (sbomIds) => {
      return hitApi.delete(`sboms/delete`, true, {
        sbomIds,
      });
    },
    ...options,
  });
