import { DateTime } from 'luxon';

interface WithDateElement {
  dateCreated?: string | Date;
  dateModified?: string | Date;
}

export const sortByLasUpdated = <T extends WithDateElement>(list: T[]) => {
  const convertDateToMiliseconds = (date?: string): number =>
    date ? DateTime.fromISO(date).toMillis() : 0;

  return list.sort((first, second) => {
    const firstDate: string | Date = first.dateModified || first.dateCreated || '';
    const secondDate: string | Date = second.dateModified || second.dateCreated || '';

    const firstDateParsed =
      typeof firstDate === 'object'
        ? convertDateToMiliseconds(firstDate.toISOString())
        : convertDateToMiliseconds(firstDate);

    const secondDateParsed =
      typeof secondDate === 'object'
        ? convertDateToMiliseconds(secondDate.toISOString())
        : convertDateToMiliseconds(secondDate);

    if (firstDateParsed > secondDateParsed) return -1;
    if (firstDateParsed < secondDateParsed) return 1;
    return 0;
  });
};
