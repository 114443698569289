import '../../Labels/Labels.scss';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Flex,
  Stack,
  Text,
  Divider,
  Tooltip,
  Title,
  MultiSelect,
  Slider,
  CloseButton,
} from '@mantine/core';
import Icon from '../../Icon/Icon';
import { ProductsFilters, UpdateFilter } from '../../../hooks/products/useProductsFilter';
import { useFetchProductsCVEIDs } from '../../../hooks/products/useFetchProductsCVEIDs';
import { forwardRef } from 'react';
import { useFetchLabels } from '../../../hooks/queries/useFetchLabels';

interface Props {
  filters: ProductsFilters;
  updateFilter: UpdateFilter;
}

export const ProductsTableFilters = ({ filters, updateFilter }: Props) => {
  const { t } = useTranslation();

  const { data: componentCveIds = [] } = useFetchProductsCVEIDs();
  const { data: labelsOptions = [] } = useFetchLabels({});

  const { hasVulns, hasCriticalVulns, recommendation, cveIds, cvssScore, labels } =
    filters;

  return (
    <>
      {/*SUGGESTED*/}
      <Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="0"
        mb="sm"
      >
        {t('tables.products.filters.assets')}
      </Title>
      <Divider my={0} />
      <Stack my="md">
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.labels')}
          placeholder={t('global.select')}
          data={labelsOptions.map((label) => ({
            color: label.color,
            label: label.name!,
            value: label._id?.toString()!,
          }))}
          itemComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                <div className="label-color-container">
                  <div className="label-color" style={{ backgroundColor: color }} />
                </div>
                <div className="label-name">{label}</div>
              </Flex>
            ),
          )}
          valueComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, onRemove, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                <div className="label-color-container">
                  <div className="label-color" style={{ backgroundColor: color }} />
                </div>
                <div className="label-name">{label}</div>
                <CloseButton
                  onMouseDown={onRemove}
                  variant="transparent"
                  size={22}
                  iconSize={14}
                  tabIndex={-1}
                />
              </Flex>
            ),
          )}
          value={labels}
          onChange={(value) => updateFilter('labels', value)}
          rightSection={labels?.length ? null : undefined} //show clear button
        />
        {/*<Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.products.filters.has-vulns')}
            name="has-vulns"
            checked={hasVulns}
            onChange={(event) => {
              updateFilter('hasVulns', event.target.checked);
            }}
          />
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.products.filters.has-critical-vulns')}
            name="has-critical-vulns"
            checked={hasCriticalVulns}
            onChange={(event) => {
              updateFilter('hasCriticalVulns', event.target.checked);
            }}
          />
        </Flex>*/}
      </Stack>
      {/*VULNERABILITIES*/}
      {/*<Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="md"
        mb="sm"
      >
        {t('tables.products.filters.vulnerabilities')}
      </Title>
      <Divider />
      <Stack my="md">
        <Stack>
          <Flex align="center" justify="space-between">
            <Text fw={500} size="sm">
              {t('tables.products.filters.recommendation.title')}
            </Text>
            <Tooltip label={t('tables.products.filters.recommendation-tooltip')}>
              <span className="icon-tooltip-cont">
                <Icon icon="info-circle" />
              </span>
            </Tooltip>
          </Flex>
          <MultiSelect
            clearable
            searchable
            placeholder={t('global.select')}
            data={[
              {
                label: t('tables.products.filters.recommendation.mitigate'),
                value: 'mitigate',
              },
              {
                label: t('tables.products.filters.recommendation.monitor'),
                value: 'monitor',
              },
              {
                label: t('tables.products.filters.recommendation.accept'),
                value: 'accept',
              },
            ]}
            value={recommendation}
            onChange={(value) => updateFilter('recommendation', value)}
            rightSection={recommendation?.length ? null : undefined} //show clear button
          />
        </Stack>

        <Stack>
          <Flex align="center" justify="space-between">
            <Text fw={500} size="sm">
              {t('tables.products.filters.cveIds')}
            </Text>
            <Tooltip label={t('tables.products.filters.cveIds-tooltip')}>
              <span className="icon-tooltip-cont">
                <Icon icon="info-circle" />
              </span>
            </Tooltip>
          </Flex>
          <MultiSelect
            clearable
            searchable
            placeholder={t('global.select')}
            data={componentCveIds}
            value={cveIds}
            onChange={(value) => updateFilter('cveIds', value)}
            rightSection={cveIds?.length ? null : undefined} //show clear button
          />
        </Stack>

        <Stack className="range-slider" py="md">
          <Flex align="center" justify="space-between">
            <Text fw={500} size="sm">
              {t('tables.products.filters.cvssScore')}
            </Text>
            <Tooltip label={t('tables.products.filters.cvssScore-tooltip')}>
              <span className="icon-tooltip-cont">
                <Icon icon="info-circle" />
              </span>
            </Tooltip>
          </Flex>
          <Slider
            min={0}
            max={10}
            defaultValue={cvssScore}
            onChangeEnd={(value) => {
              updateFilter('cvssScore', value);
            }}
            styles={{
              bar: {
                background: 'linear-gradient(to right, #c4c4c4, #ff6a00)',
              },
            }}
          />
          <Text color="gray" size="sm">
            {t('tables.products.filters.cvssScore-value', {
              value: cvssScore,
              severity:
                cvssScore < 4
                  ? t('global.low')
                  : cvssScore < 7
                  ? t('global.medium')
                  : cvssScore < 9
                  ? t('global.high')
                  : t('global.critical'),
            })}
          </Text>
        </Stack>
      </Stack>*/}
    </>
  );
};
