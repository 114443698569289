import { useEffect, useState } from 'react';
import { DEFAULT_LABEL_COLOR, LABEL_COLORS } from './ColorPicker';
import ClickableRegion from '../../../components/ClickableRegion';
import { InterfaceLabel } from '@manifest-cyber/types/interface/dbTables';
import { InterfaceManageableLabel } from './Labels';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import featureFlagDisciminators from '../../../configs/featureFlags';

interface InterfaceUseLabel {
  label: InterfaceManageableLabel;
  updateFn: (name: string, color: string, _id: string) => Promise<void>;
  insertFn: (name: string, color: string) => Promise<void>;
  deleteFn: (_id?: string) => Promise<void>;
  checkExistFn: (name?: string) => boolean;
}

export const useLabel = ({
  label,
  updateFn,
  insertFn,
  deleteFn,
  checkExistFn,
}: InterfaceUseLabel) => {
  const { t } = useTranslation();

  const [name, setName] = useState(label?.name || '');
  const [displayName, setDisplayName] = useState('');
  const [color, setColor] = useState(
    LABEL_COLORS.includes(label?.color || '') ? label?.color || '' : DEFAULT_LABEL_COLOR,
  );
  const [inlineError, setInlineError] = useState<{
    key: string;
    type: 'error' | 'warning';
  } | null>(null);
  const [isEditing, setIsEditing] = useState(label?.isEditing || false);

  useEffect(() => {
    setDisplayName(name.length <= 68 ? name : name.slice(0, 63) + '...');
  }, [name]);

  const handleNameChange = (newName: string) => {
    if (checkExistFn(newName) && newName !== label?.name) {
      setInlineError({
        key: 'exists',
        type: 'error',
      });
    } else if (newName?.length > 199) {
      setInlineError({
        key: 'length',
        type: 'warning',
      });
    } else {
      setInlineError(null);
    }
    setName(newName);
  };
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleSave();
    }
  };
  const handleSave = async () => {
    try {
      if (label?.new) {
        //inserting a new label
        await insertFn(name, color);
      } else {
        if (!label._id) {
          throw new Error(t('page.userSettings.labels.error.missing-id'));
        }
        await updateFn(label._id?.toString(), name, color);
      }
    } catch (e) {
      console.warn(e);
    }

    setIsEditing(false);
  };
  const handleCancel = async () => {
    if (label?.name && label?.color) {
      setName(label.name);
      setColor(label.color);
    }
    if (label?.new) {
      //clicking cancel on a new label before it was saved
      await deleteFn();
    }
    setInlineError(null);
    setIsEditing(false);
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleDeleteClick = () => {
    deleteFn(label._id?.toString());
  };

  const handleUpdateColor = (newColor: string) => {
    setColor(newColor);
  };

  const renderCount = (
    count: number | null | undefined,
    labelId: string | null | undefined,
    translationKeySingle: string,
    translationKeyMultiple: string,
    entity: 'assets' | 'products',
  ) => {
    if (!!count && count > 0) {
      return (
        <ClickableRegion
          className="asset-link"
          href={`/${entity}?labels=${labelId?.toString()}`}
        >
          <span>
            {t(`${count === 1 ? translationKeySingle : translationKeyMultiple}`, {
              includedInCount: count,
            })}
          </span>
        </ClickableRegion>
      );
    }
    return null;
  };

  const renderAssetCount = (includedInCount: InterfaceLabel['includedInCount']) => {
    const count =
      typeof includedInCount === 'number' ? includedInCount : includedInCount?.assets;
    return renderCount(
      count,
      label._id?.toString(),
      'page.userSettings.labels.includedInAssetsSingle',
      'page.userSettings.labels.includedInAssetsMultiple',
      'assets',
    );
  };

  const renderProductCount = (includedInCount: InterfaceLabel['includedInCount']) => {
    const count =
      typeof includedInCount === 'object' && includedInCount !== null
        ? includedInCount.products
        : null;

    return renderCount(
      count,
      label._id?.toString(),
      'page.userSettings.labels.includedInProductsSingle',
      'page.userSettings.labels.includedInProductsMultiple',
      'products',
    );
  };

  const renderLabelCount = () =>
    !!label.includedInCount && (
      <>
        {typeof label.includedInCount === 'number' ? (
          renderAssetCount(label.includedInCount)
        ) : (
          <>
            {renderAssetCount(label.includedInCount)}
            {label.includedInCount.assets != null &&
              label.includedInCount.assets > 0 &&
              label.includedInCount.products != null &&
              label.includedInCount.products > 0 &&
              ' • '}
            {renderProductCount(label.includedInCount)}
          </>
        )}
      </>
    );

  return {
    name,
    displayName,
    color,
    inlineError,
    isEditing,
    handleNameChange,
    handleKeyDown,
    handleSave,
    handleCancel,
    handleEditClick,
    handleDeleteClick,
    handleUpdateColor,
    renderLabelCount,
  };
};
