import { Box, MultiSelect, MultiSelectValueProps, Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../../../../components/ColoredMessage/ColoredMessage';
import { getSeverityLabelKey } from '../../../../../../lib/getSeverityLabelKey';
import { SbomAlertRiskCriteriasFormValues } from '../../../hooks/useSbomAlertsForms';
import {
  RiskCriteriaFilterId,
  SBOMAlertControlsConfig,
} from '../../../utils/getSbomAlertsConfig';
import ChipComponent from '../../ChipComponent/ChipComponent';
import { SliderGradient } from '../../Slider/SliderGradient';
import styles from './RiskCriteriaFilter.module.scss';

const isCvssScore = (
  criteriaId: RiskCriteriaFilterId,
  currentFilter: SBOMAlertControlsConfig['riskCriteriaSection']['controls'][keyof SBOMAlertControlsConfig['riskCriteriaSection']['controls']],
): currentFilter is SBOMAlertControlsConfig['riskCriteriaSection']['controls']['cvssScore'] => {
  return criteriaId === 'cvssScore';
};

const isMinRiskScore = (
  criteriaId: RiskCriteriaFilterId,
  currentFilter: SBOMAlertControlsConfig['riskCriteriaSection']['controls'][keyof SBOMAlertControlsConfig['riskCriteriaSection']['controls']],
): currentFilter is SBOMAlertControlsConfig['riskCriteriaSection']['controls']['minRiskScore'] => {
  return criteriaId === 'minRiskScore';
};

const isVulnRecommendations = (
  criteriaId: RiskCriteriaFilterId,
  currentFilter: SBOMAlertControlsConfig['riskCriteriaSection']['controls'][keyof SBOMAlertControlsConfig['riskCriteriaSection']['controls']],
): currentFilter is SBOMAlertControlsConfig['riskCriteriaSection']['controls']['vulnRecommendations'] => {
  return criteriaId === 'vulnRecommendations';
};

const isLicenseIssues = (
  criteriaId: RiskCriteriaFilterId,
  currentFilter: SBOMAlertControlsConfig['riskCriteriaSection']['controls'][keyof SBOMAlertControlsConfig['riskCriteriaSection']['controls']],
): currentFilter is SBOMAlertControlsConfig['riskCriteriaSection']['controls']['licenseIssues'] => {
  return criteriaId === 'licenseIssues';
};

const MultiselectValue = ({ label, onRemove, className }: MultiSelectValueProps) => {
  return (
    <div className={className}>
      <ChipComponent
        leftIcon={{
          isVisible: false,
        }}
        label={label}
        onClick={onRemove}
      />
    </div>
  );
};

interface RiskCriteriaFilterProps {
  controls: SBOMAlertControlsConfig['riskCriteriaSection']['controls'];
  criteriaId: RiskCriteriaFilterId;
  form: UseFormReturnType<SbomAlertRiskCriteriasFormValues>;
}

function RiskCriteriaFilter({ criteriaId, controls, form }: RiskCriteriaFilterProps) {
  const { t } = useTranslation();
  const currentFilter = controls[criteriaId];
  const error = form.getInputProps(criteriaId).error && (
    <Box mt={8}>
      <ErrorMessage message={form.getInputProps(criteriaId).error} />
    </Box>
  );

  return (
    <>
      {isMinRiskScore(criteriaId, currentFilter) && (
        <Select
          aria-label={currentFilter.optionsLabel}
          data={currentFilter.options}
          {...form.getInputProps(criteriaId)}
          classNames={{
            input: styles.filterText,
          }}
          error={error}
        />
      )}

      {(isVulnRecommendations(criteriaId, currentFilter) ||
        isLicenseIssues(criteriaId, currentFilter)) && (
        <MultiSelect
          aria-label={currentFilter.optionsLabel}
          classNames={{
            input: styles.filterText,
          }}
          data={currentFilter.options}
          valueComponent={MultiselectValue}
          {...{
            ...form.getInputProps(criteriaId),

            onChange: (value) => {
              form.getInputProps(criteriaId).onChange(value);
            },
          }}
          error={error}
        />
      )}

      {isCvssScore(criteriaId, currentFilter) && (
        <SliderGradient
          min={currentFilter.options.min}
          max={currentFilter.options.max}
          step={currentFilter.options.step}
          size="xs"
          {...form.getInputProps(criteriaId)}
          description={
            form.getInputProps(criteriaId).value === 10
              ? `${form.getInputProps(criteriaId).value} (${t(getSeverityLabelKey({ value: form.getInputProps(criteriaId).value }))}) ${t('page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.only')}`
              : t(
                  'page.userSettings.sbomUploadAlerts.riskCriteria.cvssScore.description',
                  {
                    value: `${form.getInputProps(criteriaId).value} (${t(getSeverityLabelKey({ value: form.getInputProps(criteriaId).value }))})`,
                  },
                )
          }
        />
      )}
    </>
  );
}

export default RiskCriteriaFilter;
