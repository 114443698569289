import { useMemo, useState } from 'react';
import { InterfaceFullGithubIntegration } from '../../../types/FullInterfaces';
import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Icon from '../../Icon/Icon';
import ClickableRegion from '../../ClickableRegion';
import {
  Button,
  Group,
  Menu,
  Loader,
  Stack,
  Tooltip,
  Title,
  ActionIcon,
  Badge,
} from '@mantine/core';
import { GitHubOrganizationRemoveConfirmModal } from '../../../pages/UserSettings/integrations/github/components/GitHubOrganizationRemoveConfirmModal';
import { GitHubOrganizationIcon } from '../../../pages/UserSettings/integrations/github/components/GitHubOrganizationIcon';
import styles from './GitHubOrganizationsTable.module.scss';

interface GitHubOrganizationsTableProps {
  gitHubOrganizations: InterfaceFullGithubIntegration[];
  isLoadingGitHubOrganizations: boolean;
  isFetchingGitHubOrganizations: boolean;
}

const columnHelper = createColumnHelper<InterfaceFullGithubIntegration>();

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const GitHubOrganizationsTable = ({
  gitHubOrganizations,
  isLoadingGitHubOrganizations,
  isFetchingGitHubOrganizations,
}: GitHubOrganizationsTableProps) => {
  const { t } = useTranslation();
  const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);
  const [fetchedGitHubIntegration, setFetchedGitHubIntegration] = useState<
    InterfaceFullGithubIntegration | undefined
  >(undefined);

  const columns = useMemo(
    () => [
      columnHelper.accessor('githubIntegration', {
        header: () => <span></span>,
        cell: ({ row }) => {
          const original = row.original;
          const numRepos = original.githubIntegration[0].repositories?.length;
          const installedByEmail = original.installedByEmail;
          const installedAt = original.installedAt
            ? new Date(original.installedAt).toLocaleDateString(
                undefined,
                dateFormatOptions,
              )
            : '';
          const href = `/settings/integrations/github/organization/${original.installationId?.toString()}/repositories`;

          return (
            <section className="github-organization-row">
              <div className="github-organization-cell">
                <div className="github-organization">
                  <Group position="apart">
                    <Group>
                      <GitHubOrganizationIcon iconWidth="50" iconHeight="50" />
                      <Stack spacing="xs">
                        <Group spacing="xs">
                          <ClickableRegion href={href}>
                            <h2 className="github-organization-header">
                              {
                                original.githubIntegration[0]
                                  .githubOrganizationDisplayName
                              }
                            </h2>
                          </ClickableRegion>
                          {original.githubIntegration[0].installMetadata?.targetType ===
                            'User' && (
                            <div>
                              <Tooltip
                                label={t(
                                  'page.userSettings.integrations.github.organization.personal-account',
                                )}
                              >
                                <ActionIcon color="orange.4" variant="transparent">
                                  <Icon icon="circle-exclamation" />
                                </ActionIcon>
                              </Tooltip>
                            </div>
                          )}
                          {original.status === 'suspended' && (
                            <Tooltip
                              label={t(
                                'page.userSettings.integrations.github.organization.suspended-tooltip',
                              )}
                            >
                              <Badge className={styles.suspended}>
                                {t(
                                  'page.userSettings.integrations.github.organization.suspended',
                                )}
                              </Badge>
                            </Tooltip>
                          )}
                        </Group>
                        <div className="github-organization-metadata">
                          <span>
                            {t('page.userSettings.integrations.github.repos', {
                              count: numRepos,
                            })}{' '}
                            &#183;{' '}
                          </span>
                          {original.installedAt ? (
                            <span>{`${t('page.userSettings.integrations.github.added-on')} ${installedAt} by ${installedByEmail}`}</span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </Stack>
                    </Group>
                    <Menu position="bottom-end">
                      <Menu.Target>
                        <Button className="manifest-menu__target" variant="default">
                          <Icon
                            icon="ellipsis-vertical"
                            style={{ display: 'flex' }}
                            aria-label={t('action.moreOptions')}
                          />
                        </Button>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          className="manifest-menu__item"
                          onClick={() => {
                            setFetchedGitHubIntegration(original);
                            setRemoveConfirmModalOpen(true);
                          }}
                        >
                          <span>
                            {t(
                              'page.userSettings.integrations.github.remove-organization',
                            )}
                          </span>
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Group>
                </div>
              </div>
            </section>
          );
        },
      }),
    ],
    [gitHubOrganizations],
  );

  const table = useReactTable({
    columns: columns,
    data: gitHubOrganizations,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (originalRow) => originalRow._id?.toString() || '',
  });

  return (
    <div className="anim-slideInUpShort">
      <hr />
      <div className="github-integration-edit-organization-header">
        <h4>{t('page.userSettings.integrations.github.all-organizations')}</h4>
      </div>
      {isLoadingGitHubOrganizations ? (
        <Loader />
      ) : (
        <>
          <div className="organization-table">
            {table.getHeaderGroups().map((headerGroup) => (
              <div className="organization-row">
                {headerGroup.headers.map((header) => (
                  <div className="organization-head-cell">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                ))}
              </div>
            ))}
            {table.getRowModel().rows.map((row) => (
              <div key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <div key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
      <GitHubOrganizationRemoveConfirmModal
        open={removeConfirmModalOpen}
        onClose={() => setRemoveConfirmModalOpen(false)}
        installationId={fetchedGitHubIntegration?.installationId?.toString()}
        organizationId={fetchedGitHubIntegration?.organizationId?.toString()}
      />
    </div>
  );
};
