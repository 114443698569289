import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Button, Divider } from '@mantine/core';
import hitApi from '../../../api';
import ClickableRegion from '../../../components/ClickableRegion';
import Icon from '../../../components/Icon';
import { useFetchIntegrations } from '../../../hooks/queries/useFetchIntegrations';
import { IIntegration } from './integrations-data';

interface RemoveIntegrationProps {
  integrationInfo: IIntegration;
}

export const RemoveIntegration = ({ integrationInfo }: RemoveIntegrationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: integrations, isLoading: isLoadingIntegrations } = useFetchIntegrations();

  useEffect(() => {
    if (isLoadingIntegrations) return;
    if (!integrations || integrations?.length === 0) {
      return navigate('/settings/integrations', { replace: true });
    }
  }, [isLoadingIntegrations]);

  const handleUnlink = async () => {
    try {
      await hitApi.delete('/integration/merge/link', true, {
        integration: integrationInfo.integrationType,
      });
      return (window.location.href = '/settings/integrations');
    } catch (e) {
      console.error(e);
      return '';
    }
  };
  return (
    <div className="integration-install-page anim-slideInDownShort">
      <ClickableRegion href="/settings/integrations">
        <ActionIcon size="xl">
          <Icon icon="arrow-left" />
        </ActionIcon>
      </ClickableRegion>
      <div className="integration-page-header">
        {integrationInfo.icon && (
          <Icon icon={integrationInfo.icon} iconStyle="fab" size="5x" />
        )}
        {integrationInfo.logoSrc && (
          <div className="logo">
            <img src={integrationInfo.logoSrc} />
          </div>
        )}
      </div>
      <Divider my="lg" />
      <div className="integration-page-content">
        <h5>{t('page.userSettings.integrations.confirmUnlinkAccount')}</h5>
        <div className="actions">
          <Button size="sm" variant="filled" onClick={handleUnlink}>
            {t('global.confirm')}
          </Button>
          <ClickableRegion href="/settings/integrations">
            <Button variant="default" size="sm">
              {t('global.cancel')}
            </Button>
          </ClickableRegion>
        </div>
      </div>
    </div>
  );
};
