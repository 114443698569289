import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

interface Params {
  labels: Record<string, string[]>;
}

export const usePutProductLabels = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ labels }: Params) => {
      return hitApi.put(`product/updateLabels`, true, {
        labels,
      });
    },
    onSuccess: (_data, { labels }) => {
      Object.keys(labels).forEach((productId) => {
        queryClient.invalidateQueries({
          queryKey: ['product', `/product/${productId}/overview`],
        });
      });
      queryClient.invalidateQueries({
        queryKey: ['products'],
      });
      queryClient.invalidateQueries({
        queryKey: ['labels'],
      });
    },
  });
};
