import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

interface FontAwesomeIconProps {
  faIcon: IconDefinition;
  style?: Style;
  fill?: string | null;
}

/**
 * Meant for PDF exports with react-pdf/renderer.
 * Provide a FontAwesome icon and it will return a PDF-compatible SVG.
 * @param param0
 * @returns
 */
const IconForPdf = ({
  faIcon: { icon },
  style = {},
  fill = null,
}: FontAwesomeIconProps) => {
  const duotone = Array.isArray(icon[4]);
  const paths = Array.isArray(icon[4]) ? icon[4] : [icon[4]];
  const color = fill || style.color || 'black';
  return (
    <Svg viewBox={`0 0 ${icon[0]} ${icon[1]}`} style={style}>
      {paths &&
        paths.map((d, index) => (
          <Path
            d={d}
            key={index}
            fill={color}
            fillOpacity={duotone && index === 0 ? 0.4 : 1.0}
          />
        ))}
    </Svg>
  );
};

export default IconForPdf;
