import { useState, useEffect } from 'react';
import { Modal, InputGroup, Input } from 'rsuite';
import { Button, Title } from '@mantine/core';
import { ReactMultiEmail } from 'react-multi-email';
import Icon from '../../../components/Icon';
import hitApi from '../../../api';
import { DateTime } from 'luxon';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import styles from './ShareModal.module.scss';

interface ShareModalProps {
  open: boolean;
  triggeredByMerge: boolean;
  sbomIds: string[];
  onClose: () => void;
}
function ShareModal({ open, sbomIds, onClose, triggeredByMerge }: ShareModalProps) {
  const { t } = useTranslation();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shared, setShared] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [linkExpirationDate, setLinkExpirationDate] = useState('');
  const [linkLoading, setLinkLoading] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [shareBtnLoading, setShareBtnLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setShareModalOpen(true);
    } else {
      setShareModalOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setEmails([]);
    setShareLink('');
    setLinkLoading(false);
    setTextCopied(false);
    setShareBtnLoading(false);
    setShared(false);
  }, [sbomIds]);

  useEffect(() => {
    setShared(false);
  }, [emails]);

  const getShareLink = async () => {
    try {
      setLinkLoading(true);
      const { success, data, errors } = await hitApi.get(
        `sboms/share?sbomIds=${sbomIds.join(',')}&includeShareLink=true`,
      );
      if (!success) throw new Error(errors);
      const {
        genericShareLink: { link, expires },
      } = data;
      setShareLink(link);
      setLinkExpirationDate(
        DateTime.fromISO(expires).toLocaleString(DateTime.DATETIME_MED),
      );
      setLinkLoading(false);
    } catch (e) {
      setShareLink('');
      setLinkLoading(false);
    }
  };

  const handleShareClick = async () => {
    try {
      setShareBtnLoading(true);
      const shareAllTheThings = await hitApi.get(
        `sboms/share?sbomIds=${sbomIds.join(',')}&emails=${emails.join(',')}`,
      );
      if (!shareAllTheThings || !shareAllTheThings?.success) {
        setErrors([...errors, t('page.uploads.errors.sharing-failure')]);
      }
      setShareBtnLoading(false);
      setShared(true);
    } catch (e) {
      setShareBtnLoading(false);
    }
  };

  const handleCopyClick = () => {
    try {
      copy(shareLink);
      setTextCopied(true);
    } catch (e) {
      setTextCopied(false);
    }
  };

  return (
    <Modal
      open={shareModalOpen}
      onClose={onClose}
      className="share-modal"
      // onEntered={shareSboms}
      // onExited={() => {
      //   setShareLink('');
      // }}
    >
      <Modal.Header>
        <Modal.Title>
          {triggeredByMerge
            ? `${t(`page.uploads.shareModal.headerMergeAndShare`)}`
            : `${t(`page.uploads.shareModal.header`)}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errors && errors.length > 0 && (
          <ul className="page-errors anim-slideInUpShort">
            {errors.map((error) => (
              <li key="error">{error}</li>
            ))}
          </ul>
        )}

        <span className={styles.subHeader}>
          {triggeredByMerge
            ? t('page.uploads.shareModal.subHeaderMergeAndShare')
            : t(`page.uploads.shareModal.subHeader`)}
        </span>
        <div className="manifest-react-multi-email">
          <Title className={styles.emailRecipientsTitle}>
            {t('page.uploads.requestSbomModal.enterEmailsTitle')}
          </Title>
          <ReactMultiEmail
            placeholder="Add emails of recipients"
            emails={emails}
            onChange={(_emails: string[]) => {
              setEmails(_emails);
            }}
            autoFocus={true}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
        </div>
        <Button
          fullWidth
          loading={shareBtnLoading}
          disabled={shareBtnLoading || shared || emails.length === 0}
          onClick={() => {
            handleShareClick();
          }}
        >
          <>
            {shared ? (
              <>
                <Icon icon="check-circle" />
                <span>
                  <strong>{t(`page.uploads.shareModal.emailSuccessStrongText`)}</strong>
                </span>
              </>
            ) : (
              <>
                <span>{t(`page.uploads.shareModal.emailShareBtnText`)}</span>
              </>
            )}
          </>
        </Button>

        <hr className="share-section-split" />
        <div className="share-link-generation-container">
          <div className="share-link-generation-item">
            <span className={styles.shareALink}>
              {t(`page.uploads.shareModal.linkShareHeader`)}
            </span>
            <p className="share-link-help-text">
              {t(`page.uploads.shareModal.linkShareSubHeader`)}
            </p>
          </div>
          {shareLink === '' && (
            <div className="share-link-generation-item">
              <Button
                variant="default"
                onClick={() => {
                  getShareLink();
                }}
                disabled={linkLoading}
                loading={linkLoading}
              >
                {t(`page.uploads.shareModal.linkShareBtnText`)}
              </Button>
            </div>
          )}
          <div className="share-link-generation-item break" />
          {shareLink !== '' && (
            <div className="share-link-generation-item share-link-container">
              <InputGroup className="link-container">
                <Input className="share-link" disabled value={shareLink} />
                <InputGroup.Button
                  className="btn-copy"
                  onClick={() => {
                    handleCopyClick();
                  }}
                >
                  {textCopied ? (
                    <>
                      <Icon icon="check-double" />
                      {t(`page.uploads.shareModal.linkCopyBtnTextCopied`)}
                    </>
                  ) : (
                    <>
                      <Icon icon="copy" />
                      {t(`page.uploads.shareModal.linkCopyBtnText`)}
                    </>
                  )}
                </InputGroup.Button>
              </InputGroup>
              <p className="expiration-help-text">
                {t(`page.uploads.shareModal.linkExpirationLabel`)}
                {linkExpirationDate} ET
              </p>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ShareModal;
