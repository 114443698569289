import { Box, Loader, TextInput, TextInputProps } from '@mantine/core';
import { GetInputProps } from '@mantine/form/lib/types';
import clsx from 'clsx';
import { ReactNode } from 'react';
import ColoredMessage from '../ColoredMessage/ColoredMessage';
import styles from './ManifestTextInput.module.scss';

interface ManifestTextInputProps
  extends Omit<TextInputProps, 'classNames'>,
    Omit<React.RefAttributes<HTMLInputElement>, 'styles'> {
  inputProps: ReturnType<GetInputProps<{}>>;
  isLoading?: boolean;
  placeholder?: string;
  label?: ReactNode;
  inputClasName?: string;
}

export const ManifestTextInput = ({
  isLoading,
  placeholder,
  label,
  inputProps,
  className,
  inputClasName,
  withAsterisk,
  ...rest
}: ManifestTextInputProps) => {
  return (
    <TextInput
      {...rest}
      {...inputProps}
      classNames={{
        input: clsx(styles.input, inputClasName),
        wrapper: styles.wrapper,
        root: className,
        description: styles.description,
      }}
      label={label}
      withAsterisk={withAsterisk}
      placeholder={placeholder}
      rightSection={isLoading && <Loader size="sm" className={styles.loader} />}
      error={
        inputProps.error ? (
          <Box mt={8}>
            <ColoredMessage message={inputProps.error} />
          </Box>
        ) : undefined
      }
    />
  );
};
