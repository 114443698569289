import { Configuration, FrontendApi } from '@ory/client';
import appConfig from '../configs/appConfig';

export const sdk = new FrontendApi(
  new Configuration({
    basePath: appConfig.orySDKUrl || 'https://auth.manifestcyber.com',
    baseOptions: {
      withCredentials: true,
    },
  }),
);
