import { MappedUsersWithAccessToSharedSbom } from '../api/shareResource/shareResource.api';

export const SHARED_ELEMENTS_USERS_WITH_ACCESS_STUB: MappedUsersWithAccessToSharedSbom[] =
  [
    {
      avatarLetter: 'J',
      dateSharedText: 'March 17, 2024',
      email: 'jackson.graham@example.com',
    },
    {
      avatarLetter: 'M',
      dateSharedText: 'March 19, 2024',
      email: 'mike.graham@example.com',
    },
    {
      avatarLetter: 'D',
      dateSharedText: 'March 27, 2024',
      email: 'daniel.graham@example.com',
    },
  ];
