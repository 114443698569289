import Icon from '../../../components/Icon/Icon';
import '../../../scss/pages/labels.scss';

interface IColorPickerProps {
  currentColor: string;
  updateColorFn: (newColor: string) => void;
}
export const LABEL_COLORS = [
  '#965294',
  '#7D65EE',
  '#163F59',
  '#2563D8',
  '#1EBCE2',
  '#12750E',
  '#4EB969',
  '#FCECA3',
  '#F3A93C',
  '#ED765D',
  '#E889AB',
  '#CE423A',
  '#CC3663',
  '#EEF2FE',
  '#878E95',
];
export const DEFAULT_LABEL_COLOR = LABEL_COLORS[0];
function ColorPicker({ currentColor, updateColorFn }: IColorPickerProps) {
  return (
    <div className="colorpicker-container">
      {LABEL_COLORS.map((labelColor, idx) => {
        return (
          <div
            key={idx}
            className="color"
            style={{ backgroundColor: labelColor }}
            onClick={() => {
              updateColorFn(labelColor);
            }}
          >
            {labelColor === currentColor && <Icon icon="check" />}
          </div>
        );
      })}
    </div>
  );
}

export default ColorPicker;
