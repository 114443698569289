import { useTranslation } from 'react-i18next';
import '../../../scss/pages/profile.scss';
import { Select, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';

export const Profile = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageChange = (value: string) => {
    setLanguage(value);
    i18n.changeLanguage(value).catch((error) => {
      console.error('Error changing language:', error);
    });
  };

  useEffect(() => {
    const handleLanguageChanged = (language: string) => setLanguage(language);

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n]);

  return (
    <div className="page-profile">
      <div className="header">{t('page.userSettings.profile.header')}</div>
      <div className="divider" />
      <Stack spacing="sm">
        <form>
          <Text id="language-label">{t('page.userSettings.profile.language')}</Text>
          <Select
            aria-labelledby="language-label"
            placeholder={t('page.userSettings.profile.pick-one')}
            data={[
              { value: 'en-US', label: '🇺🇸 English' },
              { value: 'it', label: '🇮🇹 Italian' },
              { value: 'ja', label: '🇯🇵 日本語' },
            ]}
            required
            value={language}
            onChange={handleLanguageChange}
          />
        </form>
      </Stack>
    </div>
  );
};
