import { Box, Loader, TextInput } from '@mantine/core';
import { GetInputProps } from '@mantine/form/lib/types';
import ColoredMessage from '../../../../../components/ColoredMessage/ColoredMessage';
import styles from './AlertNameInput.module.scss';

export const AlertNameInput = ({
  showLoader,
  placeholder,
  label,
  inputProps,
}: {
  inputProps: ReturnType<GetInputProps<{}>>;
  showLoader: boolean;
  placeholder?: string;
  label: string;
}) => {
  return (
    <TextInput
      {...inputProps}
      classNames={{
        input: styles.inputText,
        label: styles.label,
        wrapper: styles.inputRoot,
      }}
      label={label}
      withAsterisk
      placeholder={placeholder}
      rightSection={showLoader && <Loader size="sm" className={styles.loader} />}
      error={
        inputProps.error ? (
          <Box mt={8}>
            <ColoredMessage message={inputProps.error} />
          </Box>
        ) : undefined
      }
      maxLength={100}
    />
  );
};
