import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { bool, string } from 'prop-types';
import { CSSProperties } from 'react';
import appConfig from '../../configs/appConfig';
import './Icon.scss';
const { faIconStyle } = appConfig;

// Get our custom icons
// import iconSprite from '../../../public/gb-dash-icons-sprite.svg';
const iconSprite = '';

// Available custom icons
// e.g. for specific icons we designed instead of using FA
const customIcons: any[] = ['okta', 'auth0'];

const sharpStyles: Record<string, string> = {
  fas: 'fass',
};

export interface IconProps extends FontAwesomeIconProps {
  icon: IconName;
  iconSharp?: boolean;
  fixedWidth?: boolean;
  iconStyle?: string;
  classNames?: string;
  className?: string;
  label?: string;
  style?: CSSProperties;
  onClick?: () => void;
  spin?: boolean;
}

/**
 * Wraps FontAwesomeIcon and custom icons so that we can specify app-wide defaults (e.g. iconStyle, etc)
 * If adding a custom icon, make sure to run `generate-custom-icons` in package.json to generate a sprite and add to `customIcons` array above
 */
export const Icon = ({
  classNames = '',
  fixedWidth = true,
  iconStyle = faIconStyle,
  iconSharp = true,
  label = '',
  icon,
  className,
  ...rest
}: IconProps) => {
  let _iconStyle: any = iconStyle;
  if (!iconStyle) _iconStyle = faIconStyle;
  if (iconSharp && Object.keys(sharpStyles).includes(iconStyle as string)) {
    _iconStyle = sharpStyles[iconStyle as string];
  }

  if (!icon || icon.length < 1) return null;

  if (iconStyle === 'manifest') {
    if (!customIcons.includes(icon)) {
      console.error(`Custom Dash "${icon}" does not exist.`);
      return null;
    }

    if (customIcons.length > 0) {
      return (
        <svg
          className={`manifest-icon ${classNames ?? className}`}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
        >
          <use xlinkHref={`${iconSprite}#${icon}`} />
        </svg>
      );
    }

    return null;
  }

  // Account for add'l classes, etc
  const concatIconStyles = [];
  concatIconStyles.push(iconStyle);
  concatIconStyles.push(icon);

  if (label) {
    return (
      <div className="icon-w-label">
        <FontAwesomeIcon
          className={`fa-icon manifest-icon ${classNames ?? className}`}
          fixedWidth={fixedWidth}
          icon={[_iconStyle, icon]}
          {...rest}
        />
        <div className="label">{label}</div>
      </div>
    );
  }

  return (
    <FontAwesomeIcon
      className={`fa-icon manifest-icon ${classNames ?? className}`}
      fixedWidth={fixedWidth}
      icon={[_iconStyle, icon]}
      {...rest}
    />
  );
};

Icon.propTypes = {
  /**
   * Name of icon to use
   */
  icon: string.isRequired,
  /**
   * Optionally append classNames to custom icon
   */
  classNames: string,
  /**
   * Force fixed-width (default true)
   */
  fixedWidth: bool,
  /**
   * Icon style: hp, fal, fas, etc (defaults to `faIconStyle` in appConfig)
   */
  iconStyle: string,
  iconSharp: bool,
  label: string,
};

export default Icon;
