import { Flex } from '@mantine/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import Icon from '../Icon';
import styles from './MessageWithBackground.module.scss';
import { IconName } from '@fortawesome/fontawesome-svg-core';

interface Props {
  message: ReactNode;
  messageType: 'critical' | 'warning';
}

const MessageWithBackground = ({ message, messageType }: Props) => {
  const messageColorStyles =
    messageType === 'critical'
      ? styles.containerWithBackgroundCritical
      : styles.containerWithBackgroundMedium;
  const iconName: IconName =
    messageType === 'critical' ? 'triangle-exclamation' : 'circle-exclamation';
  return (
    <Flex className={clsx(styles.container, messageColorStyles)}>
      <Icon icon={iconName} size="sm" />
      <span className={clsx(styles.textWithBackground)}>{message}</span>
    </Flex>
  );
};

export default MessageWithBackground;
