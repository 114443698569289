interface IsUniqueStringParams {
  isCaseSensitive: boolean;
  list: string[];
  query: string;
}

export const isUniqueString = ({
  list,
  query,
  isCaseSensitive,
}: IsUniqueStringParams): boolean => {
  const normalizedQuery = isCaseSensitive ? query : query.toLowerCase();
  const seenStrings = new Set<string>();

  for (const item of list) {
    const normalizedItem = isCaseSensitive ? item : item.toLowerCase();
    if (seenStrings.has(normalizedItem)) {
      continue;
    }
    if (normalizedItem === normalizedQuery) {
      return false;
    }
    seenStrings.add(normalizedItem);
  }

  return true;
};
