import { Box, Flex, Skeleton } from '@mantine/core';
import styles from './AlertMetadata.module.scss';

export const AlertMetadata = ({
  leftText,
  rigthText,
  isLoading = false,
}: {
  rigthText: string;
  leftText: string;
  isLoading?: boolean;
}) => (
  <Flex gap={8}>
    <Skeleton visible={isLoading} className={styles.alertPropertyContainer} height={16}>
      <span className={styles.alertPropertyText}>{rigthText}</span>
    </Skeleton>

    <Skeleton visible={isLoading} className={styles.alertPropertyContainer} height={16}>
      <Flex gap={8}>
        <Box className={styles.alertPropertyText} mx={8}>
          &bull;
        </Box>
        <span className={styles.alertPropertyText}>{leftText}</span>
      </Flex>
    </Skeleton>
  </Flex>
);
