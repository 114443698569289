import { DateTime } from 'luxon';

export const isDateAfter = (firstDate?: string, secondDate?: string): boolean => {
  const firstDateFormatted = firstDate ? DateTime.fromISO(firstDate) : null;
  const secondDateFormatted = secondDate ? DateTime.fromISO(secondDate) : null;

  return Boolean(
    firstDateFormatted && secondDateFormatted && firstDateFormatted > secondDateFormatted,
  );
};
