import { v4 as uuidv4 } from 'uuid';
import {
  SbomAlertCreationAction,
  SbomAlertCreationState,
} from './alertCreateReducer.interface';

const creationInitialState: SbomAlertCreationState = {
  source: {
    state: 'edit',
  },
  riskCriteria: {
    state: 'closed',
    criterias: [{ id: `selector_${uuidv4()}` }],
  },
  deliveryMethod: {
    state: 'closed',
  },
};

const detailInitialState: SbomAlertCreationState = {
  source: {
    state: 'view',
  },
  riskCriteria: {
    state: 'view',
    criterias: [],
  },
  deliveryMethod: {
    state: 'view',
  },
};

const editInitialState: SbomAlertCreationState = {
  source: {
    state: 'edit',
  },
  riskCriteria: {
    state: 'edit',
    criterias: [],
  },
  deliveryMethod: {
    state: 'edit',
  },
};

export const getInitialState = (pageType: 'view' | 'edit' | 'create') => {
  switch (pageType) {
    case 'view':
      return detailInitialState;
    case 'edit':
      return editInitialState;
    case 'create':
      return creationInitialState;
    default:
      return creationInitialState;
  }
};

export const alertCreateReducer = (
  initialState: SbomAlertCreationState,
  action: SbomAlertCreationAction,
): SbomAlertCreationState => {
  const state: SbomAlertCreationState = JSON.parse(JSON.stringify(initialState));

  switch (action.type) {
    case 'activateRiskSection':
      state.source.state = 'view';
      state.riskCriteria.state = 'edit';
      state.deliveryMethod.state = 'closed';
      break;
    case 'activateDeliveryMethodSection':
      state.source.state = 'view';
      state.riskCriteria.state = 'view';
      state.deliveryMethod.state = 'edit';
      break;
    case 'enableEditionInSourceSection':
      state.source.state = 'edit';
      state.riskCriteria.state = 'view';
      state.deliveryMethod.state = 'view';
      break;
    case 'enableEditionInRiskSection':
      state.source.state = 'view';
      state.riskCriteria.state = 'edit';
      state.deliveryMethod.state = 'view';

      break;
    case 'enableEditionInDeliveryMethodSection':
      state.source.state = 'view';
      state.riskCriteria.state = 'view';
      state.deliveryMethod.state = 'edit';
      break;
    case 'addRiskCriteria':
      state.riskCriteria.criterias.push({ id: `selector_${uuidv4()}` });
      break;
    case 'removeRiskCriteria':
      state.riskCriteria.criterias = state.riskCriteria.criterias.filter(
        (criteria) => criteria.id !== action.payload.id,
      );
      break;
    case 'setRiskCriteriaVisibleFilters':
      state.riskCriteria.criterias = action.payload.filters;
      break;
    default:
      return state;
  }
  return state;
};
