import { AssetCountVulnerabilities } from '@manifest-cyber/types/interface/db';
import { Tooltip } from '@mantine/core';
import { Types } from 'mongoose';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useFetchLabels } from '../../hooks/queries/useFetchLabels';
import Icon from '../Icon/Icon';
import { ManifestTag } from '../ManifestTag/ManifestTag';
import './Labels.scss';

interface ILabelsProps {
  entity: {
    labelIds?: string[] | Types.ObjectId[] | null;
    countVulnerabilities?: AssetCountVulnerabilities;
  };
  condensed?: boolean;
  justifyContent?: string;
  onEditButtonClick?: () => void;
  onLabelClick?: (label: any) => void;
  showWarnings?: boolean;
  rounded?: boolean;
  inactive?: boolean;
}
interface Label {
  _id: string;
  name: string;
  displayName: string;
  color: string | string[];
}

function Labels({
  entity,
  condensed,
  justifyContent = 'flex-start',
  onEditButtonClick,
  onLabelClick,
  showWarnings = true,
  rounded,
  inactive = false,
}: ILabelsProps) {
  const { t } = useTranslation();

  const labelIds = useMemo(
    () => entity?.labelIds?.map((l) => l.toString()) || [],
    [entity],
  );

  const { data: fetchedLabels = [] } = useFetchLabels(
    { labelIds: labelIds },
    { enabled: !!labelIds?.length },
  );

  const parsedLabels = useMemo(() => {
    let labels: any[] = fetchedLabels?.map((fetchedLabel: any) => {
      const { _id, name, color } = fetchedLabel;
      const displayName = name.length <= 10 ? name : name.slice(0, 9) + '...';
      const label: Label = {
        _id,
        name,
        displayName,
        color,
      };
      return label;
    });

    if (condensed) {
      if (labels.length <= 2) {
        return labels;
      } else {
        const colors = [labels[1].color, labels[2].color];
        if (labels.length > 3) {
          colors.push(labels[3].color);
        }
        const labelSummary = {
          _id: uuidv4(),
          name: labels
            .map((l: any) => l.name)
            .slice(1)
            .join('\r\n'),
          displayName: `+${labels.length - 1} ${t('global.labels').toLocaleLowerCase()}`,
          color: colors,
          isSummary: true,
        };
        labels = [labels[0], labelSummary];
      }
    }
    return labels;
  }, [fetchedLabels]);

  return (
    <div
      className={`labels-container ${inactive ? 'isInactive' : null}`}
      style={{ justifyContent }}
    >
      {showWarnings &&
        (parseInt(`${entity?.countVulnerabilities?.inKEV}`) > 0 ? (
          <span className="kev-badger-wrapper">
            <ManifestTag variant="kev" />
          </span>
        ) : parseInt(`${entity?.countVulnerabilities?.inKEVPreviously}`) > 0 ? (
          <span className="kev-badger-wrapper">
            <ManifestTag variant="prev-kev" />
          </span>
        ) : null)}
      {parsedLabels.map((label: any) => {
        return (
          <Tooltip
            multiline
            key={label._id}
            disabled={label.displayName === label.name}
            label={<pre>{label.name}</pre>}
          >
            <div
              onClick={() => !label.isSummary && onLabelClick?.(label)}
              style={{ cursor: !label.isSummary && onLabelClick ? 'pointer' : undefined }}
              className={`label-container${rounded ? '--rounded' : ''}`}
            >
              <div className="label-color-container">
                {Array.isArray(label.color) ? (
                  <div className="multi-color-container">
                    {label.color.map((color: string, idx: number) => {
                      return (
                        <div
                          key={idx}
                          className={`label-color ${idx > 0 ? ' overlap' : ''}`}
                          style={{ backgroundColor: color }}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="label-color" style={{ backgroundColor: label.color }} />
                )}
              </div>
              <div className="label-name">{label.displayName}</div>
            </div>
          </Tooltip>
        );
      })}
      {/* {parsedLabels.length === 0 && (
        <span className='no-labels'>
          {t('jargon.no-labels')}
        </span>
      )} */}
      {onEditButtonClick && (
        <Tooltip label={t('labels.editButtonTooltip.editLabels')}>
          <div className="edit-button-container">
            <Icon icon="pen" onClick={onEditButtonClick} />
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default Labels;
