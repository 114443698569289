import { InterfaceOrganization } from '@manifest-cyber/types/interface/dbTables';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';

export const usePostOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organization,
      isChild = false,
    }: {
      organization: InterfaceOrganization;
      isChild: boolean;
    }) => {
      if (isChild) {
        return hitApi.post(`organization/${organization?._id}`, true, {
          name: organization?.name,
        });
      }

      return hitApi.post(`organization/`, true, {
        ...organization,
      });
    },
    onSuccess: () => {
      // Invalidate orgs
      queryClient.invalidateQueries(['currentOrganization']);
      queryClient.invalidateQueries(['childOrganizations']);
    },
  });
};
