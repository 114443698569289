import { Button, Checkbox, Flex, Modal, Tooltip } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostLabel } from '../../hooks/labels/usePostLabel';
import { useFetchLabels } from '../../hooks/queries/useFetchLabels';
import { DEFAULT_LABEL_COLOR } from '../../pages/UserSettings/Labels/ColorPicker';
import BasicSearch from '../BasicSearch';
import Icon from '../Icon';
import Loading from '../Loading';
import './Labels.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  labelIds: Record<string, string[]>;
  putLabels: (data: any) => Promise<void>;
  entityType: 'asset' | 'product';
}
interface Label {
  _id: string;
  name: string;
  displayName: string;
  color: string;
}

function ManageLabelsModal({ open, onClose, labelIds, putLabels, entityType }: Props) {
  const { t } = useTranslation();
  const [newLabelName, setNewLabelName] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const [labelsToApply, setLabelsToApply] = useState<Set<string>>(new Set());
  const [labelsToRemove, setLabelsToRemove] = useState<Set<string>>(new Set());

  const entityIds = useMemo(() => Object.keys(labelIds), [labelIds]);

  const queryClient = useQueryClient();

  const {
    data: labels = [],
    isLoading: isLoadingLabels,
    isError: isErrorFetchingLabels,
  } = useFetchLabels({});
  const {
    mutateAsync: postLabel,
    isLoading: isCreatingLabels,
    isError: isErrorCreatingLabel,
  } = usePostLabel();

  const parsedLabels = useMemo(
    () =>
      labels?.map(({ _id, name, color }) => {
        const displayName =
          !!name && name.length <= 40 ? name : `${name?.slice(0, 39)}...`;
        return { _id, name, displayName, color };
      }),
    [labels],
  );

  const filteredLabels = useMemo(
    () =>
      searchValue
        ? parsedLabels.filter((label) =>
            label.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
          )
        : parsedLabels,
    [parsedLabels, searchValue],
  );

  const newLabelNameExists = useMemo(
    () => parsedLabels.some((label) => label.name === newLabelName),
    [parsedLabels, newLabelName],
  );

  const handleSearch = (newSearchValue: string) => {
    setSearchValue(newSearchValue);
    setNewLabelName(newSearchValue || '');
  };

  const handleCreateNewLabel = async () => {
    if (!newLabelName) return console.warn('name is required');
    try {
      await postLabel({
        name: newLabelName,
        color: DEFAULT_LABEL_COLOR,
        type: entityType,
      });
      setNewLabelName('');
    } catch (e) {
      console.warn(e);
    }
  };

  const cleanupClosedModal = () => {
    setLabelsToApply(new Set());
    setLabelsToRemove(new Set());
    setNewLabelName('');
  };

  const handleCancel = () => {
    cleanupClosedModal();
    onClose();
  };

  const handleAddLabels = async () => {
    const newLabels = { ...labelIds };
    entityIds.forEach((id) => {
      labelsToApply.forEach((labelId) => {
        if (!newLabels[id].includes(labelId)) {
          newLabels[id].push(labelId);
        }
      });
      labelsToRemove.forEach((labelId) => {
        newLabels[id] = newLabels[id].filter((lId) => lId !== labelId);
      });
    });

    await putLabels({ labels: newLabels });
    entityIds.forEach((entityId) => {
      queryClient.invalidateQueries([entityType, `/${entityType}/${entityId}`]);
    });
    onClose();
  };

  const handleCheckboxToggle = (event: any) => {
    const value = event.target.value;
    if (event.target.checked) {
      labelsToRemove.delete(value);
      labelsToApply.add(value);
    } else {
      labelsToRemove.add(value);
      labelsToApply.delete(value);
    }
    //rerender
    setLabelsToApply(new Set(labelsToApply));
    setLabelsToRemove(new Set(labelsToRemove));
  };

  return (
    <Modal
      title={
        entityIds.length === 1
          ? t(`labels.manageLabelsModal.headerSingle`)
          : t(`labels.manageLabelsModal.headerMulti`)
      }
      opened={open}
      onClose={onClose}
      className="manage-labels-modal"
    >
      <Modal.Header>
        {entityIds.length > 1 && (
          <span className="subheader">
            {t(`labels.manageLabelsModal.subHeaderMulti`)}
          </span>
        )}
      </Modal.Header>
      <Modal.Body>
        {isErrorFetchingLabels && (
          <div className="page-errors anim-slideInUpShort">
            {t('labels.manageLabelsModal.errorFetchingLabels')}
          </div>
        )}
        {isErrorCreatingLabel && (
          <div className="page-errors anim-slideInUpShort">
            {t('labels.manageLabelsModal.errorCreatingLabel')}
          </div>
        )}
        {isLoadingLabels ? (
          <Loading />
        ) : (
          <>
            <BasicSearch
              handleSearch={handleSearch}
              minCharCount={1}
              placeholder={t('labels.manageLabelsModal.searchPlaceholder')}
            />
            <br />
            <div className="labels-container">
              {(searchValue ? filteredLabels : parsedLabels).map((label) => {
                const checked =
                  !labelsToRemove.has(label._id?.toString() as string) &&
                  (labelsToApply.has(label._id?.toString() as string) ||
                    entityIds.every((id) =>
                      labelIds[id].includes(label._id?.toString() as string),
                    ));
                const indeterminate =
                  !checked &&
                  !labelsToApply.has(label._id?.toString() as string) &&
                  !labelsToRemove.has(label._id?.toString() as string) &&
                  entityIds.some((id) =>
                    labelIds[id].includes(label._id?.toString() as string),
                  );
                return (
                  <div key={label._id?.toString() as string} className="label-container">
                    <Checkbox
                      className="label-checkbox"
                      value={label._id?.toString() as string}
                      checked={checked}
                      indeterminate={indeterminate}
                      onChange={handleCheckboxToggle}
                    />
                    <div className="label-color-container">
                      <div
                        className="label-color"
                        style={{ backgroundColor: label.color ?? '' }}
                      />
                    </div>
                    <Tooltip
                      disabled={label.name === label.displayName}
                      label={label.name}
                    >
                      <div className="label-name">{label.displayName}</div>
                    </Tooltip>
                  </div>
                );
              })}
              {newLabelName && !newLabelNameExists && (
                <div className="new-label">
                  <Icon icon="plus" className="plus-icon" />
                  {t('labels.manageLabelsModal.createLabelHelptext')}"
                  <span className="new-label-name" onClick={handleCreateNewLabel}>
                    {newLabelName}
                  </span>
                  "
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Flex className="modal-footer">
        <Button variant="default" onClick={handleCancel}>
          {t(`global.cancel`)}
        </Button>
        <Button
          loading={isLoadingLabels || isCreatingLabels}
          disabled={false}
          onClick={handleAddLabels}
        >
          {t(`global.save.buttonShort`)}
        </Button>
      </Flex>
    </Modal>
  );
}

export default ManageLabelsModal;
