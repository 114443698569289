import { Flex } from '@mantine/core';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AssetVendorFilter } from './VulnerabilityAffectedAssets/useVulnAffectedAssetsFilters';
import classNames from 'classnames';

interface OverviewTablesEmptyStateProps {
  context: string;
  searchFilter?: string;
  vendorFilter?: string;
}

export const OverviewTablesEmptyState = ({
  context,
  searchFilter,
  vendorFilter,
}: OverviewTablesEmptyStateProps) => {
  const { t } = useTranslation();

  const hasVendorFilter = vendorFilter && vendorFilter !== AssetVendorFilter.all;
  const hasFilters = searchFilter || hasVendorFilter;

  const emptyHeader = useMemo(() => {
    if (hasVendorFilter && searchFilter) {
      return t(
        'page.vulnerability.details.overview-tab.empty-table-heading-search-vendor-filter',
        {
          context,
          vendorFilter,
          searchFilter,
        },
      );
    }

    if (hasVendorFilter) {
      return t(
        'page.vulnerability.details.overview-tab.empty-table-heading-vendor-filter',
        {
          context,
          vendorFilter,
        },
      );
    }

    if (searchFilter) {
      return t(
        'page.vulnerability.details.overview-tab.empty-table-heading-search-filter',
        {
          context,
          searchFilter,
        },
      );
    }

    return t('page.vulnerability.details.overview-tab.empty-table-heading', {
      context,
    });
  }, [context, vendorFilter, searchFilter, hasVendorFilter]);

  const cta = useMemo(() => {
    if (searchFilter) {
      return t(
        'page.vulnerability.details.overview-tab.empty-table-content-search-filter',
      );
    }

    return (
      <Trans
        t={t}
        i18nKey="page.vulnerability.details.overview-tab.empty-table-cta"
        values={{
          link: t('page.vulnerability.details.overview-tab.empty-table-cta-redirection'),
        }}
      >
        <Link to="reference">
          {t('page.vulnerability.details.overview-tab.empty-table-cta-redirection')}
        </Link>
      </Trans>
    );
  }, [searchFilter]);

  return (
    <Flex
      className={classNames('empty-table-content', hasFilters && 'with-filters')}
      direction="column"
      gap={hasFilters ? 8 : 16}
    >
      <p className="header">{emptyHeader}</p>
      <p className="cta">{cta}</p>
    </Flex>
  );
};
