import { Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface TicketsTableEmptyScreenProps {
  hasAtLeastOneIntegration: boolean;
}

export const TicketsTableEmptyScreen = ({
  hasAtLeastOneIntegration,
}: TicketsTableEmptyScreenProps) => {
  const { t } = useTranslation();

  if (hasAtLeastOneIntegration) {
    return (
      <Flex justify="center">
        <p className="table-empty-screen-text tickets-empty-with-integration">
          {t('tickets.ticketList.noTickets')}
        </p>
      </Flex>
    );
  }

  return (
    <div className="tickets-empty-screen">
      <div>
        <img src="/jira_logo_expanded.png" alt="jira_logo_expanded" />
        <img src="/servicenow_logo_expanded.png" alt="jira_logo_expanded" />
        <img src="/linear_logo_expanded.png" alt="jira_logo_expanded" />
      </div>
      <p className="table-empty-screen-text">
        {t('page.vulnerability.details.action-tab.empty-states.tickets')}
        <Link to="/settings/integrations">{t('page.userSettings.page-title')}</Link>
      </p>
    </div>
  );
};
