import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import type { InterfaceUnmatchedLicense } from '@manifest-cyber/types/interface/dbTables';

interface Options {
  refetchOnWindowFocus?: boolean;
}

export const useFetchUnmatchedLicenses = (options?: Options) => {
  let fetchUrl = `licenses/unmatched`;

  return useQuery({
    queryKey: ['unmatched-licenses', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => (data?.data ?? []) as InterfaceUnmatchedLicense[],
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
