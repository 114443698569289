import axios from 'axios';
import Cookies from 'js-cookie';
import appConfig from '../configs/appConfig';

export const setProtocol = (url: string) => {
  const currentProtocol = appConfig?.useSsl ? 'https:' : 'http:';
  return `${currentProtocol}${url.replace('https:', '').replace('http:', '')}`;
};

/**
 * formattedApiUrl()
 * Wrapper for any preprocessing for URL
 * @param {string} useAuth Whether we are using authenticated routes or not
 * @returns {string}
 */
export const formattedApiUrl = (): string => {
  const returnUrl = `//${appConfig.apiUrl}/v${appConfig.apiVersion}`;
  return returnUrl;
};

/**
 * getOrganizationId()
 * Grabs our JWT from localStorage (or falls back to Cookie)
 */
const getOrganizationId = () => Cookies.get('organizationid') || '';

export const hitApi = {
  /**
   * API: GET
   * Can pass query params in URL or manually via params
   * @param {string} url Relative URL for API
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} params Pass in any query params
   * @returns Promise
   */
  get: async (
    url: string,
    useAuth: boolean = true,
    params: object = {},
    headers: object = {},
    signal?: AbortSignal,
  ) =>
    axios
      .get(`${formattedApiUrl()}/${url}`, {
        withCredentials: useAuth, // Send cookie
        headers: {
          organizationid: `${getOrganizationId()}`,
          ...headers,
        },
        params: {
          ...params,
        },
        signal,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        // TODO: Use internal logger
        if (e.code !== 'ERR_CANCELED') {
          console.error(`Error while GETing "${formattedApiUrl()}/${url}"`, e);
        }

        // If we're not already on login page, redirect to login/logout for some cases
        if (!window.location.href.includes('/login')) {
          // If the error is connection refused, we want to redirect to the login page
          // This is normally only going to happen if API has to be forcefully bumped, or if we're on local dev, etc.
          if (e?.message === 'Network Error') {
            window.location.href = '/login?logout=network-error';
          }

          // If we're not on login page and we're getting a 403/401, redirect to logout
          if (e?.response?.status === 403) {
            console.info(
              'Looks like invalid or expired session (403), logging user out...',
            );
            window.location.href = '/logout?reason=invalid-expired-session';
          } else if (e?.response?.status === 401) {
            const responseData = e?.response?.data;
            if (
              typeof responseData === 'string' &&
              responseData.includes('Feature Not Enabled')
            ) {
              return {
                success: false,
                errors: ['Feature Not Enabled'],
                data: [],
              };
            } else {
              console.info(
                'Looks like invalid or expired session (401), logging user out...',
              );
              window.location.href = '/logout?reason=invalid-expired-session';
            }
          }
        }

        return { data: null, success: false, errors: [e] };
      }),

  /**
   * API: POST
   * @param {string} url Relative URL for API
   * @param {object} body POST body
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} options Pass in any additional options.
   * @returns Promise
   */
  post: async (url: string, useAuth: boolean = true, body: {}, options: object = {}) =>
    axios
      .post(
        `${formattedApiUrl()}/${url}`,
        {
          ...body,
        },
        {
          headers: {
            organizationid: `${getOrganizationId()}`,
          },
          withCredentials: useAuth,
          ...options,
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        // TODO: Use internal logger
        console.error(`Error while POSTing "${formattedApiUrl()}/${url}"`, e);
        return { data: null, success: false, errors: [e] };
      }),
  /**
   * API: PUT
   * @param {string} url Relative URL for API
   * @param {object} body POST body
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} options Pass in any additional options.
   * @returns Promise
   */
  put: async (url: string, useAuth: boolean = true, body: {}, options: object = {}) =>
    axios
      .put(
        `${formattedApiUrl()}/${url}`,
        {
          ...body,
        },
        {
          headers: {
            organizationid: `${getOrganizationId()}`,
          },
          withCredentials: useAuth,
          ...options,
        },
      )
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        // TODO: Use internal logger
        console.error(`Error while PUTing "${formattedApiUrl()}/${url}"`, e);
        return { data: null, success: false, errors: [e] };
      }),
  /**
   * API: PUT Files
   * @param {string} url Relative URL for API
   * @param {FormData} formData to put
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} options Pass in any additional options.
   * @returns Promise
   */
  putFiles: async (
    url: string,
    formData: FormData,
    useAuth: boolean = true,
    options: object = {},
  ) => {
    return axios({
      method: 'PUT',
      url: `${formattedApiUrl()}/${url}`,
      data: formData,
      withCredentials: useAuth,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...options,
    })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        // TODO: Use internal logger
        console.error(`Error while PUTing "${formattedApiUrl()}/${url}"`, e);
        return { data: null, success: false, errors: [e] };
      });
  },
  /**
   * API: DELETE
   * @param {string} url Relative URL for API
   * @param {object} body POST body
   * @param {boolean} useAuth Include JWT (for auth'ed routes)
   * @param {object} options Pass in any additional options.
   * @returns Promise
   */
  delete: async (url: string, useAuth: boolean = true, body?: {}, options: object = {}) =>
    axios
      .delete(`${formattedApiUrl()}/${url}`, {
        headers: {
          organizationid: `${getOrganizationId()}`,
        },
        data: {
          ...body,
        },
        withCredentials: useAuth,
        ...options,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        // TODO: Use internal logger
        console.error(`Error while DELETEing "${formattedApiUrl()}/${url}"`, e);
        return { data: null, success: false, errors: [e] };
      }),
};

export default hitApi;
