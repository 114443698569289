const ICON_COLOR_CODES = [
  '#1EBCE2', // light blue,
  '#F3A93C', // dark yellow,
  '#965294', // Content/Violet
  '#4EB969', // Content/Green
  '#351E29', // dark purple,
  '#E2D686', // flax
  '#2563D8', // Core/Secondary
  '#ae2012', // dark red
  '#f72585', // hot pink
  '#ED765D', // Content/Orange
];

export const getOrgInitials = (orgName: string) => {
  if (!orgName) return '';
  const nameParts = orgName.split(' ');
  // get initials of first and last word in org name
  return (
    nameParts[0][0] + (nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : '')
  );
};
export const getOrgColorCode = (orgName: string) => {
  const initials = getOrgInitials(orgName);
  let sum = 0;
  for (var i = 0; i < initials.length; i++) {
    sum += initials.charCodeAt(i);
  }
  return ICON_COLOR_CODES[sum % 10];
};
