import { Dispatch, SetStateAction, useState } from 'react';
import {
  useURLFilterArrayState,
  useURLFilterBooleanState,
  useURLFilterNumberState,
  useURLFilterStringState,
} from '../utils/usePersistentStates';

export interface Filters {
  onlyHasVulns: boolean;
  onlyHasLicenseIssues: boolean;
  recommendations: string[];
  cveIds: string[];
  cvssScore: number;
  ecosystem: string[];
  source: string[];
  licenseTypes: string[];
  componentName: string;
}

export type UpdateFilter = <K extends keyof Filters>(
  filterName: K,
  value: Filters[K],
) => void;

export interface UseComponentsFilterReturn {
  filterSidebarOpen: boolean;
  setFilterSidebarOpen: Dispatch<SetStateAction<boolean>>;
  filters: Filters;
  updateFilter: UpdateFilter;
  getCurrentFilters: () => { field: keyof Filters; value: any }[];
  componentName: string;
  setComponentName: Dispatch<SetStateAction<string>>;
}

export const useComponentsFilter = (): UseComponentsFilterReturn => {
  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);

  // Suggested
  const [onlyHasVulns, setOnlyHasVulns] = useURLFilterBooleanState('onlyHasVulns', false);
  const [onlyHasLicenseIssues, setOnlyHasLicenseIssues] = useURLFilterBooleanState(
    'onlyHasLicenseIssues',
    false,
  );

  // Vulnerabilities
  const [recommendations, setRecommendations] = useURLFilterArrayState(
    'recommendations',
    [],
  );
  const [cveIds, setCveIds] = useURLFilterArrayState('cveIds', []);
  const [cvssScore, setCvssScore] = useURLFilterNumberState('cvssScore', 0);

  // Components
  const [ecosystem, setEcosystem] = useURLFilterArrayState('ecosystem', []);
  const [source, setSource] = useURLFilterArrayState('source', []);
  const [licenseTypes, setLicenseTypes] = useURLFilterArrayState('licenseTypes', []);

  const [componentName, setComponentName] = useURLFilterStringState('componentName', ''); //search

  const filters = {
    onlyHasVulns,
    onlyHasLicenseIssues,
    recommendations,
    cveIds,
    cvssScore,
    ecosystem,
    source,
    licenseTypes,
    componentName,
  };

  const setters = {
    onlyHasVulns: setOnlyHasVulns,
    onlyHasLicenseIssues: setOnlyHasLicenseIssues,
    recommendations: setRecommendations,
    cveIds: setCveIds,
    cvssScore: setCvssScore,
    ecosystem: setEcosystem,
    source: setSource,
    licenseTypes: setLicenseTypes,
    componentName: setComponentName,
  };

  const updateFilter: UpdateFilter = (filterName, value) => {
    const setter = setters[filterName];
    if (setter) {
      (setter as Dispatch<SetStateAction<typeof value>>)(value);
    }
  };

  const getCurrentFilters = (): { field: keyof Filters; value: any }[] => {
    const currentFilters: { field: keyof Filters; value: any }[] = [];

    for (const [field, value] of Object.entries(filters)) {
      if (Array.isArray(value) && value.length) {
        currentFilters.push({ field: field as keyof Filters, value });
      } else if (typeof value === 'boolean' && value) {
        currentFilters.push({ field: field as keyof Filters, value });
      } else if (typeof value === 'number' && value !== 0) {
        currentFilters.push({ field: field as keyof Filters, value: [value, 10] });
      } else if (typeof value === 'string' && value) {
        currentFilters.push({ field: field as keyof Filters, value: [value] });
      }
    }

    return currentFilters;
  };

  return {
    filterSidebarOpen,
    setFilterSidebarOpen,
    filters,
    updateFilter,
    getCurrentFilters,
    componentName,
    setComponentName,
  };
};
