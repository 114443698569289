import './VulnTriage.scss';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mantine/core';
import ConfirmCloseModal from './ConfimCloseModal';
import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { notifications } from '@mantine/notifications';
import SingleCVETriage from './SingleCVETriage';
import MultiCVETriage from './MultiCVETriage';

interface TriageProps {
  selectedCVEs: InterfaceVulnerability[];
  onClose: () => void;
  updateIsCloseable: Dispatch<SetStateAction<boolean>>;
  closeable: boolean;
  openCloseModal: boolean;
  setOpenCloseModal: Dispatch<SetStateAction<boolean>>;
  organizationAssetId: string;
}

function Triage(
  {
    selectedCVEs,
    onClose,
    updateIsCloseable,
    closeable,
    openCloseModal,
    setOpenCloseModal,
    organizationAssetId,
  }: TriageProps = {} as TriageProps,
) {
  const { t } = useTranslation();
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const handleConfirmClose = () => {
    setCloseModalOpen(false);
    setOpenCloseModal(false);
    setShouldResetForm(true);
    updateIsCloseable(true);
    onClose();
  };
  const handleCancel = () => {
    setCloseModalOpen(false);
    setOpenCloseModal(false);
    setShouldResetForm(false);
  };
  const handleCloseClick = () => {
    if (!closeable) {
      setCloseModalOpen(true);
      setOpenCloseModal(true);
    } else {
      onClose();
    }
  };
  const handleTriagedSaved = () => {
    setShouldResetForm(true);
    updateIsCloseable(true);
    processTriageSavedNotification();
    onClose();
  };
  useEffect(() => {
    if (openCloseModal) {
      setCloseModalOpen(true);
    }
  }, [openCloseModal]);

  const getTitle = () => {
    return selectedCVEs.length > 1
      ? t('triage.headerMulti', { count: selectedCVEs.length })
      : selectedCVEs[0]?.cveId;
  };

  const processTriageSavedNotification = () => {
    const message =
      selectedCVEs.length > 1
        ? t('triage.notifications.successMulti', { count: selectedCVEs?.length })
        : t('triage.notifications.successSingle', { cveId: selectedCVEs[0]?.cveId });
    notifications.show({
      title: t(`triage.notifications.title`),
      message,
      icon: <Icon icon="circle-check" iconStyle="far" classNames="success" />,
    });
  };

  return (
    <div className="triage-container">
      <div className="triage-content">
        <div className="header-container">
          <div className="header">{t('triage.header')}</div>
          <div className="actions">
            <Icon icon="xmark" onClick={() => handleCloseClick()} />
          </div>
        </div>
        <div className="header-content">
          <div className="title">{getTitle()}</div>
          {selectedCVEs.length > 1 && (
            <div className="subtitle">{t('triage.subtitle')}</div>
          )}
        </div>
        <div className="body">
          {selectedCVEs.length === 1 ? (
            <SingleCVETriage
              cve={selectedCVEs[0]}
              updateIsCloseable={updateIsCloseable}
              shouldResetForm={shouldResetForm}
              organizationAssetId={organizationAssetId}
              handleTriageSaved={handleTriagedSaved}
            />
          ) : (
            <MultiCVETriage
              cves={selectedCVEs}
              updateIsCloseable={updateIsCloseable}
              shouldResetForm={shouldResetForm}
              organizationAssetId={organizationAssetId}
              handleTriageSaved={handleTriagedSaved}
            />
          )}
        </div>
      </div>
      <Modal
        opened={closeModalOpen}
        onClose={() => handleCancel()}
        withCloseButton
        centered
        title={t('triage.modal.title')}
      >
        <ConfirmCloseModal
          onCancel={() => handleCancel()}
          handleConfirmClose={handleConfirmClose}
        />
      </Modal>
    </div>
  );
}

export default Triage;
