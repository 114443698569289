import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Flex, Input, Modal } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';
import { Form } from '@mantine/form';

interface RiskyTriageConfirmProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
}

export const RiskyTriageConfirm = ({
  onClose,
  opened,
  onSubmit,
  isLoading,
}: RiskyTriageConfirmProps) => {
  const { t } = useTranslation();
  const captchaValue = t('triage.form.risky-confirmation-modal.captcha');
  const [captchaText, setCaptchaText] = useState<string>('');
  const isSubmitDisabled = captchaText.toLowerCase().trim() !== captchaValue;

  const formOnSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Modal.Root
      opened={opened}
      onClose={onClose}
      centered
      className="triage-risky-confirmation"
      size={450}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          {t('triage.form.risky-confirmation-modal.header')}
          <Modal.CloseButton className="close-btn" />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formOnSubmit}>
            <Flex direction="column" gap={12}>
              <p className="triage-confirm-title">
                {t('triage.form.risky-confirmation-modal.title')}
              </p>
              <p className="triage-confirm-label">
                <Trans i18nKey="triage.form.risky-confirmation-modal.label" />
              </p>
            </Flex>
            <Input
              mt={12}
              placeholder='Enter "Confirm" to continue'
              value={captchaText}
              aria-label='Enter "Confirm" to continue'
              onChange={({ target }) => setCaptchaText(target.value)}
            />
            <Flex align="center" justify="end" gap={12} mt={12}>
              <Button variant="outline" onClick={onClose} className="btn cancel-btn">
                {t('global.cancel')}
              </Button>
              <Button
                type="submit"
                className="btn submit-btn"
                disabled={isSubmitDisabled}
                onClick={onSubmit}
                loading={isLoading}
              >
                {t('global.submit')}
              </Button>
            </Flex>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
