import type { InterfaceLabel } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';

export interface LabelInterface
  extends Omit<
    InterfaceLabel,
    '_id' | 'organizationId' | 'dateCreated' | 'dateModified'
  > {
  _id?: string;
  organizationId?: string;
  dateCreated?: string;
  dateModified?: string;
  __v?: number;
}

interface Params {
  labelIds?: string[];
  sortBy?: string;
}

interface Options {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  stateTime?: number;
}

export const LABELS_QUERY_KEY = 'labels';

export const useFetchLabels = (
  { labelIds, sortBy = 'name' }: Params,
  options?: Options,
) => {
  let fetchUrl = `/labels?`;

  if (sortBy) {
    fetchUrl += `&sortBy=${sortBy}`;
  }

  fetchUrl = fetchUrl.replace('?&', '?');

  return useQuery({
    enabled: options?.enabled && !!labelIds?.length,
    staleTime: options?.stateTime ?? 1000 * 60 * 60, // 1 hour (or until invalidated)
    queryKey: ['labels', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => {
      let labels = (data?.data ?? []) as LabelInterface[];
      if (labelIds) {
        labels = labels.filter((label) => labelIds.includes(label._id?.toString()!));
      }
      return labels;
    },
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
