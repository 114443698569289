import '../../scss/pages/assets.scss';
import { Text, Title } from '@mantine/core';
import { AssetsTable } from '../../components/DataTables/AssetsTable';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useFetchAssets } from '../../hooks/queries/useFetchAssets';

const Assets = () => {
  const { t } = useTranslation();
  return (
    <section className="page-assets anim-slideInUpShort">
      <Helmet title={t('page.assets.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <AssetsTable />
    </section>
  );
};

export default Assets;
