export const sanitizePath = (path?: string) => {
  path = path ?? '';
  // A simple check to see if the path is a valid URL
  try {
    new URL(path ?? '');
    return '/'; // If the path is a valid URL, we do not want to allow it
  } catch (_) {
    // Invalid URL, could be a relative path, so continue
  }

  // Remove leading/trailing spaces
  const trimmedPath = path.trim();

  // Split the path and query string
  const [pathPart, queryPart] = trimmedPath.split('?');

  // Remove special characters from path part
  const sanitizedPathPart = pathPart.replace(/[^a-zA-Z0-9_/-]/g, '');

  // Make sure the path does not start with 'http', 'https', or 'www'
  if (/^(http|https|www)/.test(sanitizedPathPart)) {
    return '/';
  }

  // Reconstruct the path with query string, if any
  const sanitizedPath = queryPart
    ? `${sanitizedPathPart}?${queryPart}`
    : sanitizedPathPart;

  // Finally, make sure the path is not an empty string
  return sanitizedPath.length > 0 ? sanitizedPath : '/';
};
