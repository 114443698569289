import { InterfaceFullAsset, MappedFullAsset } from '../../../../types/FullInterfaces';

export const mapAssetDetail = (asset: InterfaceFullAsset): MappedFullAsset => {
  const { sharedWithCount, ...rest } = asset;

  return {
    ...rest,
    shareInformation: {
      isShared: !!sharedWithCount && sharedWithCount > 0,
      sharingCount: sharedWithCount || 0,
    },
  };
};
