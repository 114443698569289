import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceOrganizationMemberUser } from '../../types/FullInterfaces';

interface Params {
  user: InterfaceOrganizationMemberUser;
}

export const usePostOrganizationUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ user }: Params) => {
      return hitApi.post(`user/new`, true, {
        ...user,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organizationUsers']);
      queryClient.invalidateQueries(['currentOrganization']);
    },
  });
};
