import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ManifestModal from '../../../../../components/ManifestModal/ManifestModal';
import { useDeleteGitHubIntegration } from '../../../../../hooks/mutations/useDeleteGitHubIntegration';

interface Props {
  open: boolean;
  onClose: () => void;
  organizationId: string | undefined;
  installationId: string | undefined;
}

export const GitHubOrganizationRemoveConfirmModal = ({
  open,
  onClose,
  organizationId,
  installationId,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: deleteGitHubIntegrationMutateAsync, isLoading: isRemovingAccess } =
    useDeleteGitHubIntegration();

  const handleRemoveAccess = async () => {
    if (organizationId && installationId) {
      await deleteGitHubIntegrationMutateAsync({
        organizationId: organizationId,
        installationId: installationId,
      });
      navigate('/settings/integrations/github/organizations');
      onClose();
    }
  };

  return (
    <ManifestModal
      opened={open}
      withCloseButton={true}
      onClose={onClose}
      title={t('page.userSettings.integrations.github.confirm-remove-organization')}
      body={t(
        'page.userSettings.integrations.github.remove-organization-confirmation-info',
      )}
      primaryAction={{
        label: t('page.userSettings.integrations.github.remove'),
        onClick: () => {
          handleRemoveAccess();
        },
        isLoading: isRemovingAccess,
        color: 'red',
      }}
      secondaryAction={{
        label: t('global.cancel'),
        onClick: onClose,
      }}
    />
  );
};
