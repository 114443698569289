import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import { useTranslation } from 'react-i18next';
import { useFetchRiskiestOrganizationComponentVulns } from '../../../../hooks/queries/useFetchRiskiestOrganizationComponentVulns';
import { Button, Loader, Tooltip, Text } from '@mantine/core';
import Icon from '../../../../components/Icon';
import RiskyComponentVulnPopover from './RiskyComponentVulnPopover';
import { useNavigate } from 'react-router-dom';
interface Props {
  component: InterfaceOrganizationComponent;
}
interface LicenseIssue {
  id: string;
  licenseName: string;
  type: string;
}

export const parseLicenseIssuesFromComponent = (
  component: InterfaceOrganizationComponent,
) => {
  const licenseIssues: LicenseIssue[] = [];
  if (
    component?.countLicenseIssues &&
    component?.countLicenseIssues?.total &&
    component.countLicenseIssues.total > 0
  ) {
    Object.keys(component.countLicenseIssues).forEach((licenseName, idx) => {
      if (
        licenseName?.toLocaleLowerCase().trim().replaceAll(' ', '') === 'copyleft' ||
        licenseName?.toLocaleLowerCase().trim().replaceAll(' ', '') === 'permissive' ||
        licenseName?.toLocaleLowerCase().trim().replaceAll(' ', '') === 'unknown' ||
        licenseName?.toLocaleLowerCase().trim().replaceAll(' ', '') === 'unlicensed' ||
        licenseName?.toLocaleLowerCase().trim().replaceAll(' ', '') === 'total' ||
        licenseName?.toLocaleLowerCase().trim().replaceAll(' ', '') === 'alert'
      ) {
        return;
      }
      licenseIssues.push({
        id: `${idx}`,
        licenseName,
        type: '',
      });
    });
  }
  return licenseIssues;
};
function RiskyComponentData({ component }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: riskiestVulns, isLoading: isLoadingRiskiestVulns } =
    useFetchRiskiestOrganizationComponentVulns(component?._id?.toString() || '');

  const licenseIssues: LicenseIssue[] = parseLicenseIssuesFromComponent(component);
  if (isLoadingRiskiestVulns) {
    return <Loader />;
  }
  return (
    <div className="risky-component-data-container">
      <div className="data-column">
        <div className="header-row">
          {t('tables.riskyComponentData.headers.vulnCount', {
            count: riskiestVulns?.length,
          })}
        </div>
        <div className="data">
          {riskiestVulns
            ?.slice(0, 3)
            .map((vuln) => (
              <RiskyComponentVulnPopover key={vuln?._id?.toString()} vuln={vuln} />
            ))}
          {(riskiestVulns?.length || 0) > 3 && (
            <Button
              className="btn-see-all"
              onClick={() => navigate(`/component/${component?._id}/vulnerabilities`)}
            >
              {t('tables.riskyComponentData.seeAll')}
            </Button>
          )}
        </div>
      </div>
      <div className="data-column">
        <div className="header-row">
          {t('tables.riskyComponentData.headers.licenseIssues')}
        </div>
        <div className="data">
          {licenseIssues.map((issue) => {
            return (
              <Tooltip
                key={issue?.id}
                disabled={(issue?.licenseName?.length ?? 0) < 55}
                label={issue?.licenseName}
              >
                <div className="license-issue">
                  <Icon icon="key" />
                  <Text lineClamp={2} className="license-issue-name">
                    {issue?.licenseName}
                  </Text>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div className="data-column">
        <div className="header-row">
          {t('tables.riskyComponentData.headers.dependencies')}
        </div>
        <div className="data">{t('global.comingSoon')}</div>
      </div>
    </div>
  );
}

export default RiskyComponentData;
