import { Button, Popover, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import ClickableRegion from '../../../../components/ClickableRegion';
import Icon from '../../../../components/Icon/Icon';
import { type IIntegration } from '../integrations-data';
import { useTranslation } from 'react-i18next';

interface Props extends IIntegration {
  action: 'add' | 'edit' | 'installed' | 'pending';
}

export const IntegrationCard = ({
  action,
  addHref,
  description,
  editHref,
  icon,
  logoSrc,
  title,
}: Props) => {
  const { t } = useTranslation();
  const [popoverOpened, { close: popoverClose, open: popoverOpen }] =
    useDisclosure(false);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    if (action === 'edit') {
      return navigate(editHref);
    } else if (action === 'add') {
      return navigate(addHref);
    }
  };

  return (
    <div className="integration-card">
      <span className="logo">
        {icon && <Icon icon={icon} iconStyle="fab" size="3x" />}
        {logoSrc && <img src={logoSrc} alt={title} />}
      </span>
      <div>
        <div className="title-and-action">
          <h6>{title}</h6>
          <Popover
            width={200}
            position="top"
            withArrow
            shadow="md"
            opened={popoverOpened}
            disabled={action !== 'pending'}
          >
            <Popover.Target>
              <span
                onMouseEnter={() => {
                  popoverOpen();
                }}
                onMouseLeave={() => {
                  popoverClose();
                }}
              >
                <Button
                  disabled={action === 'installed' || action === 'pending'}
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  {t(
                    `page.userSettings.integrations.${
                      action === 'edit'
                        ? 'edit'
                        : action === 'add'
                        ? 'add'
                        : action === 'pending'
                        ? 'edit'
                        : 'installed'
                    }`,
                  )}
                </Button>
              </span>
            </Popover.Target>
            <Popover.Dropdown
              sx={{
                pointerEvents: 'none',
                backgroundColor: 'var(--color-color-text-base)',
                color: 'var(--color-forceBlack)',
              }}
            >
              <Text size="sm">{t('tickets.syncingData')}</Text>
            </Popover.Dropdown>
          </Popover>
        </div>
        <p>{description}</p>
      </div>
    </div>
  );
};
