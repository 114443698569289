import { Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';

export const NameErrors = () => {
  const { t } = useTranslation();

  return (
    <Group spacing="xs" noWrap>
      <Icon icon={'triangle-exclamation'} size="xl" />
      <Text size="sm" weight="normal">
        {t('tables.products.errors.product-name-length')}
      </Text>
    </Group>
  );
};
