import { Skeleton, Switch, Tooltip, UnstyledButton } from '@mantine/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import Icon from '../Icon';
import styles from './ToggleCard.module.scss';

interface ToggleCardProps {
  toggle: {
    isDisabled: boolean;
    onClick: () => void;
    isChecked: boolean;
  };
  editAction: {
    onClick: () => void;
    tooltip: string;
  };
  deleteAction: {
    onClick: () => void;
    tooltip: string;
  };
  mainSection: {
    tooltip: string;
    label: string;
    onClick: () => void;
  };
  Details: ReactNode;
  isLoading?: boolean;
}

const ToggleCard = ({
  toggle,
  editAction,
  deleteAction,
  mainSection,
  Details,
  isLoading = false,
}: ToggleCardProps) => {
  return (
    <div className={clsx(styles.container, isLoading && styles.isLoading)}>
      <div className={styles.toggleContainer}>
        <Skeleton radius="xl" visible={isLoading} className={styles.skeleton}>
          <Switch
            checked={toggle.isChecked}
            disabled={toggle.isDisabled}
            onClick={toggle.onClick}
          />
        </Skeleton>
      </div>
      <div className={styles.manSectionContainer}>
        <Skeleton
          visible={isLoading}
          className={clsx(styles.titleSkeleton, styles.skeleton)}
        >
          <Tooltip label={mainSection.tooltip}>
            <UnstyledButton onClick={mainSection.onClick} className={styles.title}>
              {mainSection.label}
            </UnstyledButton>
          </Tooltip>
        </Skeleton>
        <div>{Details}</div>
      </div>
      <div className={styles.actions}>
        <Tooltip label={editAction.tooltip}>
          <UnstyledButton onClick={editAction.onClick} className={styles.action}>
            <Icon icon="pen" size="lg" />
          </UnstyledButton>
        </Tooltip>
        <Tooltip label={deleteAction.tooltip}>
          <UnstyledButton onClick={deleteAction.onClick} className={styles.action}>
            <Icon icon="trash-can" size="lg" />
          </UnstyledButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default ToggleCard;
