import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import hitApi from '../../api';

export interface ProductFormData {
  id: string;
  name: string;
  version: string;
  manufacturer: string;
  // types: string[];
  description: string;
  labels: string[];
}

interface UsePostProductProps {
  hasErrorCallback: (errors: string[]) => void;
  closeModal: () => void;
  isEdit: boolean;
}

export interface ApiResponseWithErrors<T> extends AxiosResponse<T> {
  errors: string[];
}

export const usePostProduct = ({
  hasErrorCallback,
  closeModal,
  isEdit,
}: UsePostProductProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<
    ApiResponseWithErrors<InterfaceOrganizationProduct[]>,
    unknown,
    ProductFormData | Omit<ProductFormData, 'id'>
  >({
    mutationFn: (newProductData: ProductFormData | Omit<ProductFormData, 'id'>) => {
      return hitApi.post('product', true, newProductData);
    },
    onSuccess: (response: ApiResponseWithErrors<InterfaceOrganizationProduct[]>) => {
      if (response.errors.length) {
        hasErrorCallback(response.errors);
      } else {
        hasErrorCallback([]);
        queryClient.invalidateQueries({
          queryKey: ['products'],
        });
        queryClient.invalidateQueries({
          queryKey: [`product`, `/product/${response.data[0]._id}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`product`, `/product/${response.data[0]._id}/overview`],
        });
        closeModal();
        if (!isEdit) {
          navigate(`/product/${response.data[0]._id}`);
        }
      }
    },
  });
};
