import { useTranslation } from 'react-i18next';
import Icon from '../../components/Icon';
import { useFetchMostImpactedAssets } from '../../hooks/queries/useFetchMostImpactedAssets';
import capitalizeWords from '../../lib/capitalizeWords';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ListItemAction } from '../../components/ListItemAction/ListItemAction';
import { ManifestTag } from '../../components/ManifestTag/ManifestTag';
import { Loader, Tooltip } from '@mantine/core';

interface Props {}

export const MostImpactedAssets = ({}: Props) => {
  const { t } = useTranslation();

  //temp
  const { isLoading: isLoadingImpactedAssets, data: fetchedImpactedAssets = [] } =
    useFetchMostImpactedAssets();

  const iconMapByType: {
    [key: string]: IconName;
  } = {
    application: 'browser',
    library: 'album-collection',
    file: 'files',
    container: 'docker',
  };

  const dynamicTruncate = (originalString: string, listWidth: number = 400) => {
    const maxChars = Math.floor(listWidth / 12);

    return originalString.length > maxChars
      ? `${originalString.substring(0, maxChars - 3)}...`
      : originalString;
  };

  return (
    <div className="widget top-assets anim-slideInRightShort">
      <h2 className="widget-header icon-right">
        {t('page.dashboard.riskiest-assets.header')}
        <Tooltip
          label={t('page.dashboard.riskiest-assets.tooltip')}
          position="top"
          withArrow
        >
          <span>
            <Icon icon="info-circle" iconSharp />
          </span>
        </Tooltip>
      </h2>
      {isLoadingImpactedAssets ? (
        <Loader />
      ) : fetchedImpactedAssets.length ? (
        <ul className="top-list">
          {fetchedImpactedAssets.map((asset) => {
            const isDocker = asset?.name?.includes('docker') || asset?.type === 'docker';
            return (
              <ListItemAction
                key={asset?._id?.toString()}
                clickableRegionProps={{
                  regionLabel: t('page.component.view-assets-details'),
                  href: `/asset/${asset?._id}`,
                }}
                iconProps={{
                  icon: isDocker ? 'docker' : `${iconMapByType[`${asset?.type}`]}`,
                  iconStyle: isDocker || asset?.type === 'container' ? 'fab' : 'far',
                }}
                iconSeverity="info"
              >
                <div className="top-vulns-detail">
                  <div className="top-row">
                    <h6>{dynamicTruncate(`${asset.name}@${asset.version}`)}</h6>
                  </div>
                  <div className="bottom-row flex-start">
                    <span>{capitalizeWords(`${asset.type}`)}</span>
                    <span className={`severity-${asset.riskScore}`}>
                      {capitalizeWords(
                        t(`page.asset.risk-score.${asset.riskScore?.toLowerCase()}`),
                      )}
                    </span>
                    <span>
                      {parseInt(`${asset?.countVulnerabilities?.inKEV}`) > 0 ||
                      parseInt(`${asset?.countVulnerabilities?.inKEVPreviously}`) > 0 ? (
                        <span className="kev-tag-wrapper">
                          <ManifestTag
                            variant={
                              parseInt(`${asset?.countVulnerabilities?.inKEV}`) > 0
                                ? 'kev'
                                : 'prev-kev'
                            }
                          />
                        </span>
                      ) : null}
                    </span>
                  </div>
                </div>
              </ListItemAction>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
