import ToggleCard from '../../../../../../components/ToggleCard/ToggleCard';
import { AlertMetadata } from '../../../components/AlertMetadata/AlertMetadata';

const noopFunction = () => {};

const AlertsListSkeleton = () => {
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <ToggleCard
          key={index}
          isLoading
          toggle={{
            isDisabled: false,
            isChecked: false,
            onClick: noopFunction,
          }}
          editAction={{
            tooltip: '',
            onClick: noopFunction,
          }}
          deleteAction={{
            tooltip: '',
            onClick: noopFunction,
          }}
          mainSection={{
            tooltip: '',
            label: 'Lorem ipsum dolor sit amet',
            onClick: noopFunction,
          }}
          Details={
            <AlertMetadata
              leftText={'Lorem ipsum dolor sit amet'}
              rigthText={'Lorem ipsum dolor sit amet'}
              isLoading
            />
          }
        />
      ))}
    </>
  );
};

export default AlertsListSkeleton;
