import { DateTime } from 'luxon';
import { TFunction } from 'i18next';

export const determineSeverityColor = (severity: string | undefined): string => {
  switch (severity) {
    case 'CRITICAL':
      return 'severity-critical';
    case 'HIGH':
      return 'severity-high';
    case 'MEDIUM':
      return 'severity-medium';
    case 'LOW':
      return 'severity-low';
    case 'INFO':
    default:
      return 'severity-info';
  }
};

export const formatScore = (score: number | undefined): string =>
  score ? `${score.toFixed(1)}` : '';
export const formatPercentile = (percentile: number | undefined): string =>
  percentile ? `${Math.round(percentile * 10000) / 100}%` : '';

export const formatDate = (
  date: Date | string | null | undefined,
  timeZone: string = 'utc',
  outputTimeZone: string = 'local', // Default to local timezone if not specified
): string => {
  let dateTime;

  if (typeof date === 'string') {
    dateTime = DateTime.fromISO(date, { zone: timeZone });
  } else if (date instanceof Date) {
    dateTime = DateTime.fromJSDate(date, { zone: timeZone });
  }

  if (dateTime && dateTime.isValid) {
    // Convert to output timezone
    dateTime = dateTime.setZone(outputTimeZone);
    return dateTime.toLocaleString(DateTime.DATETIME_MED);
  }

  return '';
};

export const generateTooltipContent = (value: number | undefined, t: TFunction): string =>
  t('page.vulnerabilities.table.body.rawValueFromEPSS', { value });
