import { InterfaceOrganization } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';

interface Params {
  organizationId?: string | null;
}

interface Options {
  staleTime?: number;
}

export const useFetchOrganization = ({ organizationId }: Params, options?: Options) => {
  const fetchUrl = `organization/${organizationId || ''}`;

  return useQuery({
    enabled: true,
    queryKey: ['currentOrganization', { _id: organizationId }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data?.[0] as InterfaceOrganization,
    onError: (error) => {
      console.error('Unable to fetch user: ', error);
    },
    staleTime: options?.staleTime,
  });
};
