import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Flex, Modal, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface ConfirmAssociationModalProps {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
}

export const ConfirmAssociationModal = ({
  isOpen,
  close,
  confirm,
}: ConfirmAssociationModalProps) => {
  const { t } = useTranslation();
  const [dontAskAgain, setDontAskAgain] = useState(false);

  useEffect(() => {
    const confirmInactiveAssetAssociation = localStorage.getItem(
      'confirmInactiveAssetAssociation',
    );
    if (confirmInactiveAssetAssociation === null) {
      localStorage.setItem('confirmInactiveAssetAssociation', 'true');
    }
  }, []);

  const handleConfirm = () => {
    if (dontAskAgain) {
      localStorage.setItem('confirmInactiveAssetAssociation', 'false');
    }
    confirm();
  };

  return (
    <Modal.Root opened={isOpen} onClose={close} size="sm">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Flex direction="column" gap={4}>
              <Modal.Title>
                <b>{t('page.assets.addInactiveAssets')}</b>
              </Modal.Title>
            </Flex>
            <Modal.CloseButton />
          </Flex>
        </Modal.Header>
        <Modal.Body>
          <Text>{t('page.assets.vulnerabilitiesDescription')}</Text>
          <Checkbox
            label={t('page.assets.dontAskAgain')}
            checked={dontAskAgain}
            onChange={(event) => setDontAskAgain(event.currentTarget.checked)}
            mt="md"
          />
          <Flex gap={8} mt={16} justify="flex-end">
            <Flex gap={8} justify="flex-end">
              <Button variant="default" onClick={close}>
                {t('global.cancel')}
              </Button>
              <Button onClick={handleConfirm}>{t('page.assets.yesAddToProduct')}</Button>
            </Flex>
          </Flex>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
