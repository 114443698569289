import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFetchProductOverview } from '../../hooks/products/useFetchProductOverview';
import { useParams } from 'react-router';
import Loading from '../../components/Loading';
import styles from './ProductOverview.module.scss';
import ProductOverviewHighlights from '../../components/Product/Overview/Highlights';
import React from 'react';
import ProductOverviewTopAssets from '../../components/Product/Overview/TopAssets';
import ProductOverviewTopVulns from '../../components/Product/Overview/TopVulns';
import ProductOverviewAbout from '../../components/Product/Overview/About';

export const ProductOverview = () => {
  const { t } = useTranslation();
  let { productId } = useParams<{ productId: string }>();

  const {
    data: productOverviewResponse,
    isLoading,
    isFetched,
    isSuccess,
    isError,
  } = useFetchProductOverview({
    productId,
  });

  if (isError) {
    return <div>{t('page.product.fetchProductError')}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess && productOverviewResponse) {
    const {
      success,
      errors,
      data: {
        countVulnerabilities,
        countHighRiskAssets,
        description,
        top5Assets,
        top5Vulns,
        labels,
      },
    } = productOverviewResponse;

    if (errors.length) {
      return (
        <ul className="page-errors anim-slideInUpShort">
          {errors.map((error) => (
            <li>{error}</li>
          ))}
          {isError && <li>{t('page.product.fetchProductError')}</li>}
        </ul>
      );
    }

    return (
      <div className={styles.product_overview}>
        <Grid>
          <Grid.Col span={12} md={9}>
            <ProductOverviewHighlights
              countHighRiskAssets={countHighRiskAssets}
              inKevVulns={countVulnerabilities?.inKEV || 0}
              mitigateVulns={countVulnerabilities?.mitigate || 0}
            />
            <Grid align="stretch" pt={6}>
              <Grid.Col span={12} md={6}>
                {!!top5Assets.length && (
                  <ProductOverviewTopAssets top5Assets={top5Assets} />
                )}
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                {!!top5Vulns.length && <ProductOverviewTopVulns top5Vulns={top5Vulns} />}
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} md={3} mb={12}>
            <ProductOverviewAbout description={description} labels={labels} />
          </Grid.Col>
        </Grid>
      </div>
    );
  }
};
