import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { getDefaultTableOptions } from '../../../../../components/MRT/ManifestMRT';
import { Fragment, useMemo } from 'react';
import { renderText } from '../../../../../components/DataTables/ComponentsTable';
import ClickableRegion from '../../../../../components/ClickableRegion';
import { useOrganizationId } from '../../../../../hooks/utils/useOrganizationId';
import { Flex, Popover } from '@mantine/core';
import BasicSearch from '../../../../../components/BasicSearch';
import { OverviewTablesEmptyState } from '../OverviewTablesEmptyState';
import classNames from 'classnames';
import { useVulnAffectedComponentFilters } from './useVulnAffectedComponentFilters';
import DataTableFooter from '../../../../../components/DataTables/DataTableFooter';
import { useFetchVulnComponents } from '../../../../../hooks/queries/useFetchVulnComponents';

const MAX_IMPACTED_VERSIONS = 4;
const MAX_COMPONENT_VERSION_LINK_LENGTH = 10;

interface VulnerabilityAffectedComponentsProps {
  vulnId: string;
  tabsLoaded: boolean;
  isActiveComponentsOnly: boolean;
}

type MergedComponent = {
  _id: string;
  components: InterfaceOrganizationComponent[];
};

const defaultTableOptions = getDefaultTableOptions<MergedComponent>();

export const VulnerabilityAffectedComponents = ({
  vulnId,
  tabsLoaded,
  isActiveComponentsOnly,
}: VulnerabilityAffectedComponentsProps) => {
  const { t } = useTranslation();

  const {
    componentSearchFilter,
    handleSetComponentSearchFilter,
    pagination,
    setPagination,
    setSorting,
    sorting,
  } = useVulnAffectedComponentFilters();

  const { data, isFetching, isInitialLoading } = useFetchVulnComponents(
    vulnId,
    componentSearchFilter,
    isActiveComponentsOnly,
    pagination,
    sorting,
  );

  const affectedComponents = data?.data;
  const totalCount = data?.count;

  const [currentOrgId] = useOrganizationId(null);

  const isEmptyTable = Boolean(!affectedComponents?.length);

  const isInLoadingState = isFetching || !tabsLoaded;

  const columns = useMemo<MRT_ColumnDef<MergedComponent>[]>(() => {
    if (isEmptyTable && !isInLoadingState) {
      return [];
    }

    return [
      {
        accessorFn: (row) => row._id,
        id: 'component_name',
        header: t('page.vulnerability.details.overview-tab.components-table.component'),
        Cell: ({ row }) => {
          return renderText(row.original?._id || '');
        },
      },
      {
        accessorFn: (row) => row,
        id: 'impactedVersions',
        header: t(
          'page.vulnerability.details.overview-tab.components-table.impacted-versions',
        ),
        enableSorting: false,
        Cell: ({ row }) => {
          const components = row.original?.components;
          const isBeyondMaxImpactedVersions = components?.length > MAX_IMPACTED_VERSIONS;

          return (
            <>
              {components?.map(
                ({ version, _id }, index, { length: componentsLength }) => {
                  // is not last component
                  const showComma = index !== componentsLength - 1;
                  const href = `/component/${_id?.toString()}/${currentOrgId || ''}`;
                  const hasSurpassedMaxLengthAllowed = index + 1 > MAX_IMPACTED_VERSIONS;
                  let normalizedComponentVersion = version || _id?.toString() || '';

                  if (
                    normalizedComponentVersion.length > MAX_COMPONENT_VERSION_LINK_LENGTH
                  ) {
                    normalizedComponentVersion = `${normalizedComponentVersion.substring(
                      0,
                      MAX_COMPONENT_VERSION_LINK_LENGTH,
                    )}...`;
                  }

                  return (
                    <Fragment key={_id?.toString()}>
                      {!hasSurpassedMaxLengthAllowed && (
                        <>
                          <ClickableRegion
                            className="table-link"
                            regionLabel={t('page.component.view-component-details')}
                            href={href}
                          >
                            {normalizedComponentVersion}
                            {showComma && ','}
                          </ClickableRegion>
                          {showComma && <>&nbsp;</>}
                        </>
                      )}
                    </Fragment>
                  );
                },
              )}
              {isBeyondMaxImpactedVersions && (
                <Popover withinPortal position="bottom">
                  <Popover.Target>
                    <span className="table-link btn-label">
                      (+{components?.length - MAX_IMPACTED_VERSIONS})
                    </span>
                  </Popover.Target>
                  <Popover.Dropdown className="light">
                    <div className="component-versions-popover">
                      {components
                        ?.slice(MAX_IMPACTED_VERSIONS)
                        ?.map(({ packageUrlNoVersion, _id, version }) => {
                          return (
                            <Flex key={_id?.toString()}>
                              <ClickableRegion
                                key={_id?.toString()}
                                className="table-link"
                                regionLabel={`${packageUrlNoVersion}`}
                                href={`/component/${_id?.toString()}/${
                                  currentOrgId || ''
                                }`}
                              >
                                {(packageUrlNoVersion || '')?.length > 40
                                  ? packageUrlNoVersion?.substring(0, 40) + '...'
                                  : packageUrlNoVersion}
                                @{version}
                              </ClickableRegion>
                            </Flex>
                          );
                        })}
                    </div>
                  </Popover.Dropdown>
                </Popover>
              )}
            </>
          );
        },
      },
    ];
  }, [isEmptyTable, isInLoadingState]);

  const table = useMantineReactTable<MergedComponent>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: classNames(
        'manifest-data-table-no-footer table-card',
        isEmptyTable && 'empty-table',
      ),
      sx: {
        boxShadow: 'none',
      },
    },
    columns,
    data: affectedComponents || [],
    enableFilters: false,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: affectedComponents?.length,
    initialState: {
      ...defaultTableOptions.initialState,
      showGlobalFilter: false,
    },
    state: {
      pagination: {
        ...pagination,
        pageSize:
          isInitialLoading || (isInLoadingState && !affectedComponents?.length)
            ? 2
            : pagination.pageSize,
      },
      isLoading: isInLoadingState,
      sorting,
      showLoadingOverlay: isInLoadingState,
    },
    onPaginationChange: setPagination,
    mantineTableBodyRowProps: {
      className: 'table-row',
    },
    mantineTableHeadCellProps: {
      className: 'table-header',
    },
    renderTopToolbar: () => (
      <Flex bg="transparent" mb={8}>
        <BasicSearch
          handleSearch={handleSetComponentSearchFilter}
          placeholder={t('global.search')}
          disabled={!affectedComponents}
          initialValue={componentSearchFilter}
        />
      </Flex>
    ),
    renderEmptyRowsFallback: () => (
      <OverviewTablesEmptyState
        context={t('page.vulnerability.details.overview-tab.components').toLowerCase()}
        searchFilter={componentSearchFilter}
      />
    ),
    onSortingChange: setSorting,
  });

  return (
    <>
      <MantineReactTable table={table} />
      <DataTableFooter
        currentPage={pagination.pageIndex}
        limitPerPage={pagination.pageSize}
        totalResults={totalCount || 0}
        onChangePage={setPagination}
      />
    </>
  );
};
