import { AssetSupplier } from '@manifest-cyber/types/interface/db';
import { TFunction } from 'i18next';

/**
 *
 * @param assetOrComponent Asset or Component to normalize from
 * @param t i18next translation fn
 * @param prioritizeField Optionally prioritize a specific field if available
 * @returns
 */
export const normalizeAuthor = (
  assetOrComponent: {
    author?: string | undefined;
    publisher?: string | undefined;
    supplier?: AssetSupplier | undefined;
  },
  t: TFunction,
  prioritizeField: 'supplier' | 'author' | 'publisher' = 'supplier',
): string => {
  // fetchedAsset?.supplier?.name || fetchedAsset?.author || fetchedAsset?.publisher || t('global.notProvided')}
  let returnValue = 'unknown-source';

  switch (prioritizeField) {
    case 'author':
      returnValue =
        assetOrComponent?.author ||
        assetOrComponent?.supplier?.name ||
        assetOrComponent?.publisher ||
        'unknown-source';
      break;
    case 'publisher':
      returnValue =
        assetOrComponent?.publisher ||
        assetOrComponent?.supplier?.name ||
        assetOrComponent?.author ||
        'unknown-source';
      break;
    default:
      returnValue =
        assetOrComponent?.supplier?.name ||
        assetOrComponent?.author ||
        assetOrComponent?.publisher ||
        'unknown-source';
  }

  return returnValue;
};

export default normalizeAuthor;
