import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';
import hitApi from '../api';
import { useAuth } from '../hooks/useAuth';
import Footer from './Footer';
import LeftNav from './LeftNav/LeftNav';
import TopBar from './TopBar';
import Snowfall from 'react-snowfall';

export const AuthedLayout = () => {
  const { user, login, logout } = useAuth();
  const { pathname } = useLocation();
  const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);
  const outlet = useOutlet();

  let userFetchIntervalId: any = null;

  const [isLeftSidebarExpanded, setIsLeftSidebarExpanded] = useState(false);
  const toggleExpandSidebar = () => {
    setIsLeftSidebarExpanded(!isLeftSidebarExpanded);
  };

  const fetchUser = async () => {
    try {
      console.info('Fetching latest user data...');

      const fetchUser = await hitApi.get('user', true);
      if (fetchUser && fetchUser.success && fetchUser.data[0]) {
        const { organizations = [] } = fetchUser.data[0];
        if (organizations && organizations?.length > 0) {
          if (!isEqual(user, fetchUser.data[0])) {
            console.info('Updating user with latest..');
            login(fetchUser.data[0], null);
          }
        } else {
          // redirect to login if user has no orgs.
          setRedirectToLogin(true);
        }
      } else {
        if (!window.location.href.includes('login')) {
          clearInterval(userFetchIntervalId);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          userFetchIntervalId = null;
          logout('expired');
        }
      }
    } catch (err) {
      console.error('Error fetching user data', err);
      if (!window.location.href.includes('login')) {
        clearInterval(userFetchIntervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        userFetchIntervalId = null;
        logout('no-fetch');
      }
    }
  };

  const setFetchUserInterval = () => {
    userFetchIntervalId = setInterval(() => fetchUser(), 5000);
  };

  // Fetch latest
  useEffect(() => {
    const initialFetchUser = async () => {
      setFetchUserInterval();
    };

    initialFetchUser();

    return () => {
      clearInterval(userFetchIntervalId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      userFetchIntervalId = null;
    };
  });

  useEffect(() => {
    // Scroll to top on route change
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  if (redirectToLogin) {
    return <Navigate to="/login" />;
  }

  if (!user || !user?._id) {
    return <Navigate to="/login" />;
  }

  if (user && !user.isPaidOrgs && !pathname.includes('vendor-portal')) {
    return <Navigate to="/logout?reason=vendor-email-link-only" />;
  }

  return (
    <div className={`app-layout ${isLeftSidebarExpanded && 'left-sidebar-expanded'}`}>
      <LeftNav isPaidOrg={user.isPaidOrgs} toggleExpandSidebar={toggleExpandSidebar} />
      <TopBar isPaidOrg={user.isPaidOrgs} />
      <div className="page-content-area">
        {outlet}
        <Footer />
      </div>
    </div>
  );
};

export default AuthedLayout;
