import { useMemo } from 'react';
import { Modal } from 'rsuite';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDeleteSBOMs } from '../../hooks/mutations/useDeleteSBOMs';
import { getSBOMName } from '../../lib/getSBOMName';
import { InterfaceSBOMAsset } from '../../types/FullInterfaces';

interface Props {
  allSboms: Array<InterfaceSBOMAsset>;
  onSuccess: () => void;
  onCancel: () => void;
  open: boolean;
  sbomIds: string[];
}

function DeleteModal({ allSboms, onSuccess, onCancel, open, sbomIds }: Props) {
  const { t } = useTranslation();

  const deleteMutation = useDeleteSBOMs({
    onSuccess,
  });

  const sbomsToDelete = useMemo(
    () => allSboms.filter((sbom) => sbomIds.includes(sbom?._id as any)),
    [allSboms, sbomIds],
  );

  return (
    <Modal open={open} onClose={onCancel} className="delete-modal">
      <Modal.Header>
        <Modal.Title>{t(`page.uploads.deleteModal.header`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t(`page.uploads.deleteModal.body`)}</p>
        <br />
        <p>
          {sbomIds?.length > 1
            ? t(`page.uploads.deleteModal.sbomsToDelete`)
            : t(`page.uploads.deleteModal.sbomToDelete`, {
                sbomName: getSBOMName(sbomsToDelete[0] as InterfaceSBOMAsset),
              })}
        </p>
        {sbomIds?.length > 1 ? (
          <ul>
            {sbomsToDelete?.map((sbom) => (
              <li key={sbom._id as any}>{getSBOMName(sbom as InterfaceSBOMAsset)}</li>
            ))}
          </ul>
        ) : null}
        {deleteMutation.isError ? (
          <p className="delete-error">{t(`page.uploads.deleteModal.error`)}</p>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="delete-modal-footer">
        <Button
          color="red"
          loading={deleteMutation.isLoading}
          onClick={() => deleteMutation.mutate(sbomIds)}
        >
          {sbomIds.length > 1
            ? t(`page.uploads.deleteModal.confirmMultiple`, { numSboms: sbomIds.length })
            : t(`page.uploads.deleteModal.confirmSingle`)}
        </Button>
        <Button
          variant="default"
          onClick={() => {
            onCancel();
          }}
        >
          {t(`global.cancel`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
