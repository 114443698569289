import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@mantine/core';
import hitApi from '../../api';
import { useAuth } from '../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { sdk } from '../../lib/orySDK';

const Logout = () => {
  const { logout, user } = useAuth();
  const location = useLocation();
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [ignoreSSO, setIgnoreSSO] = useState<boolean>();

  const { t } = useTranslation();

  const logoutUser = async (redirect: string) => {
    let logoutReason: string | null = location?.search?.split('reason=')[1] || null;
    if (logoutReason) {
      logoutReason = logoutReason?.split('&')[0];
    }

    if (logoutReason && logoutReason?.length > 0) {
      console.info('Reason', location?.search?.split('?reason=')[1]);
    }

    const hitProfile = await hitApi.get('auth/logout');

    if (hitProfile && hitProfile.success && hitProfile.data.length > 0) {
      // Do nothing
    } else {
      console.warn('Unable to log the out on remote...');
    }

    logout(`${logoutReason || 'user-manual'}`, logoutUrl);
  };

  const createLogoutFlow = () => {
    sdk
      .createBrowserLogoutFlow(undefined, {})
      .then(({ data }) => {
        setLogoutUrl(data.logout_url);
        setIgnoreSSO(false);
      })
      .catch((error) => {
        setIgnoreSSO(true);
      });
  };

  useEffect(() => {
    createLogoutFlow();
  }, []);

  useEffect(() => {
    async function logout() {
      if (ignoreSSO !== undefined) {
        await logoutUser(logoutUrl!);
      }
    }

    logout();
  }, [ignoreSSO]);

  return (
    <>
      <Loader color="green" />
      <span>{t('page.logout.waiting')}</span>
    </>
  );
};

export default Logout;
