import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceFullComponentLicenseIssue } from '../../types/FullInterfaces';

interface Params {
  componentId: string;
}

interface Options {
  enabled?: boolean;
  staleTime?: number;
}

export const useFetchComponentLicenseIssues = (
  { componentId }: Params,
  options?: Options,
) => {
  let fetchUrl = `license-issues/component/${componentId}`;

  return useQuery({
    queryKey: [
      'component-license-issues',
      {
        componentId,
      },
      `/${fetchUrl}`,
    ],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => (data?.data ?? []) as InterfaceFullComponentLicenseIssue[],
    enabled: options?.enabled,
    staleTime: options?.staleTime,
  });
};
