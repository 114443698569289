import { useState } from 'react';
import { InterfaceFullGithubIntegration } from '../../../../types/FullInterfaces';
import { useTranslation } from 'react-i18next';
import { useFetchGithubIntegrations } from '../../../../hooks/queries/useFetchGithubIntegrations';
import { useOrganizationId } from '../../../../hooks/utils/useOrganizationId';
import appConfig from '../../../../configs/appConfig';
import Icon from '../../../../components/Icon/Icon';
import {
  Button,
  Group,
  Menu,
  Stack,
} from '@mantine/core';
import ClickableRegion from '../../../../components/ClickableRegion';
import { GitHubUninstallIntegrationConfirmModal } from './components/GitHubUninstallIntegrationConfirmModal';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { IIntegration } from '../integrations-data';
import { GitHubOrganizationsTable } from '../../../../components/DataTables/GitHubOrganizationsTable/GitHubOrganizationsTable';

interface Props {
  integration: IIntegration;
}

const handleInstall = () => {
  window.open(appConfig?.githubAppUrl);
};

export const GithubIntegrationOrganizations = ({ integration }: Props) => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId(null);
  const [uninstallConfirmModalOpen, setUninstallConfirmModalOpen] = useState<boolean>(false);

  const {
    data: fetchedIntegrationData,
    isLoading: isLoadingGitHubOrganizations,
    isFetching: isFetchingGitHubOrganizations
  } =
    useFetchGithubIntegrations();

  // Filter out any rows where there is no GithubIntegration mapped to an OrganizationIntegration
  const fetchedOrganizationIntegrations: InterfaceFullGithubIntegration[] = fetchedIntegrationData ?
    fetchedIntegrationData.filter((integration) => integration.githubIntegration && integration.githubIntegration.length == 1) : [];

  return (
    <div className="github-integration-edit-organizations integration-edit-page anim-slideInDownShort">
      <ClickableRegion href="/settings/integrations">
        <Button variant="subtle" leftIcon={<Icon icon="arrow-left" />}>
          {t('page.userSettings.integrations.back')}
        </Button>
      </ClickableRegion>
      <Group position="apart">
        <div className="integration-page-header">
          <Icon icon={`${integration.icon}` as IconName} iconStyle="fab" size="5x" />
          <Stack spacing="xs">
            <h4>{integration.title}</h4>
            <div className="github-organization-metadata">
              <span>{t('page.userSettings.integrations.github.organization-page-subtitle')}</span>
            </div>
          </Stack>
        </div>
        <Group>
          <Button onClick={handleInstall}>
            <Group spacing="xs">
              <Icon icon="plus" />
              <span>{t('page.userSettings.integrations.github.add-org')}</span>
            </Group>
          </Button>
          <Menu position="bottom-end">
            <Menu.Target>
              <Button
                className="manifest-menu__target"
                variant="default"
              >
                <Icon
                  icon="ellipsis-vertical"
                  style={{ display: 'flex' }}
                  aria-label={t('action.moreOptions')}
                />
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                className="manifest-menu__item"
                onClick={() => {
                  setUninstallConfirmModalOpen(true)
                }}
              >
                <span>{t('page.userSettings.integrations.github.organization.uninstall')}</span>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
      <GitHubOrganizationsTable
        gitHubOrganizations={fetchedOrganizationIntegrations}
        isLoadingGitHubOrganizations={isLoadingGitHubOrganizations}
        isFetchingGitHubOrganizations={isFetchingGitHubOrganizations}
      />
      <GitHubUninstallIntegrationConfirmModal
        open={uninstallConfirmModalOpen}
        onClose={() => setUninstallConfirmModalOpen(false)}
        organizationId={currentOrgId || ''}
      />
    </div>
  );

};
