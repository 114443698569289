import { Button, Group } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
  handleConfirmClose: () => void;
}

function ConfirmCloseModal({ onCancel, handleConfirmClose }: Props) {
  const { t } = useTranslation();

  return (
    <div className="modal-content">
      <div className="modal-text">{t(`triage.modal.body`)}</div>

      <Group position="right">
        <Button variant="default" onClick={onCancel}>
          {t('global.cancel')}
        </Button>
        <Button color="red" onClick={handleConfirmClose}>
          {t('global.yesContinue')}
        </Button>
      </Group>
    </div>
  );
}

export default ConfirmCloseModal;
