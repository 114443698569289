import '../../../../scss/pages/integrations/github.scss';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getGitHubIntegration } from '../integrations-data';
import { IntegrationAddShell } from '../components/IntegrationAddShell';
import { IntegrationAddStart } from '../components/IntegrationAddStart';
import appConfig from '../../../../configs/appConfig';
import { IntegrationEditShell } from '../components/IntegrationEditShell';
import { GitHubIntegrationConfirm } from './components/GitHubIntegrationConfirm';
import { GithubIntegrationOrganizations } from './GitHubIntegrationOrganizations';
import { GitHubIntegrationOrganization } from './GitHubIntegrationOrganization';
import featureFlagDisciminators from '../../../../configs/featureFlags';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';

export const GitHubIntegration = () => {
  const { t } = useTranslation();

  const gitHubIntegrationInfo = getGitHubIntegration(t);

  const githubMultiOrg = useFeatureFlag(featureFlagDisciminators.githubMultiOrg);

  const githubAppUrl =
    appConfig?.appEnv == 'production'
      ? 'https://github.com/apps/manifest-cyber'
      : 'https://github.com/apps/manifest-cyber-dev';

  const handleInstall = () => {
    window.open(githubAppUrl);
  };

  return (
    <Routes>
      <Route
        path="add"
        element={
          <IntegrationAddShell integrationInfo={gitHubIntegrationInfo}>
            <IntegrationAddStart
              integrationInfo={gitHubIntegrationInfo}
              onInstall={handleInstall}
            />
          </IntegrationAddShell>
        }
      />
      <Route
        path="confirm"
        element={
          <IntegrationAddShell integrationInfo={gitHubIntegrationInfo}>
            <GitHubIntegrationConfirm />
          </IntegrationAddShell>
        }
      />
      <Route
        path="*"
        element={<GithubIntegrationOrganizations integration={gitHubIntegrationInfo} />}
      />
      <Route
        path="organizations"
        element={<GithubIntegrationOrganizations integration={gitHubIntegrationInfo} />}
      />
      <Route
        path="organization/:installationId/*"
        element={<GitHubIntegrationOrganization integration={gitHubIntegrationInfo} />}
      />
    </Routes>
  );
};
