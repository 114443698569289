import { LabelType } from '@manifest-cyber/types/interface/db';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';
import { LabelInterface, LABELS_QUERY_KEY } from '../queries/useFetchLabels';

interface UsePostLabelParams {
  name: string;
  color: string;
  type: LabelType;
}

export const usePostLabel = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      label: UsePostLabelParams,
    ): Promise<{
      success: boolean;
      data: LabelInterface[];
      errors: unknown[];
    }> => {
      const response = await hitApi.post(`label`, true, label);

      if (!response.success) {
        return Promise.reject(response.errors);
      }

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [LABELS_QUERY_KEY],
      });
    },
  });
};
