import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import type { InterfaceLicense } from '@manifest-cyber/types/interface/dbTables';

interface Options {
  refetchOnWindowFocus?: boolean;
}

export const useFetchLicenses = (options?: Options) => {
  let fetchUrl = `licenses`;

  return useQuery({
    queryKey: ['licenses', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => (data?.data ?? []) as InterfaceLicense[],
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
