import { AttachScope } from '../types/attachScope.type';

const scopes: AttachScope[] = [
  'create-asset',
  'read-all',
  'update-all',
  'create-all',
  'read-asset',
  'update-asset',
  'read-organization',
  'update-organization',
  'create-user',
  'read-user',
  'update-user',
  'upload-sbom',
];

export const API_TOKEN_SCOPE_OPTIONS: {
  label: AttachScope;
  value: AttachScope;
}[] = scopes.map((item) => ({
  label: item,
  value: item,
}));
