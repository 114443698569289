import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemAction } from '../../components/ListItemAction/ListItemAction';
import Loading from '../../components/Loading';

interface Props {
  isFetchingRecentVulns: boolean;
  fetchedRecentVulns: InterfaceVulnerability[];
}

export const MostRecentVulnerabilities = ({
  isFetchingRecentVulns,
  fetchedRecentVulns,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="most-recent-vulns anim-slideInRightShort">
      <h2 className="widget-header">{t('page.dashboard.recent-vulns.header')}</h2>
      {isFetchingRecentVulns && <Loading />}

      {!isFetchingRecentVulns && fetchedRecentVulns && (
        <ul className="top-list">
          {fetchedRecentVulns.map((vuln) => {
            // @ts-ignore
            let impactedAssets = vuln?.impactedAssets || 0;
            return (
              <ListItemAction
                clickableRegionProps={{
                  regionLabel: 'Click to View Details',
                  href: `/vulnerability/${vuln?.cveId}`,
                }}
                iconProps={{
                  icon: 'triangle-exclamation',
                  iconStyle: 'far',
                  className: 'is-recent',
                }}
                key={vuln?._id?.toString()}
                className="anim-slideInUpShort"
              >
                <div className="details-main is-recent">
                  <div className="head">
                    <h6>{vuln?.cveId}</h6>
                  </div>
                  <div className="details-main-inner is-recent">
                    <span>
                      {t('page.dashboard.recent-vulns.impactedAssets')}:{' '}
                      {impactedAssets.length > 0 ? (
                        <strong>{`${impactedAssets}`}</strong>
                      ) : (
                        impactedAssets
                      )}
                    </span>
                    <span>
                      {t('page.dashboard.recent-vulns.published')}:{' '}
                      {`${DateTime.fromISO(`${vuln?.publishDate}`).toLocaleString(
                        DateTime.DATETIME_MED,
                      )}`}
                    </span>
                  </div>
                </div>
              </ListItemAction>
            );
          })}
        </ul>
      )}
    </div>
  );
};
