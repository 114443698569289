import { Box, Flex, MultiSelect, Select, TextInput } from '@mantine/core';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import hitApi from '../../../api';
import ColoredMessage from '../../../components/ColoredMessage/ColoredMessage';
import Loading from '../../../components/Loading';
import ManifestModal from '../../../components/ManifestModal/ManifestModal';
import { useOrganizationId } from '../../../hooks/utils/useOrganizationId';
import '../../../scss/pages/api_tokens.scss';
import { API_TOKEN_SCOPE_OPTIONS } from '../ApiTokens/constants/scopeOptions.constant';
import { AttachScope } from '../ApiTokens/types/attachScope.type';
import { CreateaAPITokenBody } from '../ApiTokens/types/createTokenBody.interface';
import styles from './CreateTokenModal.module.scss';

interface ICreateTokenModalProps {
  onSuccess: (tokenSecet: string) => void;
  onCancel: () => void;
  open: boolean;
  addErrors: (error: string[], overwriteExisting: boolean) => void;
}

interface FormValues {
  name: string;
  description: string;
  expirationDate: ExpirationDate;
  attachScopes: AttachScope[];
}

const defaultFormValue: FormValues = {
  name: '',
  attachScopes: [],
  description: '',
  expirationDate: '3months',
};

type ExpirationDate = '1months' | '3months' | '6months' | '1years';
type ExpirationTimeOption = { label: string; value: ExpirationDate };

const recommendedExpirationDates: ExpirationDate[] = ['1months', '3months'];

const getExpirationTimeOptions = (t: TFunction): ExpirationTimeOption[] => [
  { label: t('global.month', { count: 1 }), value: '1months' },
  { label: t('global.month', { count: 3 }), value: '3months' },
  { label: t('global.month', { count: 6 }), value: '6months' },
  { label: t('global.year', { count: 1 }), value: '1years' },
];

function CreateTokenModal({
  open,
  onCancel,
  onSuccess,
  addErrors,
}: ICreateTokenModalProps) {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isCreatingNewToken, setIsCreatingNewToken] = useState(false);
  const expirationTimeOptions = getExpirationTimeOptions(t);
  const [formValue, setFormValue] = useState<FormValues>(defaultFormValue);

  useEffect(() => {
    if (open) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [open]);

  const createNewToken = async () => {
    setIsCreatingNewToken(true);

    if (formValue?.name && formValue?.name.length > 0) {
      const body: CreateaAPITokenBody = {
        label: formValue.name || 'My API Token',
        organizationId: currentOrgId || '',
        attachScopes: formValue.attachScopes.join(', ').trim(),
        description: formValue.description,
        expireAfter: formValue.expirationDate,
      };

      const createdToken = await hitApi.post('api-token/create', true, body);

      if (createdToken?.errors) {
        addErrors(createdToken?.errors, true);
      }

      if (createdToken?.success && createdToken?.data && createdToken?.data[0]) {
        onSuccess(createdToken?.data[0]?.tokenSecretVisibleOnceOnly);
        setFormValue(defaultFormValue);
      } else {
        addErrors(
          [t('page.userSettings.apiTokens.modal.errors.somethingWentWrong')],
          true,
        );
      }
      handleClose();
    } else {
      addErrors([t('page.userSettings.apiTokens.modal.errors.nameRequired')], false);
    }

    setIsCreatingNewToken(false);
    // await fetchTokens();
  };

  const handleConfirm = () => {
    createNewToken();
  };

  const handleClose = () => {
    setFormValue(defaultFormValue);
    onCancel();
  };

  return (
    <ManifestModal
      opened={modalOpen}
      withCloseButton
      onClose={handleClose}
      size="md"
      title={t('page.userSettings.apiTokens.modal.header')}
      body={
        <Flex direction={'column'} gap={16}>
          {!isCreatingNewToken && (
            <>
              <TextInput
                classNames={{ label: styles.controlLabel }}
                withAsterisk
                label={t('page.userSettings.apiTokens.modal.tokenName')}
                className="modal-form-input"
                value={formValue?.name}
                onChange={(x) => setFormValue({ ...formValue, name: x.target.value })}
                name="name"
              />

              <TextInput
                classNames={{ label: styles.controlLabel }}
                label={t('global.description')}
                className="modal-form-input"
                value={formValue?.description}
                onChange={(x) =>
                  setFormValue({ ...formValue, description: x.target.value })
                }
                name="description"
              />

              <MultiSelect
                classNames={{ label: styles.controlLabel }}
                label={t('page.userSettings.apiTokens.modal.scope')}
                withAsterisk
                placeholder={t('global.select')}
                value={formValue?.attachScopes}
                data={API_TOKEN_SCOPE_OPTIONS}
                onChange={(selectedValues) =>
                  setFormValue({
                    ...formValue,
                    attachScopes: selectedValues as AttachScope[],
                  })
                }
                searchable
                style={{ width: '100%' }}
              />
              <Box>
                <Select
                  classNames={{ label: styles.controlLabel }}
                  label={t('page.userSettings.apiTokens.modal.expirationTime')}
                  withAsterisk
                  value={formValue?.expirationDate}
                  data={expirationTimeOptions}
                  onChange={(value) => {
                    if (value) {
                      setFormValue({
                        ...formValue,
                        expirationDate: value as ExpirationDate,
                      });
                    }
                  }}
                />
                {!recommendedExpirationDates.includes(formValue.expirationDate) && (
                  <Box mt={8}>
                    <ColoredMessage
                      type="warning"
                      message={t(
                        'page.userSettings.apiTokens.modal.warningRecommendedExpirationTime',
                      )}
                    />
                  </Box>
                )}
              </Box>
            </>
          )}
          {isCreatingNewToken && <Loading />}
        </Flex>
      }
      primaryAction={{
        label: t('global.create'),
        onClick: handleConfirm,
        isLoading: isCreatingNewToken,
      }}
      secondaryAction={{
        label: t('global.cancel'),
        onClick: handleClose,
      }}
    />
  );
}

export default CreateTokenModal;
