import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceFullAssetLicenseIssue } from '../../types/FullInterfaces';

interface Params {
  assetId: string;
}

interface Options {
  enabled?: boolean;
}

export const useFetchAssetLicenseIssues = ({ assetId }: Params, option?: Options) => {
  let fetchUrl = `license-issues/asset/${assetId}`;

  return useQuery({
    enabled: option?.enabled,
    queryKey: ['asset-license-issues', { assetId }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => (data?.data ?? []) as InterfaceFullAssetLicenseIssue[],
  });
};
