import { Flex } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { ManifestTextInput } from '../../../components/ManifestTextInput/ManifestTextInput';

export interface PortalUserInformationFormInterface {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  role: string;
}

export interface PortalUserInformationFormProps {
  showEmailField: boolean;
  form: UseFormReturnType<PortalUserInformationFormInterface>;
}

export const PortalUserInformationForm = ({
  showEmailField,
  form,
}: PortalUserInformationFormProps) => {
  const { t } = useTranslation();

  return (
    <Flex gap="12px" direction="column" w="100%">
      <ManifestTextInput
        withAsterisk
        inputProps={form.getInputProps('firstName')}
        label={t('portal.userSettingsForm.firstName')}
      />
      <ManifestTextInput
        withAsterisk
        inputProps={form.getInputProps('lastName')}
        label={t('portal.userSettingsForm.lastName')}
      />
      {showEmailField && (
        <ManifestTextInput
          withAsterisk
          disabled
          inputProps={form.getInputProps('email')}
          label={t('portal.userSettingsForm.email')}
        />
      )}
      <ManifestTextInput
        withAsterisk
        inputProps={form.getInputProps('organization')}
        label={t('portal.userSettingsForm.organization')}
      />
      <ManifestTextInput
        withAsterisk
        inputProps={form.getInputProps('role')}
        label={t('portal.userSettingsForm.role')}
      />
    </Flex>
  );
};
