import normalizeAuthor from '../../../../lib/normalizeAuthor';
import {
  InterfaceOrganizationAsset,
  InterfaceOrganizationComponent,
} from '@manifest-cyber/types/interface/dbTables';
import { Badge, Flex, Text, Tooltip } from '@mantine/core';
import styles from '../AssetsTable.module.scss';
import { useTranslation } from 'react-i18next';
import { MRT_Row } from 'mantine-react-table';

export const SupplierColumn = ({ row }: { row: MRT_Row<InterfaceOrganizationAsset> }) => {
  const { t } = useTranslation();
  const author = normalizeAuthor(
    row.original as InterfaceOrganizationComponent,
    t,
    'author',
  );
  return (
    <Flex
      align="center"
      className={`${author === 'Unknown Source' ? 'table-na' : undefined} ${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
      sx={{ overflow: 'hidden' }}
    >
      <Tooltip disabled={(author?.length ?? 0) < 20} label={author}>
        <Text
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {author}
        </Text>
      </Tooltip>
      {row.original?.relationshipToOrg === 'first' && (
        <Badge
          sx={{
            width: '35px',
            '& span': {
              textOverflow: 'none',
              overflow: 'visible',
            },
          }}
        >
          1st
        </Badge>
      )}
    </Flex>
  );
};
