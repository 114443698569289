import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { forwardRef } from 'react';
import Icon from '../../../../components/Icon';
import { useTranslation } from 'react-i18next';
import capitalizeWords from '../../../../lib/capitalizeWords';
import ClickableRegion from '../../../../components/ClickableRegion';

interface Props {
  vuln: InterfaceVulnerability;
}
function RiskyComponentVulnPopover({ vuln }: Props) {
  const { t } = useTranslation();
  const [cvePopoverOpened, { close: closeCvePopover, open: openCvePopover }] =
    useDisclosure(false);

  const ClickableRegionRef = forwardRef<HTMLDivElement>((props, ref) => (
    <div ref={ref} className="cve-link">
      <ClickableRegion href={`/vulnerability/${vuln?.cveId?.toString()}`}>
        <Icon
          icon={
            vuln?.recommendedAction === 'mitigate'
              ? 'triangle-exclamation'
              : vuln?.recommendedAction === 'monitor'
              ? 'circle-exclamation'
              : 'circle-exclamation'
          }
        />
        {vuln?.cveId}
      </ClickableRegion>
    </div>
  ));

  function renderCvss(): React.ReactNode {
    if (vuln) {
      const { cvss2BaseScore, cvss2BaseSeverity, cvss3BaseScore, cvss3BaseSeverity } =
        vuln;
      const cvssData = {
        baseScore: '--',
        baseSeverity: '--',
      };
      //prioritize cvss3
      if (cvss2BaseScore || cvss2BaseScore === 0)
        cvssData.baseScore = cvss2BaseScore?.toString() || '--';
      if (cvss2BaseSeverity)
        cvssData.baseSeverity = capitalizeWords(cvss2BaseSeverity.toLocaleLowerCase());
      if (cvss3BaseScore || cvss3BaseScore === 0)
        cvssData.baseScore = cvss3BaseScore?.toString() || '--';
      if (cvss3BaseSeverity)
        cvssData.baseSeverity = capitalizeWords(cvss3BaseSeverity.toLocaleLowerCase());
      return (
        <>
          <span
            className={`${cvssData.baseSeverity.toLowerCase()}`}
          >{`${cvssData.baseSeverity} `}</span>
          <span>{`(${cvssData.baseScore})`}</span>
        </>
      );
    }
    return <>--</>;
  }

  function getEpssSeverity(percentile: number): string {
    if (percentile >= 80) {
      return t('jargon.severity.critical');
    } else if (percentile >= 60 && percentile < 80) {
      return t('jargon.severity.high');
    } else if (percentile >= 40 && percentile < 60) {
      return t('jargon.severity.medium');
    } else if (percentile < 40) {
      return t('jargon.severity.low');
    } else {
      return t('jargon.severity.unknown');
    }
  }

  function renderExploitability(): React.ReactNode {
    if (vuln) {
      const { epssPercentile, epssScore } = vuln;
      const epssData = {
        exploitability: '--',
        percentile: '--',
        severity: 'Unknown',
      };
      if (epssPercentile || epssPercentile === 0) {
        const percentile = Math.round(epssPercentile * 100);
        const severity = getEpssSeverity(percentile);
        epssData.percentile = percentile.toString() + '%';
        epssData.severity = severity;
      }
      if (epssScore || epssScore === 0) {
        const exploitability = Math.round(epssScore * 100);
        epssData.exploitability = exploitability.toString() + '%';
      }
      return (
        <>
          <span
            className={`${epssData.severity.toLowerCase()}`}
          >{`${epssData.severity} `}</span>
          <span>{`(${epssData.percentile})`}</span>
        </>
      );
    }
    return <>--</>;
  }
  return (
    <Popover
      width={228}
      position="top-start"
      withArrow
      shadow="md"
      opened={cvePopoverOpened}
    >
      <Popover.Target>
        <div
          onMouseEnter={openCvePopover}
          onMouseLeave={closeCvePopover}
          className={`cve ${
            vuln?.recommendedAction === 'mitigate'
              ? 'mitigate'
              : vuln?.recommendedAction === 'monitor'
              ? 'monitor'
              : ''
          } `}
        >
          <ClickableRegionRef />
        </div>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
        <div className="cve-popover-content">
          <div className="cve-popover-header">{vuln?.cveId}</div>
          <div className="cve-popover-body">
            <div className="cve-popover-body-row">
              <div className="header-row">{t('global.recommendation')}</div>
              <div className={`cve-popover-body-row-value ${vuln.recommendedAction}`}>
                {vuln?.recommendedAction
                  ? capitalizeWords(vuln.recommendedAction)
                  : t('global.na')}
              </div>
            </div>
            <div className="cve-popover-body-row">
              <div className="header-row">{t('global.cvss')}</div>
              <div className="cve-popover-body-row-value">{renderCvss()}</div>
            </div>
            <div className="cve-popover-body-row">
              <div className="header-row">{t('global.epss')}</div>
              <div className="cve-popover-body-row-value">{renderExploitability()}</div>
            </div>
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

export default RiskyComponentVulnPopover;
