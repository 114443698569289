import { DateTime } from 'luxon';
import hitApi from '../../../../api';
import { rejectFailedRequest } from '../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { APIResponse } from '../../../../types/api_response.model';

export interface UsersWithAccessToSharedSbom {
  sbomId: string;
  emails: {
    email: string;
    sharedDate: string;
  }[];
}

export interface MappedUsersWithAccessToSharedSbom {
  email: string;
  dateSharedText: string;
  avatarLetter: string;
}

const getSharedWithUsers = async (
  id: string,
): Promise<MappedUsersWithAccessToSharedSbom[]> => {
  const response = (await hitApi.get(
    `portal/sbom/${id}/portal-share`,
  )) as APIResponse<UsersWithAccessToSharedSbom>;

  const { data } = await rejectFailedRequest(response);

  return data.emails.map((user) => ({
    email: user.email,
    avatarLetter: user.email[0].toUpperCase(),
    dateSharedText: DateTime.fromISO(user.sharedDate).toFormat('MMMM dd, yyyy'),
  }));
};

export interface ShareResourcePayload {
  emails: string[];
  sbomIds: string[];
  notifyByEmail?: boolean;
}

const shareResource = async (payload: ShareResourcePayload) => {
  const response = (await hitApi.post(
    '/portal/sbomshare',
    true,
    payload,
  )) as APIResponse<{}>;
  const { data } = await rejectFailedRequest(response);

  return data;
};

export interface DeleteAccesToResourceParams {
  emails: string[];
  sbomId: string;
}

const deleteAccesToResource = async ({ emails, sbomId }: DeleteAccesToResourceParams) => {
  const response = (await hitApi.delete(`portal/sbomshare`, true, {
    emails,
    sbomId,
  })) as APIResponse<undefined>;
  const { data } = await rejectFailedRequest(response);

  return data;
};

export const ShareResourceApi = {
  getSharedWithUsers,
  shareResource,
  deleteAccesToResource,
};
