import './LicensesTable.scss';
import { useMemo, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  MultiSelect,
  Text,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableOptions,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import Icon from '../Icon/Icon';
import ClickableRegion from '../ClickableRegion';
import { InterfaceLicense } from '@manifest-cyber/types/interface/dbTables';
import { useTranslation } from 'react-i18next';
import { getDefaultTableOptions } from '../MRT/ManifestMRT';
import { useFetchLicenses } from '../../hooks/queries/useFetchLicenses';
import { useDeleteLicense } from '../../hooks/mutations/useDeleteLicense';
import { usePutLicense } from '../../hooks/mutations/usePutLicense';
import { usePostLicense } from '../../hooks/mutations/usePostLicense';
import { useAuth } from '../../hooks/useAuth';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { CreateLicenseModal } from '../../pages/UserSettings/components/CreateLicenseModal';

const defaultTableOptions = getDefaultTableOptions<InterfaceLicense>();

interface Props {
  hasAdminEditPermissions: boolean;
}

export const LicensesTable = ({ hasAdminEditPermissions }: Props) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [currentOrgId] = useOrganizationId(null);

  const hasEditPermissions = useMemo(
    () =>
      hasAdminEditPermissions ||
      (!!user?.internalRoles?.length &&
        (user?.internalRoles?.includes('staff') ||
          user?.internalRoles?.includes('admin'))) ||
      (!!user?.isAdminOfOrganizations?.length &&
        currentOrgId &&
        user?.isAdminOfOrganizations?.includes(currentOrgId)),
    [user],
  );

  const [validationErrors, setValidationErrors] = useState<
    Partial<Record<keyof InterfaceLicense, string>>
  >({});

  const [createLicenseModalOpened, setCreateLicenseModalOpened] = useState(false);

  const validateLicense = (license: InterfaceLicense): boolean => {
    const errors: Partial<Record<keyof InterfaceLicense, string>> = {};
    if (!license.shortName) {
      errors.shortName = t('page.userSettings.licenses.validationMsgs.shortName');
    }
    if (!license.fullName) {
      errors.fullName = t('page.userSettings.licenses.validationMsgs.fullName');
    }
    setValidationErrors(errors);
    return !Object.keys(errors).length;
  };

  const {
    data: fetchedLicenses = [],
    isLoading: isLoadingLicenses,
    isFetching: isFetchingLicenses,
  } = useFetchLicenses({ refetchOnWindowFocus: false });

  const { mutateAsync: postLicense, isLoading: isCreatingLicense } = usePostLicense();
  const { mutateAsync: putLicense, isLoading: isUpdatingLicense } = usePutLicense();
  const { mutateAsync: deleteLicense, isLoading: isDeletingLicense } = useDeleteLicense();

  const handleCreateLicense = async (license: InterfaceLicense) => {
    if (!validateLicense(license)) return;
    await postLicense({ license: { ...license } });
    setCreateLicenseModalOpened(false);
  };

  const handleUpdateLicense: MRT_TableOptions<InterfaceLicense>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      const license = {
        ...row.original,
        ...values,
        alertWhenFound: values.alertWhenFound === 'true',
      } as InterfaceLicense;
      if (!validateLicense(license)) return;
      await putLicense({ license });
      exitEditingMode();
    };

  const openDeleteConfirmModal = (row: MRT_Row<InterfaceLicense>) =>
    modals.openConfirmModal({
      title: t('page.userSettings.licenses.confirmDeleteModal.title'),
      children: (
        <Text>
          {t('page.userSettings.licenses.confirmDeleteModal.body', {
            shortName: row.original.shortName,
          })}
        </Text>
      ),
      labels: { confirm: t('global.delete'), cancel: t('global.cancel') },
      confirmProps: { color: 'red' },
      onConfirm: () => deleteLicense({ licenseId: row.original._id?.toString()! }),
    });

  const columns = useMemo<MRT_ColumnDef<InterfaceLicense>[]>(
    () => [
      {
        accessorFn: (row) => (row.alertWhenFound ? 'true' : 'false'),
        id: 'alertWhenFound',
        header: t('page.userSettings.licenses.table.headers.alertWhenFound'),
        size: 50,
        Header: (
          <div style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
            Alert When Found
          </div>
        ),
        filterVariant: 'checkbox',
        enableEditing: false,
        enableSorting: false,
        mantineFilterCheckboxProps: ({ column }) => ({
          label: !column.getFilterValue()
            ? t('page.userSettings.licenses.table.headers.alertFilterIndeterminate')
            : column.getFilterValue() === 'true'
              ? t('page.userSettings.licenses.table.headers.alertFilterOn')
              : t('page.userSettings.licenses.table.headers.alertFilterOff'),
        }),
        Cell: ({ cell, row }) => (
          <Tooltip
            label={t('page.userSettings.licenses.table.headers.alertWhenFoundTooltip')}
          >
            <span>
              <Checkbox
                checked={cell.getValue<string>() === 'true'}
                // disabled={!hasEditPermissions}
                disabled
                onChange={async (event) =>
                  await putLicense({
                    license: {
                      ...row.original,
                      alertWhenFound: event.currentTarget.checked,
                    },
                  })
                }
              />
            </span>
          </Tooltip>
        ),
        Edit: () => <></>,
      },
      {
        accessorKey: 'fullName',
        header: t('page.userSettings.licenses.table.headers.fullName'),
        size: 250,
        mantineEditTextInputProps: {
          required: true,
        },
        Cell: ({ renderedCellValue, row }) => (
          <Flex align="center" gap="xs">
            {renderedCellValue}{' '}
            {row.original.licenseUrl ? (
              <ClickableRegion
                regionLabel={t('page.userSettings.licenses.table.headers.licenseUrl')}
                target="_blank"
                href={row.original.licenseUrl}
              >
                <ActionIcon color="cyan">
                  <Icon icon="external-link" size="xs" />
                </ActionIcon>
              </ClickableRegion>
            ) : null}
          </Flex>
        ),
      },
      {
        accessorKey: 'shortName',
        header: t('page.userSettings.licenses.table.headers.shortName'),
        size: 150,
        mantineEditTextInputProps: {
          required: true,
        },
      },
      {
        accessorKey: 'aliases',
        header: t('page.userSettings.licenses.table.headers.shortName'),
        size: 150,
        Cell: ({ cell }) => cell.getValue<string[]>().join(', ') || t('global.none'),
        Edit: ({ cell, row, table }) => {
          const defaultAliases = new Set<string>([
            ...cell.getValue<string[]>(),
            row.original.shortName!,
            row.original.fullName!,
          ]);
          return (
            <MultiSelect
              creatable
              data={Array.from(defaultAliases)}
              disabled
              label={t('page.userSettings.licenses.table.headers.aliases')}
              value={cell.getValue<string[]>()}
              onChange={(value) => {
                row._valuesCache.aliases = value;
                table.setEditingRow({ ...row });
              }}
            />
          );
        },
      },
      {
        accessorKey: 'licenseUrl',
        header: t('page.userSettings.licenses.table.headers.licenseUrl'),
        mantineEditTextInputProps: {},
      },
      {
        accessorKey: 'licenseType',
        header: t('page.userSettings.licenses.table.headers.licenseType'),
        size: 150,
        filterVariant: 'multi-select',
        enableGlobalFilter: false,
        editVariant: 'select',
        mantineEditSelectProps: {
          clearable: true,
          creatable: true,
          data: ['Copyleft', 'Custom', 'Permissive', 'Proprietary'],
        },
      },
      {
        accessorKey: 'description',
        header: t('page.userSettings.licenses.table.headers.description'),
        mantineEditTextInputProps: {},
      },
    ],
    [validationErrors],
  );

  const table = useMantineReactTable({
    ...defaultTableOptions,
    autoResetPageIndex: false, //don't go to page 1 after editing
    columns,
    data: fetchedLicenses,
    getRowId: (row) => row._id?.toString(),
    enableFacetedValues: true,
    enableEditing: hasEditPermissions,
    enablePinning: true,
    onEditingRowSave: handleUpdateLicense,
    initialState: {
      ...defaultTableOptions.initialState,
      columnVisibility: {
        licenseUrl: false,
        description: false,
        aliases: false,
      },
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
      sorting: [{ id: 'alertWhenFound', desc: true }],
      columnFilters: [
        {
          id: 'alertWhenFound',
          value: 'true',
        },
      ],
    },
    state: {
      isLoading: isLoadingLicenses,
      isSaving: isUpdatingLicense,
      showLoadingOverlay:
        isFetchingLicenses || isUpdatingLicense || isDeletingLicense || isCreatingLicense,
    },
    renderRowActions: hasEditPermissions
      ? ({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <Tooltip label={t('global.edit')}>
              <ActionIcon onClick={() => table.setEditingRow(row)}>
                <Icon icon="edit" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label={t('global.delete')}>
              <ActionIcon onClick={() => openDeleteConfirmModal(row)}>
                <Icon icon="trash" />
              </ActionIcon>
            </Tooltip>
          </Box>
        )
      : undefined,
    renderTopToolbarCustomActions: () => (
      <>
        {hasEditPermissions && (
          <Button
            leftIcon={<Icon icon="plus" />}
            onClick={() => setCreateLicenseModalOpened(true)}
          >
            {t('page.userSettings.licenses.add-license')}
          </Button>
        )}
      </>
    ),
  });

  return (
    <>
      <MantineReactTable table={table} />
      <CreateLicenseModal
        isCreating={isCreatingLicense}
        opened={createLicenseModalOpened}
        onCancel={() => setCreateLicenseModalOpened(false)}
        onConfirm={handleCreateLicense}
        validationErrors={validationErrors}
      />
    </>
  );
};
