import { InterfaceOrganization } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';

interface Params {}

interface Options {
  staleTime?: number;
}

export const useFetchChildOrganizations = ({}: Params, options?: Options) => {
  const fetchUrl = `organization/child`;

  return useQuery({
    enabled: true,
    queryKey: ['childOrganizations', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => data?.data as any[],
    onError: (error) => {
      console.error('Unable to fetch child organizations: ', error);
    },
    staleTime: options?.staleTime,
  });
};
