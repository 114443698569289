import { useQuery, UseQueryResult } from '@tanstack/react-query';
import hitApi from '../../api';
import { rejectFailedRequest } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { InterfaceFullAsset } from '../../types/FullInterfaces';
import { APIResponse } from '../../types/api_response.model';

export interface FetchOptions {
  refetchInterval?: number;
  staleTime?: number;
  keepPreviousData?: boolean;
}

export const useFetchAsset = (
  assetId?: string,
  organizationId?: string,
  options?: FetchOptions,
): UseQueryResult<InterfaceFullAsset, unknown> => {
  const fetchUrl = `asset/${assetId}${organizationId ? `/${organizationId}` : ''}`;

  return useQuery({
    enabled: !!assetId,
    queryKey: ['asset', { _id: assetId }, `/${fetchUrl}`],
    queryFn: async () => {
      const response = (await hitApi.get(fetchUrl)) as APIResponse<InterfaceFullAsset[]>;
      return (await rejectFailedRequest(response)).data[0];
    },
    refetchInterval: options?.refetchInterval,
    staleTime: options?.staleTime,
    keepPreviousData: options?.keepPreviousData,
  });
};
