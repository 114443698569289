import { useTranslation } from 'react-i18next';
import { useFetchComponentLicenseIssues } from '../../hooks/queries/useFetchComponentLicenseIssues';
import { ActionIcon, Flex, Stack, Text, Title, Tooltip } from '@mantine/core';
import Icon from '../Icon';
import { MRT_Row } from 'mantine-react-table';
import { InterfaceOrganizationComponentWithLicenses } from '../../types/FullInterfaces';
import { renderText } from './ComponentsTable';
import { InterfaceLicense } from '@manifest-cyber/types/interface/dbTables';

interface LicenseCellProps {
  row: MRT_Row<InterfaceOrganizationComponentWithLicenses>;
}

export const ComponentsLicenseCell = ({ row }: LicenseCellProps) => {
  const { t } = useTranslation();

  const rawLicenses = row?.original?.licensesData;
  let licenses: string = '';
  if (rawLicenses && rawLicenses.length > 0) {
    for (const thisLicense of rawLicenses) {
      const name =
        thisLicense?.fullName || thisLicense?.shortName || thisLicense?.aliases?.[0];
      if (name) {
        if (licenses.length > 0) {
          licenses += ', ';
        }
        licenses += `${name}`;
      }
    }
    licenses = licenses.trim();
  }

  const hasLicenseIssue = !!row.original.countLicenseIssues?.total;

  const { data: componentLicenseIssues } = useFetchComponentLicenseIssues(
    {
      componentId: row.original._id?.toString()!,
    },
    {
      enabled: !!hasLicenseIssue,
      staleTime: 1000 * 60 * 60, // 1 hour
    },
  );

  return licenses && licenses?.length > 0 ? (
    <Flex align="center" gap="xl" className={hasLicenseIssue ? 'critical' : undefined}>
      {renderText(`${licenses}`)}
      {componentLicenseIssues?.map((licenseIssue) => (
        <Tooltip
          label={
            <Stack>
              <Title order={6} lh="1rem">
                {licenseIssue?.license?.fullName}
              </Title>
              <Text lh="1rem">
                {t('tables.components.type', {
                  licenseType: licenseIssue?.license?.licenseType,
                })}
              </Text>
            </Stack>
          }
        >
          <ActionIcon color="red.5" variant="transparent">
            <Icon icon="circle-exclamation" />
          </ActionIcon>
        </Tooltip>
      ))}
    </Flex>
  ) : (
    <span className="table-na">{t('global.notProvided')}</span>
  );
};
