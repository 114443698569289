import { Card, Table, Text, Title, Tooltip } from '@mantine/core';
import capitalizeWords from '../../../../lib/capitalizeWords';
import Icon from '../../../Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TopVulns.module.scss';
import {
  FetchProductOverviewResponse,
  ProductOverviewVulnerability,
} from '../../../../hooks/products/useFetchProductOverview';
import ClickableRegion from '../../../ClickableRegion';
import { VulnerabilityRiskAction } from '@manifest-cyber/types/interface/db';

type ProductOverviewData = FetchProductOverviewResponse['data'];

interface InterfaceProductOverviewTopVulns {
  top5Vulns: ProductOverviewData['top5Vulns'];
}

export const ProductOverviewTopVulns = ({
  top5Vulns,
}: InterfaceProductOverviewTopVulns) => {
  const { t } = useTranslation();

  const getRecommendActionStyle = (recommendAction?: VulnerabilityRiskAction | null) => {
    if (recommendAction === 'mitigate') {
      return styles.mitigate;
    }

    return '';
  };

  return (
    <Card className={styles.topVulns}>
      <Title className={styles.title}>
        {t('page.product.top5Vulns')}{' '}
        <Tooltip label={t('page.product.ordering-tooltip')} position="top" withArrow>
          <span>
            <Icon icon="info-circle" iconSharp />
          </span>
        </Tooltip>
      </Title>
      {!top5Vulns && <Text className={styles.noVulns}>{t('page.product.noVulns')}</Text>}
      {!!top5Vulns && (
        <Table>
          <thead>
            <tr>
              <th className={styles.tableHead}>{t('page.product.vulnId')}</th>
              <th className={styles.tableHead}>{t('page.product.recommend')}</th>
              <th className={styles.tableHead}>{t('page.product.numberOfAssets')}</th>
            </tr>
          </thead>
          <tbody>
            {top5Vulns.map(
              (vulnerability: ProductOverviewVulnerability, index: number) => (
                <tr key={index}>
                  <td className={styles.tableCell}>
                    {vulnerability.kevData?.inKEV ? (
                      <Icon icon="bolt" classNames={styles.inKev} />
                    ) : (
                      <Icon icon="triangle-exclamation" classNames={styles.mitigate} />
                    )}{' '}
                    <ClickableRegion
                      className={styles.vulnName}
                      target="_blank"
                      href={`/vulnerability/${vulnerability.cveId}`}
                    >
                      {vulnerability.cveId}
                    </ClickableRegion>
                  </td>
                  <td className={styles.tableCell}>
                    <Text
                      className={getRecommendActionStyle(vulnerability.recommendedAction)}
                    >
                      {vulnerability.recommendedAction &&
                        capitalizeWords(vulnerability.recommendedAction)}
                    </Text>
                  </td>
                  <td className={styles.tableCell}>{vulnerability.occurrences}</td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      )}
    </Card>
  );
};
