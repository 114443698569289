import { useMemo, useState } from 'react';
import {
  Repository,
  RepositoryManifestSettings,
} from '@manifest-cyber/types/interface/dbIntegration';
import { useTranslation } from 'react-i18next';
import { SelectPicker } from 'rsuite';
import { Badge, Button, Flex, Loader, Switch, Tooltip } from '@mantine/core';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { usePostGitHubSettings } from '../../../../../../hooks/mutations/usePostGitHubSettings';
import Icon from '../../../../../../components/Icon/Icon';
import { InterfaceFullGithubIntegration } from '../../../../../../types/FullInterfaces';
import styles from './GitHubOrganizationRepositories.module.scss';

interface Props {
  fetchedGitHubIntegration: InterfaceFullGithubIntegration;
  isLoadingGitHubIntegration: boolean;
}

const columnHelper = createColumnHelper<Repository>();

export const GitHubOrganizationRepositories = ({
  fetchedGitHubIntegration,
  isLoadingGitHubIntegration,
}: Props) => {
  const { t } = useTranslation();

  const [editedRepositorySettings, setEditedRepositorySettings] = useState<
    Record<string, RepositoryManifestSettings>
  >({});
  const [settingsEdited, setSettingsEdited] = useState<boolean>(false);

  const githubIntegration = fetchedGitHubIntegration.githubIntegration[0];

  const fetchedRepositories = githubIntegration.repositories || [];
  const fetchedRepositorySettings = githubIntegration.repositoryManifestSettings || {};

  const { mutateAsync: postGitHubSettings, isLoading: isSavingGitHubSettings } =
    usePostGitHubSettings();

  const currentRepositorySettings = {
    ...fetchedRepositorySettings,
    ...editedRepositorySettings,
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => t('global.name'),
        cell: ({ cell, row }) => (
          <div
            className="repository-name-cell"
            style={{
              opacity:
                currentRepositorySettings[row.id]?.isEnabled === false ||
                githubIntegration.status === 'suspended'
                  ? 0.5
                  : 1,
            }}
          >
            <div className="repository-name-alerts">
              {row?.original?.actionRequired && row?.original?.status !== 'archived' && (
                <div className="action-required-icon">
                  <Tooltip
                    label={
                      row?.original?.actionRequired?.includes('page.userSettings')
                        ? t(`${row?.original?.actionRequired}`)
                        : `${row?.original?.actionRequired}`
                    }
                  >
                    <span>
                      <Icon icon="exclamation-triangle" />
                    </span>
                  </Tooltip>
                </div>
              )}
              <div className="name-privacy">
                <div className="repository-name">
                  {cell.getValue()}
                  {row?.original?.status === 'archived' && (
                    <Badge className={styles.archived}>
                      {t('page.userSettings.integrations.github.repo-archived')}
                    </Badge>
                  )}
                </div>
                <div className="repository-last-modified">
                  {row?.original?.actionRequired &&
                    row?.original?.status !== 'archived' && (
                      <Tooltip
                        label={
                          row?.original?.actionRequired?.includes('page.userSettings')
                            ? t(`${row?.original?.actionRequired}`)
                            : `${row?.original?.actionRequired}`
                        }
                      >
                        <div className="action-required">
                          <span>
                            {t(
                              'page.userSettings.integrations.github.actions-required.short',
                            )}
                          </span>
                        </div>
                      </Tooltip>
                    )}
                  <div className="privacy">
                    {t(
                      `page.userSettings.integrations.github.repo-privacy-${
                        row.original.private ? 'private' : 'public'
                      }`,
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      }),

      columnHelper.accessor(
        (originalRow) =>
          currentRepositorySettings[originalRow.id.toString()]?.triggers?.[0]
            ?.triggerTarget,
        {
          id: 'generationFrequency',
          header: () => t('global.generationFrequency'),
          cell: ({ cell, row }) => (
            <div className="select-cell">
              <SelectPicker
                block
                disabled={
                  currentRepositorySettings[row.id]?.isEnabled === false ||
                  githubIntegration.status === 'suspended'
                }
                data={[
                  {
                    label: t(
                      'page.userSettings.integrations.github.generationFrequencyOptions.push',
                    ),
                    value: 'push',
                  },
                  {
                    label: t(
                      'page.userSettings.integrations.github.generationFrequencyOptions.day',
                    ),
                    value: 'day',
                  },
                  {
                    label: t(
                      'page.userSettings.integrations.github.generationFrequencyOptions.week',
                    ),
                    value: 'week',
                  },
                  {
                    label: t(
                      'page.userSettings.integrations.github.generationFrequencyOptions.month',
                    ),
                    value: 'month',
                  },
                ]}
                onChange={(val) => {
                  if (val) {
                    setEditedRepositorySettings((prev) => ({
                      ...prev,
                      [row.original.id.toString()]: {
                        ...prev[cell.row.original.id.toString()],
                        triggers: [
                          {
                            triggerType: val === 'push' ? 'push' : 'time',
                            triggerCriteria:
                              val === 'push' ? row.original.defaultBranch : '',
                            triggerTarget: val !== 'push' ? val : 'push',
                          },
                        ],
                      },
                    }));
                    setSettingsEdited(true);
                  }
                }}
                defaultValue={
                  currentRepositorySettings[row.id.toString()]?.triggers?.[0]
                    ?.triggerTarget
                }
              />
            </div>
          ),
        },
      ),
      columnHelper.accessor(
        (originalRow) =>
          currentRepositorySettings[originalRow.id]?.triggers?.[0]?.triggerCriteria,
        {
          id: 'triggerCriteria',
          header: () => (
            <Tooltip
              label={t('page.userSettings.integrations.branch-tooltip')}
              multiline
              width={400}
            >
              <span>{t('global.branch')}</span>
            </Tooltip>
          ),
          cell: ({ cell, row }) => (
            <div className="select-cell">
              <Tooltip
                label={t('page.userSettings.integrations.branch-tooltip')}
                multiline
                width={400}
              >
                <div>
                  <SelectPicker
                    disabled //temp
                    block
                    data={[
                      {
                        label: row.original.defaultBranch,
                        value: row.original.defaultBranch,
                      },
                    ]}
                    defaultValue={cell.getValue() || row.original.defaultBranch}
                  />
                </div>
              </Tooltip>
            </div>
          ),
        },
      ),
      columnHelper.accessor(
        (originalRow) => currentRepositorySettings[originalRow.id]?.isEnabled ?? true,
        {
          id: 'isEnabled',
          header: () => (
            <Tooltip label={t('page.userSettings.integrations.isEnabled')}>
              <span>{t('global.enabled')}</span>
            </Tooltip>
          ),
          cell: ({ cell, row }) => (
            <div className="select-cell">
              <Switch
                disabled={
                  row.original.status === 'archived' ||
                  githubIntegration.status === 'suspended'
                }
                defaultChecked={currentRepositorySettings[row.id]?.isEnabled ?? true}
                onChange={(event) => {
                  setEditedRepositorySettings((prev) => ({
                    ...prev,
                    [row.original.id.toString()]: {
                      ...prev[cell.row.original.id.toString()],
                      isEnabled: event.currentTarget.checked,
                    },
                  }));
                  setSettingsEdited(true);
                }}
              />
            </div>
          ),
        },
      ),
    ],
    [fetchedRepositories, currentRepositorySettings],
  );

  const table = useReactTable({
    columns: columns,
    data: fetchedRepositories,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (originalRow) => originalRow.id.toString(),
  });

  const handleSubmitRepositoryChanges = async () => {
    await postGitHubSettings({
      githubOrganizationId: githubIntegration.githubOrganizationId?.toString()!,
      manifestOrganizationId: githubIntegration.manifestOrganizationId?.toString()!,
      repositoryManifestSettings: editedRepositorySettings,
    });
    setSettingsEdited(false);
  };

  const renderActionItems = () => {
    const reposNeedingAction = fetchedRepositories.filter(
      (thisRepo) => thisRepo?.actionRequired && thisRepo?.actionRequired?.length > 0,
    );

    if (reposNeedingAction?.length > 0) {
      return (
        <div className="repositories-need-action">
          <Icon icon="exclamation-triangle" />
          <span>
            {t('page.userSettings.integrations.github.repositories-need-action', {
              count: reposNeedingAction?.length,
            })}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <div className="github-integration-edit-repositories anim-slideInUpShort">
        <div className="github-integration-edit-repositories-header">
          <div className="left">
            <h4>
              <span>
                {t('page.userSettings.integrations.github.my-repositories', {
                  count: fetchedRepositories.length,
                })}
              </span>
            </h4>
            {renderActionItems()}
          </div>
          <div className="right">
            <Button
              disabled={!settingsEdited}
              loading={isSavingGitHubSettings}
              title={
                !editedRepositorySettings ||
                Object.keys(editedRepositorySettings).length < 1
                  ? t('global.saveChanges-disabled-tooltip')
                  : t('global.saveChanges-tooltip')
              }
              onClick={handleSubmitRepositoryChanges}
            >
              {t('global.saveChanges')}
            </Button>
          </div>
        </div>
        {isLoadingGitHubIntegration ? (
          <Loader />
        ) : (
          <>
            <div className="repository-table">
              {table.getHeaderGroups().map((headerGroup) => (
                <div className="repository-row">
                  {headerGroup.headers.map((header) => (
                    <div className="repository-head-cell">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  ))}
                </div>
              ))}
              {table.getRowModel().rows.map((row) => (
                <div className="repository-row" key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <div key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <Flex justify="center">
        <Button
          disabled={!settingsEdited}
          loading={isSavingGitHubSettings}
          title={
            !editedRepositorySettings || Object.keys(editedRepositorySettings).length < 1
              ? t('global.saveChanges-disabled-tooltip')
              : t('global.saveChanges-tooltip')
          }
          onClick={handleSubmitRepositoryChanges}
        >
          {t('global.saveChanges')}
        </Button>
      </Flex>
    </>
  );
};
