import { useForm, UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useFetchLabels } from '../../../../hooks/queries/useFetchLabels';
import { GetSbomAlertReturnValue } from '../api/sbomAlerts/sbomAlerts.api';
import {
  RiskCriteriasUUID,
  SbomAlertCreationState,
} from '../reducer/alertCreateReducer.interface';
import {
  LicenseIssuesFilterValue,
  MappedSbomAlert,
  MinRiskScoreFilter,
  UploadedViaFilterValue,
  VulnRecommendationsFilterValue,
} from '../types/SBOMAlerts.interface';
import {
  getSBOMAlertsControls,
  RiskCriteriaFilterId,
  SBOMAlertControlsConfig,
} from '../utils/getSbomAlertsConfig';

export type SbomAlertSourceFormValues = {
  name: string;
  labels: string[];
  uploadedVia: [UploadedViaFilterValue, ...UploadedViaFilterValue[]];
};

export type RiskCriteriaTypeFormField = {
  [key: RiskCriteriasUUID]: RiskCriteriaFilterId;
};

export type SbomAlertRiskCriteriasFormValues = {
  cvssScore: number;
  vulnRecommendations: VulnRecommendationsFilterValue[];
  licenseIssues: LicenseIssuesFilterValue[];
  onCISAKEVList: boolean;
  minRiskScore?: MinRiskScoreFilter['value'];
  riskCriteriaSelectedFilters: {
    [key: RiskCriteriasUUID]: RiskCriteriaFilterId;
  };
};

export type SbomAlertDeliveryMethodsFormValues = {
  emails: string[];
};

const getFormValueCriterias = (
  criterias: SbomAlertCreationState['riskCriteria']['criterias'],
): RiskCriteriaTypeFormField =>
  criterias.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr.value }),
    {} as RiskCriteriaTypeFormField,
  );

const getCriteriasFromAlert = (
  alert: MappedSbomAlert,
): SbomAlertCreationState['riskCriteria']['criterias'] => {
  const criterias: SbomAlertCreationState['riskCriteria']['criterias'] = [];
  if (typeof alert.cvssScore === 'number') {
    criterias.push({ id: `selector_${uuidv4()}`, value: 'cvssScore' });
  }

  if (alert.vulnRecommendations.length > 0) {
    criterias.push({ id: `selector_${uuidv4()}`, value: 'vulnRecommendations' });
  }

  if (alert.licenseIssues.length > 0) {
    criterias.push({ id: `selector_${uuidv4()}`, value: 'licenseIssues' });
  }

  if (alert.onCISAKEVList) {
    criterias.push({ id: `selector_${uuidv4()}`, value: 'onCISAKEVList' });
  }

  if (alert.minRiskScore) {
    criterias.push({ id: `selector_${uuidv4()}`, value: 'minRiskScore' });
  }

  return criterias;
};

export const useSbomAlertForms = ({
  riskCriteriaState,
  updateRiskCriteriaSelectors,
  alertResponse,
}: {
  riskCriteriaState: SbomAlertCreationState['riskCriteria'];
  updateRiskCriteriaSelectors?: (
    selectors: SbomAlertCreationState['riskCriteria']['criterias'],
  ) => void;
  alertResponse?: GetSbomAlertReturnValue | undefined;
}): {
  sectionsConfig: SBOMAlertControlsConfig;
  sourceForm: UseFormReturnType<SbomAlertSourceFormValues>;
  riskCriteriasForm: UseFormReturnType<SbomAlertRiskCriteriasFormValues>;
  deliveryMethodsForm: UseFormReturnType<SbomAlertDeliveryMethodsFormValues>;
  riskCriteriasInitialValues: SbomAlertRiskCriteriasFormValues;
} => {
  const { t } = useTranslation();
  const { data: allLabels = [] } = useFetchLabels({});
  const sectionsConfig = getSBOMAlertsControls({
    t,
    labelsResponse: allLabels,
  });

  const requiredFieldMessage = t('global.form.errors.requiredField');

  const sourceForm = useForm<SbomAlertSourceFormValues>({
    initialValues: {
      name: '',
      uploadedVia: sectionsConfig.sourceSection.controls.uploadedVia.value,
      labels: sectionsConfig.sourceSection.controls.labels.value,
    },
    validate: (values: SbomAlertSourceFormValues) => {
      const errors: Partial<Record<keyof SbomAlertSourceFormValues, string>> = {};

      if (!values.name || values.name.trim().length === 0) {
        errors.name = t('page.userSettings.sbomUploadAlerts.enterNameError');
      }

      if (!values.uploadedVia || values.uploadedVia.length === 0) {
        errors.uploadedVia = t('global.form.errors.mustSelectAnOption');
      }

      return errors;
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const riskCriteriasInitialValues: SbomAlertRiskCriteriasFormValues = {
    cvssScore: sectionsConfig.riskCriteriaSection.controls.cvssScore.value,
    vulnRecommendations:
      sectionsConfig.riskCriteriaSection.controls.vulnRecommendations.value,
    licenseIssues: sectionsConfig.riskCriteriaSection.controls.licenseIssues.value,
    onCISAKEVList: sectionsConfig.riskCriteriaSection.controls.onCISAKEVList.value,
    minRiskScore: sectionsConfig.riskCriteriaSection.controls.minRiskScore.value,
    riskCriteriaSelectedFilters: getFormValueCriterias(riskCriteriaState.criterias),
  };

  const riskCriteriasForm = useForm<SbomAlertRiskCriteriasFormValues>({
    initialValues: {
      cvssScore: riskCriteriasInitialValues.cvssScore,
      vulnRecommendations: riskCriteriasInitialValues.vulnRecommendations,
      licenseIssues: riskCriteriasInitialValues.licenseIssues,
      onCISAKEVList: riskCriteriasInitialValues.onCISAKEVList,
      minRiskScore: riskCriteriasInitialValues.minRiskScore,
      riskCriteriaSelectedFilters: riskCriteriasInitialValues.riskCriteriaSelectedFilters,
    },
    validate: ({
      riskCriteriaSelectedFilters,
      vulnRecommendations,
      licenseIssues,
      cvssScore,
      onCISAKEVList,
      minRiskScore,
    }: SbomAlertRiskCriteriasFormValues) => {
      const errors: Record<string, string> = {};

      if (riskCriteriaState.criterias.length === 0) {
        errors[`riskCriteriaSelectedFilters`] = t(
          'page.userSettings.sbomUploadAlerts.mustAddAtLeastOneCriteria',
        );
      }
      riskCriteriaState.criterias.forEach((selector) => {
        const value = riskCriteriaSelectedFilters[selector.id];
        if (!value) {
          errors[`riskCriteriaSelectedFilters.${selector.id}`] = t(
            'global.form.errors.mustSelectAnOption',
          );
        }
      });

      const riskCriteriaSelectedFiltersList = Object.entries(
        riskCriteriaSelectedFilters,
      ) as unknown as [RiskCriteriasUUID, RiskCriteriaFilterId];
      const riskCriteriaValues = Object.values(riskCriteriaSelectedFilters);

      riskCriteriaSelectedFiltersList.forEach(([filter, value]) => {
        if (filter && !value) {
          errors[`riskCriteriaSelectedFilters.${filter}`] = t(
            'global.form.errors.mustSelectAnOption',
          );
        }
      });

      const vulnRecommendationsIsApplied = riskCriteriaValues.some(
        (filter) => filter === 'vulnRecommendations',
      );

      const cvssScoreIsApplied = riskCriteriaValues.some(
        (filter) => filter === 'cvssScore',
      );
      const licenseIssuesIsApplied = riskCriteriaValues.some(
        (filter) => filter === 'licenseIssues',
      );
      const onCISAKEVListIsApplied = riskCriteriaValues.some(
        (filter) => filter === 'onCISAKEVList',
      );
      const minRiskScoreIsApplied = riskCriteriaValues.some(
        (filter) => filter === 'minRiskScore',
      );

      if (vulnRecommendationsIsApplied && vulnRecommendations.length === 0) {
        errors['vulnRecommendations'] = t('global.form.errors.mustSelectAnOption');
      }

      if (licenseIssuesIsApplied && licenseIssues.length === 0) {
        errors['licenseIssues'] = t('global.form.errors.mustSelectAnOption');
      }

      if (cvssScoreIsApplied && typeof cvssScore !== 'number') {
        errors['cvssScore'] = requiredFieldMessage;
      }

      if (onCISAKEVListIsApplied && !onCISAKEVList) {
        errors['onCISAKEVList'] = requiredFieldMessage;
      }

      if (minRiskScoreIsApplied && !minRiskScore) {
        errors['minRiskScore'] = t('global.form.errors.mustSelectAnOption');
      }

      return errors;
    },
    validateInputOnChange: true,
  });

  const deliveryMethodsForm = useForm<SbomAlertDeliveryMethodsFormValues>({
    initialValues: {
      emails: [],
    },
    validate: ({ emails }: SbomAlertDeliveryMethodsFormValues) => {
      const errors: Record<string, string> = {};

      if (emails.length === 0) {
        errors['emails'] = t('page.userSettings.sbomUploadAlerts.enterEmailErrorMessage');
      }

      return errors;
    },
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (alertResponse?.mappedAlert) {
      const alert = alertResponse.mappedAlert;
      const criterias = getCriteriasFromAlert(alert);

      sourceForm.setValues({
        name: alert.name,
        labels: alert.labels || [],
        uploadedVia: alert.uploadedVia,
      });

      riskCriteriasForm.setValues({
        cvssScore: alert.cvssScore || riskCriteriasInitialValues.cvssScore,
        vulnRecommendations:
          alert.vulnRecommendations || riskCriteriasInitialValues.cvssScore,
        licenseIssues: alert.licenseIssues || riskCriteriasInitialValues.licenseIssues,
        onCISAKEVList: alert.onCISAKEVList || riskCriteriasInitialValues.onCISAKEVList,
        minRiskScore: alert.minRiskScore || riskCriteriasInitialValues.minRiskScore,
        riskCriteriaSelectedFilters:
          getFormValueCriterias(criterias) ||
          riskCriteriasInitialValues.riskCriteriaSelectedFilters,
      });

      deliveryMethodsForm.setValues({
        emails: alert.emails,
      });

      updateRiskCriteriaSelectors?.(criterias);
    }
  }, [alertResponse?.mappedAlert]);

  return {
    sourceForm,
    riskCriteriasForm,
    deliveryMethodsForm,
    sectionsConfig,
    riskCriteriasInitialValues,
  };
};
