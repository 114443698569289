import { Flex } from '@mantine/core';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionableCard } from '../../../../../components/ActionableCard/ActionableCard';
import Icon from '../../../../../components/Icon';
import ManifestModal from '../../../../../components/ManifestModal/ManifestModal';
import { usePutAlert } from '../../../../../hooks/mutations/usePutAlert';
import { useFetchUser } from '../../../../../hooks/queries/useFetchUser';
import SbomAlertHeader from '../../components/AlertHeader/AlertHeader';
import { AlertMetadata } from '../../components/AlertMetadata/AlertMetadata';
import DeliveryMethod from '../../components/DeliveryMethodSection/DeliveryMethodSection';
import RiskCriteriaSection from '../../components/RiskCriteriaSection/RiskCriteriaSection';
import Source from '../../components/SourceSection/SourceSection';
import { useFetchSbomAlert } from '../../hooks/useFetchSbomAlert';
import { useSbomAlertForms } from '../../hooks/useSbomAlertsForms';
import { alertCreateReducer, getInitialState } from '../../reducer/alertCreateReducer';
import { getAlertMetadata } from '../../utils/getAlertMetadata';

interface ModalProps {
  title: string;
  description: string;
  onConfirm: () => void;
  confirmLabel: string;
}

function AlertDetail() {
  const [{ source, riskCriteria, deliveryMethod }] = useReducer(
    alertCreateReducer,
    getInitialState('view'),
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { alertId } = useParams();

  const {
    data: alertResponse,
    isLoading: isLoadingAlert,
    refetch,
    error,
  } = useFetchSbomAlert(alertId);
  const rawAlert = alertResponse?.rawAlert;
  const mappedAlert = alertResponse?.mappedAlert;

  const { deliveryMethodsForm, riskCriteriasForm, sourceForm, sectionsConfig } =
    useSbomAlertForms({
      riskCriteriaState: riskCriteria,
      alertResponse,
    });

  const userIdByAction =
    mappedAlert?.lastActionType === 'updated'
      ? mappedAlert.modifiedByUserId
      : mappedAlert?.createdByUserId;
  const userId = mappedAlert ? userIdByAction : undefined;

  const { data: userInfo } = useFetchUser({
    userId,
  });

  const alertMetadata = getAlertMetadata({
    t,
    userInfo,
    hasNeverRan: mappedAlert?.hasNeverRan || true,
    dateModifiedText: mappedAlert?.dateModifiedText,
    dateCreatedText: mappedAlert?.dateCreatedText,
    lastActionType: mappedAlert?.lastActionType || 'created',
    dateLastExecutedText: mappedAlert?.dateLastExecutedText,
  });

  const { mutateAsync: updateAlert, isLoading: isUpdatingAlert } = usePutAlert();
  const [modal, setModal] = useState<ModalProps | undefined>(undefined);
  if (!alertId) {
    navigate('/settings/sbom-upload-alerts', { replace: true });
    return;
  }

  const deleteAlert = async () => {
    if (!rawAlert) {
      return;
    }

    await updateAlert({
      alert: {
        ...rawAlert,
        active: false,
        dateDeleted: new Date().toISOString(),
      },
    });
    navigate('/settings/sbom-upload-alerts', { replace: true });
  };

  const updateAlertStatus = async ({ isActive }: { isActive: boolean }) => {
    if (!rawAlert) {
      return;
    }

    await updateAlert({
      alert: {
        ...rawAlert,
        active: isActive,
      },
    });

    closeModal();
  };

  const onCancel = () => {
    navigate(`/settings/sbom-upload-alerts`);
  };

  const gotToEdit = () => {
    navigate(`/settings/sbom-upload-alerts/edit/${alertId}`);
  };

  const closeModal = () => {
    setModal(undefined);
  };

  return (
    <>
      {Boolean(error) && (
        <ActionableCard
          title={t('error.general.errorLoadingData')}
          subtitle={t('error.general.errorLoadingDataSubtitle')}
          Icon={<Icon icon="circle-exclamation" size="lg" />}
          primaryAction={{
            label: t('global.retry'),
            onClick: () => {
              refetch();
            },
            isLoading: isLoadingAlert,
          }}
        />
      )}
      {!error && (
        <Flex gap={20} direction={'column'}>
          <SbomAlertHeader
            isLoading={isLoadingAlert}
            primaryAction={{
              label: mappedAlert?.isActive
                ? t('page.userSettings.sbomUploadAlerts.deactivate')
                : t('page.userSettings.sbomUploadAlerts.activate'),
              onClick: () => {
                if (isUpdatingAlert) {
                  return;
                }

                if (mappedAlert?.isActive) {
                  setModal({
                    title: t('page.userSettings.sbomUploadAlerts.disableModal.title'),
                    description: t(
                      'page.userSettings.sbomUploadAlerts.disableModal.body',
                    ),
                    onConfirm: () => {
                      updateAlertStatus({ isActive: false });
                    },
                    confirmLabel: t('global.yesContinue'),
                  });
                  return;
                }

                updateAlertStatus({ isActive: true });
              },
              isLoading: isUpdatingAlert,
              isFilled: Boolean(!mappedAlert?.isActive),
            }}
            secondaryAction={{
              onClick: gotToEdit,
              label: t('global.edit'),
              icon: <Icon icon="pen" />,
            }}
            menuAction={{
              label: t('page.userSettings.sbomUploadAlerts.deleteAlert'),
              onClick: () => {
                setModal({
                  title: t('page.userSettings.sbomUploadAlerts.deleteModal.title'),
                  description: t('page.userSettings.sbomUploadAlerts.deleteModal.body'),
                  onConfirm: deleteAlert,
                  confirmLabel: t('global.delete'),
                });
              },
            }}
            badge={{
              isVisible: true,
              isActive: mappedAlert?.isActive,
              label: mappedAlert?.isActive ? t('global.active') : t('global.inactive'),
            }}
            title={sourceForm.getInputProps('name').value}
            goBack={onCancel}
          />

          <AlertMetadata
            isLoading={isLoadingAlert}
            leftText={alertMetadata.lastUpdateText}
            rigthText={alertMetadata.lastExecutedText}
          />

          <Source
            isLoading={isLoadingAlert}
            form={sourceForm}
            sectionConfig={sectionsConfig.sourceSection}
            state={source.state}
          />

          <RiskCriteriaSection
            isLoading={isLoadingAlert}
            riskCriterias={riskCriteria.criterias}
            form={riskCriteriasForm}
            state={riskCriteria.state}
            sectionConfig={sectionsConfig.riskCriteriaSection}
          />

          <DeliveryMethod
            form={deliveryMethodsForm}
            state={deliveryMethod.state}
            isLoading={isLoadingAlert}
          />
        </Flex>
      )}
      {modal && (
        <ManifestModal
          opened
          withCloseButton
          onClose={closeModal}
          title={modal.title}
          body={modal.description}
          primaryAction={{
            label: modal.confirmLabel,
            onClick: modal.onConfirm,
            isLoading: isUpdatingAlert,
            color: 'red',
          }}
          secondaryAction={{
            label: t('global.cancel'),
            onClick: closeModal,
          }}
        />
      )}
    </>
  );
}

export default AlertDetail;
