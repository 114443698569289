import { ReactNode, isValidElement } from 'react';

export interface BasicTableRow<T extends Object> {
  name: string;
  value: string;
  icon: ReactNode | string;
  rowId: string;
  data: T;
}

interface BasicTableProps<T extends Object> {
  onRowClick?: (row: T) => void;
  data: BasicTableRow<T>[];
  title: string;
  noResults?: ReactNode;
}

export const BasicTable = <T extends Object>({
  onRowClick,
  data,
  title,
  noResults,
}: BasicTableProps<T>) => {
  return (
    <div className="basic-table">
      <p className="table-title">{title}</p>

      {data.length
        ? data.map(({ rowId, name, value, icon, data }) => {
            const isIconComponent = isValidElement(icon);

            return (
              <div key={rowId} className="row" onClick={() => onRowClick?.(data)}>
                {isIconComponent ? (
                  icon
                ) : (
                  <img src={icon?.toString()} className="row-icon" />
                )}
                <p className="row-name">{name}</p>
                <p className="row-value">{value}</p>
              </div>
            );
          })
        : noResults}
    </div>
  );
};
