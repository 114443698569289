import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../../components/ClickableRegion';
import { InterfaceVulnerabilityWithVexInfoAndMitigations } from '../../../types/FullInterfaces';

interface FixesTabProps {
  references: InterfaceVulnerabilityWithVexInfoAndMitigations['references'];
  mitigation: InterfaceVulnerabilityWithVexInfoAndMitigations['mitigationFlat'];
}

export const FixesTab = ({ mitigation, references }: FixesTabProps) => {
  const { t } = useTranslation();

  const advisoryReference = references?.find(
    ({ type, url }) => type?.toLowerCase() === 'advisory' && url,
  );

  const hasFoundFixes = mitigation && mitigation.length > 0;

  return (
    <div className="fixes-tab anim-slideInDownShort">
      <h6 className="section-title">{t('page.vulnerability.details.main-tabs.fixes')}</h6>
      <div className="card fixes-container">
        {hasFoundFixes ? (
          <>
            {advisoryReference && (
              <ClickableRegion
                regionLabel={`${advisoryReference?.type}`}
                href={advisoryReference?.url}
                target="_blank"
              >
                {t('page.vulnerability.details.nvd')}
              </ClickableRegion>
            )}
            <ul className="mitigation-list">
              {mitigation.map((mi, index) => (
                <li key={index}>{mi.toString()}</li>
              ))}
            </ul>
          </>
        ) : (
          <p className="empty-text">{t('page.vulnerability.details.fixes-tab.empty')}</p>
        )}
      </div>
    </div>
  );
};
