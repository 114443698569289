import { Flex, Stack, Text } from '@mantine/core';
import styles from '../AssetsTable.module.scss';
import ClickableRegion from '../../../ClickableRegion';
import { truncateString } from '../../../../lib/truncateString';
import { IconCategory } from '@tabler/icons-react';
import { resolveType } from '../resolvers';
import Icon from '../../../Icon';
import { DateTime } from 'luxon';
import { MRT_Cell, MRT_Row } from 'mantine-react-table';
import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { useTranslation } from 'react-i18next';

export const NameColumn = ({
  cell,
  row,
}: {
  cell: MRT_Cell<InterfaceOrganizationAsset>;
  row: MRT_Row<InterfaceOrganizationAsset>;
}) => {
  const { t } = useTranslation();
  const tooltipContent = [cell.getValue<string>()];
  if (row.original?.isActive === false) {
    tooltipContent.push(t('page.assets.inactiveStatus'));
  }

  return (
    <Stack
      sx={{ gap: '2px' }}
      className={`${row.original?.isActive === false ? styles.isInactiveAssetRow : ''}`}
    >
      <ClickableRegion
        className="table-link"
        regionLabel={tooltipContent}
        href={`/asset/${row.original._id}`}
      >
        <Text fw="bold" size="lg" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {truncateString(cell.getValue<string>(), 50)}
        </Text>
      </ClickableRegion>
      <Flex gap="sm">
        <Text color="gray" sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <IconCategory size="14px" />
          {resolveType(row.original, t)}
        </Text>
        <Text color="gray" sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <Icon icon="refresh" />
          {t('global.updated', {
            date: DateTime.fromISO(`${row.original.dateModified}`).toRelative(),
          })}
        </Text>
      </Flex>
    </Stack>
  );
};
