import '../../scss/pages/vulnerability_v2.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFetchVuln } from '../../hooks/queries/useFetchVuln';
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/Loading';
import { Box, Modal, Loader as MantineLoader } from '@mantine/core';
import { VulnerabilityMeta } from './components/VulnerabilityMeta';
import { CreateSimpleVexModal } from '../Vulnerability/CreateSimpleVexModal';
import { useFetchVulnImpact } from '../../hooks/queries/useFetchVulnImpact';
import { VulnPdfExportDocument } from './components/VulnPdf';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Icon from '../../components/Icon';
import { VulnerabilityDetailChart } from '../Vulnerability/VulnerabilityDetailChart';
import { useVulnPdfHandlers } from './hooks/useVulnPdfHandlers';
import { VulnerabilityV2Tabs } from './tabs';

export const VulnerabilityV2 = () => {
  const { vulnerabilityId } = useParams<{ vulnerabilityId: string }>();

  const { t } = useTranslation();

  const [errors, setErrors] = useState<string[]>([]);

  const [showSimpleVexModal, setShowSimpleVexModal] = useState<boolean>(false);
  const {
    closePdfDownloadModal,
    openPdfDownloadModal,
    chartDataUri,
    isPdfDownloadModalOpen,
  } = useVulnPdfHandlers();

  const { data: fetchedVulnData, isLoading: isFetchingVuln } = useFetchVuln(
    vulnerabilityId!,
  );

  const { data: fetchedVulnImpact, isFetching: isVulnImpactFetching } =
    useFetchVulnImpact(vulnerabilityId);

  const handleAddToError = (errorMsg: string) => {
    setErrors((prevErrors) => [...prevErrors, errorMsg]);
  };

  return (
    <section className="page-vulnerability-details">
      <Helmet title={t('page.vulnerability.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      {errors.length > 0 && (
        <ul className="page-errors anim-slideInUpShort">
          {errors.map((error) => (
            <li key="error">{error}</li>
          ))}
        </ul>
      )}
      {isFetchingVuln && <Loading />}
      {!isFetchingVuln && fetchedVulnData && (
        <>
          <div className="vuln-content">
            <VulnerabilityMeta
              {...fetchedVulnData}
              onExportReport={openPdfDownloadModal}
              vulnId={vulnerabilityId || ''}
              published={fetchedVulnData.publishDate?.toString()}
              onOpenVexModal={() => setShowSimpleVexModal(true)}
              isLoading={isVulnImpactFetching || isFetchingVuln}
            />
            {/* Rendered because no other option, check: https://github.com/apexcharts/apexcharts.js/issues/2819#issuecomment-997216211 */}
            <Box pos="fixed" top={0} left={0} opacity={0}>
              {fetchedVulnData && fetchedVulnImpact && vulnerabilityId && (
                <VulnerabilityDetailChart
                  fetchedVulnData={fetchedVulnData}
                  countImpactedAssets={fetchedVulnImpact.countImpactedAssets}
                  vulnerabilityId={vulnerabilityId}
                  hideBrowserChart
                />
              )}
            </Box>
            <VulnerabilityV2Tabs
              handleAddToErrors={handleAddToError}
              setShowSimpleVexModal={setShowSimpleVexModal}
              vuln={fetchedVulnData}
            />
          </div>
          {vulnerabilityId && fetchedVulnImpact && (
            <CreateSimpleVexModal
              cveId={vulnerabilityId}
              impactedAssets={fetchedVulnImpact.impactedAssets || []}
              opened={showSimpleVexModal}
              onCancel={() => {
                setShowSimpleVexModal(false);
              }}
              onConfirm={() => {
                setShowSimpleVexModal(false);
              }}
            />
          )}
          <Modal
            opened={isPdfDownloadModalOpen}
            onClose={closePdfDownloadModal}
            size={'xl'}
            title={
              <>
                <PDFDownloadLink
                  document={
                    <VulnPdfExportDocument
                      fetchedVulnData={fetchedVulnData}
                      isFetching={isVulnImpactFetching || isFetchingVuln}
                      vulnImpact={fetchedVulnImpact}
                      exportGraphImageBase64={chartDataUri}
                    />
                  }
                  fileName={`${vulnerabilityId}-export.pdf`}
                >
                  {({ loading }) =>
                    loading ? (
                      <div className="pdf-modal-download-loading">
                        <MantineLoader
                          variant="dots"
                          size={'xs'}
                          style={{
                            position: 'relative',
                            top: '-2px',
                            marginRight: '5px',
                          }}
                        />
                        <span>{t('pdf-export.loading-preview')}</span>
                      </div>
                    ) : (
                      <div className="pdf-modal-download">
                        <Icon icon="arrow-down-to-bracket" />
                        <span>{t('pdf-export.download')}</span>
                      </div>
                    )
                  }
                </PDFDownloadLink>
              </>
            }
          >
            <div className="pdf-preview">
              <PDFViewer showToolbar={false} style={{ width: '100%', height: '80vh' }}>
                <VulnPdfExportDocument
                  fetchedVulnData={fetchedVulnData}
                  isFetching={isVulnImpactFetching}
                  vulnImpact={fetchedVulnImpact}
                  exportGraphImageBase64={chartDataUri}
                />
              </PDFViewer>
            </div>
          </Modal>
        </>
      )}
    </section>
  );
};
