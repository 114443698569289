import React from 'react';
import './NumberBadge.scss';
import classNames from 'classnames';

interface NumberBadgeProps {
  number: number;
  active: boolean;
}

const NumberBadge = ({ number, active }: NumberBadgeProps) => {
  return (
    <div
      className={classNames('anim-slideInDownShort number-badge', {
        'number-badge--active': active,
      })}
    >
      {number}
    </div>
  );
};

export default NumberBadge;
