import { MRT_Row } from 'mantine-react-table';
import classNames from 'classnames';
import React from 'react';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { useTranslation } from 'react-i18next';

export const Mitigate = ({ row }: { row: MRT_Row<InterfaceOrganizationProduct> }) => {
  const { t } = useTranslation();
  const mitigateVulns =
    row.original.countVulnerabilities?.mitigate !== undefined
      ? row.original.countVulnerabilities?.mitigate
      : 0;

  return (
    <p
      className={classNames({
        'products-table__mitigate-vulns': row.original.countVulnerabilities?.mitigate,
        'table-na': !row.original.countVulnerabilities?.mitigate,
      })}
    >
      {mitigateVulns}
    </p>
  );
};
