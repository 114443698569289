import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';

interface CVEIDDatum {
  label: string;
  value: string;
}

export const useFetchProductsCVEIDs = () => {
  let fetchUrl = `products/cveids`;

  return useQuery({
    staleTime: 60000, // 1 minute
    queryKey: ['products-cveids', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) =>
      data?.data
        ?.map((cveId: string) => ({
          label: cveId,
          value: cveId,
        }))
        .sort((a: CVEIDDatum, b: CVEIDDatum) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }) as CVEIDDatum[],
  });
};
