import { useMutation, useQueryClient } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceOrganizationSlaSetting } from '@manifest-cyber/types/interface/dbTables';
import { AxiosResponse } from 'axios';

interface ApiResponseWithErrors<T> extends AxiosResponse<T> {
  errors: string[];
}

export const usePostSLAs = () => {
  const queryClient = useQueryClient();
  return useMutation<
    ApiResponseWithErrors<InterfaceOrganizationSlaSetting>,
    unknown,
    InterfaceOrganizationSlaSetting
  >({
    mutationFn: (body: InterfaceOrganizationSlaSetting) => {
      return hitApi.post(`sla/setting`, true, body);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`slas`]);
    },
  });
};
