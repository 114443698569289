/**
 * Utility function to convert an object to a URL with query parameters.
 * @param {string} baseUrl - The base URL to which query parameters will be appended.
 * @param {Record<string, any>} params - The object containing query parameters.
 * @returns {string} - The constructed URL with query parameters.
 */

export const buildUrlWithParams = (
  baseUrl: string,
  params: Record<string, string | number | object | null | undefined | unknown[]>,
): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (typeof value === 'object') {
        searchParams.append(key, JSON.stringify(value));
      } else {
        searchParams.append(key, value.toString());
      }
    }
  });

  const parsedParams = searchParams.toString();

  return `${baseUrl}${parsedParams ? `?${parsedParams}` : ''}`;
};
