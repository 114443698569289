import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { Button, Flex, Input, Loader, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Icon/Icon';
import { DateTime } from 'luxon';
import ClickableRegion from '../../../components/ClickableRegion';
import appConfig from '../../../configs/appConfig';
import { Link } from 'react-router-dom';
import { getTypeIcon } from '../../../lib/getTypeIcon';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { truncateString } from '../../../lib/truncateString';
import DataTableFooter from '../../../components/DataTables/DataTableFooter';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import featureFlagDisciminators from '../../../configs/featureFlags';

interface InterfaceOrganizationAssetWithSbom extends InterfaceOrganizationAsset {
  latestSbomForThisVersion: {
    _id: string;
    organizationAssetId: any;
  }[];
}

export const AssetVersionsTab = ({
  versions,
  onChangeSearchValue,
  currentOrganizationAssetId,
  pagination,
  isLoading,
}: {
  versions: Partial<InterfaceOrganizationAssetWithSbom>[];
  onChangeSearchValue: any;
  currentOrganizationAssetId: string;
  pagination: {
    currentPage: number;
    limitPerPage: number;
    totalResults: number;
    onChangePage: any; // Function to fire
  };
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, 200);

  useEffect(() => {
    onChangeSearchValue(debouncedSearch);
  }, [debouncedSearch]);
  return (
    <div className="asset-versions-content anim-slideInUp">
      <div className="asset-versions-left">
        <div className="asset-versions-search">
          <Input
            type="text"
            className="search-input"
            icon={<Icon icon="search" />}
            placeholder={t('page.asset.versions.search')}
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            aria-label={t('page.asset.versions.search')}
          />
        </div>
        <div className="asset-versions-list">
          <div className="asset-versions-list-header">
            <div className="version-info">
              {t('page.asset.versions.list-header.version')}
            </div>
            <div>{t('page.asset.versions.list-header.totalVulns')}</div>
            <div>{t('page.asset.versions.list-header.mitigate')}</div>
            <div>{t('page.asset.versions.list-header.monitor')}</div>
            <div>&nbsp;</div>
          </div>
          <ul>
            {isLoading && (
              <div className="loading-overlay">
                <Loader variant="dots" />
              </div>
            )}
            {versions &&
              versions.map((version) => (
                <li
                  key={`${version._id}`}
                  className={`asset-versions-list-item ${!version?.isActive && version?.isActive != undefined ? 'asset-versions-list-item--inactive' : ''} ${
                    `${version?._id}` === currentOrganizationAssetId ? 'is-current' : ''
                  }`}
                >
                  <div className="version-info info-list-item">
                    <div className="version-icon">
                      <Icon
                        icon={
                          `${getTypeIcon(
                            `${version?.type}`,
                            `${version?.fullyQualifiedName}`,
                          )}` as IconName
                        }
                      />
                    </div>
                    <div className="version-details">
                      <Flex gap={8} align={'flex-start'}>
                        <h4>
                          <ClickableRegion
                            regionLabel={version?.version}
                            href={`/asset/${version?._id}`}
                          >
                            <>{truncateString(`${version.version}`, 24)}</>
                          </ClickableRegion>
                        </h4>
                        {!version?.isActive && version?.isActive != undefined ? (
                          <div className="inactive-tag">{t('global.inactive')}</div>
                        ) : null}
                      </Flex>

                      <span>
                        {t('page.asset.versions.uploaded', {
                          date: DateTime.fromISO(`${version.dateCreated}`).toLocaleString(
                            DateTime.DATE_MED,
                          ),
                        })}
                      </span>
                    </div>
                  </div>
                  <div className="version-total-vulns">
                    <ClickableRegion
                      regionLabel={t('page.asset.versions.link-version')}
                      href={`/asset/${version?._id}/vulnerabilities`}
                    >
                      <span>{version?.countVulnerabilities?.total}</span>
                    </ClickableRegion>
                  </div>
                  <div className="version-mitigate-vulns">
                    <ClickableRegion
                      regionLabel={t('page.asset.versions.link-vulnerabilities')}
                      href={`/asset/${version?._id}/vulnerabilities`}
                    >
                      <>
                        <Icon icon="triangle-exclamation" />
                        <span>{version?.countVulnerabilities?.mitigate}</span>
                      </>
                    </ClickableRegion>
                  </div>
                  <div className="version-monitor-vulns">
                    <ClickableRegion
                      regionLabel={t('page.asset.versions.link-vulnerabilities')}
                      href={`/asset/${version?._id}/vulnerabilities`}
                    >
                      <>
                        <Icon icon="eye" />
                        <span>{version?.countVulnerabilities?.monitor}</span>
                      </>
                    </ClickableRegion>
                  </div>
                  <div className="version-download-sbom">
                    <Tooltip label={t('page.asset.versions.download')}>
                      <span className="icon-tooltip-cont">
                        <Link
                          to={`//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${version?.latestSbomForThisVersion?.[0]?._id}?redirect=1`}
                          aria-label={t('page.asset.versions.downloadAriaLabel')}
                        >
                          <Icon icon="arrow-down-to-line" />
                        </Link>
                      </span>
                    </Tooltip>
                  </div>
                </li>
              ))}
          </ul>
          <DataTableFooter
            currentPage={pagination.currentPage}
            limitPerPage={pagination.limitPerPage}
            totalResults={pagination.totalResults}
            onChangePage={pagination.onChangePage}
          />
        </div>
      </div>
      <div className="asset-versions-right">{/* Graph here later */}</div>
    </div>
  );
};
