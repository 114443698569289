import React from 'react';
import { useTranslation } from 'react-i18next';
import appConfig from '../configs/appConfig';
import ClickableRegion from './ClickableRegion';

const environment = appConfig.envLabel === 'Production' ? '' : `${appConfig.envLabel} `;
const currentVersion = appConfig.currentVersion;

const versionDisplay = `Version ${currentVersion}`;

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      &copy;{' '}
      {t('app.copyright.display', {
        company: t('app.copyright.company'),
        year: new Date().getFullYear(),
        writ: t('app.copyright.writ'),
      })}{' '}
      <ClickableRegion
        regionLabel={t('global.api-docs')}
        href="https://api-docs.manifestcyber.com"
        className="footer-link"
        target="_blank"
      >
        {t('global.apiDocs')}
      </ClickableRegion>
      &bull;
      <ClickableRegion
        regionLabel={t('global.service-status')}
        href="https://status.manifestcyber.com"
        className="footer-link"
        target="_blank"
      >
        {t('global.service-status')}
      </ClickableRegion>
      &bull; &nbsp;
      {environment}
      {versionDisplay}
    </footer>
  );
};

export default Footer;
