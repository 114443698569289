import { StyleSheet, Font } from '@react-pdf/renderer';

const pdfColors = {
  primary: '#1EBCE2',
  white: '#FFFFFF',
  text: '#131B26',
  textLight: '#192434',
  textLight2: '#5A5F67',
  border: '#D7D8DC',
  red: '#CC3663',
  redRgb: '204,54,99',
  headerBg: '#19303F',
};

const pdfFontSizes = {
  sectionHeader: '8px',
  p: '9px',
};

// PDF Export Styles
Font.register({
  family: 'Karla',
  fonts: [
    {
      src: '/fonts/karla/static/Karla-Light.ttf',
      fontWeight: 300,
      fontStyle: 'normal',
    },
    {
      src: '/fonts/karla/static/Karla-Regular.ttf',
      fontWeight: 400,
      fontStyle: 'normal',
    },
    {
      src: '/fonts/karla/static/Karla-SemiBold.ttf',
      fontWeight: 500,
      fontStyle: 'normal',
    },
    {
      src: '/fonts/karla/static/Karla-Bold.ttf',
      fontWeight: 600,
      fontStyle: 'normal',
    },
  ],
});
export const pdfStyles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  page: {
    backgroundColor: pdfColors.white,
    paddingBottom: 45,
    fontSize: '12px',
    fontFamily: 'Karla',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: pdfFontSizes.p,
    bottom: 20,
    left: 20,
    right: 0,
    textAlign: 'left',
    color: 'grey',
    fontFamily: 'Karla',
  },
  pageContent: {
    position: 'relative',
    padding: 20,
    width: '100%',
  },
  section: {
    marginBottom: 10,
    padding: 18,
    width: '100%',
    border: `1px solid ${pdfColors.border}`,
    borderRadius: '2px',
  },
  sectionNoBotPad: {
    marginBottom: 10,
    padding: 18,
    paddingBottom: 5,
    width: '100%',
    border: `1px solid ${pdfColors.border}`,
    borderRadius: '2px',
  },
  header: {
    position: 'relative',
    overflow: 'hidden',
    height: 'auto',
    width: '100%',
  },
  headerInner: {
    backgroundColor: pdfColors.headerBg,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    flexDirection: 'row',
    padding: '16px 20px',
  },
  headerLeft: {
    width: '40%',
    zIndex: 1,
  },
  headerImage: {
    height: '20px',
    width: '100px',
  },
  headerBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  headerBackgroundImage: {
    position: 'relative',
    width: '100%',
    zIndex: 0,
    opacity: 0.8,
  },
  headerRight: {
    width: '60%',
    textAlign: 'right',
    color: pdfColors.white,
    zIndex: 1,
  },
  headerRightTitle: {
    fontWeight: 'bold',
    fontSize: '10px',
    marginBottom: '3px',
    fontFamily: 'Karla',
  },
  headerRightSubtitle: {
    fontWeight: 'normal',
    fontSize: '8px',
    marginBottom: '5px',
  },
  headerLeftOverlay: {
    position: 'absolute',
    top: 3,
    left: 0,
    textAlign: 'left',
    width: '40%',
    color: pdfColors.white,
    padding: '16px 20px',
  },
  headerImage2: {
    position: 'relative',
    height: '20px',
    width: '80px',
  },
  headerRightOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    textAlign: 'right',
    width: '100%',
    color: pdfColors.white,
    padding: '16px 20px',
  },
  linkOverride: {
    color: pdfColors.text,
    textDecoration: 'none', // This doesn't actually appear to work
  },
  overviewSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  // Left Side (title, stats, description)
  overviewSectionLeft: {
    width: '50%',
    flexShrink: 0,
    flexGrow: 0,
  },
  // Right side of overview (reco, graph)
  overviewSectionRight: {
    width: '45%',
    marginLeft: 10,
  },

  // Main CVE title
  overviewSectionTitle: {
    fontFamily: 'Karla',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  // Main CVE title link
  overviewSectionTitleLinkIcon: {
    height: '10px',
    position: 'relative',
    marginTop: '-15px',
    left: '10px',
    color: pdfColors.primary,
  },
  // Reco container
  overviewSectionRecommendation: {
    backgroundColor: `rgba(${pdfColors.redRgb},0.1)`,
    border: `1px solid ${pdfColors.red}`,
    color: pdfColors.red,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '10px',
    fontSize: '11px',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overviewSectionRecommendationTextLabel: {},
  // Recommendation
  overviewSectionRecommendationText: {
    fontSize: '13px',
    color: pdfColors.text,
    marginLeft: '8px',
    position: 'relative',
    top: '0px',
  },
  // Publish Date
  overviewSectionLeftPublish: {
    flexShrink: 0,
    flexGrow: 0,
    marginTop: '6px',
    fontSize: '8px',
    marginBottom: '12px',
    color: pdfColors.textLight,
  },
  // Quick Stats
  overviewSectionLeftStats: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  overviewSectionLeftStatsBlock: {
    borderRight: `1px solid ${pdfColors.border}`,
    paddingRight: '8px',
    marginRight: '8px',
  },
  overviewSectionLeftStatsBlockLast: {
    borderRight: `none`,
    paddingRight: '0px',
  },
  overviewSectionLeftStatsBlockTitle: {
    textTransform: 'uppercase',
    color: pdfColors.primary,
    fontSize: pdfFontSizes.sectionHeader,
    textAlign: 'left',
  },
  overviewSectionLeftStatsBlockValue: {
    color: pdfColors.text,
    fontSize: '13px',
    textAlign: 'left',
    marginTop: '5px',
  },
  overviewSectionLeftKevTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '10px',
  },
  overviewSectionLeftKevTitleText: {
    textTransform: 'uppercase',
    color: pdfColors.red,
    fontSize: pdfFontSizes.sectionHeader,
    flexShrink: 0,
    flexGrow: 1,
    position: 'relative',
    paddingRight: '20px',
  },
  overviewSectionLeftKevLine: {
    height: `1px`,
    backgroundColor: pdfColors.red,
    flexGrow: 1,
    flexShrink: 1,
    width: '46%',
    position: 'relative',
    top: '0px',
  },
  overviewSectionLeftDescriptionTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '10px',
  },
  overviewSectionLeftDescriptionTitleText: {
    textTransform: 'uppercase',
    color: pdfColors.primary,
    fontSize: pdfFontSizes.sectionHeader,
    flexShrink: 0,
    flexGrow: 0,
    position: 'relative',
    paddingRight: '20px',
  },
  overviewSectionLeftDescriptionLine: {
    height: `1px`,
    backgroundColor: pdfColors.primary,
    flexGrow: 1,
    flexShrink: 1,
    width: '100%',
    position: 'relative',
    top: '0px',
  },
  overviewSectionLeftDescription: {
    color: pdfColors.text,
    fontSize: pdfFontSizes.p,
    marginTop: '5px',
  },
  impactedComponentsList: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  impactedComponentsListItem: {
    color: pdfColors.text,
    fontSize: pdfFontSizes.p,
    marginTop: '10px',
    marginRight: '10px',
    padding: '4px 8px',
    border: `1px solid ${pdfColors.border}`,
    borderRadius: '11px',
  },
  impactedAssetsList: {
    marginTop: '10px',
    fontSize: pdfFontSizes.p,
  },
  impactedAssetsListItemHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: '10px',
    marginTop: '10px',
    color: pdfColors.textLight2,
  },
  impactedAssetsListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    borderTop: `1px solid ${pdfColors.border}`,
    marginBottom: '10px',
    color: pdfColors.text,
  },
  impactedAssetsListItemColumnA: {
    width: '40%',
    flexShrink: 0,
    textAlign: 'left',
  },
  impactedAssetsListItemColumnB: {
    width: '30%',
    textAlign: 'left',
  },
  impactedAssetsListItemColumnC: {
    width: '30%',
    textAlign: 'left',
  },
});
