import type {
  InterfaceNotificationEvent,
  InterfaceNotificationSetting,
} from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';

interface Options {
  refetchOnWindowFocus?: boolean;
}

export const useFetchSbomUploadAlerts = (
  notificationEventId: string,
  options?: Options,
) => {
  let fetchUrl = `organization/notificationSettings?notificationEventId=${notificationEventId}`;
  return useQuery({
    queryKey: ['sbomUploadAlerts', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => (data?.data ?? []) as InterfaceNotificationSetting[],
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};

export const useFetchSbomUploadAlert = (alertId: string, options?: Options) => {
  let fetchUrl = `organization/notificationSettings/${alertId}`;
  return useQuery({
    queryKey: ['sbomUploadAlert', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => (data?.data ?? {}) as InterfaceNotificationSetting,
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};

export const useFetchActiveNotificationEvents = (options?: Options) => {
  let fetchUrl = `notificationSystem/activeEvents`;

  return useQuery({
    queryKey: ['activeNotificationEvents', `/${fetchUrl}`],
    queryFn: async () => {
      const result = await hitApi.get(fetchUrl);
      if (!result.success) {
        return Promise.reject(result.errors);
      }

      return result;
    },
    select: (data) => (data?.data ?? []) as InterfaceNotificationEvent[],
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
