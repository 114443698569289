import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import TriageForm from './TriageForm';

interface Props {
  cves: InterfaceVulnerability[];
  updateIsCloseable: Dispatch<SetStateAction<boolean>>;
  shouldResetForm: boolean;
  organizationAssetId: string;
  handleTriageSaved: () => void;
}

function MultiCVETriage({
  cves,
  organizationAssetId,
  updateIsCloseable,
  shouldResetForm,
  handleTriageSaved,
}: Props) {
  return (
    <TriageForm
      cves={cves}
      organizationAssetId={organizationAssetId}
      shouldResetForm={shouldResetForm}
      updateIsCloseable={updateIsCloseable}
      onSave={handleTriageSaved}
    />
  );
}

export default MultiCVETriage;
