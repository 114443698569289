import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';

interface Params {
  componentId: string;
  organizationId: string;
}

export const useFetchComponent = ({ componentId, organizationId }: Params) => {
  const fetchUrl = `component/id/${componentId}/${organizationId}`;

  return useQuery({
    queryKey: ['component', { _id: componentId }, `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) =>
      data?.data as {
        component: InterfaceOrganizationComponent;
        versions: InterfaceOrganizationComponent[];
      },
  });
};
