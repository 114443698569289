import { type IconName } from '@fortawesome/fontawesome-svg-core';
import { IntegrationStatus } from '@manifest-cyber/types/interface/db';
import { TFunction } from 'i18next';

export interface IIntegration {
  integrationType: string;
  description: string;
  addHref: string;
  editHref: string;
  installText: string;
  icon: IconName | null;
  logoSrc: string | null;
  subtitle: string;
  title: string;
  features: string[];
  integrationStatus: IntegrationStatus | 'pending' | null;
}

export const getGitHubIntegration = (t: any): IIntegration => ({
  integrationType: 'github',
  description: t('page.userSettings.integrations.github.description'),
  addHref: 'github/add',
  editHref: 'github/repositories',
  icon: 'github-square',
  logoSrc: null,
  subtitle: t('page.userSettings.integrations.github.subTitle'),
  title: t('page.userSettings.integrations.github.title'),
  features: t('page.userSettings.integrations.github.features', {
    returnObjects: true,
  }),
  installText: t('page.userSettings.integrations.github.install'),
  integrationStatus: null,
});

export const getJiraIntegration = (t: any): IIntegration => ({
  integrationType: 'jira',
  description: t('page.userSettings.integrations.jira.description'),
  addHref: 'jira/add',
  editHref: 'jira/remove',
  icon: null,
  logoSrc: '/jira_logo.png',
  subtitle: t('page.userSettings.integrations.jira.subTitle'),
  title: t('page.userSettings.integrations.jira.title'),
  features: t('page.userSettings.integrations.jira.features', {
    returnObjects: true,
  }),
  installText: t('page.userSettings.integrations.jira.configure'),
  integrationStatus: null,
});

export const getServiceNowIntegration = (t: any): IIntegration => ({
  integrationType: 'servicenow',
  description: t('page.userSettings.integrations.servicenow.description'),
  addHref: 'servicenow/add',
  editHref: 'servicenow/remove',
  icon: null,
  logoSrc: '/servicenow_logo.png',
  subtitle: t('page.userSettings.integrations.servicenow.subTitle'),
  title: t('page.userSettings.integrations.servicenow.title'),
  features: t('page.userSettings.integrations.servicenow.features', {
    returnObjects: true,
  }),
  installText: t('page.userSettings.integrations.servicenow.configure'),
  integrationStatus: null,
});

export const getLinearIntegration = (t: any): IIntegration => ({
  integrationType: 'linear',
  description: t('page.userSettings.integrations.linear.description'),
  addHref: 'linear/add',
  editHref: 'linear/remove',
  icon: null,
  logoSrc: '/linear_logo.png',
  subtitle: t('page.userSettings.integrations.linear.subTitle'),
  title: t('page.userSettings.integrations.linear.title'),
  features: t('page.userSettings.integrations.linear.features', {
    returnObjects: true,
  }),
  installText: t('page.userSettings.integrations.linear.configure'),
  integrationStatus: null,
});

export const getAllIntegrations = (t: TFunction): IIntegration[] => {
  const preAddedIntegrations: IIntegration[] = [
    getGitHubIntegration(t),
    getJiraIntegration(t),
    getServiceNowIntegration(t),
    getLinearIntegration(t),
  ];

  return preAddedIntegrations;
};
