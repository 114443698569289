import { Menu, Tooltip } from '@mantine/core';
import Icon from '../../../components/Icon';
import { IconName } from '@fortawesome/fontawesome-svg-core';

interface Props {
  icon: IconName;
  text: string;
  disabled?: boolean;
  disabledText?: string;
  onClick: () => void;
}

export const MenuButtonItem = (props: Props) => {
  const { text, icon, disabled, disabledText, onClick } = props;
  return disabled && disabledText ? (
    <Tooltip label={disabledText}>
      <div data-testid="tooltip">
        <Menu.Item className="manifest-menu__item" icon={<Icon icon={icon} />} disabled>
          <span>{text}</span>
        </Menu.Item>
      </div>
    </Tooltip>
  ) : (
    <Menu.Item
      className="manifest-menu__item"
      onClick={onClick}
      disabled={disabled}
      icon={<Icon icon={icon} />}
    >
      <span>{text}</span>
    </Menu.Item>
  );
};
