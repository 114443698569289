import { Stack } from '@mantine/core';
import { IconCategory } from '@tabler/icons-react';
import { truncateString } from '../../../../../lib/truncateString';
import styles from './NameCell.module.scss';

export const NameCell = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Stack sx={{ gap: '8px' }}>
      <span className={styles.title}>{truncateString(title, 50)}</span>
      <span className={styles.subtitle}>
        <IconCategory size="14px" />
        {subtitle}
      </span>
    </Stack>
  );
};
