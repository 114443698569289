import { useTranslation } from 'react-i18next';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Group,
  Menu,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import './ProductHeader.scss';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { DateTime } from 'luxon';
import { ProductModal } from '../ProductModal/ProductModal';
import React, { useMemo, useState } from 'react';
import Icon from '../../Icon';
import { useFetchProductCapabilities } from '../../../hooks/products/useFetchProductCapabilities';
import { useSBOMDownload } from '../../../contexts/SBOMDownload.context';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import featureFlagDisciminators from '../../../configs/featureFlags';
import Labels from '../../Labels';
import ManageLabelsModal from '../../Labels/ManageLabelsModal';
import { usePutProductLabels } from '../../../hooks/products/usePutProductLabels';
import { useQueryClient } from '@tanstack/react-query';

interface ProductHeaderProps {
  product: InterfaceOrganizationProduct;
  orgName: string;
}

export const ProductHeader = ({ product, orgName }: ProductHeaderProps) => {
  const { t } = useTranslation();
  const { addToDownloadQueue } = useSBOMDownload();

  const { data } = useFetchProductCapabilities({
    productId: product._id?.toString(),
  });

  const { mutateAsync: putProductLabels } = usePutProductLabels();

  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [labelEditModalOpen, setLabelEditModalOpen] = useState(false);

  const unableToMergeReason = useMemo(() => {
    if (data?.capabilities.reason === 'asset formats mismatching')
      return t('product.unable-to-generate-sbom-formats-mismatching');
    if (data?.capabilities.reason === 'assets limit reached')
      return t('product.unable-to-generate-sbom-limit-reached');
    return '';
  }, [data]);

  const productFormData = {
    id: product._id?.toString() || '',
    manufacturer: product.manufacturer || '',
    description: product.description || '',
    name: product.name || '',
    version: product.version || '',
    types: product.types ? product.types.map((type) => type._id.toString()) : [],
    labels: product.labelIds ? product.labelIds.map((labelId) => labelId.toString()) : [],
  };

  const downloadSbomHandler = () => {
    if (!product._id) {
      return;
    }
    addToDownloadQueue(product);
  };

  return (
    <Flex className="product-header" direction="column" gap={8}>
      <Flex justify="space-between" align="center">
        <Flex direction="column" gap={8}>
          <Group spacing="xs">
            {product?.relationship === 'first' && (
              <Badge
                mx={0}
                sx={{
                  width: '35px',
                  '& span': {
                    textOverflow: 'none',
                    overflow: 'visible',
                  },
                }}
              >
                {t('global.first')}
              </Badge>
            )}
            {product?.relationship === 'third' && (
              <Badge
                mx={0}
                color="gray"
                sx={{
                  width: '35px',
                  '& span': {
                    textOverflow: 'none',
                    overflow: 'visible',
                  },
                }}
              >
                {t('global.third')}
              </Badge>
            )}
            <Text className="product-header__organization" size="sm">
              {orgName}
            </Text>
          </Group>
          <Group spacing="xs">
            <Title className="product-header__product-name" order={2}>
              {product.name}
            </Title>
            <Badge className="product-header__version" variant="filled" size="xl">
              {product.version}
            </Badge>
          </Group>
          <Group spacing="xl">
            <Group spacing="xs">
              <Icon icon="calendar" size="xl" color="#1EBCE2" />
              <Text>
                {t('product.created-at', {
                  date: DateTime.fromISO(`${product?.dateCreated}`).toLocaleString({
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  }),
                })}
              </Text>
              <Tooltip label={t('page.product.labelsDescription')}>
                <span className="product-header__tag-icon">
                  <Icon icon="tag" />
                </span>
              </Tooltip>
              <Labels
                entity={product}
                condensed
                onEditButtonClick={() => setLabelEditModalOpen(true)}
                rounded
              />
            </Group>
            <Group spacing="xs">
              {product.metadata?.sku ? (
                <>
                  <Icon icon="qrcode" size="xl" color="#1EBCE2" />
                  <Text>
                    <span className="product-header__sku">{t('product.sku')}</span>{' '}
                    {product.metadata?.sku}
                  </Text>
                </>
              ) : null}
            </Group>
          </Group>
        </Flex>
        <Group spacing="xs">
          <Tooltip
            disabled={data?.capabilities.canMerge || !unableToMergeReason}
            label={unableToMergeReason}
          >
            <span>
              <Button
                variant="default"
                onClick={downloadSbomHandler}
                disabled={!data?.capabilities.canMerge}
                leftIcon={<Icon icon="arrow-down-to-bracket" />}
                className="product-header__sbomDownload"
              >
                {t('product.downloadSbom')}
              </Button>
            </span>
          </Tooltip>
          <Menu withinPortal position="bottom-end">
            <Menu.Target>
              <ActionIcon
                variant="filled"
                className="product-header__actions"
                size="lg"
                aria-label={t('product.actions')}
              >
                <Icon icon="ellipsis-vertical" size="lg" />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  setIsEditMode(true);
                  setModalOpen(true);
                }}
                icon={<Icon icon="pen" size="sm" />}
              >
                {t('global.edit')}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Flex>
      <ProductModal
        opened={isModalOpen}
        setOpened={setModalOpen}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        editProductData={productFormData}
      />
      <ManageLabelsModal
        key="product-header-labels-modal"
        putLabels={putProductLabels}
        entityType="product"
        labelIds={{
          [product._id?.toString() as string]:
            product.labelIds?.map((labelId) => labelId.toString()) || [],
        }}
        open={labelEditModalOpen}
        onClose={() => setLabelEditModalOpen(false)}
      />
    </Flex>
  );
};
