import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SharingPortalApi, UpdatePortalPayload } from '../../sharingPortal.api';
import { SHARING_PORTAL_QUERY_KEY } from '../useFetchSharingportal/useFetchSharingPortal';

export const usePostSharingPortal = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: UpdatePortalPayload): Promise<void> =>
      SharingPortalApi.updatePortal(payload),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SHARING_PORTAL_QUERY_KEY],
      });
    },
  });
};
