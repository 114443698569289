import {
  useURLFilterStringState,
  useURLandLocalStorageSortingState,
} from '../../../../../hooks/utils/usePersistentStates';
import { MRT_PaginationState } from 'mantine-react-table';
import { useCallback, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

export enum AssetsFilterSortParams {
  search = 'assets_search',
  sort = 'assets_sort',
  relationship = 'assets_relationship',
  page = 'assets_page',
  limit = 'assets_limit',
}

export enum AssetVendorFilter {
  all = 'all',
  firstParty = 'first',
  thirdParty = 'third',
}

export const useVulnAffectedAssetsFilters = () => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [assetVendorFilter, setAssetVendorFilter] = useURLFilterStringState(
    AssetsFilterSortParams.relationship,
    AssetVendorFilter.all,
    'replaceIn',
  );

  const [assetSearchFilter, setAssetSearchFilter] = useURLFilterStringState(
    AssetsFilterSortParams.search,
    '',
    'replaceIn',
  );

  const debouncedAssetSearchFilter = useRef(debounce(setAssetSearchFilter, 200));

  const handleSetAssetSearchFilter = useCallback((value: string) => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    debouncedAssetSearchFilter.current(value);
  }, []);

  const handleSetAssetVendorFilter = (value: string) => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setAssetVendorFilter(value);
  };

  const [sorting, setSorting] = useURLandLocalStorageSortingState(
    'impactAssets',
    [],
    'replaceIn',
  );

  return {
    assetVendorFilter,
    setAssetVendorFilter: handleSetAssetVendorFilter,
    assetSearchFilter,
    handleSetAssetSearchFilter,
    sorting,
    setSorting,
    pagination,
    setPagination,
  };
};
