import { DateTime } from 'luxon';
import Icon from '../../../components/Icon';
import styles from './ApiTokenDate.module.scss';

interface Props {
  date: string;
}

const ALMOST_EXPIRED_DAY_LIMIT = 30;

export const ApiTokenDate = (props: Props) => {
  const { date } = props;
  const curr = DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
  const startDate = DateTime.now();
  const endDate = DateTime.fromISO(date);
  const diff = endDate.diff(startDate);
  const daysDiff = Math.round(diff.as('days'));

  // negative diff means older than today so expired.
  const expired = daysDiff <= 0;
  if (expired)
    return (
      <div className={styles.expired}>
        <Icon icon="circle-exclamation" />
        {curr}
      </div>
    );

  const almostExpired = daysDiff <= ALMOST_EXPIRED_DAY_LIMIT;
  if (almostExpired)
    return (
      <div className={styles.almost_expired}>
        <Icon icon="triangle-exclamation" />
        {curr}
      </div>
    );

  return <div>{curr}</div>;
};
