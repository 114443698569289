import { useQuery } from '@tanstack/react-query';
import hitApi from '../../api';
import type { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';

export const useFetchTopVulns = () => {
  const filters = [{ field: 'assetActive', value: [true] }];
  const queryParams = new URLSearchParams({ filters: JSON.stringify(filters) });

  const fetchUrl = `vulnerabilities/top?${queryParams.toString()}`;

  return useQuery({
    queryKey: ['topVulns', `/${fetchUrl}`],
    queryFn: () => hitApi.get(fetchUrl),
    select: (data) => (data?.data ?? []) as InterfaceVulnerability[],
  });
};
